import {APP} from '../helpers/constants';

    export function setUser(payload) {
        return {
            type: APP.USER,
            payload
        }
    }

    export function setData(payload) {
        return {
            type: APP.DATA,
            payload
        }
    }

    export function setSettings(payload) {
        return {
            type: APP.SETTINGS,
            payload
        }
    }

    export function setActiveTab(payload) {
        return {
            type: APP.ACTIVE_TAB,
            payload
        }
    }
    
    export function setLocal(payload) {
        return {
            type: APP.LOCAL,
            payload
        }
    }
    
    export function setTheme(payload) {
        return {
            type: APP.THEME,
            payload
        }
    }
    
    export function setDashExpandedDevice(payload) {
        return {
            type: APP.DASH_EXPANDED_DEVICE,
            payload
        }
    }
    
    export function setShowModal(payload) {
        return {
            type: APP.SHOW_DEFAULT_MODAL,
            payload
        }
    }
    
    export function setShowSecondModal(payload) {
        return {
            type: APP.SHOW_SECOND_MODAL,
            payload
        }
    }
    
    export function setDashFilterModel(payload) {
        return {
            type: APP.DASH_FILTER_MODEL,
            payload
        }
    }
    
    export function setDashFilterRoom(payload) {
        return {
            type: APP.DASH_FILTER_ROOM,
            payload
        }
    }
    
    export function setAppWsIsOk(payload) {
        return {
            type: APP.APP_WS_IS_OK,
            payload
        }
    }