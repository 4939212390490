import './viewCameras.css';
import { Component } from 'react';

import { Samy } from 'react-samy-svg';
import { userGetCamerasDevices } from '../../helpers/funcs';

import { deviceColors } from '../../helpers/constants';
import webcamButton from '../../images/devices/webcamButton.svg';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewCameras extends Component {
    constructor(props){
        super(props);

        this.open = this.open.bind(this);
        this.preview = this.preview.bind(this);
        this.close = props.close;

        let user = this.props.appState.user;
        let cameras = userGetCamerasDevices(user);
        console.log(cameras);
    }

    open(camera){
        //console.log(camera);
        if (!camera.info || !camera.info.localIp) return;

        window.open(`http://${camera.info.localIp}/web/index.html`, '_blank').focus();
        //window.open(`http://${camera.info.localIp}:8085/123-0.jpg`, '_blank').focus();
        //window.open(`http://${camera.info.localIp}/web/Preview.html`, '_blank').focus(); ///web/index.html
    }

    preview(camera){
        if (!camera.info || !camera.info.localIp) return;

        window.open(`http://${camera.info.localIp}:8085/123-0.jpg`, '_blank').focus();
    }

    render() {
        let local = this.props.appState.local;
        let user = this.props.appState.user;
        let isDark = this.props.appState.isDarkTheme;
        let cameras = userGetCamerasDevices(user);

        let iconStyle = {
            strokeWidth: 2, 
            strokeMiterlimit: 0,
            stroke: isDark
                ? deviceColors.borderGreyDark
                : deviceColors.borderGreyLight
        };
        iconStyle.fill = iconStyle.stroke;
        
        return (
            <div className="ViewCameras">
                <p>
                    {local.CameraPreviewsTitle}
                </p>
                <div className='ViewCameras__Cameras'>
                    {
                        cameras.map((c, i) =>{
                            return <div 
                                className='ViewCameras__Camera' key={`camera${i}${c.guid}`}>
                                <div className="ViewCameras__Camera__MainImage">
                                    <Samy path={webcamButton} style={iconStyle} />
                                </div>
                                <p>{c.settings.name}</p>
                                <button 
                                    style={{height:"45px"}}
                                    onClick={(e) => this.open(c)}>
                                    {local.CameraOpenTitle}
                                </button>
                                <button 
                                    style={{height:"45px"}}
                                    onClick={(e) => this.preview(c)}>
                                    {'Live preview'}
                                </button>
                                {/* <iframe src={`http://${c.info.localIp}:8085/123-0.jpg`}
                                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                                    title={c.settings.name}
                                    id={`http://${c.info.localIp}:8085/123-0.jpg`}
                                    height={"100%"}
                                    width={"100%"}
                                    display="initial"
                                    position="relative" /> */}
                            </div>;
                        })
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewCameras);