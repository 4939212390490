import './viewEditShare.css';
import { Component } from 'react';

import { openNotificationWithIcon } from '../../../helpers/funcs';
import { updateUserRelationsShared, getUser
    } from '../../../helpers/helperApi';
import { ApiActions } from '../../../helpers/constants';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewEditShare extends Component {
    constructor(props){
        super(props);

        this.save = this.save.bind(this);
        this.close = props.close;

        var device = this.props.device;
        var friends = this.props.appState.user.relations.friends;
        var shared = this.props.appState.user.relations.shared[device.guid];
        if (shared === undefined || shared === null){
            shared = [];
        }

        var checked = {};
        for(var i in friends){
            var f = friends[i];
            checked[f] = shared.includes(f);
        }
        
        this.state = {
            changed: false,
            checked
        };
    }

    async save(){
        var local = this.props.appState.local;
        var device = this.props.device;

        var shared = [];
        for(var prop in this.state.checked){
            if (this.state.checked[prop] === true){
                shared.push(prop);
            }
        }
        const relations = this.props.appState.user.relations;
        let thisDeviceShared = relations.shared[device.guid]
        if (thisDeviceShared === undefined) thisDeviceShared = [];
        let result = false;
        for(let s of shared){
            if (thisDeviceShared.includes(s) === false){
                result = await updateUserRelationsShared(
                    {login: s, guid: device.guid}, ApiActions.Post);
            }
        }
        for(let s of thisDeviceShared){
            if (shared.includes(s) === false){
                result = await updateUserRelationsShared(
                    {login: s, guid: device.guid}, ApiActions.Delete);
            }
        }
        if (result === true){
            const newUser = await getUser();
            this.props.appActions.setUser({user: newUser});
            openNotificationWithIcon("info", local.Save, local.Success);
            this.close();
        } else {
            openNotificationWithIcon("info", local.Save, local.Fail);
        }
    }

    render() {
        var local = this.props.appState.local;
        var friends = this.props.appState.user.relations.friends;
        
        return (
            <div className="ViewEditShare">
                <p>
                    {local.RelationsSharedTitle}
                </p>
                <div className='ViewEditShare__Friends'>
                    {
                        friends.map((el, i)=>{
                            return <div key={'ViewEditShare__Friend'+el} 
                                className="ViewEditShare__Friend checkbox">
                                <input type="checkbox"
                                    id={`viewEditShare-${el}`}
                                    name={`viewEditShare-${el}`}
                                    value={this.state.checked[el]}
                                    className="custom-checkbox"
                                    defaultChecked={this.state.checked[el]} 
                                    onChange={(e)=> {
                                        const checked = this.state.checked;
                                        checked[el] = !checked[el];
                                        this.setState({changed: true, checked: checked});
                                    }} 
                                    />
                                <label htmlFor={`viewEditShare-${el}`}>
                                    {el}
                                </label>
                            </div>;
                        })
                    }
                </div>
                <button 
                    disabled={this.state.changed === false}
                    onClick={this.save}>
                    {local.Save}
                </button>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewEditShare);