import './viewNewObject.css';
import { Component } from 'react';

import { ModalActions, NotificationTypeEnum,
    LimitType, 
    deviceColors} from "../../helpers/constants";
import { defTimer, userGetHubs, 
    checkLimitByType,
    openNotificationWithIcon } from "../../helpers/funcs";

import { v4 as uuidv4 } from 'uuid';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewNewObject extends Component {

    constructor(props){
        super(props);

        this.click = this.click.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
        this.state = {
            hovered: null
        }
    }

    click(e, b){
        if (e) e.stopPropagation();
        let local = this.props.appState.local;
        let user = this.props.appState.user;
        this.props.hidePopover();

        if (b === local.ButtonTitleNew){
            let limitMsg = checkLimitByType(user, local, LimitType.ButtonsCount);
            if (limitMsg === null){
                this.props.appActions.setShowModal({
                    modalAction: ModalActions.EditObject,
                    objectModal: { 
                        model: 'ScenarioDevice.ButtonSensor',
                        Settings: {
                            Name: '',
                            ButtonType: 0
                        }
                    }
                });
            } else {
                openNotificationWithIcon("info", local.AppName, limitMsg);
            }
        }

        if (b === local.TimerTitleNew){
            this.props.appActions.setShowModal({
                modalAction: ModalActions.EditObject,
                objectModal: { 
                    model: 'TimerDevice.TimerSensor',
                    locationGuid: null,
                    offset: 0,
                    Settings: {
                        Name: ''
                    }
                }
            });
        }

        if (b === local.LocationNewTitle){
            this.props.appActions.setShowModal({
                modalAction: ModalActions.NewRoom,
                objectModal: { 
                    guid: uuidv4(),
                    favourite: false,
                    name: '',
                    description: '',
                    locationType: 0,
                    devices: [],
                    realDevices: []
                }
            });
        }

        if (b === local.GateNewTitle){
            this.props.appActions.setShowModal({
                modalAction: ModalActions.NewGate,
                objectModal: null
            });
        }

        if (b === local.DeviceNewTitle){
            this.props.appActions.setShowModal({
                modalAction: ModalActions.NewDevice,
                objectModal: null
            });
        }

        if (b === local.PlaceNew){
            this.props.appActions.setShowModal({
                modalAction: ModalActions.EditPlace,
                objectModal: {
                    color: '#FF0000',
                    geoPoint:{
                        latitude: this.props.appState.defPoint.latitude,
                        longitude: this.props.appState.defPoint.longitude
                    },
                    label: ''
                }
            });
        }

        if (b === local.ScenarioTitleNew){
            let limitMsg = checkLimitByType(user, local, LimitType.ScenariosCount);
            if (limitMsg === null){
                this.props.appActions.setShowModal({
                    modalAction: ModalActions.NewScenario,
                    objectModal: {
                        guid: uuidv4(),
                        sendPush: true,
                        enabled: true,
                        showOnDashBoard: false,
                        notificationType: NotificationTypeEnum.Regular,
                        useAsShield: false,
                        actors: [],
                        conditions: [],
                        timeFilter: defTimer(
                            this.props.appState.defCron,
                            this.props.appState.localTZid)
                    }
                });
            } else {
                openNotificationWithIcon("info", local.AppName, limitMsg);
            }
        }
    }
    
    onMouseOver(i) {
        this.setState({ hovered: i });
    }

    onMouseOut() {
        this.setState({ hovered: null });
    }

    render() {
        var user = this.props.appState.user;
        var local = this.props.appState.local;
        var isDark = this.props.appState.isDarkTheme;

        var popupButtons = [
            local.ButtonTitleNew,
            local.TimerTitleNew, 
            local.LocationNewTitle, 
            local.ScenarioTitleNew,
            //local.CamerasNewTitle,
            local.GateNewTitle
        ];

        const hubs = userGetHubs(user);
        if (hubs.length > 0){
            popupButtons.push(local.DeviceNewTitle);
        }

        popupButtons.push(local.PlaceNew);

        return (
            <div className="ViewNewObject">
                {
                    popupButtons.map((b, i)=>{
                        return <button key={"newObject"+b}
                            onMouseOver={this.onMouseOver.bind(this, i)}
                            onMouseOut={this.onMouseOut}
                            style={{
                                color: this.state.hovered === i ? deviceColors.borderBlue : isDark ? "#B8B8B8" : "white",
                                backgroundColor: '#6B6965',
                                borderColor: '#6B6965',
                                margin: "10px 0px", 
                                width: "100%", 
                                height:"45px",
                                cursor: 'pointer'
                            }}
                            onClick={(e) => this.click(e, b)}>
                            {b}
                        </button>;
                    })
                }
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewNewObject);