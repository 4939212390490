import './viewLogin.css';
import './langs.css';
import { Component } from 'react';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Samy } from 'react-samy-svg';
import { deviceColors, Credentials } from '../../helpers/constants';
import { updateToken, getUser, 
    userPasswordRecover, 
    checkUserExists, userRegistration } from '../../helpers/helperApi';
import { openNotificationWithIcon } from '../../helpers/funcs';
import { ApiActions } from '../../helpers/constants';

import Cookies from 'js-cookie';
import ViewLoading from '../viewLoading/viewLoading';
import showAbout from '../viewModal/modalAbout';
import ViewFogotPassword from '../viewFogotPassword/viewFogotPassword';
import ViewRegistration from '../viewRegistration/viewRegistration';

import background from '../../images/other/background_alt.png';
// import yard from '../../images/other/yard.jpeg';
// import kitchen from '../../images/other/kitchen.jpeg';
import info from '../../images/other/info.png';
import logo from '../../images/other/vku_logo.svg';
import GooglePlay from '../../images/other/GooglePlay.png';
import AppStore from '../../images/other/AppStore.png';

const languages = [
    { code: 'AppLanguageEn', name: 'English'},
    { code: 'AppLanguageRu', name: 'Русский'},
    //{ code: 'AppLanguageDeu', name: 'Русский'},
]

class ViewLogin extends Component {
    constructor(props){
        super(props);

        this.demo = this.demo.bind(this);
        this.login = this.login.bind(this);
        this.onLangChange = this.onLangChange.bind(this);
        this.loadUserData = this.loadUserData.bind(this);
        this.checkAutoEnter = this.checkAutoEnter.bind(this);
        this.registration = this.registration.bind(this);
        this.recoverPassword = this.recoverPassword.bind(this);

        var lang = this.props.appState.local.weatherLocale === 'ru' 
            ? languages[1] : languages[0];

        this.count = 0;
        this.state = {
            loginStatus: 0, // 0 - default, 1 - fogotPassword, 2 - registration
            login: "",
            password: "",
            autoComplete: 'on',
            loading: false,
            changed: false,
            autoEnter: Cookies.get('autoEnter') === "true",
            lang: lang.code,
            langName: lang.name,
        }
    }

    async login(event) {
        if (event) event.preventDefault();
        var owner = this;
        var local = this.props.appState.local;

        if (!this.state.login || !this.state.password){
            openNotificationWithIcon("info", 
                local.AuthMessageTitle, local.AuthMessageNoData);
            return;
        }
        this.setState({loading: true});

        Credentials.autoEnterPassed = true;
        Credentials.login = this.state.login;
        Credentials.password = this.state.password;

        var token = await updateToken();
        this.setState({loading: false});

        if (token !== null){
            Cookies.set('autoEnter', owner.state.autoEnter);
            await owner.loadUserData();
        }
    }

    async loadUserData(){
        var owner = this;

        let user = await getUser();
        if (user !== null){
            owner.setState({loading: false});

            let appHome = Cookies.get('AppHome') ?? 'AppHomeRooms';
            if (appHome === 'AppHomeRooms')
                this.props.appActions.setActiveTab({activeTab: 2})
            if (appHome === 'AppHomeDash')
                this.props.appActions.setActiveTab({activeTab: 1})

            owner.props.appActions.setUser({
                user: user
            })
        }
    }

    componentDidMount(){
        //setTimeout(this.checkAutoEnter, 1500);
    }

    checkAutoEnter(){
        if (Credentials.autoEnterPassed === true || this.state.autoEnter === false){
            return;
        } else {
            var email = document.getElementById('LoginAppTitle__email');
            var pass = document.getElementById('LoginAppTitle__password');
            email.focus();
            email.blur();
            pass.focus();
            pass.blur();

            if (email.value === '' && pass.value === ''){
                this.count++;
                if (this.count < 3){
                    setTimeout(this.checkAutoEnter, 500);
                }
            } else {
                this.login(null);
            }
        }
    }

    demo(e){
        this.state.login = 'demo@vkonnektu.com';
        this.state.password = 'demo';
        this.login(e);
    }

    onLangChange(){
        if (this.state.lang === languages[0].code){
            this.props.appActions.setLocal({local: 'ruRu'});
            this.setState({lang: languages[1].code, langName: languages[1].name});
        } else {
            this.props.appActions.setLocal({local: 'enEn'});
            this.setState({lang: languages[0].code, langName: languages[0].name});
        }
    }

    async registration() {
        let owner = this;
        let local = this.props.appState.local;

        if (!this.state.login || !this.state.password){
            openNotificationWithIcon("info", 
                local.AuthMessageTitle, local.RegistrationLoginIncorrect + ". " + local.RegistrationPasswordEmpty);
            return;
        }
        let result = await checkUserExists(this.state.login, ApiActions.Get);
        if (result === true){
            openNotificationWithIcon('error', local.appTitle, local.RegistrationLoginExists);
            return;
        }

        result = await userRegistration(this.state.login, this.state.password, ApiActions.Post);
        if (result === true){
            openNotificationWithIcon('info', local.appTitle, local.RegistrationWaitConfirm);
        } else {
            openNotificationWithIcon('error', local.appTitle, local.RegistrationError);
        }
        
        Credentials.login = this.state.login;
        Credentials.password = this.state.password;
        Cookies.set('autoEnter', true);
        owner.props.close();
    }

    async recoverPassword() {
        let owner = this;
        let local = this.props.appState.local;

        if (!this.state.login){
            openNotificationWithIcon("info", 
                local.AuthMessageTitle, local.RegistrationLoginIncorrect);
            return;
        }
        let result = await checkUserExists(this.state.login, ApiActions.Get);
        if (result === false){
            openNotificationWithIcon('error', local.appTitle, local.LoginDoestExists);
            return;
        }

        result = await userPasswordRecover(this.state.login, ApiActions.Get);
        if (result === true){
            openNotificationWithIcon('info', local.RecoverPasswordTitle, local.RecoverPasswordOK);
        } else {
            openNotificationWithIcon('error', local.RecoverPasswordTitle, local.RecoverPasswordFail);
        }
        owner.props.close();
    }
    
    render() {
        var local = this.props.appState.local;
        var isDark = this.props.appState.isDarkTheme;
        //var borderColor = isDarkTheme ? deviceColors.borderGreyDark : deviceColors.borderGreyLight;

        let backImage = background;
        // if (this.state.loginStatus === 1) backImage = kitchen;
        // if (this.state.loginStatus === 2) backImage = yard;

        // const options = languages.map(language => {
        //     if(language.code != this.state.lang){
        //         return <li onClick={this.onLangChange}>
        //             <div value={language.code} className={language.code} />
        //         </li>
        //     }
        // });

        return (
            <div className="ViewLogin" style={{backgroundImage: `url(${backImage})`}}
                >
                <a href='https://vkonnektu.com' target="_blank">
                    <div className='ViewLogin__logo'>
                        <Samy path={logo} />
                    </div>
                </a>
                <div className='ViewLogin__powered'>
                    {'POWERED BY AUTOSTUDIO 2022'}
                </div>
                <div className='ViewLogin__stores'>
                    <a href='https://apps.apple.com/ru/app/vku-умный-дом-от-vkonnektu/id1517314002' target="_blank">
                        <img src={AppStore} alt='' />
                    </a>
                    <a href='https://play.google.com/store/apps/details?id=com.vkonnektu.droid&hl=ru&gl=US' target="_blank">
                        <img src={GooglePlay} alt='' />
                    </a>
                </div>
                <div className="Credentials">
                    <div className='Credentials__UpButtons'>
                        <button className='Credentials__UpButtons__Button'
                            style={{
                                borderLeft: '0px',
                                borderTop: '0px',
                                borderRight: '0px', 
                                borderBottom: this.state.loginStatus === 0 ? '1px solid #FFF' : '0px'}}
                            onClick={() => this.setState({loginStatus: 0})}>
                            {local.ViewLogin__UpButtons__Enter}
                        </button>
                        <button className='Credentials__UpButtons__Button'
                            style={{
                                borderLeft: '0px',
                                borderTop: '0px',
                                borderRight: '0px', 
                                borderBottom: this.state.loginStatus === 2 ? '1px solid #FFF' : '0px'}}
                            onClick={() => this.setState({loginStatus: 2})}>
                            {local.ViewLogin__UpButtons__Registration}
                        </button>
                        {/* <button className='Credentials__UpButtons__Button'
                            style={{
                                borderLeft: '0px',
                                borderTop: '0px',
                                borderRight: '0px', 
                                borderBottom: this.state.loginStatus === 1 ? '1px solid #FFF' : '0px'}}
                            onClick={() => this.setState({loginStatus: 1})}>
                            {local.RecoverPasswordTitle}
                        </button> */}
                    </div>
                    {
                        this.state.loginStatus !== 0 ? null :
                        <form className='Credentials__form' autoComplete={this.state.autoComplete}>
                            <input
                                id='LoginAppTitle__email'
                                autoComplete="email"
                                value={this.state.login}
                                onChange={(e) => 
                                    this.setState({login: e.target.value, changed: true})}
                                style={{margin: "10px 0px", height:"45px"}}
                                placeholder={local.AuthLogin}
                                type="email"
                            />
                            <input
                                id='LoginAppTitle__password'
                                autoComplete="current-password"
                                value={this.state.password}
                                onChange={(e) => 
                                    this.setState({password: e.target.value, changed: true})}
                                style={{margin: "10px 0px", height:"45px"}}
                                placeholder={local.AuthPassword}
                                type="password"
                            />
                            <div className='Credentials__form__AuthForgotPasswordLink'
                                onClick={(e) => this.setState({loginStatus: 1})}>
                                {local.AuthForgotPasswordLink}
                            </div>
                            <div className='Credentials__form__DownButtons'>
                                <button className='Credentials__form__Demo'
                                    type='button'
                                    onClick={this.demo}>
                                    {local.AuthDemo}
                                </button>
                                <button className='Credentials__form__AuthEnter'
                                    type="submit"
                                    onClick={this.login}>
                                    {local.AuthEnter}
                                </button>
                            </div>
                            <div className='ViewLogin__langAndAbout'>
                                <div className="ViewLogin__lang"
                                    onClick={this.onLangChange}> 
                                    <div className={this.state.lang} />
                                    {/* <ul class="ViewLogin__lang__dropdown">
                                        {options}
                                    </ul> */}
                                    <p>{this.state.langName}</p>
                                </div>
                                <div className='ViewLogin__about'
                                    onClick={async () => await showAbout(local, isDark)}>
                                    <img src={info} alt='' />
                                    <p>{local.ViewLogin__About__Title}</p>
                                </div>
                            </div>
                        </form>
                    }
                    {
                        this.state.loginStatus !== 1 ? null :
                        <div className='Credentials__form'>
                            <input
                                autoComplete="email"
                                value={this.state.login}
                                onChange={(e)=> this.setState({login: e.target.value, changed: true})}
                                style={{margin: "10px 0px", height:"45px"}}
                                placeholder={local.AuthLogin}
                                type="email"
                            />
                            <div className='Credentials__buttons'>
                                <button className='Credentials__form__AuthRecoverAndReg'
                                    onClick={this.recoverPassword}>
                                    {local.RecoverPasswordTitle}
                                </button>
                                <div>
                                    <div className="ViewLogin__lang"
                                        onClick={this.onLangChange}> 
                                        <div className={this.state.lang} />
                                        {/* <ul class="ViewLogin__lang__dropdown">
                                            {options}
                                        </ul> */}
                                        <p>{this.state.langName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {
                        this.state.loginStatus !== 2 ? null :
                        <div className='Credentials__form'>
                            <input
                                autoComplete='off'
                                value={this.state.login}
                                onChange={(e)=> this.setState({login: e.target.value, changed: true})}
                                style={{margin: "10px 0px", height:"45px"}}
                                placeholder={local.AuthLogin}
                                type="email"
                            />
                            <input
                                autoComplete='off'
                                value={this.state.password}
                                onChange={(e)=> this.setState({password: e.target.value, changed: true})}
                                style={{margin: "10px 0px", height:"45px"}}
                                placeholder={local.AuthPassword}
                                type="password"
                            />
                            <div className='Credentials__buttons'>
                                <button className='Credentials__form__AuthRecoverAndReg'
                                    onClick={this.registration}>
                                    {local.AuthReg}
                                </button>
                                <div>
                                    <div className="ViewLogin__lang"
                                        onClick={this.onLangChange}> 
                                        <div className={this.state.lang} />
                                        <p>{this.state.langName}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <ViewLoading enabled={this.state.loading}/>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewLogin);