import './viewRoomNew.css';
import { Component } from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import { ApiActions } from '../../helpers/constants';
import { LocationTypes, roomStandartImage } from '../../helpers/helperRoom';
import { getDevicesWithSensors, 
    deviceFullName, imageDecode,
    openNotificationWithIcon } from '../../helpers/funcs';
import { updateRoom, getCustomImage, getUser } from '../../helpers/helperApi';

import { v4 as uuidv4 } from 'uuid';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewRoomNew extends Component {
    constructor(props){
        super(props);

        this.save = this.save.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleDescr = this.handleDescr.bind(this);
        this.loadNewImage = this.loadNewImage.bind(this);
        this.close = props.close;

        var room = this.props.room;
        var user = this.props.appState.user;
        var devicesWithSensors = getDevicesWithSensors(user);
        var devices = [];
        for(var i in devicesWithSensors){
            devices.push({
                selected: false,
                device: devicesWithSensors[i]
            });
        }
        
        this.state = {
            customRoomRaw: null,
            customRoom: null,
            guid: room.guid,
            selectedindex: 0,
            name: room.name,
            descr: room.description,
            favourite: room.favourite,
            devices
        };
    }

    async save(){
        let user = this.props.appState.user;
        let local = this.props.appState.local;
        let room = this.props.room;
        room.devices = [];

        for(var i in this.state.devices){
            var d = this.state.devices[i];
            if (d.selected === true){
                room.devices.push(d.device.guid);
            }
        }
        room.guid = this.state.guid;
        room.name = this.state.name;
        room.description = this.state.descr;
        room.favourite = this.state.favourite;
        room.locationType = this.state.selectedindex;
        
        let customImageId = uuidv4();

        if (this.state.selectedindex === LocationTypes.Custom){
            if (this.state.customRoomRaw === null){
                openNotificationWithIcon("info", local.Update, local.Fail);
                return;
            }
            let decodedBytes = imageDecode(this.state.customRoomRaw);
            let customImage = { id: customImageId, data:  decodedBytes };
            let imResult = await getCustomImage(user, customImage, ApiActions.Post);
            if (imResult === false){
                openNotificationWithIcon("info", local.Update, local.Fail);
                return;
            }
            room.customImage = customImageId;
        }
        var result = await updateRoom(user, room, ApiActions.Post);
        if (result === true){
            let newUser = await getUser();
            this.props.appActions.setUser({ user: newUser });
            this.close();
            openNotificationWithIcon("info", local.Update, local.Success);
        } else {
            openNotificationWithIcon("info", local.Update, local.Fail);
        }
    }

    handleName(event){
        this.setState({
            name: event.target.value,
            changed: true
        });
    }

    handleDescr(event){
        this.setState({
            descr: event.target.value,
            changed: true
        });
    }

    loadNewImage(file){
        let owner = this;
        var reader = new FileReader();
        
        reader.onload = async function(e) {
            //let decodedBytes = imageDecode(reader.result);
            owner.setState({
                customRoomRaw: reader.result,
                customRoom: "data:image/jpeg;base64,"+window.btoa(reader.result)
            });
        };
        reader.readAsBinaryString(file);
    }

    render() {
        var local = this.props.appState.local;
        var isDark = this.props.appState.isDarkTheme;

        var allRooms = [];
        for(var prop in LocationTypes){
            var img = roomStandartImage(LocationTypes[prop]);
            if (img !== null){
                allRooms.push(img);
            }
        }
        
        return (
            <div className="ViewRoomNew">
                <p>
                    {local.NewRoom}
                </p>
                <div className="ViewRoomNew__Rooms">
                    <Carousel 
                        infiniteLoop={true}
                        showStatus={false}
                        showThumbs={false}
                        onChange={(i)=>this.setState({selectedindex: i})}>
                        {
                            allRooms.map((el, i) =>{
                                if (i === LocationTypes.Custom){
                                    return <div key={`roomImg${i}`} 
                                        className='ViewRoomNew__Rooms__CustomImage'>
                                        <img key={`roomImg${i}`} src={this.state.customRoom ?? el} alt=''/>
                                        <div className='ViewRoomNew__Rooms__CustomImage__File__Label'
                                            style={{
                                                borderColor: isDark ? '#B8B8B8' : '#6B6965',
                                                backgroundColor: isDark ? '#282828' : '#FFF',
                                                color: isDark ? '#B8B8B8' : '#6B6965'
                                            }}>
                                            <input style={{display: 'none'}}
                                                onChange={(e) => this.loadNewImage(e.target.files[0])}
                                                type="file"
                                                id="SettingsAccount__File"
                                                name="file"
                                                accept='.png, .jpg, .jpeg' />
                                            <label htmlFor='SettingsAccount__File' >
                                                {local.LocationNewSelectPhoto}
                                            </label>
                                        </div>
                                    </div>;
                                } else
                                    return <img key={`roomImg${i}`} src={el} alt=''/>
                            })
                        }
                    </Carousel>
                </div>
                <input className="ViewRoomNew__Name"
                    value={this.state.name}
                    onChange={this.handleName}
                    style={{margin: "10px 0px", height:"45px" }}
                    placeholder={local.LocationName}/>
                <input className="ViewRoomNew__Descr"
                    value={this.state.descr}
                    onChange={this.handleDescr}
                    style={{margin: "10px 0px", height:"45px" }}
                    placeholder={local.LocationDescription}/>
                <div className="checkbox">
                    <input type="checkbox"
                            id={"ViewRoomNew-fav"}
                            name={"ViewRoomNew-fav"}
                            value={this.state.favourite}
                            className="custom-checkbox"
                            defaultChecked={this.state.favourite} 
                            onChange={(e)=> this.setState({favourite: !this.state.favourite, changed: true})}
                        />
                    <label htmlFor={"ViewRoomNew-fav"}>
                        {local.LocationFavourite}
                    </label>
                </div>
                <div className="ViewRoomNew__Devices">
                    {
                        this.state.devices.map((el, i) =>{
                            return <div className="ViewRoomNew__Sensor checkbox"
                                        key={`ViewRoomNew__Sensor${i}`}>
                                <input type="checkbox"
                                    id={`ViewRoomNew__Sensor-${el.device.guid}`}
                                    name={`ViewRoomNew__Sensor-${el}`}
                                    value={el.selected}
                                    className="custom-checkbox"
                                    defaultChecked={el.selected} 
                                    onChange={(e)=> {
                                        el.selected = !el.selected;
                                        this.forceUpdate();
                                    }}
                                />
                                <label htmlFor={`ViewRoomNew__Sensor-${el.device.guid}`}>
                                    {deviceFullName(el.device)}
                                </label>
                            </div>;
                        })
                    }
                </div>
                <button
                    onClick={this.save}>
                    {local.Save}
                </button>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewRoomNew);