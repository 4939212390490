import './viewMenu.css';
import { Component } from 'react';

import MenuItem from './menuItem';

import icon from '../../images/other/icon192.png';
import { Tooltip } from 'antd';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const openGeocoder = require('node-open-geocoder');
const ct = require('countries-and-timezones');
var weather = require('openweather-apis');

class ViewMenu extends Component {
    constructor(props) {
        super(props);

        this.getWeather = this.getWeather.bind(this);
        this.getLocation = this.getLocation.bind(this);

        this.mouseOutTimer = undefined;
        this.mouseOverTimer = undefined;

        let allTimeZones = ct.getAllTimezones();
        let localOffset = new Date().getTimezoneOffset() * (-1);
        for (var tz in allTimeZones) {
            var t = allTimeZones[tz];
            if (t.utcOffset === localOffset) {
                this.props.appState.localTZid = t.name;
            }
        }

        this.state = {
            location: "",
            city: "",
            hum: "",
            temp: "",
            pres: "",
            count: null
        }
        //setTimeout(this.getLocation, 10);
    }

    getLocation() {
        var owner = this;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(p => {
                owner.props.appState.defPoint.latitude = p.coords.latitude;
                owner.props.appState.defPoint.longitude = p.coords.longitude;

                setTimeout(this.getWeather(p.coords), 10);

                openGeocoder()
                    .reverse(p.coords.longitude, p.coords.latitude)
                    .end((err, res) => {
                        if (err === null) {
                            if (res.address !== undefined && res.address.city !== undefined) {
                                this.setState({ city: res.address.city });
                            } else {
                                if (res.address !== undefined && res.address.state !== undefined) {
                                    this.setState({ city: res.address.state });
                                }
                            }
                        }
                    });
            }
            );
        } else {
            this.setState({ location: "Geolocation is not supported by this browser." });
        }
    }

    getWeather(coords) {
        let owner = this;
        let local = this.props.appState.local;
        weather.setAPPID("1e060027a80410562dc6b68e759ddef8");
        weather.setLang(local.weatherLocale);
        weather.setCoordinate(coords.latitude, coords.longitude);

        weather.getTemperature(function (err, temp) {
            //if (console && err) console.log(err);
            if (!err)
                owner.setState({ temp: temp });
        });
        weather.getHumidity(function (err, hum) {
            //if (console && err) console.log(err);
            if (!err)
                owner.setState({ hum: hum });
        });
        weather.getPressure(function (err, pres) {
            //if (console && err) console.log(err);
            if (!err)
                owner.setState({ pres: 0.75006 * pres });
        });

    }

    onMouseMenuOverOut(over) {
        let setCollapsed = this.props.setCollapsed;

        if (this.mouseOutTimer) {
            clearTimeout(this.mouseOutTimer);
        }
        if (this.mouseOverTimer) {
            clearTimeout(this.mouseOverTimer);
        }

        if (over === true) {
            this.mouseOverTimer = setTimeout(() => {
                setCollapsed(false);
                clearTimeout(this.mouseOverTimer);
            }, 500);
        } else {
            this.mouseOutTimer = setTimeout(() => {
                setCollapsed(true);
                clearTimeout(this.mouseOutTimer);
            }, 500);
        }
    }

    render() {
        let local = this.props.appState.local;
        
        let titleStyle = {
            borderTop: '1px solid rgba(0,0,0,.2)',
            padding: '10px 5px 5px 5px', 
            textAlign: 'center',
            fontSize: 'medium',
            color: '#2C98D2'
        }

        let opacity = {
            visibility: this.props.collapsed ? 'false' : 'true',
            opacity: this.props.collapsed ? 0 : 1
        }

        return (
            <div className="ViewMenu"
                style={{
                    width: `var(${this.props.collapsed
                        ? '--menu-width-collapsed'
                        : '--menu-width-opened'})`
                }}
                // onMouseOver={this.onMouseMenuOverOut.bind(this, true)}
                // onMouseOut={this.onMouseMenuOverOut.bind(this, false)}
                >
                <Tooltip placement="right" title={'VKU'} mouseEnterDelay={0}>
                    <a href='https://vkonnektu.com' target="_blank">
                        <div className="viewMenu__item">
                            <div className="viewMenu__item__content">
                                <img src={icon} alt="" />
                            </div>
                        </div>
                    </a>
                    {/* <div className="viewMenu__item">
                        <div className="viewMenu__item__content">
                            <img src={icon} alt="" />
                            {
                                this.props.collapsed === true ? null :
                                <a href='https://vkonnektu.com' target="_blank"
                                    style={{margin: 'auto 5px', fontSize: 'medium'}}>
                                    <p>VKU</p>
                                </a>
                            }
                        </div>
                    </div> */}
                </Tooltip>
                <MenuItem activeTab={1} collapsed={this.props.collapsed} />
                <MenuItem activeTab={2} collapsed={this.props.collapsed} />
                <MenuItem activeTab={4} collapsed={this.props.collapsed} />
                <MenuItem activeTab={6} collapsed={this.props.collapsed} />
                
                <MenuItem activeTab={3} collapsed={this.props.collapsed} />
                <div className='ViewMenu__additional'>
                    <p style={{...titleStyle, ...opacity}}>{this.state.city}</p>
                    {/* <p style={{...titleStyle, ...opacity}}>{local.MenuInformationWeather}</p> */}
                    <p style={opacity}>{`${local.SensorTypeNameTemperature} ${Math.round(this.state.temp * 10)/10}°`}</p>
                    <p style={opacity}>{`${local.SensorTypeNameHumidity} ${this.state.hum}%`}</p>
                    <p style={opacity}>{`${local.SensorTypeNamePressure} ${Math.round(this.state.pres)} ${local.SensorPressure}`}</p>
                </div>
                <MenuItem activeTab={5} collapsed={this.props.collapsed} />
                <MenuItem activeTab={99} collapsed={this.props.collapsed} />
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewMenu);