import './viewEditPlace.css';
import { Component } from 'react';

import { openNotificationWithIcon, deleteByGuid } from '../../../helpers/funcs';

import { googleMapApiKey, ApiActions } from '../../../helpers/constants'; 
import GoogleMapReact from 'google-map-react';
import pin_red from '../../../images/other/pin_red.png';
import { getUser, updatePlace } from '../../../helpers/helperApi';
import confirm from '../../viewModal/modalConfirm';

import { v4 as uuidv4 } from 'uuid';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const openGeocoder = require('node-open-geocoder');

class ViewEditPlace extends Component {
    constructor(props){
        super(props);

        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.handleName = this.handleName.bind(this);
        this.onBoundsChange = this.onBoundsChange.bind(this);
        this.close = props.close;

        var place = this.props.place;
        
        this.state = {
            guid: place.guid,
            changed: false,
            name: place.label,
            point: place.geoPoint,
            adress: ''
        };
    }

    async save(){
        var local = this.props.appState.local;
        var user = this.props.appState.user;
        var place = this.props.place;

        place.guid = this.state.guid;
        place.label = this.state.name;
        place.geoPoint = this.state.point;

        if (place.guid === undefined){
            place.guid = uuidv4();
            let result = await updatePlace(user, place, ApiActions.Post);
            if (result === true){
                let newUser = await getUser();
                this.props.appActions.setUser({ user: newUser });
                openNotificationWithIcon("info", local.Save, local.Success);
                this.close();
            } else {
                // TODO if error what??
            }
        } else {
            let result = await updatePlace(user, place, ApiActions.Put);
            if (result === true){
                let newUser = await getUser();
                this.props.appActions.setUser({ user: newUser });
                openNotificationWithIcon("info", local.Update, local.Success);
                this.close();
            } else {
                // TODO if error what??
            }
        }
    }

    async delete(){
        var local = this.props.appState.local;
        var user = this.props.appState.user;
        var place = this.props.place;
        var isDarkTheme = this.props.appState.isDarkTheme;
        
        if (await confirm(local.appTitle, local.PlaceDeleteAsk, local.Yes, local.Cancel, isDarkTheme) === false){
            return;
        }

        var result = await updatePlace(user, place, ApiActions.Delete);
        if (result === true){
            deleteByGuid(user.locations, place);
            this.props.appActions.setUser({ user: user });
            openNotificationWithIcon("info", local.Delete, local.Success);
            this.close();
        } else {
            // TODO if error what??
        }
    }

    handleName(event){
        this.setState({
            name: event.target.value,
            changed: true
        });
    }

    onBoundsChange(event){
        var owner = this;
        this.setState({
            point:{
                latitude: event[0],
                longitude: event[1]
            },
            changed: true
        });

        openGeocoder()
            .reverse(event[1], event[0])
            .end((err, res) => {
                if (err === null){
                    if (res.display_name !== undefined){
                        this.setState({ adress: res.display_name });
                    }
                }
            });

        // Geocode.fromLatLng(event[0], event[1]).then(
        //     (response) => {
        //         if (response?.results?.length > 0
        //             && response.results[0].address_components !== undefined){
        //                 owner.setState({
        //                     adress: response.results[0].formatted_address
        //                 });
        //         }
        //     },
        //     (error) => {
        //         console.error(error);
        //     }
        // );
    }

    render() {
        var local = this.props.appState.local;
        var place = this.props.place;
        var point = {
            lat: this.state.point.latitude,
            lng: this.state.point.longitude
        };
        
        return (
            <div className="ViewEditPlace">
                <p>
                    {place.guid === undefined ? local.PlaceNew : local.PlaceEdit}
                </p>
                <input className="ViewEditPlace__Label"
                    value={this.state.name}
                    onChange={this.handleName}
                    style={{margin: "10px 0px", height:"45px" }}
                    placeholder={local.PlaceNameTitle}/>
                <input className="ViewEditPlace__Adress"
                    disabled={true}
                    value={this.state.adress}
                    style={{margin: "10px 0px", height:"45px" }}
                    placeholder={local.PlaceAdressTitle}/>
                {
                    <div className='ViewEditDevice__Map'>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: googleMapApiKey }}
                            defaultCenter={point}
                            defaultZoom={13}
                            onBoundsChange={this.onBoundsChange}>
                            <MapMarker
                                lat={point.lat}
                                lng={point.lng}
                                text="some text"
                            />
                        </GoogleMapReact>
                    </div>
                }
                <div className='ViewEditPlace__Buttons'>
                    <button 
                        disabled={this.state.changed === false}
                        onClick={this.save}>
                        {local.Save}
                    </button>
                    {
                        place.guid === undefined ? null :
                        <button
                            onClick={this.delete}>
                            {local.Delete}
                        </button>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewEditPlace);

const MapMarker = ({ text }) => 
    <div className='Tracker__Map__Pin'>
        <img className='Tracker__Map__Pin__Image' src={pin_red} alt='' />
    </div>;