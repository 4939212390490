import './viewRoom.css';
import { Component } from 'react';

import { Samy } from 'react-samy-svg';

import { roomGetImage } from '../../helpers/helperRoom';
import { deviceBorderColor, devicesGetMult } from '../../helpers/funcs';
import { deviceImage, deviceIconFill } from '../../helpers/helperDevice';
import { sensorValueWithDem } from '../../helpers/helperSensor';
import { deviceColors, ModalActions, ApiActions } from "../../helpers/constants";

import { getCustomImage } from '../../helpers/helperApi';

import imgFavourite from '../../images/other/favourite.svg';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewRoom extends Component {

    constructor(props){
        super(props);

        this.loadImage = this.loadImage.bind(this);
        this.openRoom = this.openRoom.bind(this);

        this.loadImageTimeout = null;
    }

    async loadImage(){
        var owner = this;
        var room = this.props.room;
        var user = this.props.appState.user;

        var image = await getCustomImage(user, room.customImage, ApiActions.Get);
        this.loadImageTimeout = null;
        if (image !== null){
            room.imageData = image;
            owner.forceUpdate();
        }
    }

    openRoom(){
        if (this.props.clickDisabled === true) return;

        this.props.appActions.setShowModal({
            modalAction: ModalActions.EditRoom,
            objectModal: this.props.room
        });
    }
    
    render() {
        let user = this.props.appState.user;
        //var local = this.props.appState.local;
        let room = this.props.room;
        let isDarkTheme = this.props.appState.isDarkTheme;
        let deviceMult = devicesGetMult(room.realDevices);
        let multText = "";
        if (deviceMult !== null && deviceMult !== undefined){
            if (deviceMult.temp !== undefined){
                multText += ` ${sensorValueWithDem(deviceMult.temp)} `;
            }
            if (deviceMult.hum !== undefined){
                multText += ` ${sensorValueWithDem(deviceMult.hum)} `;
            }
        }

        var imgFavouriteStyle = {
            strokeWidth: 1, 
            strokeMiterlimit: 0, 
            fill: room.favourite === true ? deviceColors.borderOrange : "#00000000"
        }
        var descr = '';
        if (room.description !== undefined && room.description !== null){
            descr = ` (${room.description})`;
        }

        if (room.locationType === 21 && !room.imageData && this.loadImageTimeout === null){
            this.loadImageTimeout = setTimeout(this.loadImage, 50);
        }

        return (
            <div className="ViewRoom"
                onClick={this.openRoom}>
                <img src={roomGetImage(room) ?? room.imageData} alt="" />
                <div className="ViewRoom__name">
                    {room.name+descr}
                </div>
                <div className="ViewRoom__name">
                    {multText}
                </div>
                <div className="ViewRoom__Favourite">
                    <Samy path={imgFavourite} style={imgFavouriteStyle}/>
                </div>
                <div className="ViewRoom__devices">
                    {
                        room.realDevices.map((device, index) =>{
                            var borderColor = deviceBorderColor(device, isDarkTheme);
                            var iconStyle = deviceIconFill(user, device, borderColor);
                            iconStyle.strokeWidth = iconStyle.strokeWidth + 1;
                            iconStyle.opacity = 1;
                            return <div className="ViewRoom__device__mainImage" key={room.guid+device.guid}>
                                <Samy path={deviceImage(user, device)} style={iconStyle} />
                            </div>
                        })
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewRoom);