import { userGetPromaSatDevices } from './funcs';

export function checkUnitConnected(user, unit){
    var promaDevices = userGetPromaSatDevices(user);
    for(let pd of promaDevices){
        if (pd.unitId === unit.Id){
            return true;
        }
    }
    return false;
}

export function getUnitName(unit){
    var name = unit.Name;
    if (unit.Description !== undefined
        && unit.Description !== null
        && unit.Description !== ''){
            name += ` (${unit.Description})`;
        }
    return name;
}