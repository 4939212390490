import './viewFogotPassword.css';
import { Component } from 'react';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Samy } from 'react-samy-svg';
import { deviceColors, ApiActions } from '../../helpers/constants';
import { checkUserExists, userPasswordRecover } from '../../helpers/helperApi';
import { openNotificationWithIcon } from '../../helpers/funcs';

import logo from '../../images/other/vku_logo.svg';

class ViewFogotPassword extends Component {
    constructor(props){
        super(props);

        this.login = this.login.bind(this);

        this.state = {
            login: "",
            loading: false,
            changed: false,
        }
    }

    async login(event) {
        let owner = this;
        let local = this.props.appState.local;

        if (!this.state.login){
            openNotificationWithIcon("info", 
                local.AuthMessageTitle, local.RegistrationLoginIncorrect);
            return;
        }
        let result = await checkUserExists(this.state.login, ApiActions.Get);
        if (result === false){
            openNotificationWithIcon('error', local.appTitle, local.LoginDoestExists);
            return;
        }

        result = await userPasswordRecover(this.state.login, ApiActions.Get);
        if (result === true){
            openNotificationWithIcon('info', local.RecoverPasswordTitle, local.RecoverPasswordOK);
        } else {
            openNotificationWithIcon('error', local.RecoverPasswordTitle, local.RecoverPasswordFail);
        }
        owner.props.close();
    }
    
    render() {
        var local = this.props.appState.local;
        var isDarkTheme = this.props.appState.isDarkTheme;
        var borderColor = isDarkTheme ? deviceColors.borderGreyDark : deviceColors.borderGreyLight;

        return (
            <div className="ViewFogotPassword Credentials">
                <div className='ViewLogin__logo'>
                    <Samy path={logo} />
                </div>
                {/* <div className="LoginAppTitle">{local.appTitle}</div> */}
                {/* <p>{local.AuthForgotPassword}</p> */}
                <input
                    autoComplete="email"
                    value={this.state.login}
                    onChange={(e)=> this.setState({login: e.target.value, changed: true})}
                    style={{margin: "10px 0px", height:"45px"}}
                    placeholder={local.AuthLogin}
                    type="email"
                />
                <div className='Credentials__buttons'>
                    <button onClick={(event) => this.login(event)}>
                        {local.RecoverPasswordTitle}
                    </button>
                    <button onClick={this.props.close}>
                        {local.Back}
                    </button>
                </div>
            </div>
        );
  }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewFogotPassword);