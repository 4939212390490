import './viewDeviceSelector.css';
import { Component } from 'react';

import { deviceColors, timeDelayDevice } from "../../helpers/constants";
import { deviceImage,
    deviceIconFill } from '../../helpers/helperDevice';
import { sensorName, sensorImage } from '../../helpers/helperSensor';
import { getOperatorName } from '../../helpers/funcs';

import { Samy } from 'react-samy-svg';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewIfThenDevice extends Component{

    render(){
        const cond = this.props.cond;
        const user = this.props.appState.user;
        const local = this.props.appState.local;
        var sensor = null;
        var device = null;
        if (cond.actorGuid === timeDelayDevice.sensors[0].guid){
            sensor = timeDelayDevice.sensors[0];
            device = timeDelayDevice;
        } else {
            sensor = user.sensors[cond.sensorGuid ?? cond.actorGuid];
            device = sensor.device;
        }
        
        const iconStyle = deviceIconFill(user, device, deviceColors.borderBlue);

        var value = '';
        if (cond.actorGuid === undefined){
            if (sensor.sensorValueType !== 'WithoutValue'){
                if (sensor.model.includes("GeoSensor")){
                    var place = null;
                    for(var i in user.locations){
                        if (user.locations[i].guid === cond.value.locationGuid){
                            place = user.locations[i];
                        }
                    }
                    if (place !== null){
                        value = `${cond.value.distance}${local.SensorGeo} ${getOperatorName(local, cond.operator)} ${place.label}`;
                    }
                } else {
                    value = `${getOperatorName(local, cond.operator)} ${cond.value} ${sensorName(user, sensor, local)}`;
                }
            } else {
                value += sensorName(user, sensor, local);
            }
        } else {
            if (cond.actorGuid === timeDelayDevice.sensors[0].guid){
                value = `${local.ScenarioThenTimeDelay}: ${cond.value} ${local.Seconds}`;
            } else if (sensor.model.includes('X3IT01.ButtonSensor')) {
                value = `${sensorName(user, sensor, local)}`;
            } else {
                value = `${cond.value} ${sensorName(user, sensor, local)}`;
            }
        }

        return <div className='ViewCondition'>
            <div className="ViewCondition__Device__MainImage">
                <Samy path={sensorImage(user, sensor)} style={iconStyle} />
            </div>
            <p>{value}</p>
            <div className="ViewCondition__Device__DeviceImage">
                <Samy path={deviceImage(user, device)} style={iconStyle} />
            </div>
        </div>;
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewIfThenDevice);