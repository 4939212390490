export const LocalizedStrings = {
    ruRu: {
        name: 'ruRu',
        weatherLocale: 'ru',
        cultureName: 'ru-RU',
        lcid: 1049,
        appTitle: 'VKU умный дом',
        AuthMessageNoData: "Введите логин и пароль",

        Alert: "тревога",
        AuthAutoEnter: "Запомнить меня",
        AuthEnter: "Войти",
        AuthDemo: "Демо",
        NotInDemo: "Функция не поддерживается в демо режиме",
        AppName: "VKU",
        AppSettings: "Настройки",
        AppLanguage: "Язык",
        AppLanguageEn: "English",
        AppLanguageRu: "Русский",
        AppRoomsType: "Отображение комнат",
        AppRoomsTypeStandart: "Стандартное",
        AppRoomsTypeModern: "Современное",
        AppKeepScreenOn: "Не блокировать экран",
        AppScreenSettings: "Экран",
        AppHomeScreen: "Начальный экран",
        AppHomeScreenDash: "Панель",
        AppHomeScreenRooms: "Дом",
        AppVersion: "Версия приложения",
        AuthChange: "Сменить аккаунт",
        AuthLogin: "Логин",
        AuthPassword: "Пароль",
        AuthReg: "Регистрация",
        Back: "Назад",
        PasswordChangeLogin: "Логин",
        PasswordChangePasswordOld: "Старый пароль",
        PasswordChangePasswordNew: "Новый пароль",
        PasswordChangePasswordConfirm: "Подтвердить",
        BangingNo: "тихо",
        BangingYes: "стучат",
        HeatAlarm: "тревога",
        Close: "Закрыть",
        Closed: "закр.",
        Exit: "Выход",
        LocationChange: "Сменить",
        LocationChangeButtonTitle: "Установить",
        LocationFavourite: "Избранное",
        LocationDescription: "Описание",
        LocationEmptyPicture: "Изображение повреждено. Откройте для обновления",
        LocationNewCustom: "TODO",
        LocationFlatName: "Flat",
        LocationHouseFloorName: "HouseFloor",
        LocationHouseName: "House",
        LocationName: "Название",
        LocationNew: "Новое",
        LocationInDeviceInfo: "Местоположение",
        LocationInDeviceInfoNone: "нет",
        AreYouSure: "Вы уверены?",
        LocationNewPlaceHolder: "название местоположения",
        LocationPickerPlaceHolder: "SelectLocationType",
        LocationRoomName: "Room",
        LocationRoomsName: "Комнаты",
        NewRoom: "Новая комната",
        MenuDashboard: "Панель",
        MenuEquipment: "Оборудование",
        MenuRooms: "Комнаты",
        MenuHome: "Дом",
        MenuScenarios: "Сценарии",
        MenuSettings: "Настройки",
        MenuNewItem: "Опции",
        No: "нет",
        Off: "выкл.",
        OK: "OK",
        On: "вкл.",
        SmokeAlert: "Пожар!",
        Opened: "откр.",
        SensorDark: "Темно",
        SensorBright: "Светло",
        PageDevicesTitle: "Устройства",
        PageSettingsTitle: "Настройки",
        Save: "Сохранить",
        Update: "Обновить",
        Yes: "Да",
        DeviceActionsInfo: "Об устройстве",
        DeviceActionsLocationChange: "Сменить местоположение",
        DeviceActionsLocationNew: "Добавить в новое местоположение",
        DeviceActionsTitle: "Опции",
        LocationChangeClear: "Удалить",
        LocationChangeNew: "Добавить местоположение",
        LocationChangeTitle: "Местоположения",
        LocationEmpty: "Нет местоположений",
        DeviceActionsAddToExistingLocation: "Добавить в комнату",
        DeviceInfoSensors: "Список сенсоров:",
        DeviceInfoShowInDashboard: "Показывать на панели",
        DeviceActionsShowSameType: "Показать устройства той же модели",
        ScenarioTitleEdit: "Редактировать сценарий",
        ScenarioTitleNew: "Добавить сценарий",
        DeviceNewTitle: "Добавить устройство",
        ScenarioDescription: "Описание",
        ScenarioIfTitle: "ЕСЛИ",
        ScenarioName: "Название",
        ScenarioThenTitle: "ТОГДА",
        ScenarioIfNew: "ЕСЛИ",
        ScenarioIfNewCondition: "Выбор сенсора:",
        ScenarioIfNewOperand: "Выберите оператор:",
        ScenarioShowOnDashtitle: "Отображать на панели",
        ScenarioIfNewValue: "значение",
        ScenarioIfNewValueHelp: "Значение сенсора:",
        ScenarioIfNewPickerHelp: "Значение сенсора:",
        ScenarioIfNewDelayHelp: "Укажите время ожидания",
        ScenarioThenNew: "ТОГДА",
        ScenarioThenTimeDelay: "Ожидание",
        Delete: "Удалить",
        LocationNewTitle: "Добавить комнату",
        LocationNewDevices: "Устройства",
        LocationNewDeviceSelect: "выберите устройство",
        LocationNewSelectPhoto: "Выбрать фото",
        ScenariosEmpty: "Нет сценариев",
        DeviceInfoChartsTitle: "История",
        DeviceInfoChartsInterval: "Временной интервал: ",
        DeviceInfoChartsIntervalDay: "День",
        DeviceInfoChartsIntervalMonth: "Месяц",
        DeviceInfoChartsIntervalWeek: "Неделя",
        SensorCommandTitle: "Выберите действие",
        SensorControlClose: "Закрыть",
        SensorControlOpen: "Открыть",
        SensorControlStop: "Остановить",
        SensorControlTitle: "Управление",
        ScenarioCreateNewButton: "Создать новую кнопку",
        ScenarioCreateNewButtonName: "Название кнопки (10 символов максимум)",
        ScenarioButtonsEmpty: "Нет кнопок",
        TimerButtonFriday: "П",
        TimerButtonMonday: "П",
        TimerButtonSaturday: "С",
        TimerButtonSunday: "В",
        TimerButtonThursday: "Ч",
        TimerButtonTuesday: "В",
        TimerButtonWednesday: "С",
        TimerButtonWeekdays: "Будни",
        TimerButtonWeekends: "Выходные",
        TimerNameTitle: "Введите название таймера",
        TimerCheckboxAlert: "Уведомление",
        TimerCheckboxVibrate: "Вибро",
        TimerButtonsTitle: "Таймеры",
        TimerTitleEdit: "Редактировать таймер",
        TimerTitleNew: "Добавить таймер",
        TimerTypeTime: "Точное время",
        TimerTypeSunset: "Закат солнца",
        TimerTypeSunrise: "Рассвет",
        TimerFillAllData: "Для сохранения нужно заполнить все поля. Так же, для использования опций заката и рассвета, нужно добавить адрес, так как их время зависит от местоположения.",
        TimerTypeOffset: "Временной разброс, м",
        TimerTypeAdress: "Адрес использование",
        ButtonSensorName: "Кнопка",
        CameraSensorName: "Камера",
        Cancel: "Отмена",
        TimerDeleteAsk: "Этот таймер используется в сценарии. Вы уверены что хотите его удалить?",
        TimerSensorName: "Таймер",
        ButtonButtonsTitle: "Кнопки",
        ButtonDeleteAsk: "Эта кнопка используется в сценарии. Вы уверены что хотите его удалить?",
        ButtonTitleEdit: "Редактировать кнопку",
        ButtonTitleNew: "Добавить кнопку",
        SensorWOvalueNewScenarion: "Добавить сценарий",
        ScenarioCreateFromSensor: "Сохранить и создать сценарий",
        NotificationsTitle: "Уведомления",
        ScenarioSendPushTitle: "Отправлять уведомление",
        ScenarioUseAsShieldTitle: "Управляется кнопкой Охрана",
        ScenarioSecurityTitle: "Охрана",
        ScenarioSecurityInfo: "Для управления камерами воспользуйтесь мобильным приложением VKU",
        CameraPreviewsTitle: "Камеры",
        CameraInformationTitle: "Информация о камере",
        CameraIsOffline: "Камера нe в сети",
        CameraScreensAll: "Все камеры",
        CameraQualityTitle: "Качество видео ",
        CameraQualitySdTitle: "низкое",
        CameraQualityHdTitle: "высокое",
        ScenarioShowOnDashboardTitle: "Отображать на панели",
        DataLoading: "Загрузка...",
        AuthForgotPassword: "Забыл пароль",
        AuthForgotPasswordLink: "Забыли пароль?",
        ScenarioAlertPushTitle: "Важное уведомление",
        PresetButtonChannelMinus: "-",
        PresetButtonChannelPlus: "+",
        PresetButtonFan: "Fan",
        PresetButtonMenu: "Menu",
        PresetButtonMenuDown: "Down",
        PresetButtonMenuExit: "Exit",
        PresetButtonMenuLeft: "Left",
        PresetButtonMenuOk: "OK",
        PresetButtonMenuRight: "Right",
        PresetButtonMenuUp: "Up",
        PresetButtonMinus: "Minus",
        PresetButtonMode: "Mode",
        PresetButtonMute: "Mute",
        PresetButtonOn: "On",
        PresetButtonPlus: "Plus",
        PresetButtonSmart: "Smart",
        PresetButtonSource: "Source",
        PresetButtonVolumeMinus: "-",
        PresetButtonVolumePlus: "+",
        PresetsConditioner: "Air conditioner",
        PresetsMisc: "Sundry",
        PresetsTv: "TV",
        ButtonIrCurrentDelete: "Удалить",
        ButtonIrCurrentRetrain: "Переобучить",
        ButtonIrCurrentTitle: "Опции",
        ButtonIrPush: "Нажать",
        ButtonIrLearn: "Обучить",
        ButtonIrLearnAsk: "Готовы обучить?",
        ButtonIrLearnInfo: "Теперь нажмите нужную кнопку на Вашем пульте ДУ",
        ButtonIrLearnTitle: "ИК кнопка",
        ButtonTypeGoToBed: "Пора спать",
        ButtonTypeHolidays: "Выходные",
        ButtonTypeLeaveHome: "Покидая дом",
        ButtonTypeOther: "Остальные",
        ButtonTypeReturnHome: "Возвращаюсь домой",
        ButtonTypeBath: "Ванная",
        ButtonTypeCrop: "Сад",
        ButtonTypeFireplace: "Камин",
        ButtonTypeGarden: "Еще сад",
        ButtonTypeGate: "Ворота",
        ButtonTypeGreenenergy: "Ветер",
        ButtonTypeHeater: "Нагреватель",
        ButtonTypHouse: "Дом",
        ButtonTypeJacuzzi: "Джакузи",
        ButtonTypeKitchen: "Кухня",
        ButtonTypeLivingRoom: "Гостинная",
        ButtonTypePlanting: "Огород",
        ButtonTypePump: "Насос",
        ButtonTypeRain: "Дождь",
        ButtonTypeSauna: "Сауна",
        ButtonTypeSun: "Солнечно",
        ButtonTypeSwimmingPool: "Бассейн",
        ButtonTypWateringCan: "Полив",
        ButtonTypeSelect: "Выберите тип кнопки:",
        DeviceNameTitle: "Название устройства",
        ScenarioNewButtons: "Кнопки",
        ScenarioNewSelectDevice: "Выберите устройство, таймер или кнопку",
        ScenarioNewTimers: "Таймеры",
        RegistrationError: "Во время регистрации произошла ошибка",
        RegistrationLoginExists: "Логин уже используется",
        LoginDoestExists: "Логин не существует",
        LoginUserTheSame: "Нельзя отправить запрос в друзья самому себе",
        RegistrationLoginIncorrect: "Нужно ввести валидный E-mail",
        RegistrationLoginTitle: "Логин (E-mail)",
        RegistrationPasswordEmpty: "Нужно ввести пароль",
        RegistrationWaitConfirm: "Перейдите по ссылке в полученном письме, чтобы подтвердить регистрацию",
        UserInfoTitle: "Аккаунт",
        UserAddMoney: "Пополнить счет",
        UserInfoName: "Имя",
        UserInfoLastName: "Фамилия",
        UserInfoAdress: "Улица",
        UserInfoCountry: "Страна",
        UserInfoState: "Область",
        UserInfoDate: "Месяц, год рождения",
        UserInfoCity: "Город",
        UserInfoPostal: "Индекс",
        UserInfoPhone: "Телефон",
        UserInfoFriends: "Друзья",
        UserInfoSexTitle: "Пол",
        UserInfoSexMale: "Мужской",
        UserInfoSexFemale: "Женский",
        UserInfPhoneConfirm: "Подтвердить телефон",
        ChangePasswordTitle: "Сменить пароль",
        ChangePasswordLoginNotExists: "Не правильный пароль",
        ChangePasswordConfirm: "Перейдите по ссылке в полученном письме для смены пароля",
        ChangePasswordNotSame: "Пароли не совпадают",
        ChangePasswordError: "Во время смены пароля произошла ошибка",
        DeviceNewHelp: "Нажмите кнопку внизу, чтобы переключить хаб в режим добавления устройства. Затем, следуя инструкциям на упаковке нового устройства, подключите его к сети VKU (обычно, достаточно зажать кнопку сброса на 6 секунд).",
        DeviceNewModeTitle: "Режим добавления:",
        DeviceNewNameHelp: "Обнаружено новое устройство. Пожалуйста укажите название",
        GateNewAdded: "Хаб успешно добавлен",
        GateNewAnotherUser: "Данный хаб успешно зарегистрирован",
        GateNewButtonTitle: "Добавить",
        GateNewHelp: "Пожалуйста, подключите хаб к Интернет. Затем введите логин и пароль указанный на нем",
        GateNewNotFound: "Хаб не найден",
        GateNewTitle: "Добавить хаб",
        GateDeleteText: "Удаление хаба привет к удалению всех устройств привязанных к нему у Вас и Ваших друзей, если Вы ими делились. Вы уверены?",
        EquipmentTrackersTitle: "Трекеры",
        EquipmentLinkedDevicesTitle: "Связанные устройста",
        DeviceActionsDelete: "Удалить устройство",
        DeviceActionsDeleteFromLocation: "Удалить из комнат",
        DeviceActionsAskDelete: "Вы уверены что хотите удалить устройство?",
        DeviceDeleteOkTitle: "Команда удаления устройства успешно отправлена.",
        DeviceNewGatewayTitle: "Выберите хаб:",
        ScenarioEmptyTitle: "Нужно указать название и описание сценария. Так же нужно указать как минимум одно условие и следствие.",
        ButtonIrDeletedSuccessTitle: "Кнопка успешно удалена",
        ButtonIrDeleteFailedTitle: "Во время удаления кнопки произошла ошибка. Попробуйте позже",
        ButtonIrLearnFailedTitle: "Во время обучения кнопки произошла ошибка. Попробуйте позже",
        ButtonIrLearnSuccessTitle: "Кнопка обучена успешно",
        PresetButtonOff: "Off",
        ButtonIrInvokeFailedTitle: "Не удалось вызвать",
        ButtonIrInvokeSuccessTitle: "Успешный вызов",
        ButtonIrMessageTitle: "ИК кнопка",
        ButtonsIrMisc: "ИК кнопки",
        ButtonsIrMiscNewTitle: "Новая ИК кнопка",
        ButtonsIrMiscNewNameTitle: "Название кнопки",
        ButtonsIrMiscNewGroupTitle: "Название группы (не обязательно)",
        ButtonIrMessageSendFailedTitle: "ИК кнопка не отвечает на комманды. Удалить её?",
        Success: "Успешно",
        Fail: "Произошла ошибка",
        ScenarioTimeDuration: "Конец",
        ScenarioTimeEnabled: "Включить расписание",
        ScenarioTimeStart: "Начало",
        ScenarioTimeDaysError: "Нужно выбрать минимум один день",
        DeviceLocationSendTitle: "Разрешить отправку местоположения",
        CamerasTitle: "Камеры",
        CamerasNewTitle: "Добавить камеру",
        CamerasMainSettingsTitle: "Основные настройки",
        CamerasNewHelp: "Отсканируте или введите название",
        CamerasNewName: "Название",
        CameraFullscreenButtonTitle: "Открыть",
        CamerasNewPassword: "Пароль",
        CamerasNewDescription: "Описание",
        CamerasNewNodata: "Нужно ввести название и пароль",
        CamerasNewIncorrect: "Введенных логина и/или пароля не существует",
        CamerasNewExists: "Устройство с таким названием уже подключено",
        CamerasNewAddTitle: "Добавить",
        CamerasNewTryConnectTitle: "Соединиться",
        CamerasNewHotspotDoestSuppots: "Ваше устройство не поддерживает управление wi-fi соединением. Пожалуйста подключитесь к сети {0} вручную, пароль по умолчаюнию - \"admin\"",
        CamerasNewAdded: "Камера успешно добавлена",
        CamerasNewScan: "Отсканировать QR код",
        CameraVolumeTitle: "Громкость",
        CamerasFilesTitle: "Записи",
        CamerasFailFile: "Ошибка открытия записи",
        CamerasRenameFile: "Переименовать",
        CamerasMailToFile: "Отправить",
        CamerasSaveToAlbumFile: "Сохранить",
        CamerasRenameTitleFile: "Введите новое имя файла",
        CamerasNewLanTitle: "Только проводное подключение",
        CamerasNewLanError: "Камера не подключена к Интернет. Проверьте кабель",
        CamerasNewWifiHelp: "Введите название wi-fi сети и ключ, они будут сохранены в камере",
        CamerasSdRecords: "Записи на SD карте",
        CamerasSdFormat: "Форматировать SD карту",
        CamerasWiFiChangeTitle: "Сменить wi-fi сеть",
        CamerasWiFiChangeHelp: "Список доступных wi-fi сетей:",
        CamerasWiFiChangeCurrent: "Установленная wi-fi сеть: {0}",
        CamerasWiFiChangeNodata: "Нужно выбрать wi-fi сеть и ввести пароль",
        CamerasWiFiChangeSuccess: "wi-fi установлен успешно",
        CamerasRemove: "Удалить из аккаунта",
        Apply: "Применить",
        CamerasFailOnline: "Не получается соединиться с камерой",
        DeviceDimmerShowDimmerButtonTitle: "Показать слайдер",
        CameraSettings0: "Мягкая",
        CameraSettings1: "Стандартная",
        CameraSettings2: "Яркая",
        CameraSettingsColor: "Цветность",
        CameraSettingsRotation: "Поворот",
        CameraMediaSettings: "Настройки медиа",
        CameraAlarmSettings: "Настройки тревоги",
        CameraRecSettings: "Настройки записи",
        CameraAlarmSettingsMotionDetection: "Распознавание движения",
        CameraAlarmSettingsSensitivity: "Чувствительность",
        CameraAlarmSettingsSiren: "Сирена",
        CameraAlarmSettingsNotification: "Уведомление",
        CameraAlarmSettingsMode: "Отправлять фото",
        CameraAlarmSettingsSchedule: "Расписание",
        CameraAlarmSettingsScheduleEnabled: "Включено",
        CameraRecSettingsSchedule: "Записать",
        CameraRecSettingsScheduleEnabled: "Включено",
        CameraAlarmSettingsTrigger: "Запись по слежению",
        CameraAlarmSettingsAutoTracking: "Слежение за объектом",
        CameraPasswordChangeTitle: "Сменить пароль",
        CameraPasswordChangeOldTitle: "Старый пароль",
        CameraPasswordChangeNewTitle: "Новый пароль",
        CameraPasswordChangeConfirmTitle: "Подтвердить пароль",
        CamerasPasswordChangeNodata: "Введите корректные данные",
        CamerasPasswordChangeSuccess: "Пароль на камере изменен успешно",
        CameraRecordsDaysTitle: "Дни с записями",
        CameraRecordsDaysCommonTitle: "обычные",
        CameraRecordsDaysAlarmTitle: "тревога",
        CameraRecordsHoursTitle: "Часы с записями",
        CameraRecordsMinutesTitle: "Интервалы",
        CameraRecordsMinutes: "мин.",
        CameraRecordsDaysNoData: "нет таких дней в месяце",
        CameraDateSettingsTitle: "Настройки времени",
        CameraDateSettingsSet: "Установить дату",
        CameraMainOptions: "Настройки камеры",
        CameraMainInformation: "Информация",
        RelationsFriends: "Список друзей",
        RelationsStatusFriend: "Друг",
        RelationsStatusIncomning: "Запрос получен",
        RelationsStatusOutcoming: "Запрос отправлен",
        RelationsEmpty: "Нет данных",
        RelationsSendRequest: "Отправить запрос",
        RelationsUserLogin: "Логин пользователя",
        RelationsUserLoginAlready: "Этот пользователь уже у Вас в друзьях",
        RelationsUserLoginAlreadyOutgoing: "Этому пользователю Вы уже отправили запрос",
        RelationsUserFriendDelete: "Удалить пользователя из друзей?",
        RelationsIncoming: "Запрос дружбы",
        RelationsIncomingAccept: "Принять",
        RelationsIncomingReject: "Отклонить",
        RelationsOutgoingCancel: "Отменить запрос?",
        RelationsSharedTitle: "Поделиться (да/нет)",
        RelationsLinkedDelete: "Отменить связь",
        RelationsLinkedCount: "связаны",
        RelationsSharedCount: "переданы",
        RelationsPhotoAsk: "Сделать снимок или выбрать из галереи?",
        RelationsPhotoAskPhoto: "Фото",
        RelationsPhotoAskGalery: "Галерея",
        SensorValueBattery: "заряжается",
        SensorValueHeatAlarm: "Перегрев",
        SensorValueHeatNormal: "норма",
        SensorTypeNameOnline: "В сети",
        SensorTypeNameBattery: "Батарея",
        SensorTypeNameBatteryVoltage: "Вольтаж батареи",
        SensorTypeNameLinkQuality: "Качество сигнала",
        SensorTypeNameLeakage: "Утечка",
        SensorTypeNameHumidity: "Влажность",
        SensorTypeNameTemperature: "Температура",
        SensorTypeNameBrightness: "Освещенность",
        SensorTypeNamePressure: "Давление",
        SensorTypeNameMotion: "Движение",
        SensorTypeNameTamperProof: "Несанкционированный доступ",
        SensorTypeNameTheDoor: "Дверь",
        SensorTypeNameKnock: "Стук",
        SensorTypeNameHeatAlarm: "Тепловая сигнализация",
        SensorTypeNamePM25Temperature: "PM25 температура",
        SensorTypeNamePM25Humidity: "PM25 влажность",
        SensorTypeNamePM25particles: "PM25 частицы",
        SensorTypeNameGasLeak: "Утечка газа",
        SensorTypeNamePlugSwitch: "Выключатель",
        SensorTypeNameРowerConsumption: "Потребляемая мощность",
        SensorTypeNameRMSVoltage: "Напряжение",
        SensorTypeNameRMSCurrentPower: "Текущая мощность",
        SensorTypeNameActivePower: "Текущее потребление",
        SensorTypeNameSmokeSensor: "Датчик дыма",
        SensorTypeNameCurtainMotor: "Занавески",
        SensorTypeNameCurtainTrip: "Путешествие зановесок",
        SensorTypeNameIRButton: "ИК кнопка",
        SensorTypeNameAddNewDevice: "Добавить устройство",
        SensorTypeNameRemoveDevice: "Удалить стройство",
        SensorTypeNameSmartDimmer: "Диммер",
        SensorTypeNameSwitch: "Переключатель",
        SensorTypeNameGeo: "Адрес",
        SensorTypeNameCourse: "Направление",
        SensorTypeNameIgnition: "Зажигание",
        SensorTypeNameSpeed: "Скорость",
        SensorGeoShowMap: "Карта",
        SensorDemPM25: "мкг/м3",
        SensorDemBrightness: "лк",
        SensorDemV: "В",
        SensorDemCm: "см",
        SensorDemKWh: "кВт/ч",
        SensorDemW: "Вт",
        SensorDemdB: "дБ",
        SensorSpeed: "км/ч",
        SensorPressure: "мм рт.ст.",
        SensorGeo: "м",
        Seconds: "секунд",
        SensorGeoIn: "к",
        SensorGeoOut: "от",
        TimeMinutesShort: "м.",
        TimeSecondsShort: "с.",
        LoginError: "Не удаётся войти. Проверьте правильность написания логина и пароля.",
        RecoverPasswordTitle: "Восстановить пароль",
        RecoverPasswordOK: "Ваш пароль отправлен на электронную почту.",
        RecoverPasswordFail: "Во время восстановления пароля произошла ошибка",
        PlaceTitle: "Адрес",
        PlaceNew: "Добавить адрес",
        PlaceEdit: "Редактировать адрес",
        PlacesTitle: "Адреса",
        PlaceDef: "Вы здесь",
        PlaceNameTitle: "Название",
        PlaceAdressTitle: "Адрес",
        FillAllData: "Заполните все поля для сохранения",
        PlaceRadiusTitle: "Радиус, м",
        PlaceDeleteInUseAsk: "Этот адрес используется в сценарии. Вы уверены что хотите его удалить?",
        PlaceDeleteAsk: "Вы уверены что хотите удалить адрес?",
        PlaceInside: "Въезд",
        PlaceOutside: "Выезд",
        ThemeSettingNameTitle: "Цветовая тема",
        ThemeSettingNameLight: "Светлая",
        ThemeSettingNameDark: "Темная",
        ThemeSettingNameControlled: "Определять автоматически",
        PromaSatTitle: "PromaSat",
        PromaSatHelp: "Введите данные аккаунта PromaSat для добавления Вашего транспорта в систему VKU",
        PromaSatChangeAccount: "При смене аккаунта, ранее привязанные устройства будут удалены вместе со связями. Вы уверены?",
        PromaSatUnitHelp: "Тап на устройство для добавления или удаления из системы",
        PromaSatUnitAdd: "Удалить из системы",
        PromaSatUnitRemove: "Добавить в систему",
        PromaSatUnitInSystem: "Подключен к системе",
        PromaSatUnitsMax: "Достигнуто максимальное количество подключенных устройств",
        PermissionRequestPhotoLibrary: "Для этого необходим доступ к фото галереи",
        PermissionDeniedMessage: "Нет разрешений на продолжение действия. Вы может изменить это в настройках",
        MessageUserUpdating: "Обновление данных..",
        PaymentsTitle: "Покупки",
        Online: "Онлайн",
        Offline: "Оффлайн",
        PlugResetTitle: "Сброс общего потребления",
        AmountTitle: "Сумма на счету",
        AmountRubTitle: "р",
        AmountOtherTitle: " ",
        AccountFileSelector: "Сменить фото",
        AccountTypeTitle: "Тип аккаунта",
        AccountTypeFreeTitle: "Бесплатный",
        AccountTypePremiumTitle: "Премиальный",
        AccountPremiumEndTitle: "Действителен до",
        TransactionsTitle: "Транзакции",
        CameraNewWifiAsk: "Для первичной настройки камеры перейдите в настройки Wifi и подлючитесь к сети {0}, если система предложит отключится от сети из-зи отсутствия интернета в данной сети тогда откажитесь. После вернитесь в приложение VKU и повторите попытку.",
        TimeZoneTitle: "Часовой пояс",
        LimitPremiumPromasatCount: "Достигнуто максимальное количество блоков PromaSat",
        LimitPromasatCount: "Достигнуто максимальное количество блоков PromaSat. Для увеличения количества подключите премиум аккаунт",
        LimitButtonsCount: "Достигнуто максимальное количество кнопок. Для увеличения количества подключите премиум аккаунт",
        LimitCameraShare: "Чтобы делиться с друзьями камерами необходимо подключить премиум аккаунт",
        LimitDeviceShare: "Чтобы делиться устройствами с друзьями необходимо подключить премиум аккаунт",
        LimitPremiumDeviceShareCount: "Достигнуто максимальное количество устройств",
        LimitScenariosCount: "Достигнуто максимальное количество сценариев. Для увеличения количества подключите премиум аккаунт",
        LimitFriendsCount: "Достигнуто максимальное количество друзей. Для увеличения количества подключите премиум аккаунт",
        LimitAboutTitle: "Премиум аккаунт",
        LimitAboutTextTitle: `После подключения премиум аккаунта Вам будут доступны следующие возможности:
<br>1. Делиться устройствами более чем с 5 друзьями
<br>2. Создавать более 30 различных сценариев
<br>3. Создавать более 10 кнопок управления сценариями
<br>4. Делиться с друзьями более чем 10 устройствами
<br>5. Делиться Вашими камерами с друзьями
<br>6. Добавить до 10 устройств PromaSat (без премиум аккаунта доступно только 3)`,
        LimitUsersCountTitle: "Сервер временно не доступен, либо выполнен вход в аккаунт на другом устройстве. Одновременное использование системы на нескольких устройствах запрещено",
        SubscriptionEnableTitle: "Активировать подписку",
        SubscriptionEnableListTitle: "Доступные подписки",
        SubscriptionMonth200Title: "Один месяц за 200руб.",
        SubscriptionYear2000Title: "Один год за 2000руб.",
        CameraNewErrorText: "Произошла ошибка. Убедитесь, что Wi-Fi включен",
        NoMoneyTitle: "На счету недостаточно средств",
        PremiumExpiriedAlertTitle: "У вас истек срок действия премиум аккаунта. Скоро привелегии будут сняты.",
        PremiumPrivilegiesRemovedTitle: "Прошло три дня после окончания срока действия премиум аккаунта. Ваши привелегии были сняты.",
        NotificationActionReaded: "Прочитано",
        NotificationActionNotReaded: "Не прочитано",
        CommandNotSendedWaitingTitle: "Команда не отправлена. Ожидание ответа на предыдущую команду...",
        CommandNotSendedResponceTitle: "Ответ на команду не пришёл",
        Edit: "Редактировать",
        TurnOn: "Включить",
        TurnOff: "Выключить",
        RoomDeleteAsk: "Вы уверены что хотите удалить эту комнату?",
        TriggerDeleteAsk: "Вы уверены что хотите удалить этот сценарий?",
        ButtonDeleteAskMain: "Вы уверены что хотите удалить эту кнопку?",
        TimerDeleteAskMain: "Вы уверены что хотите удалить этот таймер?",
        CameraOpenTitle: "Открыть",
        SubCostTitle: "Стоимость",
        SubEnableTitle: "Подключить",
        SubDurationTitle: "Продолжительность",
        SubDurationMonthTitle: "Месяц",
        SubDurationYearTitle: "Год",
        ViewLogin__UpButtons__Enter: 'Вход',
        ViewLogin__UpButtons__Registration: 'Регистрация',
        ViewLogin__About__Title: 'О программе',
        Modal__About__MainText: `Сервис умного дома VKU состоит из датчиков, передающих информацию о своем состоянии, исполнительных устройств, видеокамер и приложения для смартфонов, в котором пользователи контролируют статусы датчиков, дистанционно включают оборудование, настраивают сценарии, получают уведомления.
        <br>Преимуществами наших устройств является компактность, эргономичность, малое энергопотребление, дающее длительные сроки автономной работы и надежность, а плюсами сервиса является высокая скорость работы, надежность и резервирование данных в облачных сервисах.
        <br>Мы гордимся, что наш сервис создан Российскими программистами, имеющими огромный опыт по созданию телематических приложений, а используемые нами технологии являются передовыми и инновационными. Немаловажную роль имеет русскоязычная поддержка наших клиентов, готовая помочь решить любые сложности в работе сервиса и обучить владельцев тонкостям настройки сценариев и работы устройств.`,
        Modal__About__1Text: `Разработано ООО "Автостудия" / Autostudio LLC, 2022.
        <br>Все права защищены`,
        Modal__About__21Text: `Сайт:`,
        Modal__About__22Text: `Техническая поддержка:`,
        Modal__About__23Text: `Отдел продаж:`,
        Modal__About__24Text: `ИП Дуб Александр Юрьевич
        <br>ОГРНИП 320253600038018`,
        Modal__About__25Text: `Политика конфиденциальности / Privacy policy`,
        SettingsShowSummaryVoltageTitle: 'Показывать общее потребление',
        SettingsShowCurrentNotificationsTitle: 'Показывать ленту уведомлений',
        ViewCameraPreviewSelectionTitle: 'Выберите камеры для отображения',
        ViewCameraPreviewSelectionMaxCount: 'Достигнуто максимальное количество камер',
        ViewCameraPreviewSelectionError: 'Нет данных по данной камере',
        MenuInformationWeather: 'Погода',
        AllRooms: 'Все комнаты',
        LocationPermissionError: 'Не предоставлено разрешение на поиск местоположения',
        WsStatusTitle: 'Статус соединения',
        WsStatusOkTitle: 'Подключено',
        WsStatusFailTitle: 'Нет подключения',
        TotalActivePower: 'Текущее общее потребление',
        DeleteUserButtonTitle: 'Удалить аккаунт',
        DeleteUserInfo: 'Удаление аккаунта приведёт к невозможности использования данной электронной почты для входа в систему ВКУ. Для подтверждения вам на электронную почту придёт ссылка на подтверждение удаления.',
    },
    enEn: {
        name: 'enUS',
        weatherLocale: 'en',
        cultureName: 'en-US',
        lcid: 1033,
        appTitle: 'VKU smart home',
        AuthMessageNoData: "Please enter login and password",

        Alert: "Alert",
        AuthAutoEnter: "Remember me",
        AuthEnter: "Login",
        AuthDemo: "Demo",
        NotInDemo: "Function is not supported in demo mode",
        AppName: "VKU",
        AppSettings: "App settings",
        AppVersion: "Version",
        AppHomeScreen: "Home screen",
        AppHomeScreenDash: "Dashboard",
        AppHomeScreenRooms: "Home",
        AppRoomsType: "Rooms view",
        AppRoomsTypeStandart: "Standart",
        AppRoomsTypeModern: "Modern",
        AppScreenSettings: "Screen settings",
        AppKeepScreenOn: "Keep screen on",
        AppLanguage: "Language",
        AppLanguageEn: "English",
        AppLanguageRu: "Русский",
        AuthChange: "Change account",
        AuthLogin: "Login",
        AuthPassword: "Password",
        AuthReg: "Registration",
        Back: "Back",
        PasswordChangeLogin: "Login",
        PasswordChangePasswordOld: "Old password",
        PasswordChangePasswordNew: "New password",
        PasswordChangePasswordConfirm: "Confirm",
        BangingNo: "quiet",
        BangingYes: "banging",
        HeatAlarm: "alarm",
        Close: "close",
        Closed: "closed",
        Exit: "Exit",
        LocationChange: "Change",
        LocationChangeButtonTitle: "Set",
        LocationDescription: "Location description",
        LocationEmptyPicture: "Image is corrupted. Open to update",
        LocationNewCustom: "TODO",
        LocationFlatName: "Flat",
        LocationHouseFloorName: "HouseFloor",
        LocationHouseName: "House",
        LocationName: "Location name",
        LocationNew: "New",
        LocationInDeviceInfo: "Location",
        LocationInDeviceInfoNone: "none",
        AreYouSure: "Are yoy sure?",
        LocationNewPlaceHolder: "location name",
        LocationPickerPlaceHolder: "SelectLocationType",
        LocationRoomName: "Room",
        LocationRoomsName: "Rooms",
        NewRoom: "New room",
        MenuDashboard: "Dashboard",
        MenuEquipment: "Equipment",
        MenuRooms: "Rooms",
        MenuHome: "Home",
        MenuScenarios: "Scenarios",
        MenuSettings: "Settings",
        MenuNewItem: "Options",
        No: "no",
        Off: "Off",
        OK: "OK",
        On: "On",
        SmokeAlert: "Fire!",
        Opened: "opened",
        SensorDark: "Dark",
        SensorBright: "Bright",
        Open: "open",
        PageDevicesTitle: "Devices",
        PageSettingsTitle: "Settings",
        Save: "Save",
        Update: "Update",
        Yes: "Yes",
        DeviceActionsInfo: "Device info",
        DeviceActionsLocationChange: "Change device location",
        DeviceActionsLocationNew: "Add to new location",
        DeviceActionsTitle: "Device actions",
        LocationChangeClear: "Remove",
        LocationChangeNew: "New location",
        LocationChangeTitle: "Existing locations",
        LocationFavourite: "Favourite",
        LocationEmpty: "No locations",
        DeviceActionsAddToExistingLocation: "Add to existing location",
        DeviceInfoSensors: "Sensors list:",
        DeviceInfoShowInDashboard: "Show in dashboard",
        DeviceActionsShowSameType: "Show same model devices",
        ScenarioTitleEdit: "Edit scenario",
        ScenarioTitleNew: "New scenario",
        DeviceNewTitle: "New device",
        ScenarioDescription: "Description",
        ScenarioIfTitle: "IF",
        ScenarioName: "Name",
        ScenarioThenTitle: "THEN",
        ScenarioIfNew: "IF",
        ScenarioIfNewCondition: "Select sensor:",
        ScenarioIfNewOperand: "Select operand:",
        ScenarioShowOnDashtitle: "Show on dashboard",
        ScenarioIfNewValue: "value",
        ScenarioIfNewValueHelp: "Sensor value:",
        ScenarioIfNewPickerHelp: "Sensor value:",
        ScenarioIfNewDelayHelp: "Type time delay",
        ScenarioThenNew: "THEN",
        ScenarioThenTimeDelay: "Time delay",
        Delete: "Delete",
        LocationNewTitle: "New location",
        LocationNewDevices: "Devices",
        LocationNewDeviceSelect: "select device",
        LocationNewSelectPhoto: "Select photo",
        ScenariosEmpty: "No scenarios",
        DeviceInfoChartsTitle: "History",
        DeviceInfoChartsInterval: "History interval: ",
        DeviceInfoChartsIntervalDay: "Day",
        DeviceInfoChartsIntervalMonth: "Month",
        DeviceInfoChartsIntervalWeek: "Week",
        SensorCommandTitle: "Select action",
        SensorControlClose: "Close",
        SensorControlOpen: "Open",
        SensorControlStop: "Stop",
        SensorControlTitle: "Control",
        ScenarioCreateNewButton: "Create new button",
        ScenarioCreateNewButtonName: "Button name (ten characters max)",
        ScenarioButtonsEmpty: "No buttons",
        TimerButtonFriday: "F",
        TimerButtonMonday: "M",
        TimerButtonSaturday: "S",
        TimerButtonSunday: "S",
        TimerButtonThursday: "T",
        TimerButtonTuesday: "T",
        TimerButtonWednesday: "W",
        TimerButtonWeekdays: "Weekdays",
        TimerButtonWeekends: "Weekends",
        TimerNameTitle: "Enter timer name",
        TimerCheckboxAlert: "Show alert",
        TimerCheckboxVibrate: "Vibrate",
        TimerButtonsTitle: "Timers",
        TimerTitleEdit: "Edit timer",
        TimerTitleNew: "New timer",
        TimerTypeTime: "Exact time",
        TimerTypeSunset: "Sunset",
        TimerTypeSunrise: "Sunrise",
        TimerFillAllData: "To save you need to fill in all the fields. Also, to use the options for sunset and sunrise, you need to add an address, since their time depends on the location.",
        TimerTypeOffset: "Time offset, m",
        TimerTypeAdress: "Sunset/sunrise adress:",
        ButtonSensorName: "Button",
        CameraSensorName: "Camera",
        Cancel: "Cancel",
        TimerDeleteAsk: "This timer is used in scenarios. Are you sure want to remove it?",
        TimerSensorName: "Timer",
        ButtonButtonsTitle: "Buttons",
        ButtonDeleteAsk: "This button is used in scenarios. Are you sure want to remove it?",
        ButtonTitleEdit: "Edit button",
        ButtonTitleNew: "New button",
        SensorWOvalueNewScenarion: "New scenario",
        ScenarioCreateFromSensor: "Save and create scenario",
        NotificationsTitle: "Notifications",
        ScenarioSendPushTitle: "Send notification",
        ScenarioUseAsShieldTitle: "Controlled by security button",
        ScenarioSecurityTitle: "Security",
        ScenarioSecurityInfo: "To control cameras, use the VKU mobile application",
        CameraPreviewsTitle: "Cameras",
        CameraInformationTitle: "Camera information",
        CameraIsOffline: "Camera is offline",
        CameraScreensAll: "All cameras",
        CameraQualityTitle: "Video quality is ",
        CameraQualitySdTitle: "low",
        CameraQualityHdTitle: "high",
        ScenarioShowOnDashboardTitle: "Show on dashboard",
        DataLoading: "Loading...",
        AuthForgotPassword: "Forgot password",
        AuthForgotPasswordLink: "Forgot password?",
        ScenarioAlertPushTitle: "Important alert",
        PresetButtonChannelMinus: "-",
        PresetButtonChannelPlus: "+",
        PresetButtonFan: "Fan",
        PresetButtonMenu: "Menu",
        PresetButtonMenuDown: "Down",
        PresetButtonMenuExit: "Exit",
        PresetButtonMenuLeft: "Left",
        PresetButtonMenuOk: "OK",
        PresetButtonMenuRight: "Right",
        PresetButtonMenuUp: "Up",
        PresetButtonMinus: "Minus",
        PresetButtonMode: "Mode",
        PresetButtonMute: "Mute",
        PresetButtonOn: "On",
        PresetButtonPlus: "Plus",
        PresetButtonSmart: "Smart",
        PresetButtonSource: "Source",
        PresetButtonVolumeMinus: "-",
        PresetButtonVolumePlus: "+",
        PresetsConditioner: "Air conditioner",
        PresetsMisc: "Sundry",
        PresetsTv: "TV",
        ButtonIrCurrentDelete: "Delete",
        ButtonIrCurrentRetrain: "Retrain",
        ButtonIrCurrentTitle: "Options",
        ButtonIrPush: "Push",
        ButtonIrLearn: "Train",
        ButtonIrLearnAsk: "Ready to train?",
        ButtonIrLearnInfo: "Now push the button on your IR remote controller",
        ButtonIrLearnTitle: "Ir button",
        ButtonTypeGoToBed: "Go to bed",
        ButtonTypeHolidays: "Holidays",
        ButtonTypeLeaveHome: "Leave home",
        ButtonTypeOther: "Other",
        ButtonTypeReturnHome: "Return to home",
        ButtonTypeBath: "Bath",
        ButtonTypeCrop: "Garden",
        ButtonTypeFireplace: "Fireplace",
        ButtonTypeGarden: "Another garden",
        ButtonTypeGate: "Gates",
        ButtonTypeGreenenergy: "Wind",
        ButtonTypeHeater: "Heater",
        ButtonTypHouse: "House",
        ButtonTypeJacuzzi: "Jacuzzi",
        ButtonTypeKitchen: "Kitchen",
        ButtonTypeLivingRoom: "Living room",
        ButtonTypePlanting: "Planting",
        ButtonTypePump: "Pump",
        ButtonTypeRain: "Rain",
        ButtonTypeSauna: "Sauna",
        ButtonTypeSun: "Sun",
        ButtonTypeSwimmingPool: "Swimming pool",
        ButtonTypWateringCan: "Watering can",
        ButtonTypeSelect: "Select button type:",
        DeviceNameTitle: "Device name",
        ScenarioNewButtons: "Buttons",
        ScenarioNewSelectDevice: "Select device, buttons or timers",
        ScenarioNewTimers: "Timers",
        RegistrationError: "Error occurred during registration",
        RegistrationLoginExists: "Login already in use",
        LoginDoestExists: "Login does't exists",
        LoginUserTheSame: "You cannot send a friend request to yourself",
        RegistrationLoginIncorrect: "You should enter a valid email",
        RegistrationLoginTitle: "Login (email)",
        RegistrationPasswordEmpty: "You should specify a password",
        RegistrationWaitConfirm: "Confirm your registration by clicking on the link in the received email",
        UserInfoTitle: "Account",
        UserAddMoney: "Top up balance",
        UserInfoName: "First name",
        UserInfoLastName: "Last name",
        UserInfoAdress: "Street",
        UserInfoCountry: "Country",
        UserInfoState: "State",
        UserInfoDate: "Month, year of birth",
        UserInfoCity: "City",
        UserInfoPostal: "Postal / Zip Code",
        UserInfoPhone: "Phone",
        UserInfoFriends: "Friends",
        UserInfoSexTitle: "Sex",
        UserInfoSexMale: "Male",
        UserInfoSexFemale: "Female",
        UserInfPhoneConfirm: "Confirm phone number",
        ChangePasswordTitle: "Change password",
        ChangePasswordLoginNotExists: "Wrong password",
        ChangePasswordConfirm: "Confirm password changing by clicking on the link in the received email",
        ChangePasswordNotSame: "Passwords does't match",
        ChangePasswordError: "Error occurred during changing password",
        DeviceNewHelp: "Switch gateway to \"add device mode\" by clicking the button below. Then, following the instructions of the new device, connect it to the network (usually, just hold down the reset button for 6 seconds).",
        DeviceNewModeTitle: "Add mode:",
        DeviceNewNameHelp: "New device detected. Please enter name",
        GateNewAdded: "Gateway was successfully added",
        GateNewAnotherUser: "This gateway is allready registered",
        GateNewButtonTitle: "Add",
        GateNewHelp: "Please connect the gateway to the Internet. When enter the username and password written on it",
        GateNewNotFound: "Gateway not found",
        GateNewTitle: "Add gateway",
        GateDeleteText: "The removal of hub will bring to deletion of all its linked devices that belong to you and your friends, if you shared with them. Are you sure?",
        EquipmentTrackersTitle: "Trackers",
        EquipmentLinkedDevicesTitle: "Linked devices",
        DeviceActionsDelete: "Delete device",
        DeviceActionsDeleteFromLocation: "Delete from location",
        DeviceActionsAskDelete: "Are you sure want to delete this device?",
        DeviceDeleteOkTitle: "The device removal command was successfully sent.",
        DeviceNewGatewayTitle: "Select gateway:",
        ScenarioEmptyTitle: "You should enter name and description of scenario. You also need at least one condition and reaction.",
        ButtonIrDeletedSuccessTitle: "Button deleted successfully",
        ButtonIrDeleteFailedTitle: "An error occurred while deleting the button. Try to train again",
        ButtonIrLearnFailedTitle: "An error occurred while learning the button. Try to train again",
        ButtonIrLearnSuccessTitle: "Button trained successfully",
        PresetButtonOff: "Off",
        ButtonIrInvokeFailedTitle: "Invoke failed",
        ButtonIrInvokeSuccessTitle: "Invoked successfully",
        ButtonIrMessageTitle: "IR button",
        ButtonsIrMisc: "IR buttons",
        ButtonsIrMiscNewTitle: "New IR button",
        ButtonsIrMiscNewNameTitle: "Button name",
        ButtonsIrMiscNewGroupTitle: "Button group (optional)",
        ButtonIrMessageSendFailedTitle: "IR button did not respond to the command. Delete it?",
        Success: "Success",
        Fail: "Error occured",
        ScenarioTimeDuration: "End time",
        ScenarioTimeEnabled: "Enable time filter",
        ScenarioTimeStart: "Start time",
        ScenarioTimeDaysError: "You should select at least one day",
        DeviceLocationSendTitle: "Allow to sending your device location",
        CamerasTitle: "Cameras",
        CamerasNewTitle: "Add camera",
        CamerasMainSettingsTitle: "Main settings",
        CamerasNewHelp: "Scan or enter camera name",
        CamerasNewName: "Name",
        CameraFullscreenButtonTitle: "Open",
        CamerasNewPassword: "Password",
        CamerasNewDescription: "Description",
        CamerasNewNodata: "You should enter camera name and password",
        CamerasNewIncorrect: "Device with this name and password does't exist",
        CamerasNewExists: "Device with this name are already connected",
        CamerasNewAddTitle: "Add",
        CamerasNewTryConnectTitle: "Try connect",
        CamerasNewHotspotDoestSuppots: "Your device doesn't suppot in app wifi connection. Please connect to wifi {0} mannualy, default password is \"admin\"",
        CamerasNewAdded: "Camera was successfully added",
        CamerasNewScan: "Scan QR code",
        CameraVolumeTitle: "Volume",
        CamerasFilesTitle: "Records",
        CamerasFailFile: "Could't open file",
        CamerasRenameFile: "Rename",
        CamerasMailToFile: "Send",
        CamerasSaveToAlbumFile: "Save",
        CamerasRenameTitleFile: "Enter new file name",
        CamerasNewLanTitle: "Use only LAN connection",
        CamerasNewLanError: "Camera is not connected to internet. Please check LAN cable",
        CamerasNewWifiHelp: "Enter your wifi name and password, they will be set to the camera",
        CamerasSdRecords: "SD card records",
        CamerasSdFormat: "Format SD card",
        CamerasWiFiChangeTitle: "Change camera wifi",
        CamerasWiFiChangeHelp: "Select from avaliable wifi list:",
        CamerasWiFiChangeCurrent: "Current wifi: {0}",
        CamerasWiFiChangeNodata: "You should select wifi and enter its password",
        CamerasWiFiChangeSuccess: "Wifi changed successfully",
        CamerasRemove: "Remove from account",
        Apply: "Apply",
        CamerasFailOnline: "Could't connect to the camera",
        DeviceDimmerShowDimmerButtonTitle: "Show dimmer slider",
        CameraSettings0: "Soft",
        CameraSettings1: "Standard",
        CameraSettings2: "Colorful",
        CameraSettingsColor: "Colour",
        CameraSettingsRotation: "Rotation",
        CameraMediaSettings: "Media settings",
        CameraAlarmSettings: "Alarm settings",
        CameraRecSettings: "Record settings",
        CameraAlarmSettingsMotionDetection: "Motion detection",
        CameraAlarmSettingsSensitivity: "Sensitivity",
        CameraAlarmSettingsSiren: "Siren",
        CameraAlarmSettingsNotification: "Notification",
        CameraAlarmSettingsMode: "Send photo",
        CameraAlarmSettingsSchedule: "Schedule",
        CameraAlarmSettingsScheduleEnabled: "Enabled",
        CameraRecSettingsSchedule: "Record",
        CameraRecSettingsScheduleEnabled: "Enabled",
        CameraAlarmSettingsTrigger: "Trigger Recording",
        CameraAlarmSettingsAutoTracking: "Keeping track of an object",
        CameraPasswordChangeTitle: "Change password",
        CameraPasswordChangeOldTitle: "Old Password",
        CameraPasswordChangeNewTitle: "New Password",
        CameraPasswordChangeConfirmTitle: "Confirm Password",
        CamerasPasswordChangeNodata: "You should enter valid data",
        CamerasPasswordChangeSuccess: "Camera password changed successfully",
        CameraRecordsDaysTitle: "Days with records",
        CameraRecordsDaysCommonTitle: "normal",
        CameraRecordsDaysAlarmTitle: "alarm",
        CameraRecordsHoursTitle: "Hours with record",
        CameraRecordsMinutesTitle: "Time intervals",
        CameraRecordsMinutes: "min.",
        CameraRecordsDaysNoData: "no such days in this month",
        CameraDateSettingsTitle: "Date settings",
        CameraDateSettingsSet: "Set date",
        CameraMainOptions: "Camera options",
        CameraMainInformation: "Information",
        RelationsFriends: "Friends list",
        RelationsStatusFriend: "Friend",
        RelationsStatusIncomning: "Request recieved",
        RelationsStatusOutcoming: "Request sent",
        RelationsEmpty: "No data",
        RelationsSendRequest: "Send request",
        RelationsUserLogin: "User login",
        RelationsUserLoginAlready: "This user already your friend",
        RelationsUserLoginAlreadyOutgoing: "This user allready have your friend request",
        RelationsUserFriendDelete: "Delete this user from friends?",
        RelationsIncoming: "Friend request",
        RelationsIncomingAccept: "Accept",
        RelationsIncomingReject: "Reject",
        RelationsOutgoingCancel: "Cancel request?",
        RelationsSharedTitle: "Share (yes/no)",
        RelationsLinkedDelete: "Remove link",
        RelationsLinkedCount: "linked",
        RelationsSharedCount: "shared",
        RelationsPhotoAsk: "Take photo or pick from galery?",
        RelationsPhotoAskPhoto: "Photo",
        RelationsPhotoAskGalery: "Galery",
        SensorValueBattery: "On power",
        SensorValueHeatAlarm: "Overheat",
        SensorValueHeatNormal: "Normal",
        SensorTypeNameOnline: "Is online",
        SensorTypeNameBattery: "Battery",
        SensorTypeNameBatteryVoltage: "Battery voltage",
        SensorTypeNameLinkQuality: "Link quality",
        SensorTypeNameLeakage: "Leakage",
        SensorTypeNameHumidity: "Humidity",
        SensorTypeNameTemperature: "Temperature",
        SensorTypeNameBrightness: "Brightness",
        SensorTypeNamePressure: "Pressure",
        SensorTypeNameMotion: "Motion",
        SensorTypeNameTamperProof: "Tamper proof",
        SensorTypeNameTheDoor: "The door",
        SensorTypeNameKnock: "Knock",
        SensorTypeNameHeatAlarm: "Heat alarm",
        SensorTypeNamePM25Temperature: "PM25 Temperature",
        SensorTypeNamePM25Humidity: "PM25 Humidity",
        SensorTypeNamePM25particles: "PM25 particles",
        SensorTypeNameGasLeak: "Gas leak",
        SensorTypeNamePlugSwitch: "Plug switch",
        SensorTypeNameРowerConsumption: "Рower consumption",
        SensorTypeNameRMSVoltage: "RMS voltage",
        SensorTypeNameRMSCurrentPower: "RMS current power",
        SensorTypeNameActivePower: "Active power",
        SensorTypeNameSmokeSensor: "Smoke sensor",
        SensorTypeNameCurtainMotor: "Curtain Motor",
        SensorTypeNameCurtainTrip: "Curtain Trip",
        SensorTypeNameIRButton: "IR button",
        SensorTypeNameAddNewDevice: "Add new device",
        SensorTypeNameRemoveDevice: "Remove device",
        SensorTypeNameSmartDimmer: "Smart dimmer",
        SensorTypeNameSwitch: "Switch",
        SensorTypeNameGeo: "Adress",
        SensorTypeNameCourse: "Course",
        SensorTypeNameIgnition: "Ignition",
        SensorTypeNameSpeed: "Speed",
        SensorGeoShowMap: "Map",
        SensorDemPM25: "p/c",
        SensorDemBrightness: "lx",
        SensorDemV: "V",
        SensorDemCm: "cm",
        SensorDemKWh: "KWh",
        SensorDemW: "W",
        SensorDemdB: "dB",
        SensorSpeed: "km/h",
        SensorPressure: "mm Hg",
        SensorGeo: "m",
        Seconds: "seconds",
        SensorGeoIn: "to",
        SensorGeoOut: "from",
        TimeMinutesShort: "m.",
        TimeSecondsShort: "s.",
        LoginError: "Unable to log in. Check the spelling of the login and password.",
        RecoverPasswordTitle: "Recover password",
        RecoverPasswordOK: "Your password has been sent by email.",
        RecoverPasswordFail: "Error occurred during registration",
        PlaceTitle: "Place",
        PlaceNew: "New place",
        PlaceEdit: "Edit place",
        PlacesTitle: "Places",
        PlaceDef: "Yoy are here",
        PlaceNameTitle: "Name",
        PlaceAdressTitle: "Adress",
        PlaceRadiusTitle: "Radius, m",
        PlaceDeleteInUseAsk: "This place is used in scenarios. Are you sure want to remove it?",
        PlaceDeleteAsk: "Are you sure want to remove this place?",
        PlaceInside: "Drive into",
        PlaceOutside: "Drive out",
        FillAllData: "Fill in all fields to save",
        ThemeSettingNameTitle: "Color theme",
        ThemeSettingNameLight: "Light",
        ThemeSettingNameDark: "Dark",
        ThemeSettingNameControlled: "Auto selection",
        PromaSatTitle: "PromaSat",
        PromaSatHelp: "Enter your PromaSat account credentials to add your vehicles to the VKU system",
        PromaSatChangeAccount: "When changing the account, previously connected devices will be deleted with the connections. Are you sure?",
        PromaSatUnitHelp: "Tap on device to add or remove from system",
        PromaSatUnitAdd: "Remove from system",
        PromaSatUnitRemove: "Add to system",
        PromaSatUnitInSystem: "Connected",
        PromaSatUnitsMax: "The maximum number of connected devices has been reached.",
        PermissionRequestPhotoLibrary: "This requires access to the photo gallery",
        PermissionDeniedMessage: "No permissions to continue. You can change this in the settings",
        MessageUserUpdating: "Updating data..",
        PaymentsTitle: "Payments",
        Online: "Online",
        Offline: "Offline",
        PlugResetTitle: "Resetting total consumption",
        AmountTitle: "Account amount",
        AmountRubTitle: "rub",
        AmountOtherTitle: "",
        AccountFileSelector: "Change photo",
        AccountTypeTitle: "Account type",
        AccountTypeFreeTitle: "Free",
        AccountTypePremiumTitle: "Premium",
        AccountPremiumEndTitle: "Valid until",
        TransactionsTitle: "Transactions",
        CameraNewWifiAsk: "To configure the camera for the first time, go to the Wifi settings and connect to the {0} network, if the system prompts you to disconnect from the network due to the lack of Internet in this network, then refuse. Then return to the VKU application and try again.",
        TimeZoneTitle: "Time zone",

        LimitPremiumPromasatCount: "The maximum number of PromaSat Units are achieved",
        LimitPromasatCount: "The maximum number of PromaSat Units are achieved. To increase the number you need to get a premium account",
        LimitButtonsCount: "The maximum number of buttons are achieved. To increase the number you need to get a premium account",
        LimitCameraShare: "To share the cameras with friends you need to get a premium account",
        LimitDeviceShare: "To share the devices with friends you need to get a premium account",
        LimitPremiumDeviceShareCount: "The maximum number of devices are achieved",
        LimitScenariosCount: "The maximum number of scenarios are achieved. To increase the number you need to get a premium account",
        LimitFriendsCount: "The maximum number of scenarios are achieved. To increase the number you need to get a premium account",
        LimitAboutTitle: "Premium account",
        LimitAboutTextTitle: `After receiving a premium account, the following opportunities will be valuable: 
<br>1. Sharing the devices with more than 5 friends
<br>2. Setting up more than 30 variable scenarios
<br>3. Setting up more than 10 scenario control buttons
<br>4. Sharing more than 10 devices with the friends
<br>5. Sharing your cameras with your friends
<br>6. Adding up to 10 Promasat devices (only 3 devices are available with out a premium account)`,
        LimitUsersCountTitle: "Server is temporarily unavailable, or signed in to your account on a different device. Simultaneous use by the system on several devices is prohibited",
        SubscriptionEnableTitle: "Activate subscription",
        SubscriptionEnableListTitle: "Available subscriptions",
        SubscriptionMonth200Title: "One month for 200Rub",
        SubscriptionYear2000Title: "One year for 2000Rub",
        CameraNewErrorText: "Error occured. Please check WiFi is turned on",
        NoMoneyTitle: "There are not enough funds in the account",
        PremiumExpiriedAlertTitle: "Your premium account has expired. The privileges will be removed soon.",
        PremiumPrivilegiesRemovedTitle: "Three days have passed since the expiration of the premium account. Your privileges have been removed.",
        NotificationActionReaded: "Readed",
        NotificationActionNotReaded: "Not readed",
        CommandNotSendedWaitingTitle: "Command not sended. Waiting for a response to the previous command...",
        CommandNotSendedResponceTitle: "Responce did't come",
        Edit: "Edit",
        TurnOn: "Turn on",
        TurnOff: "Turn off",
        RoomDeleteAsk: "Are you sure want to remove this room?",
        TriggerDeleteAsk: "Are you sure want to remove this scenario?",
        ButtonDeleteAskMain: "Are you sure want to remove this button?",
        TimerDeleteAskMain: "Are you sure want to remove this timer?",
        CameraOpenTitle: "Open",
        SubCostTitle: "Cost",
        SubEnableTitle: "Connect",
        SubDurationTitle: "Duration",
        SubDurationMonthTitle: "Month",
        SubDurationYearTitle: "Year",
        ViewLogin__UpButtons__Enter: 'Login',
        ViewLogin__UpButtons__Registration: 'Registration',
        ViewLogin__About__Title: 'About',
        Modal__About__MainText: `Сервис умного дома VKU состоит из датчиков, передающих информацию о своем состоянии, исполнительных устройств, видеокамер и приложения для смартфонов, в котором пользователи контролируют статусы датчиков, дистанционно включают оборудование, настраивают сценарии, получают уведомления.
        <br>Преимуществами наших устройств является компактность, эргономичность, малое энергопотребление, дающее длительные сроки автономной работы и надежность, а плюсами сервиса является высокая скорость работы, надежность и резервирование данных в облачных сервисах.
        <br>Мы гордимся, что наш сервис создан Российскими программистами, имеющими огромный опыт по созданию телематических приложений, а используемые нами технологии являются передовыми и инновационными. Немаловажную роль имеет русскоязычная поддержка наших клиентов, готовая помочь решить любые сложности в работе сервиса и обучить владельцев тонкостям настройки сценариев и работы устройств.`,
        Modal__About__1Text: `Разработано ООО "Автостудия" / Autostudio LLC, 2022.
        <br>Все права защищены`,
        Modal__About__21Text: `Сайт:`,
        Modal__About__22Text: `Техническая поддержка:`,
        Modal__About__23Text: `Отдел продаж:`,
        Modal__About__24Text: `ИП Дуб Александр Юрьевич
        ОГРНИП 320253600038018`,
        Modal__About__25Text: `Политика конфиденциальности / Privacy policy`,
        SettingsShowSummaryVoltageTitle: 'Show total consumption',
        SettingsShowCurrentNotificationsTitle: 'Show notification feed',
        ViewCameraPreviewSelectionTitle: 'Select cameras to display',
        ViewCameraPreviewSelectionMaxCount: 'Maximum number of cameras reached',
        ViewCameraPreviewSelectionError: 'No data for this camera',
        MenuInformationWeather: 'Weather',
        AllRooms: 'All rooms',
        LocationPermissionError: 'Location permission not granted',
        WsStatusTitle: 'Connection status',
        WsStatusOkTitle: 'Connected',
        WsStatusFailTitle: 'Disconnected',
        TotalActivePower: 'Total power consumption',
        DeleteUserButtonTitle: 'Delete account',
        DeleteUserInfo: 'Deleting an account will make it impossible to use this e-mail to log in to the VKU system. For confirmation, you will receive a link to confirm the deletion by e-mail.'
    }
};