import './settingsButtons.css';
import { Component } from 'react';

import SettingsViewDevice from '../settingsViewDevice/settingsViewDevice';

import { Samy } from 'react-samy-svg';
import { ModalActions, deviceColors, LimitType } from '../../../helpers/constants';
import plus from '../../../images/menu/menu_plus.svg';
import { checkLimitByType,
    openNotificationWithIcon } from "../../../helpers/funcs";

import { userGetScenarioDevice } from '../../../helpers/funcs';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class SettingsButtons extends Component {
    constructor(props){
        super(props);

        this.AddNewButton = this.AddNewButton.bind(this);
    }

    AddNewButton(){
        let local = this.props.appState.local;
        let user = this.props.appState.user;

        let limitMsg = checkLimitByType(user, local, LimitType.ButtonsCount);
        if (limitMsg === null){
            this.props.appActions.setShowModal({
                modalAction: ModalActions.EditObject,
                objectModal: { 
                    model: 'ScenarioDevice.ButtonSensor',
                    Settings: {
                        Name: '',
                        ButtonType: 0
                    }
                }
            });
        } else {
            openNotificationWithIcon("info", local.AppName, limitMsg);
        }
    }

    render() {
        var tab = this.props.appState.activeTab;
        var user = this.props.appState.user;
        if (user === null || user === undefined) return null;
        
        var device = userGetScenarioDevice(user);
        if (device === undefined) return null;

        let buttonViews = device.sensors.map((el, index) => {
            return <SettingsViewDevice key={"settingsButtonsDevice" + el.guid} sensor={el} />;
        });
        buttonViews.splice(0, 0, 
            <div className='SettingsButtonsDevice AddButton' 
                style={{borderColor: deviceColors.borderBlue}}
                onClick={this.AddNewButton}>
                <Samy path={plus} />
            </div>);
        
        return (
            <div style={{display: tab === 5 ? "block" : "none"}}>
                <div className="SettingsButtons">
                    {
                        buttonViews
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(SettingsButtons);