import { createStore, applyMiddleware } from 'redux';
import createLogger from 'redux-logger';
import { rootReducer } from './reducers';
import { composeWithDevTools } from '@redux-devtools/extension';

import thunk from 'redux-thunk';

const composeEnhancers = composeWithDevTools({
	serialize: true,
});

export default function configureStore(initialState) {
    const loadLogger = false;
    let mws = loadLogger ? [thunk, createLogger()] : [thunk];
    const store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(...mws)),
        initialState
    );
    if (module.hot) {
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers').rootReducer;
            store.replaceReducer(nextRootReducer)
        });
    }
    return store
}