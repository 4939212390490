import axios from "axios";

import { origin, server, serverPromaSat, Credentials, CheckDev, ApiActions } from './constants';
import {
    imageEncode,
    setSensorValues,
    userGetScenarioDevice, userGetTimersDevice,
    userSetSettings, fillDevices, 
    userFillDeviceSettings, userFillMainSensors, userFillTriggers, userFillShowOnDashboard
} from './funcs';
import {
    deviceTrueDevice,
    getDeviceHub, getHubSensor
} from './helperDevice';
import {
    sendToGuidGuid
} from './helperWs';
import { 
    roomGetDevices 
} from './helperRoom';

import { openNotificationWithIcon } from './funcs';

const writeToConsole = true;
const showErrors = true;

function defHeaders(forToken = false) {
    var obj = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }
    if (CheckDev() === false) {
        //obj.headers.Origin = origin;
    }
    if (forToken === false) {
        obj.headers.Authorization = `Bearer ${Credentials.token}`;
    }
    return obj;
}

async function handleIf401(error) {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (showErrors === true && console) console.log(error.response.data);
        if (showErrors === true && console) console.log(error.response.status);
        if (showErrors === true && console) console.log(error.response.headers);
        if (showErrors === true){
            if (typeof error.response.data === 'string')
                openNotificationWithIcon('error', 'Request status - ' + error.response.status, error.response.data);
        }
        if (error.response.status === 401) {
            return await updateToken(Credentials.login, Credentials.password) === true;
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        if (showErrors === true && console) console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        if (showErrors === true && console) console.log('Error', error.message);
    }
    if (showErrors === true && console) console.log(error.config);
    return false;
}

const lockObj = {
    updateToken: false,
    count: 0
};

export async function updateToken() {
    if (lockObj.updateToken === true) return false;
    lockObj.updateToken = true;
    var result;
    try {
        result = await axios.post(`${server}/api/login`, {
            login: Credentials.login,
            password: Credentials.password
        }, defHeaders(true));
    } catch { }
    lockObj.updateToken = false;

    if (result?.status === 200) {
        const token = result.data;
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        Credentials.token = token;
        return true;
    }
    lockObj.count++;
    if (lockObj.count === 3) console.log(Credentials);
    if (writeToConsole === true && console) console.log(result);
    return false;
}

export async function getWsToken(user) {
    var result;
    try {
        result = await axios.get(`${server}/api/WsToken`, defHeaders());

        if (result.status === 200) {
            if (user.wsToken === result.data) {
                return false;
            }
            user.wsToken = result.data;
            return true;
        }
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await getWsToken(user);
        } else {
            return false;
        }
    }
}

export async function getUser() {
    var result;
    try {
        result = await axios.get(`${server}/api/user`, defHeaders());
        if (result.status === 200){
            let user = result.data;
            userSetSettings(user);
            let devices = {};
            fillDevices(user.devices, devices);
            user.allDevices = devices;
            userFillDeviceSettings(user);
            userFillMainSensors(user);
            userFillTriggers(user);
            userFillShowOnDashboard(user);
            let res = await getSensorValues(user);
            // TODO showDimer ??
            
            return user;
        }
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return null;
        }
        if (await handleIf401(error) === true) {
            return await getUser();
        } else {
            return null;
        }
    }
}

async function getSensorValues(user) {
    var result;
    try {
        result = await axios.get(`${server}/api/sensorvalue`, defHeaders());
        if (result.status === 200) {
            var values = result.data;
            setSensorValues(user, values);
            return true;
        }
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await getSensorValues(user);
        } else {
            return false;
        }
    }
}

export async function getCustomImage(user, customImage, method) {
    var result;
    try {
        var headers = defHeaders();

        if (method === ApiActions.Get){
            headers.responseType = 'arraybuffer';
            result = await axios.get(`${server}/api/userroom/${customImage}`, headers);
            if (result.status === 200) {
                var image = imageEncode(result.data);
                return image;
            } else{
                return null;
            }
        }
        if (method === ApiActions.Post){
            console.log(customImage.data)
            result = await axios.post(`${server}/api/userroom/${customImage.id}`, 
                customImage.data, headers);
            return true;
        }
        return false;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await getCustomImage(user, customImage, method);
        } else {
            return false;
        }
    }
}

export async function getCameraInfo(id) {
    var result;
    try {
        var headers = defHeaders();
        result = await axios.get(`${server}/api/ipcam/info/${id}`, headers);

        return result.data;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return null;
        }
        if (await handleIf401(error) === true) {
            return await getCameraInfo(id);
        } else {
            return null;
        }
    }
}

export async function updateTrigger(trigger, method) {
    var result;
    try {
        var headers = defHeaders();

        if (method === ApiActions.Put)
            result = await axios.put(`${server}/api/trigger/`, trigger, headers);
        if (method === ApiActions.Delete)
            result = await axios.delete(`${server}/api/trigger/${trigger.guid}`, headers);
        if (method === ApiActions.Post)
            result = await axios.post(`${server}/api/trigger/`, trigger, headers);

        return true;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await updateTrigger(trigger, method);
        } else {
            return false;
        }
    }
}

export async function updateRoom(user, room, method) {
    var result;
    try {
        var headers = defHeaders();

        if (method === ApiActions.Put){
            if (room.realDevices){
                delete room.realDevices;
            }
            result = await axios.put(`${server}/api/room`, room, headers);
            room.realDevices = roomGetDevices(user, room);
        }
        if (method === ApiActions.Delete)
            result = await axios.delete(`${server}/api/room/${room.guid}`, headers);
        if (method === ApiActions.Post) {
            result = await axios.post(`${server}/api/room`, room, headers);
            room.guid = result.data.guid;
        }

        return true;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await updateRoom(user, room, method);
        } else {
            return false;
        }
    }
}

export async function updateButton(user, button, method) {
    var result;
    try {
        var headers = defHeaders();
        var sd = userGetScenarioDevice(user);

        if (method === ApiActions.Put)
            result = await axios.put(`${server}/api/scenariodevice/${sd.guid}/${button.guid}`, button.settings, headers);
        if (method === ApiActions.Delete)
            result = await axios.delete(`${server}/api/scenariodevice/${sd.guid}/${button.guid}`, headers);
        if (method === ApiActions.Post) {
            result = await axios.post(`${server}/api/scenariodevice/${sd.guid}`, button.settings, headers);
            const newButton = result.data;
            newButton.device = sd;
            sd.sensors.push(newButton);
        }

        return true;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await updateButton(user, button, method);
        } else {
            return false;
        }
    }
}

export async function updateTimer(user, timer, method) {
    var result;
    try {
        var headers = defHeaders();
        var td = userGetTimersDevice(user);

        if (method === ApiActions.Put) {
            timer.device = undefined;
            result = await axios.put(`${server}/api/timerdevice/${td.guid}/${timer.guid}`, timer, headers);
            timer.device = td;
        }
        if (method === ApiActions.Delete)
            result = await axios.delete(`${server}/api/timerdevice/${td.guid}/${timer.guid}`, headers);
        if (method === ApiActions.Post) {
            result = await axios.post(`${server}/api/timerdevice/${td.guid}`, timer, headers);
            const newTimer = result.data;
            newTimer.device = td;
            td.sensors.push(newTimer);
        }

        return true;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await updateTimer(user, timer, method);
        } else {
            return false;
        }
    }
}

export async function updatePlace(user, place, method) {
    var result;
    try {
        var headers = defHeaders();

        if (method === ApiActions.Put)
            result = await axios.put(`${server}/api/location/`, place, headers);
        if (method === ApiActions.Delete)
            result = await axios.delete(`${server}/api/location/${place.guid}`, headers);
        if (method === ApiActions.Post) {
            result = await axios.post(`${server}/api/location/`, place, headers);
            user.locations.push(place);
        }

        return true;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await updatePlace(user, place, method);
        } else {
            return false;
        }
    }
}

export async function userPicture(user, image, method) {
    var result;
    try {
        var headers = defHeaders();

        if (method === ApiActions.Get){
            headers.responseType = 'arraybuffer';
            result = await axios.get(`${server}/api/userpicture/${user.login}`, headers);
            return imageEncode(result.data);
        }
        if (method === ApiActions.Post){
            console.log(image);
            result = await axios.post(`${server}/api/userpicture`, image, headers);
            return true;
        }

        return null;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await userPicture(user, image, method);
        } else {
            return false;
        }
    }
}

export async function updateDevice(user, ws, device, method) {
    var result;
    try {
        var headers = defHeaders();
        let td = deviceTrueDevice(user, device);
        let h = getDeviceHub(user, td);
        if (h === null) return false;

        if (method === ApiActions.Delete){
            let ds = getHubSensor(h, 'RemoveDeviceSensor');
            if (ds === null) return false;

            sendToGuidGuid(ws, user, ds.guid, td.guid);
            result = await axios.delete(`${server}/api/device/${device.guid}`, headers);
        }

        return true;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await updateDevice(user, ws, device, method);
        } else {
            return false;
        }
    }
}

export async function updateHub(local, hub, method) {
    var result;
    try {
        var headers = defHeaders();

        // if (method === ApiActions.Put)
        //     result = await axios.put(`${server}/api/minigateway/`, place, headers);
        if (method === ApiActions.Delete)
            result = await axios.delete(`${server}/api/minigateway/${hub.guid}`, headers);
        if (method === ApiActions.Post)
            result = await axios.post(`${server}/api/minigateway/`, hub, headers);

        return local.GateNewAdded;
    } catch (error) {
        if (error.response.status === 400) {
            if (error.response.data.includes("another user"))
                return local.GatewayRegisteredByAnotherUser;
            else
                return local.GateNewNotFound;
        }

        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await updateHub(local, hub, method);
        } else {
            return local.Fail;
        }
    }
}

export async function updateUserSettings(userSettings, method) {
    var result;
    try {
        var headers = defHeaders();

        // if (method === ApiActions.Put)
        //     result = await axios.put(`${server}/api/location/`, place, headers);
        // if (method === ApiActions.Delete)
        //     result = await axios.delete(`${server}/api/location/${place.guid}`, headers);
        if (method === ApiActions.Post)
            result = await axios.post(`${server}/api/usersettings/`, userSettings, headers);

        return true;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await updateUserSettings(userSettings, method);
        } else {
            return false;
        }
    }
}

export async function updateUserRelationsShared(obj, method) {
    var result;
    try {
        var headers = defHeaders();

        if (method === ApiActions.Post)
            result = await axios.post(`${server}/api/relations/shared/${obj.login}/${obj.guid}`, headers);
        if (method === ApiActions.Delete)
            result = await axios.delete(`${server}/api/relations/shared/${obj.login}/${obj.guid}`, headers);

        return true;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await updateUserRelationsShared(obj, method);
        } else {
            return false;
        }
    }
}

export async function updateUserRelationsLinked(obj, method) {
    var result;
    try {
        var headers = defHeaders();

        if (method === ApiActions.Delete)
            result = await axios.delete(`${server}/api/relations/linked/${obj.guid}`, headers);

        return true;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await updateUserRelationsLinked(obj, method);
        } else {
            return false;
        }
    }
}

export async function getHistory(s, start, end) {
    var result;
    try {
        var headers = defHeaders();
        let sd = start.startOf('day').utc().format();
        let ed = end.endOf('day').utc().format();
        result = await axios.get(`${server}/api/history/${s.guid}/${sd}/${ed}`, headers);

        return result.data;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return null;
        }
        if (await handleIf401(error) === true) {
            return await getHistory(s, start, end);
        } else {
            return null;
        }
    }
}

// status - friends, outgoing, incoming
export async function updateUserRelations(login, status, method) {
    var result;
    try {
        var headers = defHeaders();

        if (method === ApiActions.Post)
            result = await axios.post(`${server}/api/relations/${status}/${login}`, headers);
        if (method === ApiActions.Delete)
            result = await axios.delete(`${server}/api/relations/${status}/${login}`, headers);
        if (method === ApiActions.Put)
            result = await axios.put(`${server}/api/relations/${status}/${login}`, headers);

        return true;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await updateUserRelations(login, status, method);
        } else {
            return false;
        }
    }
}

export async function checkUserExists(login, method) {
    var result;
    try {
        var headers = defHeaders(true);

        if (method === ApiActions.Get){
            result = await axios.get(`${server}/api/loginexists/${login}`, headers);
            return result.data;
        }
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await checkUserExists(login, method);
        } else {
            return false;
        }
    }
}

export async function userRegistration(login, password, method) {
    var result;
    try {
        var headers = defHeaders(true);

        if (method === ApiActions.Post){
            result = await axios.post(`${server}/api/emailconfirmation`, 
                { login: login, password: password }, headers);
            return true;
        }
    } catch (error) {
        return false;
    }
}

export async function userPasswordRecover(login, method) {
    var result;
    try {
        var headers = defHeaders(true);

        if (method === ApiActions.Get){
            result = await axios.get(`${server}/api/passwordrecover/${login}`, headers);
            return true;
        }
    } catch (error) {
        return false;
    }
}

export async function getNotifications(method, list) {
    var result;
    try {
        var headers = defHeaders();

        if (method === ApiActions.Get){
            result = await axios.get(`${server}/api/notificationlist/`, headers);
            return result.data;
        }
        if (method === ApiActions.Put){
            result = await axios.put(`${server}/api/notificationlist/`, list, headers);
            return true;
        }

        return true;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await getNotifications(method);
        } else {
            return false;
        }
    }
}

export async function getTransactions(method) {
    var result;
    try {
        var headers = defHeaders();

        if (method === ApiActions.Get){
            result = await axios.get(`${server}/api/transaction/`, headers);
            return result.data;
        }

        return true;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await getTransactions(method);
        } else {
            return false;
        }
    }
}

export async function updateSubscription(title, method) {
    var result;
    try {
        var headers = defHeaders();

        if (method === ApiActions.Get){
            result = await axios.get(`${server}/api/subscription/getlist`, headers);
            return result.data;
        }
        if (method === ApiActions.Post){
            result = await axios.post(`${server}/api/subscription/${title}`, headers);
            return true;
        }

        return false;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await updateSubscription(title, method);
        } else {
            return false;
        }
    }
}

export async function changePassword(login, password, method) {
    var result;
    try {
        var headers = defHeaders();

        if (method === ApiActions.Post){
            result = await axios.post(`${server}/api/passwordchange`,
                { login: login, password: password }, headers);
            return true;
        }

        return false;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await changePassword(login, password, method);
        } else {
            return false;
        }
    }
}

export async function accountDelete() {
    var result;
    try {
        var headers = defHeaders();

        result = await axios.post(`${server}/api/deleteuser`,
            { }, headers);
        return true;

    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await accountDelete();
        } else {
            return false;
        }
    }
}

export async function updateIrButton(sensor, settings, method) {
    var result;
    try {
        var headers = defHeaders();

        if (method === ApiActions.Post)
            result = await axios.post(
                `${server}/api/X3IT01IRTransmitterButtons/${sensor.device.guid}`, settings, headers);
        if (method === ApiActions.Delete)
            result = await axios.delete(
                `${server}/api/X3IT01IRTransmitterButtons/${sensor.device.guid}/${sensor.guid}`, headers);
        if (method === ApiActions.Put)
            result = await axios.put(
                `${server}/api/X3IT01IRTransmitterButtons/${sensor.device.guid}/${sensor.guid}`, settings, headers);

        return true;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await updateIrButton(sensor, settings, method);
        } else {
            return false;
        }
    }
}

// ******************************* PromaSat web api functions *******************************

export async function promaSatAccount(obj, method) {
    let result;
    try {
        var headers = defHeaders(true);

        if (method === ApiActions.Get){
            var requestData = `?login=${obj.login}&password=${obj.password}`;
            result = await axios.get(`${serverPromaSat}/login.ashx${requestData}`, headers);
            return result.data.Status === 'OK';
        }

        return false;
    } catch (error) {
        return false;
    }
}

export async function promaSatUnits(obj, method) {
    let result;
    try {
        var headers = defHeaders(true);

        if (method === ApiActions.Get){
            var requestData = `?login=${obj.login}&password=${obj.password}`;
            result = await axios.get(`${serverPromaSat}/login.ashx${requestData}`, headers);
            if (result.data.Status === 'OK'){
                result = await axios.get(`${serverPromaSat}/s.ashx?action=GetUnits`, headers);
                if (result.data.Status === 'OK'){
                    return result.data.Result;
                }
            }

            return null;
        }

    } catch (error) {
        return null;
    }
}

export async function updatePromaSatUnit(obj, method) {
    var result;
    try {
        var headers = defHeaders();

        if (method === ApiActions.Delete)
            result = await axios.delete(`${server}/api/promasat/device/${obj.Id}`, headers);
        if (method === ApiActions.Post)
            result = await axios.post(`${server}/api/promasat/device/${obj.Id}`, headers);

        return true;
    } catch (error) {
        lockObj.count++;
        if (lockObj.count === 3){
            lockObj.count = 0;
            return false;
        }
        if (await handleIf401(error) === true) {
            return await updatePromaSatUnit(obj, method);
        } else {
            return false;
        }
    }
}

export async function updatePromaSatUnitVkuLink(obj, method) {
    let result;
    try {
        var headers = defHeaders(true);

        if (method === ApiActions.Get){
            var requestData = `?login=${obj.login}&password=${obj.password}`;
            result = await axios.get(`${serverPromaSat}/login.ashx${requestData}`, headers);
            if (result.data.Status === 'OK'){
                result = await axios.get(
                    `${serverPromaSat}/s.ashx?action=setvku&unitid=${obj.Id}&loginVku=${obj.login}&enabled=${obj.enable}`, 
                    headers);

                if (result.data.Status === 'OK'){
                    return result.data.Result;
                }
            }

            return null;
        }

        return false;
    } catch (error) {
        return false;
    }
}