import './viewDeviceSelector.css';
import { Component } from 'react';

import { deviceColors, ModalActions } from "../../helpers/constants";
import { deviceImage,
    deviceIconFill } from '../../helpers/helperDevice';
import { getConditions, getActors, sensorName } from '../../helpers/helperSensor';
import { deviceFullName, deviceBorderColor,
    getOperatorsNames } from '../../helpers/funcs';

import { v4 as uuidv4 } from 'uuid';
import { Samy } from 'react-samy-svg';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewDeviceSelector extends Component {
    constructor(props){
        super(props);

        this.onClose = this.onClose.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSensorChanged = this.onSensorChanged.bind(this);
        this.onOperatorChanged = this.onOperatorChanged.bind(this);
        this.onPlaceChanged = this.onPlaceChanged.bind(this);
        this.onValueChanged = this.onValueChanged.bind(this);

        this.selectedSensors = [];
        this.places = this.props.appState.user.locations;

        this.state = {
            selectedDevice: null,
            selectedSensor: 0,
            selectedPlace: 0,
            selectedOperator: 0,
            selectedValue: 0,
            value: 0,
            radius: 50
        };
    }

    onClose(){
        this.setState({
            selectedDevice: null,
            selectedSensor: 0,
            selectedPlace: 0,
            selectedOperator: 0,
            selectedValue: 0,
            value: 0,
            radius: 50
        });
        
        this.props.appActions.setShowSecondModal({
            modalSecondAction: ModalActions.None,
            modalSecondFunc: null
        });
    }

    onSave(){
        const selSensor = this.selectedSensors[this.state.selectedSensor];
        var obj = {
            guid: uuidv4()
        };
        
        var modalAction = this.props.appState.modalSecondAction;
        if (modalAction === ModalActions.SelectIf){
            obj.sensorGuid = selSensor.guid;

            if (selSensor.sensorValueType === "WithoutValue"){
            
            } else if (selSensor.model.includes("GeoSensor")){
                obj.operator = this
                    .selectedSensors[this.state.selectedSensor]
                    .possibleOperators[this.state.selectedOperator];
                obj.value = {
                    locationGuid: this.places[this.state.selectedPlace].guid,
                    distance: this.state.radius
                }
            } else if (selSensor.possibleValues === undefined){
                obj.value = this.state.value;
                obj.operator = this
                    .selectedSensors[this.state.selectedSensor]
                    .possibleOperators[this.state.selectedOperator];
            } else {
                obj.operator = this
                    .selectedSensors[this.state.selectedSensor]
                    .possibleOperators[this.state.selectedOperator];
                obj.value = this
                    .selectedSensors[this.state.selectedSensor]
                    .possibleValues[this.state.selectedValue];
            }

            this.props.appState.modalSecondFunc('condition', obj);
        }
        if (modalAction === ModalActions.SelectThen){
            obj.actorGuid = selSensor.guid;

            if (selSensor.model === 'TimeDelay'){
                obj.type = 'Delay';
                obj.value = this.state.value;
            } else {
                obj.type = 'Command';
                if (selSensor.model === "X3IT01.ButtonSensor"){
                    obj.value = 'InvokeSuccess';
                } else {
                    if (selSensor.possibleValues === undefined){
                        obj.value = this.state.value;
                    } else {
                        obj.value = selSensor.possibleValues[this.state.selectedValue];
                    }
                }
            }

            this.props.appState.modalSecondFunc('actor', obj);
        }

        this.onClose();
    }

    onSensorChanged(e){
        //var s = this.selectedSensors[parseInt(e.target.value)]
        this.setState({
            selectedSensor: parseInt(e.target.value)
        });
    }

    onOperatorChanged(e){
        //var s = this.selectedSensors[parseInt(e.target.value)]
        this.setState({
            selectedOperator: parseInt(e.target.value)
        });
    }

    onPlaceChanged(e){
        //var p = this.places[parseInt(e.target.value)]
        this.setState({
            selectedPlace : parseInt(e.target.value)
        });
    }

    onValueChanged(e){
        //var p = this.places[parseInt(e.target.value)]
        this.setState({
            selectedValue : parseInt(e.target.value)
        });
    }
    
    render() {
        const local = this.props.appState.local;
        const isDarkTheme = this.props.appState.isDarkTheme;
        const user = this.props.appState.user;

        var modalAction = this.props.appState.modalSecondAction;
        if (modalAction !== ModalActions.SelectIf
            && modalAction !== ModalActions.SelectThen) return null;

        var saveDisabled = true;

        var lOperators = false;
        var slPlaceSelector = false;
        var pOperators = false;
        var pValues = false;
        var stValue = false;
        var stRadius = false;
        var isDelay = false;

        var devices = [];

        if (modalAction === ModalActions.SelectIf){
            const conditions = getConditions(user);
            devices = conditions.devices;
            if (this.state.selectedDevice !== null){
                this.selectedSensors = [];
                conditions.sensors.forEach(s =>{
                    if (s.device.guid === this.state.selectedDevice.guid){
                        this.selectedSensors.push(s);
                    }
                });
                const selSensor = this.selectedSensors[this.state.selectedSensor];
                if (selSensor !== null){
                    if (selSensor.sensorValueType === "WithoutValue"){
                        saveDisabled = false;
                    } else if (selSensor.model.includes("GeoSensor")){
                        saveDisabled = false;
                        lOperators = true;
                        pOperators = true;
                        slPlaceSelector = true;
                        stRadius = true;
                    } else if (selSensor.possibleValues === undefined){
                        saveDisabled = !Number.isInteger(this.state.value);
                        pOperators = true;
                        lOperators = true;
                        stValue = true;
                    } else {
                        saveDisabled = false;
                        pOperators = true;
                        lOperators = true;
                        pValues = true;
                    }
                }
            }
        }
        if (modalAction === ModalActions.SelectThen){
            const actors = getActors(user);
            devices = actors.devices;
            if (this.state.selectedDevice !== null){
                this.selectedSensors = [];
                actors.sensors.forEach(s =>{
                    if (s.device.guid === this.state.selectedDevice.guid){
                        this.selectedSensors.push(s);
                    }
                });
                const selSensor = this.selectedSensors[this.state.selectedSensor];
                console.log(selSensor)
                
                if (selSensor.model === 'TimeDelay'){
                    saveDisabled = false;
                    isDelay = true;
                    lOperators = true;
                    stValue = true;
                } else if (selSensor.model === "X3IT01.ButtonSensor"){
                    saveDisabled = false;
                    this.state.value = 'InvokeSuccess';
                } else if (selSensor.possibleValues === undefined){
                    saveDisabled = !Number.isInteger(this.state.value);
                    pOperators = true;
                    lOperators = true;
                    stValue = true;
                } else {
                    saveDisabled = false;
                    pOperators = true;
                    lOperators = true;
                    pValues = true;
                }
            }
        }

        return (
            <div className={"ViewDeviceSelector"}>
                {
                    <div className={"ViewDeviceSelector__main"}
                        style={{
                            borderColor: deviceColors.borderBlue,
                            backgroundColor: isDarkTheme 
                                ? deviceColors.backGroundDark 
                                : deviceColors.backGroundLight}}>
                        <div className='ViewDeviceSelector__main__Devices'>
                        {
                            devices.map((device, i) => {
                                const borderColor = deviceBorderColor(device, isDarkTheme);
                                const iconStyle = deviceIconFill(user, device, borderColor);

                                return <div className='ViewDeviceSelector__main__Devices__Device'
                                    key={`ViewDeviceSelector__main__Devices__Device${device.guid}`}
                                    onClick={() => this.setState({
                                        selectedDevice: device,
                                        selectedSensor: 0,
                                        selectedPlace: 0,
                                        selectedOperator: 0,
                                        selectedValue: 0,
                                        value: device.model === "TimeDelay" ? 60 : 0
                                    })}>
                                    <div className="ViewDeviceSelector__main__Devices__Device__MainImage">
                                        <Samy path={deviceImage(user, device)} style={iconStyle} />
                                    </div>
                                    <p key={device.guid}>{deviceFullName(device, local)}</p>
                                </div>;
                            })
                        }
                        </div>
                        <div className='ViewDeviceSelector__main__Selected'>
                            {
                                this.state.selectedDevice === null ? null :
                                <div>
                                    <p>{deviceFullName(this.state.selectedDevice, local)}</p>
                                    {
                                        isDelay === true ? null :
                                        <div className='ViewDeviceSelector__main__Selected_Sensor'>
                                            <p>{local.ScenarioIfNewCondition}</p>
                                            <select
                                                defaultValue={this.state.selectedSensor}
                                                onChange={this.onSensorChanged}>
                                                {
                                                    this.selectedSensors.map((s, i)=>{
                                                        return <option value={i} key={"selectedSensor"+i}>
                                                            {s != null ? sensorName(user, s, local) : ''}
                                                    </option>;
                                                })}
                                            </select>
                                        </div>
                                    }
                                    {
                                        lOperators === false ? null :
                                        <div className='ViewDeviceSelector__main__Selected_Operators'>
                                            <p>{modalAction === ModalActions.SelectIf 
                                                ? local.ScenarioIfNewOperand
                                                : local.ScenarioIfNewValueHelp}</p>
                                            {
                                                pOperators === false || modalAction === ModalActions.SelectThen ? null :
                                                <select
                                                    defaultValue={this.state.selectedOperator}
                                                    onChange={this.onOperatorChanged}
                                                    >
                                                    {
                                                        getOperatorsNames(local, 
                                                            this.selectedSensors[this.state.selectedSensor].possibleOperators)
                                                            .map((o, i)=>{
                                                            return <option value={i} key={"selectedOperator"+i}>
                                                                {o != null ? o : ''}
                                                        </option>;
                                                    })}
                                                </select>
                                            }
                                            {
                                                stValue === false ? null :
                                                <input
                                                    value={this.state.value}
                                                    onInput={(e, value) => {
                                                        if (e.constructor.name === "SyntheticBaseEvent") 
                                                            this.forceUpdate();
                                                    }}
                                                    onChange={(e)=> {
                                                        let v = parseInt(e.target.value);
                                                        if (Number.isNaN(v)) v = 60;
                                                        if (isDelay === true){
                                                            if (v > 3600) v = 3600;
                                                            if (v < 1) v = 1;
                                                        }
                                                        this.setState({value: v});
                                                    }}>
                                                </input>
                                            }
                                            {
                                                pValues === false ? null :
                                                <select
                                                    defaultValue={this.state.selectedValue}
                                                    onChange={this.onValueChanged}>
                                                    {
                                                        this.selectedSensors[this.state.selectedSensor]
                                                            .possibleValues.map((v, i)=>{
                                                                return <option value={i} key={"selectedValue"+i}>
                                                                    {v}
                                                        </option>;
                                                    })}
                                                </select>
                                            }
                                            {
                                                slPlaceSelector === false ? null :
                                                <select
                                                    defaultValue={this.state.selectedPlace}
                                                    onChange={this.onPlaceChanged}>
                                                    {
                                                        this.places.map((p, i)=>{
                                                            return <option value={i} key={"selectedPlace"+i}>
                                                                {p != null ? p.label : ''}
                                                        </option>;
                                                    })}
                                                </select>
                                            }
                                        </div>
                                    }
                                    {
                                        stRadius === false ? null :
                                        <div className='ViewDeviceSelector__main__Selected_Radius'>
                                            <p>{local.PlaceRadiusTitle}</p>
                                            <input
                                                    value={this.state.radius}
                                                    onInput={(e, value) => {
                                                        if (e.constructor.name === "SyntheticBaseEvent") 
                                                            this.forceUpdate();
                                                    }}
                                                    onChange={(e)=> {
                                                        var v = parseInt(e.target.value);
                                                        if (Number.isNaN(v)) v = 0;
                                                        if (v > 10000) v = 10000;
                                                        if (v < 10) v = 10;
                                                        this.setState({radius: v});
                                                    }}
                                                />
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        <button 
                            style={{margin: "10px 0px", height:"45px"}}
                            disabled={saveDisabled}
                            onClick={this.onSave}>
                            {local.Save}
                        </button>
                        <button 
                            style={{margin: "10px 0px", height:"45px"}}
                            onClick={this.onClose}>
                            {local.Close}
                        </button>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewDeviceSelector);