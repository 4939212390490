import React from 'react';
import ReactDOM from 'react-dom';
import LocalProviderWrapper from './localProviderWrapper.js';

import { Provider } from 'react-redux'

import configureStore from './configStore';
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <LocalProviderWrapper />
  </Provider>,
  document.getElementById('root')
);
