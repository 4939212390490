import './viewEditButton.css';
import { Component } from 'react';

import { Samy } from 'react-samy-svg';

import { deviceColors, ApiActions } from "../../../helpers/constants";
import { ButtonTypes, 
    buttonGetIcon, 
    buttonGetIconStrokeWidth,
    buttonGetIconFill,
    buttonGetTypeName } from '../../../helpers/helperSensor';
import { openNotificationWithIcon, userGetScenarioDevice, deleteByGuid } from '../../../helpers/funcs';
import { getUser, updateButton } from "../../../helpers/helperApi";
import confirm from '../../viewModal/modalConfirm';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewEditButton extends Component {
    constructor(props){
        super(props);

        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.onTypeChanged = this.onTypeChanged.bind(this);
        this.close = props.close;

        var button = this.props.sensor;
        if (button.settings === undefined){
            button.settings = {
                Name: '',
                ButtonType: 0
            };
        }
        
        this.state = {
            changed: false,
            name: button.settings.Name,
            type: button.settings.ButtonType,
            icon: null
        };
    }

    async save(){
        var local = this.props.appState.local;
        var user = this.props.appState.user;
        var button = this.props.sensor;
        button.settings.Name = this.state.name;
        button.settings.ButtonType = this.state.type;
        if (button.guid === undefined){
            let result = await updateButton(user, button, ApiActions.Post);
            if (result === true){
                let newUser = await getUser();
                this.props.appActions.setUser({ user: newUser });
                openNotificationWithIcon("info", local.Save, local.Success);
                this.close();
            } else {
                // TODO if error what??
            }
        } else {
            let result = await updateButton(user, button, ApiActions.Put);
            if (result === true){
                let newUser = await getUser();
                this.props.appActions.setUser({ user: newUser });
                openNotificationWithIcon("info", local.Update, local.Success);
                this.close();
            } else {
                // TODO if error what??
            }
        }
    }

    async delete(){
        var local = this.props.appState.local;
        var user = this.props.appState.user;
        var button = this.props.sensor;
        var isDarkTheme = this.props.appState.isDarkTheme;
        
        if (await confirm(local.appTitle, local.ButtonDeleteAskMain, local.Yes, local.Cancel, isDarkTheme) === false){
            return;
        }

        var result = await updateButton(user, button, ApiActions.Delete);
        if (result === true){
            var sd = userGetScenarioDevice(user);
            if (sd !== null){
                deleteByGuid(sd.sensors, button);
                this.props.appActions.setUser({ user: user });
            }
            openNotificationWithIcon("info", local.Delete, local.Success);
            this.close();
        } else {
            // TODO if error what??
        }
    }

    onTypeChanged(e){
        var button = this.props.sensor;
        this.state.type = parseInt(e.target.value);
        this.setState({
            changed: this.state.changed === true 
                || button.settings.ButtonType !== this.state.type
        });
    }

    render() {
        var local = this.props.appState.local;
        var button = this.props.sensor;

        var iconStyle = {
            strokeWidth: buttonGetIconStrokeWidth(this.state.type), 
            strokeMiterlimit: 0,
            stroke: deviceColors.borderBlue,
            fill: buttonGetIconFill(this.state.type, deviceColors.borderBlue)
        };
        const icon = buttonGetIcon(this.state.type);
        
        return (
            <div className="ViewEditButton">
                <p>
                    {button.guid === undefined ? local.ButtonTitleNew : local.ButtonTitleEdit}
                </p>
                <input className="ViewEditButton__Name"
                    value={this.state.name}
                    onInput={(e, value) => {
                        if (e.constructor.name === "SyntheticBaseEvent") 
                            this.forceUpdate();
                    }}
                    onChange={(e)=> this.setState({name: e.target.value, changed: true})}
                    style={{margin: "10px 0px", height:"45px"}}
                    placeholder={local.ScenarioCreateNewButtonName}/>
                <select required={true} 
                    defaultValue={this.state.type}
                    onChange={(e) => this.onTypeChanged(e)}>
                    {
                        Object.entries(ButtonTypes).map((el, i)=>{
                            return <option value={el[1]} key={"ButtonTypes"+i}>
                                {buttonGetTypeName(el[1], local)}
                        </option>;
                    })}
                </select>
                <div className="ViewEditButton__Image" key={icon}>
                    <Samy path={icon} style={iconStyle} />
                </div>
                <div className='ViewEditButton__Buttons'>
                    <button 
                        disabled={this.state.changed === false}
                        onClick={this.save}>
                        {local.Save}
                    </button>
                    {
                        button.guid === undefined ? null :
                        <button
                            onClick={this.delete}>
                            {local.Delete}
                        </button>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewEditButton);