import { notification } from 'antd';

import { deviceColors, FriendStatus, ApiActions, dashFilterDefault,
    globalLocale, CurrencyEnum, ModeEnum, LimitType } from './constants';
import { 
    deviceGetDefaultMainSensor, 
    deviceIsLinked, 
    deviceIsShared, 
    deviceTrueDevice } from "./helperDevice";
import deviceName from '../components/viewModal/modalDeviceName';
import { updateUserRelationsShared, 
    updatePromaSatUnitVkuLink,
    updatePromaSatUnit,
    updateButton,
    updateTrigger,
    updateUserRelations } from "./helperApi";

const openGeocoder = require('node-open-geocoder');

//var Enumerable = require('linq');
export function fillDevices(devices, res){
    devices.forEach((d, i)=>{
        res[d.guid] = d;
        if (d.devices !== null && d.devices !== undefined)
            fillDevices(d.devices, res);
    })
}

export function getDashBoardDevices(user, model){
    let devices = getDevicesWithSensors(user);
    let dashDevices = [];
    devices.forEach(d =>{
        if (deviceShowInDashboard(user, d) && !deviceIsCamera(d)) {
            if (model && model !== dashFilterDefault){
                // let trueDevice = deviceTrueDevice(user, d);
                // if (model === "CompositeDevice"){
                //     if (trueDevice.model.includes("CompositeDevice")
                //         //&& trueDevice.devices.length === 2
                //         ){
                //             dashDevices.push(d);
                //         }
                // } else 
                if (d.model === model){
                    dashDevices.push(d);
                }
            }
            else
                dashDevices.push(d);
        }
    });
    return dashDevices;
}

export function getDashBoardUniqueDevices(user, devices){
    let uniqueDevices = [dashFilterDefault];
    devices.forEach(d => {
        let model = d.model;
        // if (model === "OnOffSwitch")
        // {
        //     var trueDevice = deviceTrueDevice(user, d);
        //     if (trueDevice.model.includes("CompositeDevice") 
        //         && trueDevice.devices.length === 2) {
        //         model = 'CompositeDevice';
        //     }
        // }
        if (!uniqueDevices.includes(model)) uniqueDevices.push(model);
    });
    return uniqueDevices;
}

export function userGetScenarioDevice(user){
    for(var d of user.devices){
        if (d.model.includes("ScenarioDevice")){
            return d;
        }
    }
    return undefined;
}

export function userGetCamerasDevices(user){
    var cameras = [];
    for(var d of user.devices){
        if (d.model.includes("GenericIpcam")){
            cameras.push(d);
        }
    }
    return cameras;
}

export function userGetTimersDevice(user){
    for(var d of user.devices){
        if (d.model.includes("TimerDevice")){
            return d;
        }
    }
    return undefined;
}

export function getDevicesWithSensors(user){
    let res = [];
    if (user.devices !== null && user.devices !== undefined){
        user.devices.forEach(d =>{
            res = res.concat(getNotIgnoredDevices(d));
        });
    }
    return res;
}

export function getAllDevicesWithSensors(user){
    let res = [];
    if (user.devices !== null && user.devices !== undefined){
        user.devices.forEach(d =>{
            res = res.concat(getDevices(d));
        });
    }
    return res;
}

export function getNotIgnoredDevices(d){
    let res = [];
    if (!ignoredDevices.includes(d.model) && deviceHasSensors(d)){
        res.push(d);
    }
    if (d.devices !== null && d.devices !== undefined)
    {
        d.devices.forEach(dc => {
            res = res.concat(getNotIgnoredDevices(dc));
        });
    }
    return res;
}

export function getDevices(d){
    let res = [];
    if (deviceHasSensors(d)){
        res.push(d);
    }
    if (d.devices !== null && d.devices !== undefined)
    {
        d.devices.forEach(dc => {
            res = res.concat(getDevices(dc));
        });
    }
    return res;
}

export function deviceHasSensors(d){
    return d.sensors !== null && d.sensors !== undefined
        && d.sensors.length > 1;
}

export function deviceIsCamera(device){
    return device.model.includes("GenericIpcam");
}

export function deviceShowInDashboard(user, device){
    return user.settings.showInDashboard[device.guid] || deviceIsLinked(user, device);
}

export function userGetSensors(user, update = false){
    if (update || user.sensors === null || user.sensors === undefined){
        let sensors = {};
        user.devices.forEach(el =>{
            let ds = getFullSensorList(el);
            ds.forEach(s => sensors[s.guid] = s);
        });
        user.sensors = sensors;
    }
    return user.sensors;
}

export function getFullSensorList(d){
    let result = [];
    if (d.sensors !== null && d.sensors !== undefined){
        d.sensors.forEach(s =>{
            s.device = d;
            result.push(s);
        })
    }
    if (d.devices !== null && d.devices !== undefined){
        d.devices.forEach(d =>{
            result = result.concat(getFullSensorList(d));
        });
    }
    return result;
}

export function setSensorValues(user, values){
    for(const [k, value] of Object.entries(values)){
        setSensorValue(user, k, value);
    }
}

export function setSensorValue(user, guid, value){
    var sensors = userGetSensors(user);
    if (!Object.keys(sensors).includes(guid)) return;

    var sensor = sensors[guid];
    var bytes = base64ToBytes(value);
    //var sendOn = bytes.slice(1, 8);
    sensor.value = bytes.slice(8, bytes.length);
    updateParsedValue(sensor);

    if (sensor.model.includes("OnlineSensor") && sensor.device !== undefined){
        sensor.device.online = sensor.value[0] === 1;
    }
}

export function parseFloatValue(sensor, value){
    var bytes = base64ToBytes(value);
    return convertFloat(sensor, bytes);
}

export function base64ToBytes(base64) {
    var binary_string = window.atob(base64);
    var bytes = [];
    for (var i = 0; i < binary_string.length; i++) {
        bytes.push(binary_string.charCodeAt(i));
    }
    return bytes;
}

export function imageEncode(arrayBuffer) {
    //let u8 = new Uint8Array(arrayBuffer)
    let b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer),function(p,c){return p+String.fromCharCode(c)},''))
    let mimetype="image/jpeg"
    return "data:"+mimetype+";base64,"+b64encoded;
}

function base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

export function imageDecode(file){
    var b64_btoa = window.btoa(file);
    let decodedBytes = base64ToArrayBuffer(b64_btoa);
    return decodedBytes;
}

function getNumber(arr, index = 0){
    if (arr.length === index) return 0;
    let num = arr[index] * Math.pow(255, index);
    return num + getNumber(arr, index + 1);
}

export function updateParsedValue(sensor){
    if (sensor.value === null || sensor.value === undefined) return null;
    if (sensor.sensorValueType === "Float"){
        sensor.parsedValue = convertFloat(sensor);
    }
    if (sensor.sensorValueType === "Discrete"){
        sensor.parsedValue = sensor.value[0] === 1;
    }
    var v = sensor.value;
    if (sensor.sensorValueType === "Integer"){
        if (sensor.model.includes("LinkQualitySensor")){
            sensor.dbValue = Math.round(v[0] * 91.0 / 255.0 - 81.0);
            sensor.parsedValue = sensor.dbValue;
            sensor.parsedValue = getSignalStatus(sensor.parsedValue);
        } else {
            if (v.length === 1){
                sensor.parsedValue = v[0];
            } else {
                sensor.parsedValue = getNumber(sensor.value);
            }
        }
    }
    if (sensor.sensorValueType === "ByteArray"){
        sensor.parsedValue = v;
    }
    if (sensor.sensorValueType === "Geo"){
        if (v === null || v === undefined || v.length !== 8)
            sensor.parsedValue = null;
        else {
            var lat = getFloatFromBytes(v);
            var lng = getFloatFromBytes(v, 4);
    
            sensor.parsedValue = {lat, lng};
            openGeocoder()
                .reverse(lng, lat)
                .end((err, res) => {
                    if (err === null){
                        if (res.display_name !== undefined){
                            sensor.adress = res.display_name;
                            sensor.adressShort = res.display_name;
                        }
                    }
                });
            // Geocode.setLanguage(globalLocale.locale);
            // Geocode.setApiKey(googleGeoApiKey);
            // Geocode.fromLatLng(lat, lng).then(
            //     (response) => {
            //         if (response?.results?.length > 0
            //             && response.results[0].formatted_address !== undefined){
            //                 sensor.adress = response.results[0].formatted_address;
            //                 sensor.adressShort = response.results[0].formatted_address;
            //                 for (let i = 0; i < response.results[0].address_components.length; i++) {
            //                     const ac = response.results[0].address_components[i];
            //                     if (ac.types.includes('locality')){
            //                         sensor.adressShort = ac.short_name;
            //                         break;
            //                     }
            //                 }
            //         }
            //     },
            //     (error) => { }
            // );
        }
    }
    if (sensor.sensorValueType === "None"){
        sensor.parsedValue = v;
    }

    return sensor.parsedValue;
}

function getSignalStatus(v){
    if (v === null || v === undefined) return "offline";

    if (v < -80) return "offline";
    if (v < -61) return "bad";
    if (v < -48) return "good";
    if (v < -35) return "verygood";
    return "best";
}

function convertFloat(sensor, value = null){
    var v = value === null ? sensor.value : value;
    var n = getNumber(v);
    if (sensor.model.includes("RmsVoltageSensor")
        || sensor.model.includes("RmsCurrentPowerSensor")
        || sensor.model.includes("ActivePowerSensor")){
        return n / 10.0;
    }

    if (sensor.model.includes("PowerConsumptionSensor"))
    {
        return n / 10000.0;
    }

    if (sensor.sensorValueType === "Integer")
    {
        if (sensor.model.includes("LinkQualitySensor"))
        {   
            return Math.round(v[0] * 91.0 / 255.0 - 81.0);
        }
        if (v.length === 1)
        {
            return v[0];
        }
        return n;
    }

    let val;
    if (v.length === 1)
    {
        val = v[0] * 1.0;
    } else if (v.length === 2){
        val = twoComponetsIntoSignedInt(v[1], v[0]);
    } else {
        val = n * 1.0;
    }

    if (!sensor.model.includes("X3PD01.HumiditySensor"))
    {
        val /= 100.0;
    }
    return val;
}

function twoComponetsIntoSignedInt(byteA, byteB){
    var sign = byteA & (1 << 7);
    var x = (((byteA & 0xFF) << 8) | (byteB & 0xFF));
    if (sign) {
        var result = 0xFFFF0000 | x;
    }
    return result ?? x;
}

function getFloatFromBytes(arr, index = 0){
    var data = new Uint8Array(4);
    data[0] = arr[index+0];
    data[1] = arr[index+1];
    data[2] = arr[index+2];
    data[3] = arr[index+3];
    var f32 = new Float32Array(data.buffer);
    var f32value = f32[0];
    return f32value;
}

export function deviceBorderColor(device, isDark){
    return device.online 
        ? (deviceIsOrange(device) 
            ? (deviceIsRedAlert(device) 
                ? deviceColors.borderAlert 
                : deviceColors.borderOrange) 
            : deviceColors.borderBlue) 
        : (isDark 
            ? deviceColors.borderGreyDark 
            : deviceColors.borderGreyLight);
}

export function deviceIsOrange(device){
    if (alertDevices.includes(device.model)){
        for(const s of device.sensors){
            if (alertSensors.includes(s.model)
                && s.parsedValue !== null && s.parsedValue !== undefined){
                    if (device.model === "Dimer"){
                        if (s.model.includes("SwitchSensor") && s.parsedValue === true){
                            return true;
                        }
                        if (s.model.includes("DimmerSensor") && s.parsedValue !== 0x1){
                            return true;
                        }
                        continue;
                    }
                    if (typeof s.parsedValue === "boolean" && s.parsedValue === true) return true;
                    if (typeof s.parsedValue === "number" && s.parsedValue > 0) return true;
                }
        }
    }
    return false;
}

export function deviceIsRedAlert(device){
    return redAlertDevices.includes(device.model);
}

export function deviceFullName(device, local){
    if (device.model === "TimeDelay") return local.ScenarioThenTimeDelay;

    if (device.settings === null || device.settings === undefined)
        return device.modelDescription;
    if (device.settings.order === 1 || device.settings.order === -1) 
        return device.settings.name;
    return `${device.settings.name} (${device.settings.order})`;
}

export function deviceIsPowered(device){
    return poweredDevices.includes(device.model)
}

export function devicesGetMult(devices){
    if (devices === undefined || devices === null) return undefined;
    var multOrPm = undefined;
    for(var d of devices){
        if (d.model.includes("X3MD01") || d.model.includes("X3PD01")){
            multOrPm = {
                temp: undefined,
                hum: undefined
            };
            for(var s of d.sensors){
                if (s.model.includes("TemperatureSensor")) multOrPm.temp = s;
                if (s.model.includes("HumiditySensor")) multOrPm.hum = s;
            }
            break;
        }
    }
    return multOrPm;
}

export function userGetScenarioDevices(user){
    var arr = [];
    for(var d of user.devices){
        if (d.model === "ScenarioDevice") arr.push(d);
    }
    return arr;
}

export function userGetPromaSatDevice(user){
    for(var d of user.devices){
        if (d.model === "PromasatGateway") return d;
    }
    return null;
}

export function userGetScenarioButtons(user, devices){
    var arr = [];
    for(var sd of devices){
        for(var s of sd.sensors){
            for(var t of user.triggers){
                for(var c of t.conditions){
                    if (c.sensorGuid === s.guid){
                        arr.push(s);
                    }
                }
            }
        }
    }
    return arr;
}

export function userGetHubs(user){
    var hubs = [];

    for(var d of user.devices){
        if (d.model.includes("X3Gw01")){
            hubs.push(d);
        }
    }
    return hubs;
}

export function userGetLinkedDevices(user){
    var devices = [];
    for(var d of user.devices){
        if (d.model.includes("LinkedDevice")){
            devices = devices.concat(d.devices);
        }
    }
    return devices;
}

export function userGetCameraDevices(user){
    var devices = [];
    for(var d of user.devices){
        if (d.model.includes("GenericIpcam")){
            devices.push(d);
        }
    }
    return devices;
}

export function userGetPromaSatDevices(user){
    var devices = [];
    for(var d of user.devices){
        if (d.model.includes("PromasatGateway")){
            devices = devices.concat(d.devices);
        }
    }
    return devices;
}

export function userIsDemo(user){
    return user?.role?.toLowerCase() === 'demo';
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
export const openNotificationWithIcon = (type, msg, descr) => {
    notification[type]({ message: msg, description: descr });
};
export function guid(){
    let u = Date.now().toString(16) + Math.random().toString(16) + '0'.repeat(16);
    let guid = [u.substr(0,8), u.substr(8,4), '4000-8' + u.substr(13,3), u.substr(16,12)].join('-');
    console.log(guid);
    return guid;
}

export function defTimer(cron, localId){
    return {
        duration: 8 * 60,
        enabled: false,
        cronStart: cron,
        timeZoneId: localId
    };
}

export function getCurrencyName(currency, local){
    switch (currency){
        case CurrencyEnum.Unknown: return local.AmountOtherTitle;
        case CurrencyEnum.RUB: return local.AmountRubTitle;
        default: return local.AmountOtherTitle;
    }
}

export function getDurationName(dur, local){
    switch (dur){
        case 'Year': return local.SubDurationYearTitle;
        case 'Month': return local.SubDurationMonthTitle;
        default: return local.SubDurationMonthTitle;
    }
}

export function getAccountModeName(mode, local){
    switch (mode){
        case ModeEnum.Free: return local.AccountTypeFreeTitle;
        case ModeEnum.Premium: return local.AccountTypePremiumTitle;
        default: return local.AccountTypeFreeTitle;
    }
}


const alertSensors = [
    "X3WD01.WaterSensor",
    "X3DS01.OpenCloseSensor", "X3DS01.KnockSensor",
    "X3GD02.GasSensor", "X3SD02.SmokeSensor",
    "X2SK11.SwitchSensor", "X3SW13.SwitchSensor", "X3SW13.SwitchSensor", "OnOffSwitch.SwitchSensor",
    "X3MD01.MotionSensor", "X3MD01.TamperProofSensor",
    "X3MC01MultiControl.SwitchSensor",
    "SmartDimmerModule.SwitchSensor", "SmartDimmerModule.DimmerSensor", 
    "Dimmer.DimmerSensor", "Dimmer.SwitchSensor",
    "PromasatTracker.SpeedSensor", "PromasatTracker.IgnitionSensor"
]

const redAlertDevices = [ "X3SD02", "X3PD01", "X3WD01", "X3MD01", "X3DS01" ]

const alertDevices = [ 
    "X3WD01", "X3DS01", "X3GD02", "X3SD02", "X2SK11",
    "X3SW13", "X3MD01", "X3SWxx", "OnOffSwitch", 
    "Dimmer", "X3MC01MultiControl", "PromasatTracker"
]

const ignoredDevices = [
    "X3Gw01",
    "ScenarioDevice",
    "TimerDevice",
    "GenericIpcam"
]

const poweredDevices = [ "X0RE01", "X3SD02", "X2CC11", "X2SK11", "OnOffSwitch" ]

var hexChar = ["0", "1", "2", "3", "4", "5", "6", "7","8", "9", "a", "b", "c", "d", "e", "f"];

// function dateFromCTicks(ticks){
//     //ticks are in nanotime; convert to microtime
//     var ticksToMicrotime = ticks / 10000;
//     //ticks are recorded from 1/1/1; get microtime difference from 1/1/1/ to 1/1/1970
//     var epochMicrotimeDiff = 2208988800000;
//     //new date is ticks, converted to microtime, minus difference from epoch microtime
//     var tickDate = new Date(ticksToMicrotime - epochMicrotimeDiff);
//     return tickDate;
// }

function byteToHex(b) {
  return hexChar[(b >> 4) & 0x0f] + hexChar[b & 0x0f];
}
const wsDebug = false;
export function parseWsBlob(arrayBuffer){
    var dv = new DataView(arrayBuffer);
    var result = {
        protocolVersion: dv.getUint8(0),
        messageType: dv.getUint8(1),
        userVersion: dv.getUint8(2),
        guid: null,
        value: null
    }
    var guid = '';
    for(let i=3; i<7; i++){
        guid = byteToHex(dv.getUint8(i)) + guid;
    }
    var guid2 = '';
    for(let i=7; i<9; i++){
        guid2 = byteToHex(dv.getUint8(i)) + guid2;
    }
    guid += '-' + guid2;
    var guid3 = '';
    for(let i=9; i<11; i++){
        guid3 = byteToHex(dv.getUint8(i)) + guid3;
    }
    guid += '-' + guid3 + '-';
    for(let i=11; i<19; i++){
        guid += byteToHex(dv.getUint8(i));
        if (i === 6 || i === 8 || i === 10 || i === 12)
            guid += '-';
    }
    result.guid = guid;

    // var tickArray = [];
    // for(var i=26; i>=19; i--){
    //     tickArray.push(dv.getUint8(i));
    // }
    // result.sendOn = dateFromCTicks(getNumber(tickArray));

    var value = [];
    for(let i=27; i<dv.byteLength; i++){
        value.push(dv.getUint8(i));
    }
    result.value = value;

    if (wsDebug === true && console) console.log(result);
    return result;
}

export function deleteByGuid(array, item){
    var i = -1;
    for(var f in array){
        var el = array[f];
        if (el.guid === item.guid){
            i = f;
        }
    }
    if (i !== -1) array.splice(i, 1);
}

const OperatorEnum = {
    Equals: 'Equals',
    NotEquals: 'NotEquals',
    MoreThan: 'MoreThan',
    LessThan: 'LessThan',
    Inside: 'Inside',
    Outside: 'Outside',
}

export function getOperatorsNames(local, operators) {
    var res = [];
    for (var i in operators) {
        res.push(getOperatorName(local, operators[i]));
    }
    return res;
}

export function getOperatorName(local, operator){
    var res = [];
    switch (operator)
    {
        case OperatorEnum.Equals:
            res.push("=");
            break;
        case OperatorEnum.LessThan:
            res.push("<");
            break;
        case OperatorEnum.MoreThan:
            res.push(">");
            break;
        case OperatorEnum.NotEquals:
            res.push("<>");
            break;
        case OperatorEnum.Inside:
            res.push(local.PlaceInside);
            break;
        case OperatorEnum.Outside:
            res.push(local.PlaceOutside);
            break;
        default: ; 
    }
    return res[0];
}

export function userSetSettings(user){
    let settings = user.settings;

    if (settings === null || settings === undefined){
        settings = {
            time: new Date(),
            info: {
                adress: null,
                adressCity: null,
                adressCountry: null,
                adressPostal: null,
                adressState: null,
                burnDate: "1986-01-01T00:00:00",
                lastName: null,
                name: "",
                phoneNumber: null,
                sex: 0
            }
        };
        user.settings = settings;
    }
    if (settings.devicesSettings === null || settings.devicesSettings === undefined)
        settings.devicesSettings = [];
    if (settings.mainSensors === null || settings.mainSensors === undefined)
        settings.mainSensors = {};
    if (settings.promaSatUser === null || settings.promaSatUser === undefined)
        settings.promaSatUser = { login:'', password:'' };
    if (settings.showDimmer === null || settings.showDimmer === undefined)
        settings.showDimmer = {};
    if (settings.showInDashboard === null || settings.showInDashboard === undefined)
        settings.showInDashboard = {};
}

export function userFillDeviceSettings(user){
    const devices = user.allDevices;
    user.settings.devicesSettings.forEach((ds, i)=>{
        if (ds.deviceId in devices){
            devices[ds.deviceId].settings = ds;
        }
    });
    for(var id in devices){
        var d = devices[id];
        if (d.settings === null || d.settings === undefined){
            setDefaultSetting(d);
            user.settings.devicesSettings.push(d.settings);
        }
    }
}

export function userFillMainSensors(user){
    const devices = user.allDevices;
    for(var id in devices){
        var d = devices[id];
        if (!(d.guid in user.settings.mainSensors)){
            var ds = deviceGetDefaultMainSensor(d);
            if (ds !== null) user.settings.mainSensors[d.guid] = ds.guid;
        }
    }
}

export function userFillShowOnDashboard(user){
    const devices = user.allDevices;
    for(var id in devices){
        var d = devices[id];
        if (!(d.guid in user.settings.showInDashboard)){
            user.settings.showInDashboard[d.guid] = true;
        }
    }
}

export function userFillTriggers(user){
    for(var f in user.triggers){
        var t = user.triggers[f];
        if (t.timeFilter === null || t.timeFilter === undefined){
            t.timeFilter = defTimer('10 08 ? * MON,TUE,WED,THU,FRI', 'Etc/UTC');
        } else {
            if (t.timeFilter.timeZoneId === '') t.timeFilter.timeZoneId = 'Etc/UTC';
        }
        if (t.showOnDashBoard === null || t.showOnDashBoard === undefined){
            t.showOnDashBoard = false;
        }
        if (t.useAsShield === null || t.useAsShield === undefined){
            t.useAsShield = false;
        }
    }
}

function setDefaultSetting(device){
    var s = {
        deviceId: device.guid,
        name: device.modelDescription.replace("detector", "sensor"),
        order: 1
    };
    // if (s.name === null || s.name === ''){
    //     if (device.model == "TimerDevice") s.name = res.TimerButtonsTitle;
    //     if (device.model == "ScenarioDevice") s.name = res.ButtonButtonsTitle;
    // }
    device.settings = s;
}

export async function checkNewUserForNewDevices(local, user, newUser, onSave, isDark){
    const newDevices = [];
    for(var newI in newUser.allDevices){
        var newD = newUser.allDevices[newI];
        if (newD.model === "ScenarioDevice"
            || newD.model === "TimerDevice"
            || newD.model === "TimeDelay"
            //|| newD.model === "PromasatTracker"
            || deviceIsLinked(newUser, newD) === true) continue;
        
        let exists = false;
        for(var oldI in user.allDevices){
            var oldD = user.allDevices[oldI];
            if (oldD.guid === newD.guid) {
                exists = true;
                break;
            }
        }
        
        if (exists === false){
            newDevices.push(newD);
        }
    }
    for(var d of newDevices){
        if (await deviceName(local, newUser, d, isDark) === true){
            if (onSave) onSave();
        }
    }
}

export function getAllFriends(user){
    let friends = [];
    let rel = user.relations;
    if (rel.friends !== null && rel.friends !== undefined){
        for(let f of rel.friends){
            friends.push({
                name: f,
                status: FriendStatus.Friends
            });
        }
    }
    if (rel.incoming !== null && rel.incoming !== undefined){
        for(let f of rel.incoming){
            friends.push({
                name: f,
                status: FriendStatus.Incoming
            });
        }
    }
    if (rel.outgoing !== null && rel.outgoing !== undefined){
        for(let f of rel.outgoing){
            friends.push({
                name: f,
                status: FriendStatus.Outgoing
            });
        }
    }

    return friends;
}

export function getFriendStatusTitle(local, status){
    switch (status){
        case FriendStatus.Incoming: return local.RelationsStatusIncomning;
        case FriendStatus.Outgoing: return local.RelationsStatusOutcoming;
        default: return local.RelationsStatusFriend
    }
}

export function getFriendDevicesTitle(local, relations, friend){
    let linked = 0, shared = 0;
    for(let f in relations.linked){
        if (relations.linked[f] === friend) linked++;
    }
    for(let f in relations.shared){
        let s = relations.shared[f];
        if (s.includes(friend)) shared++;
    }
    return `${shared} ${local.RelationsSharedCount}, ${linked} ${local.RelationsLinkedCount}`;
}

export function getUserFriendExists(user, local, login){
    if (user.login === login){
        return local.LoginUserTheSame;
    }
    let rel = user.relations;
    if (rel.friends !== null && rel.friends !== undefined){
        for(let f of rel.friends){
            if (f === login) return local.RelationsUserLoginAlready;
        }
    }
    if (rel.outgoing !== null && rel.outgoing !== undefined){
        for(let f of rel.outgoing){
            if (f === login) return local.RelationsUserLoginAlreadyOutgoing;
        }
    }
    return local.OK;
}

export function getSecurityButtonEnable(user){
    let securityButtonExists = false;
    let enabled = true;
    for(let t of user.triggers){
        if (t.useAsShield === true){
            securityButtonExists = true;
            if (enabled === true && t.enabled === false)
                enabled = false;
        }
    }
    return { securityButtonExists, enabled };
}

export async function checkPremiumLimits(user, local){
    if (user.account.mode !== ModeEnum.Free) return;

    //user.account.endOn = "11/5/2021";
    var date1 = new Date(user.account.endOn);
    var date2 = new Date();
    var diffInDays = Math.round((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));

    if (diffInDays < 0) return;
    if (diffInDays < 3) {
        openNotificationWithIcon('info', local.appTitle, local.PremiumExpiriedAlertTitle);
        return;
    }
    let needToSay = false;
    let result = false;

    { // trackers
        let trackers = userGetPromaSatDevices(user);
        if (trackers.length > 3){
            needToSay = true;
            let pg = userGetPromaSatDevice(user);
            for(let i in trackers){
                if (i < 3) continue;
                if (deviceIsShared(user, trackers[i]) === true){
                    let users = user.relations.shared[trackers[i].guid];
                    for(let u of users){
                        result = await updateUserRelationsShared(
                            {login: u, guid: trackers[i].guid}, ApiActions.Delete);
                    }
                } 
                result = await updatePromaSatUnitVkuLink({
                    login: user.settings.promaSatUser.login,
                    password: user.settings.promaSatUser.password,
                    enable: false,
                    Id: trackers[i].unitId
                }, ApiActions.Get);
                if (result === true){
                    result = await updatePromaSatUnit({ Id: trackers[i].unitId }, ApiActions.Delete);
                }
            }
        }
    }

    { // buttons
        let scenarioDevices = userGetScenarioDevices(user);
        let buttons = userGetScenarioButtons(user, scenarioDevices);
        if (buttons.length >= 10){
            needToSay = true;
            for(let i in buttons){
                if (i <= 10) continue;
                result = await updateButton(user, buttons[i], ApiActions.Delete);
            }
        }
    }

    { // cameras shares clear
        let cameras = userGetCamerasDevices(user);
        for(let c of cameras){
            if (deviceIsShared(user, c) === true){
                needToSay = true;
                let users = user.relations.shared[c.guid];
                for(let u of users){
                    result = await updateUserRelationsShared(
                        {login: u, guid: c.guid}, ApiActions.Delete);
                }
            }
        }
    }

    { // devices shares clear
        let count = 0;
        for(let guid in user.relations.shared){
            let device = user.allDevices[guid];
            if (device === undefined || deviceIsCamera(device) === true) continue;
            for(let user in user.relations.shared[guid]){
                if (count < 10) count++;
                else {
                    needToSay = true;
                    result = await updateUserRelationsShared(
                        {login: user, guid: guid}, ApiActions.Delete);
                }
            }
        }
    }

    { // scenarios
        if (user.triggers.length > 30){
            needToSay = true;
            for(let i in user.triggers){
                if (i < 30) continue;
                result = await updateTrigger(user.triggers[i], ApiActions.Delete);
            }
        }
    }

    { // friends
        let friends = getAllFriends(user);
        if (friends.length > 5){
            needToSay = true;
            for(let i in friends){
                if (i < 5) continue;
                let friend = friends[i];

                switch (friend.status){
                    case FriendStatus.Incoming:{
                        result = await updateUserRelations(friend.name, 'incoming', ApiActions.Delete);
                        break;
                    }
                    case FriendStatus.Outgoing:{
                        result = await updateUserRelations(friend.name, 'outgoing', ApiActions.Delete);
                        break;
                    }
                    default:{
                        result = await updateUserRelations(friend.name, 'friends', ApiActions.Delete);
                        break;
                    }
                }
            }
        }
    }

    if (needToSay === true){
        openNotificationWithIcon('info', local.appTitle, local.PremiumPrivilegiesRemovedTitle);
    }
    // true - need to update user
    return needToSay;
}

export function checkLimitByType(user, local, limit){
    if (userIsDemo(user) === true){
        return local.NotInDemo;
    }

    let isPrem = user.account.mode === ModeEnum.Premium;

    if (limit === LimitType.PromasatUnitsCount){
        let trackers = userGetPromaSatDevices(user);

        if (isPrem === true){
            if (trackers.length >= 10) return local.LimitPremiumPromasatCount;
        } else {
            if (trackers.length >= 3) return local.LimitPromasatCount;
        }
    }

    if (limit === LimitType.ButtonsCount){
        let scenarioDevices = userGetScenarioDevices(user);
        let buttons = userGetScenarioButtons(user, scenarioDevices);

        if (isPrem === false){
            if (buttons.length >= 10) return local.LimitButtonsCount;
        }
    }

    if (limit === LimitType.CameraShare){
        if (isPrem === false){
            return local.LimitCameraShare;
        }
    }

    if (limit === LimitType.DeviceShare){
        let count = 0;
        for(let guid in user.relations.shared){
            count += user.relations.shared[guid].length;
        }
        if (isPrem === false){
            if (count >= 10) return local.LimitPremiumDeviceShareCount;
        }
    }

    if (limit === LimitType.ScenariosCount){
        let count = user.triggers.length;
        if (isPrem === false){
            if (count >= 30) return local.LimitScenariosCount;
        }
    }

    if (limit === LimitType.FriendsCount){
        let count = user.relations.friends.length;
        if (isPrem === false){
            if (count >= 5) return local.LimitFriendsCount;
        }
    }

    return null; // it's ok
}