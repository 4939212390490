import './viewMenu.css';
import { Component } from 'react';

import dashSelected from '../../images/menu/menu_dash_selected.svg';
import dash from '../../images/menu/menu_dash.svg';
import roomsSelected from '../../images/menu/menu_rooms_selected.svg';
import rooms from '../../images/menu/menu_rooms.svg';
import scenariosSelected from '../../images/menu/menu_scenarios_selected.svg';
import scenarios from '../../images/menu/menu_scenarios.svg';
import settingsSelected from '../../images/menu/menu_settings_selected.svg';
import settings from '../../images/menu/menu_settings.svg';
import plus from '../../images/menu/menu_plus.svg';
import exit from '../../images/menu/exit.svg';
import exitSelected from '../../images/menu/exitSelected.svg';
import camera from '../../images/menu/camera.svg';
import cameraSelected from '../../images/menu/cameraSelected.svg';

import { Credentials } from '../../helpers/constants';
import { Popover, Tooltip } from 'antd';

import ViewNewObject from '../viewNewObject/viewNewObject';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class MenuItem extends Component {

    constructor(props){
        super(props);

        this.exit = this.exit.bind(this);

        var tab = props.activeTab;
        this.setActiveTab = this.setActiveTab.bind(this, tab);
        this.onMouseOut = this.onMouseOut.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.hidePopover = this.hidePopover.bind(this);

        this.state = {
            hovered: false,
            ppVisible: false
        }
    }

    setActiveTab(tab, e){
        e.stopPropagation();

        if (tab === 3){
            // + button
        } else if (tab === 99){
            this.exit();
        } else {
            this.props.appActions.setActiveTab({activeTab: tab});
        }
    }

    exit() {
        Credentials.token = null;
        Credentials.login = null;
        Credentials.password = null;
        const ws = this.props.appState.ws;
        if (ws !== null && ws.readyState === 1){
            ws.close();
        }
        this.props.appState.activeTab = 2;
        this.props.appState.isDarkTheme = true;
        this.props.appState.dashExpandedDevice = null;
        this.props.appState.settingsDefTab = 0;
        this.props.appState.ws = null;
        this.props.appActions.setUser({user: null});
    }
    
    onMouseOver() {
        this.setState({ hovered: true });
    }

    onMouseOut() {
        this.setState({ hovered: false });
    }

    handleVisibleChange(visible) {
        this.setState({ ppVisible: visible });
    };

    hidePopover(){
        this.setState({ ppVisible: false });
    }

    render() {
        var tab = this.props.activeTab;
        var isSelected = this.state.hovered ||
            tab === this.props.appState.activeTab;
        var local = this.props.appState.local;
        let { collapsed } = this.props;

        var imgSrc = plus;
        var nameTitle = "";
        let itemStyle = {};
        if (tab === 3){
        } else if (tab === 1){
            imgSrc = isSelected ? dashSelected : dash;
            nameTitle = local.MenuDashboard;
        } else if (tab === 2){
            imgSrc = isSelected ? roomsSelected : rooms;
            nameTitle = local.MenuHome;
        } else if (tab === 4){
            imgSrc = isSelected ? scenariosSelected : scenarios;
            nameTitle = local.MenuScenarios;
        } else if (tab === 5){
            itemStyle.marginTop = 'auto';
            imgSrc = isSelected ? settingsSelected : settings;
            nameTitle = local.MenuSettings;
        } else if (tab === 99){
            imgSrc = isSelected ? exitSelected : exit;
            nameTitle = local.Exit;
        } else if (tab === 6){
            imgSrc = isSelected ? cameraSelected : camera;
            nameTitle = local.CameraPreviewsTitle;
        }

        return (
            <div className="viewMenu__item" style={itemStyle}
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
                onClick={e => this.setActiveTab(e)}>
                {
                    tab === 3
                    ? <div>
                        <Popover
                            placement="right"
                            onVisibleChange={this.handleVisibleChange}
                            mouseEnterDelay={0}
                            trigger="hover"
                            visible={this.state.ppVisible}
                            content={tab === 3 ? <ViewNewObject hidePopover={this.hidePopover} /> : null}>
                            <img className={"menu_plus_img"} 
                                src={imgSrc} alt=""
                                onClick={(e) => e.stopPropagation()}/>
                        </Popover>
                    </div>
                    : 
                    <Tooltip placement="right" title={nameTitle} mouseEnterDelay={0}>
                        <div className='viewMenu__item__content'>
                            <img src={imgSrc} alt=""/>
                            <p style={{
                                visibility: !collapsed,
                                opacity: collapsed === true ? 0 : 1,
                                color: isSelected ? '#2C98D2' : '#B8B8B8'}}>
                                {nameTitle}
                            </p>
                        </div>  
                    </Tooltip>
                }
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);