export const APP = {
    USER: 'APP_USER',
    DATA: 'APP_DATA',
    SETTINGS: 'APP_SETTINGS',
    ACTIVE_TAB: 'APP_ACTIVE_TAB',
    LOCAL: 'APP_LOCAL',
    THEME: 'APP_THEME',
    DASH_EXPANDED_DEVICE: 'DASH_EXPANDED_DEVICE',
    SHOW_DEFAULT_MODAL: "SHOW_DEFAULT_MODAL",
    SHOW_SECOND_MODAL: "SHOW_SECOND_MODAL",
    DASH_FILTER_MODEL: "DASH_FILTER_MODEL",
    DASH_FILTER_ROOM: "DASH_FILTER_ROOM",
    APP_WS_IS_OK: "APP_WS_IS_OK"
}

export const ModalActions = {
  EditObject: 'EditObject',
  EditRoom: 'EditRoom',
  SelectRoom: 'SelectRoom',
  EditShare: 'EditShare',
  NewRoom: 'NewRoom',
  NewGate: 'NewGate',
  NewScenario: 'NewScenario',
  NewDevice: 'NewDevice',
  EditPlace: 'EditPlace',
  EditScenario: 'EditScenario',
  SelectIf: 'SelectIf',
  SelectThen: 'SelectThen',
  OpenCameras: 'OpenCameras',
  OpenIR: 'OpenIR',
  None: 'none'
}

export const ApiActions = {
    Get: 'Get',
    Post: 'Post',
    Put: 'Put',
    Delete: 'Delete'
  }

export const NotificationTypeEnum = {
    Regular: 'Regular',
    Alert: 'Alert'
}

export const CurrencyEnum = {
    Unknown: 'Unknown',
    RUB: 'RUB',
}

export const ModeEnum = {
    Free: 'Free',
    Premium: 'Premium',
}

export const FriendStatus = {
    Friends: 'Friends',
    Incoming: 'Incoming',
    Outgoing: 'Outgoing'
}

export const LimitType = {
    CameraShare: 'CameraShare',
    DeviceShare: 'DeviceShare',
    ScenariosCount: 'ScenariosCount',
    ButtonsCount: 'ButtonsCount',
    FriendsCount: 'FriendsCount',
    PromasatUnitsCount: 'PromasatUnitsCount'
}

export const COLORS = ['#328FCB', '#AB393A', '#E18940', '#BC4D33', '#60943F', '#CBA130',
    '#72A7DA', '#A1BF65', '#8165A2', '#46A1B9', '#B61917', '#608AA4', '#A05B2C',
    '#5E4977', '#8D8D8D', '#1C8F6D', '#B87FB6', '#AA9B87', '#BE504D']

export const deviceColors = {
    borderAlert: "#E03B12",
    borderOrange: "#FD5C02",
    borderBlue: "#2C98D2",
    borderGreyLight: "#6B6965",
    borderGreyDark: "#B8B8B880",
    backGroundDark: "#282828",
    backGroundLight: "#FFF",
    backGroungCircleButton: "#A9A9A9",
    statusOk: '#47bd41',
    statusOff: '#E03B12'
}

export const timeDelayDevice = {
    model: "TimeDelay",
    sensors: [{
        guid: "2f23efed-1137-4273-be11-7d71d4df5904",
        model: "TimeDelay",
        device: null
    }]
}

export const dashFilterDefault = 'all';

export const Days = [ "MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN" ]

export var server = serverPrefix();
export var serverPromaSat = serverPromaSatPrefix();

export const googleMapApiKey = 'AIzaSyCXtwGU9Ms8jgDGW-yBeIl7bdXiN3MvNVI';
//export const googleGeoApiKey = 'AIzaSyADqzTiUcVTEiTcS6JT38HYYvypmLcw7CQ';
export const globalLocale = {
    locale: 'ru-Ru'
};
// export const optGeocode = {
//     provider: 'yandex',
//     language: globalLocale.locale
// };

function serverPrefix(){
    if (CheckDev() === true) {
        return "."
    } else {
        // production code
        return `https://server.vkonnektu.com`;
    }
}

function serverPromaSatPrefix(){
    if (CheckDev() === true) {
        return "."
    } else {
        // production code
        return `http://map.proma-sat.ru/`;
    }
}

export const origin = `https://web.vkonnektu.com`; 

export function CheckDev(){
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}


export const wsServerProt = "wss";
export const serverProt = "https";
export const serverAddress = "server.vkonnektu.com";
export const vkuSite = "https://vkonnektu.com/";

export const Credentials = {
    autoEnterPassed: false,
    token: null,
    login: null,
    password: null
}