import battery_30 from '../images/sensors/battery_30.svg';
import battery_90 from '../images/sensors/battery_90.svg';
import battery_100 from '../images/sensors/battery_100.svg';
import battery_powder from '../images/sensors/battery_power.svg';
import temperature from '../images/sensors/temperature.svg';
import humidity from '../images/sensors/humidity.svg';
import X3PD01 from '../images/sensors/X3PD01.svg';
import lamp from '../images/sensors/lamp.svg';
import movie from '../images/sensors/movie.svg';
import button from '../images/sensors/button.svg';
import knock from '../images/sensors/knock.svg';
import open from '../images/sensors/open.svg';
import close from '../images/sensors/close.svg';
import fire from '../images/sensors/fire.svg';
import IR_Transmitter from '../images/sensors/IR_Transmitter.svg';
import Water_sensor from '../images/sensors/Water_sensor.svg';
import Gas_sensor from '../images/sensors/Gas_sensor.svg';
import battery_power from '../images/sensors/battery_power.svg';
import Smart_plug_Total_KWh from '../images/sensors/Smart_plug_Total_KWh.svg';
import Smart_plug_Current_v from '../images/sensors/Smart_plug_Current_v.svg';
import Smart_plug_Avg_W from '../images/sensors/Smart_plug_Avg_W.svg';
import Smart_plug_Current_w from '../images/sensors/Smart_plug_Current_w.svg';
import Smoke_sensor from '../images/sensors/Smoke_sensor.svg';
import zigbee from '../images/sensors/zigbee.svg';
import signal_bad from '../images/sensors/signal_bad.svg';
import signal_best from '../images/sensors/signal_best.svg';
import signal_good from '../images/sensors/signal_good.svg';
import signal_offline from '../images/sensors/signal_offline.svg';
import signal_verygood from '../images/sensors/signal_verygood.svg';
import timer from '../images/sensors/timer.svg';
import speed from '../images/sensors/speed.svg';
import compass from '../images/sensors/compass.svg';
import pin from '../images/sensors/pin.svg';
import engine from '../images/sensors/engine.svg';
import Smart_wall_switch from '../images/devices/Smart_wall_switch.svg';
import Сurtains_device from '../images/sensors/Сurtains_device.svg';
import lightbulb from '../images/sensors/lightbulb.svg';
import dimmer from '../images/sensors/dimmer.svg';
import go_to_bed from '../images/sensors/go_to_bed.svg';
import holidays from '../images/sensors/holidays.svg';
import leave_home from '../images/sensors/leave_home.svg';
import return_home from '../images/sensors/return_home.svg';
import bath from '../images/sensors/bath.svg';
import crop from '../images/sensors/crop.svg';
import fireplace from '../images/sensors/fireplace.svg';
import garden from '../images/sensors/garden.svg';
import gate from '../images/sensors/gate.svg';
import greenenergy from '../images/sensors/greenenergy.svg';
import heater from '../images/sensors/heater.svg';
import house from '../images/sensors/house.svg';
import jacuzzi from '../images/sensors/jacuzzi.svg';
import kitchen from '../images/sensors/kitchen.svg';
import livingroom from '../images/sensors/livingroom.svg';
import planting from '../images/sensors/planting.svg';
import pump from '../images/sensors/pump.svg';
import rain from '../images/sensors/rain.svg';
import sauna from '../images/sensors/sauna.svg';
import sun from '../images/sensors/sun.svg';
import swimmingpool from '../images/sensors/swimmingpool.svg';
import wateringcan from '../images/sensors/wateringcan.svg';
import shield from '../images/sensors/shield.svg';
import webcamButton from '../images/devices/webcamButton.svg';

import { deviceTrueDevice } from './helperDevice';
import { deviceIsPowered, getAllDevicesWithSensors } from './funcs';
import { timeDelayDevice } from './constants';

export function sensorImage(user, sensor) {
    if (sensor.guid === "security") return shield;
    if (sensor.guid === "cameras") return webcamButton;

    if (sensor.model.includes("BatterySensor")){
        if (sensor.parsedValue === undefined 
            || sensor.parsedValue === null) return battery_30;
        if (sensor.parsedValue < 30) return battery_30;
        if (sensor.parsedValue < 60) return battery_90;
        if (sensor.parsedValue <= 100) return battery_100;
        return battery_powder;
    }
    if (sensor.model.includes("TimeDelay")) return timer;
    if (sensor.model.includes("TemperatureSensor")) return temperature;
    if (sensor.model.includes("HumiditySensor")) return humidity;
    if (sensor.model.includes("Pm25Sensor")) return X3PD01;
    if (sensor.model.includes("BrightnessSensor")) return lamp;
    if (sensor.model.includes("MotionSensor")) return movie;
    if (sensor.model.includes("KnockSensor")) return knock;
    if (sensor.model.includes("TamperProofSensor")) return close;
    if (sensor.model.includes("HeatAlarmSensor")) return fire;

    if (sensor.model.includes("SwitchSensor")) {
        var trueDevice = deviceTrueDevice(user, sensor.device);
        if (trueDevice.model.includes("CompositeDevice") 
            && trueDevice.devices.length === 2){
            return lightbulb;
        }
        else {
            return Smart_wall_switch;
        }
    }

    if (sensor.model.includes("TransmitterSensor")) return IR_Transmitter;
    if (sensor.model.includes("WaterSensor")) return Water_sensor;
    if (sensor.model.includes("GasSensor")) return Gas_sensor;
    if (sensor.model.includes("BatteryVoltageSensor")) return battery_power;
    // *******************************************************************
    if (sensor.model.includes("PowerConsumptionSensor")) return Smart_plug_Total_KWh;
    if (sensor.model.includes("RmsVoltageSensor")) return Smart_plug_Current_v;
    if (sensor.model.includes("RmsCurrentPowerSensor")) return Smart_plug_Avg_W;
    if (sensor.model.includes("ActivePowerSensor")) return Smart_plug_Current_w;
    // *******************************************************************
    if (sensor.model.includes("SmokeSensor")) return Smoke_sensor;
    if (sensor.model.includes("OnlineSensor")) return zigbee;
    if (sensor.model.includes("LinkQualitySensor")){
        if (sensor.parsedValue === undefined 
            || sensor.parsedValue === null) return signal_offline;
        if (sensor.parsedValue < -80) return signal_offline;
        if (sensor.parsedValue < -61) return signal_bad;
        if (sensor.parsedValue < -48) return signal_good;
        if (sensor.parsedValue < -35) return signal_verygood;
        return signal_best;
    }
    if (sensor.model.includes("TimeDelay")) return timer;
    if (sensor.model.includes("ControlSensor")) return Сurtains_device;
    if (sensor.model.includes("TripSensor")) return Сurtains_device;
    if (sensor.model.includes("IR_Transmitter")) return IR_Transmitter;
    if (sensor.model.includes("DimmerSensor")) return dimmer;
    if (sensor.model.includes("TimerSensor")) return timer;

    // *******************************************************************
    if (sensor.model.includes("PromasatTracker.SpeedSensor")) return speed;
    if (sensor.model.includes("PromasatTracker.CourseSensor")) return compass;
    if (sensor.model.includes("PromasatTracker.GeoSensor")) return pin;
    if (sensor.model.includes("IgnitionSensor")) return engine;
    // *******************************************************************

    if (sensor.model.includes("OpenCloseSensor")) 
        return  sensor.parsedValue === undefined  
            || sensor.parsedValue === null 
            || sensor.parsedValue
            ? open 
            : close;

    if (sensor.model.includes("ButtonSensor")){
        if (sensor.settings !== null
            && sensor.settings !== undefined
            && sensor.settings.ButtonType !== null
            && sensor.settings.ButtonType !== undefined){
                return buttonGetIcon(sensor.settings.ButtonType);
            } else {
                return buttonGetIcon(ButtonTypes.Other);
            }
    }

    return  null;
}

export function sensorValueWithDem(sensor, local, useShortAdress = false){
    if (sensor.model.includes('GeoSensor')){
        if (useShortAdress) return sensor.adressShort;
        return sensor.adress;
    }
    
    let val = sensor.parsedValue;
    if (sensor.model.includes('LinkQualitySensor')){
        val = sensor.dbValue;
    }
    const dem = sensorDemension(sensor, local);

    if (val === null || val === undefined || !sensorShowValue(sensor)) 
        return dem;
    if (dem === null || dem === undefined)
        return val + '';
    
    if (sensor.sensorValueType === "Float"){
        val = Math.round(val * 10) / 10;
    }
    return `${val} ${dem}`;
}

export function sensorDemension(sensor, local){

    if (sensor.model.includes("TemperatureSensor")) return "°";
    if (sensor.model.includes("HumiditySensor")) return "%";
    if (sensor.model.includes("Pm25Sensor")) return local.SensorDemPM25;
    //if (sensor.model.includes("BrightnessSensor")) return local.SensorDemBrightness;
    if (sensor.model.includes("TransmitterSensor")) return "send";
    if (sensor.model.includes("BatteryVoltageSensor")) return local.SensorDemV;
    if (sensor.model.includes("TripSensor")) return local.SensorDemCm;
    // *******************************************************************
    if (sensor.model.includes("PowerConsumptionSensor")) return local.SensorDemKWh;
    if (sensor.model.includes("RmsVoltageSensor")) return local.SensorDemV;
    if (sensor.model.includes("RmsCurrentPowerSensor")) return local.SensorDemW;
    if (sensor.model.includes("ActivePowerSensor")) return local.SensorDemW;
    // *******************************************************************
    if (sensor.model.includes("SpeedSensor")) return local.SensorSpeed;
    if (sensor.model.includes("CourseSensor")) return "⭡";
    if (sensor.model.includes("GeoSensor")) return local.SensorGeoShowMap;
    // *******************************************************************

    if (sensor.model.includes("BatterySensor"))
    {
        if (deviceIsPowered(sensor.device))
        {
            return sensor.parsedValue === null || sensor.parsedValue === undefined 
                ? "" 
                : local.SensorValueBattery;
        }
        if (sensor.parsedValue === null || sensor.parsedValue === undefined) 
            return "";
        return sensor.parsedValue === 255 ? local.SensorValueBattery : "%";
    }
    if (sensor.model.includes("ButtonSensor")) {
        return local.ButtonSensorName;
    }
    if (sensor.model.includes("TimerSensor")) {
        return local.TimerSensorName;
    }
    if (sensor.model.includes("LinkQualitySensor")) {
        return local.SensorDemdB;
    }
    if (sensor.model.includes("OnlineSensor"))
    {
        return sensor.parsedValue === null || sensor.parsedValue === undefined 
            ? local.No 
            : (sensor.parsedValue ? local.Yes : local.No);
    }
    if (sensor.model.includes("IgnitionSensor"))
    {
        return sensor.parsedValue === null || sensor.parsedValue === undefined 
            ? "" 
            : (sensor.parsedValue ? local.On : local.Off);
    }
    if (sensor.model.includes("OpenCloseSensor"))
    {
        return sensor.parsedValue === null || sensor.parsedValue === undefined  
            ? "" 
            : (sensor.parsedValue ? local.Opened : local.Closed);
    }
    if (sensor.model.includes("SwitchSensor"))
    {
        return sensor.parsedValue === null || sensor.parsedValue === undefined  
            ? "" 
            : (sensor.parsedValue ? local.On : local.Off);
    }
    if (sensor.model.includes("BrightnessSensor"))
    {
        return sensor.parsedValue === null || sensor.parsedValue === undefined 
            ? "" 
            : (sensor.parsedValue ? local.SensorBright : local.SensorDark);
    }
    if (sensor.model.includes("WaterSensor"))
    {
        return sensor.parsedValue === null || sensor.parsedValue === undefined 
            ? "" 
            : (sensor.parsedValue ? local.Alert : local.SensorValueHeatNormal);
    }
    if (sensor.model.includes("GasSensor"))
    {
        return sensor.parsedValue === null || sensor.parsedValue === undefined 
            ? "" 
            : (sensor.parsedValue ? local.Alert : local.SensorValueHeatNormal);
    }
    if (sensor.model.includes("SmokeSensor"))
    {
        return sensor.parsedValue === null || sensor.parsedValue === undefined
            ? "" 
            : (sensor.parsedValue ? local.SmokeAlert : local.SensorValueHeatNormal);
    }
    if (sensor.model.includes("TamperProofSensor"))
    {
        return sensorIsShowingEvent(sensor) 
            ? local.Alert 
            : local.OK;
    }
    if (sensor.model.includes("KnockSensor"))
    {
        return sensorIsShowingEvent(sensor) 
            ? local.BangingYes 
            : local.BangingNo;
    }
    if (sensor.model.includes("HeatAlarmSensor"))
    {
        return sensorIsShowingEvent(sensor)
            ? local.SensorValueHeatAlarm
            : local.SensorValueHeatNormal;
    }
    if (sensor.model.includes("MotionSensor"))
    {
        return sensor.parsedValue === null || sensor.parsedValue === undefined 
            ? "" 
            : (sensor.parsedValue ? local.Yes : local.No);
    }
    if (sensor.model.includes("DimmerSensor"))
    {
        return sensor.parsedValue === null || sensor.parsedValue === undefined 
            ? local.No 
            : sensor.parsedValue;
    }
    if (sensor.model.includes("ControlSensor"))
    {
        var val = sensorControlCurtainsStatus(sensor);
        if (val === null) return local.SensorControlTitle;

        if (val.Value === 0) return local.SensorControlOpen;
        if (val.Value === 1) return local.SensorControlClose;
        if (val.Value === 2) return local.SensorControlStop;
    }

    return '';
}

export function sensorIsShowingEvent(sensor){
    return sensor.isShowingEvent === true;
}

export function sensorControlCurtainsStatus(sensor){
    if (sensor.value === null || sensor.value === undefined
        || sensor.value.length === 0)
        return null;
    return sensor.value[0];
}

export function sensorShowValue(sensor){
    if (sensor.model.includes("TamperProofSensor")) return false;
    if (sensor.model.includes("HeatAlarmSensor")) return false;
    if (sensor.model.includes("KnockSensor")) return false;
    if (sensor.model.includes("OnlineSensor")) return false;
    if (sensor.model.includes("BrightnessSensor")) return false;
    if (sensor.model.includes("OpenCloseSensor")) return false;
    if (sensor.model.includes("SwitchSensor")) return false;
    if (sensor.model.includes("MotionSensor")) return false;
    if (sensor.model.includes("TransmitterSensor")) return false;
    if (sensor.model.includes("WaterSensor")) return false;
    if (sensor.model.includes("GasSensor")) return false;
    if (sensor.model.includes("ControlSensor")) return false;
    if (sensor.model.includes("DimmerSensor")) return false;
    if (sensor.model.includes("SmokeSensor")) return false;
    if (sensor.model.includes("CourseSensor")) return false;
    if (sensor.model.includes("IgnitionSensor")) return false;
    if (sensor.model.includes("GeoSensor")) return false;
    if (sensor.model.includes("Button")) return false;
    if (sensor.model.includes("BatterySensor") 
        && (deviceIsPowered(sensor.device) || sensor.parsedValue === 255)) return false;

    return true;
}

export function sensorShowInDashboard(sensor){
    if (sensor.model.includes("BatterySensor")) return false;
    if (sensor.model.includes("OnlineSensor")) return false;
    if (sensor.model.includes("LinkQualitySensor")) return false;
    if (sensor.model.includes("RmsCurrentPowerSensor")) return false;
    if (sensor.model.includes("BatteryVoltageSensor")) return false;
    if (sensor.model.includes("Course")) return false;

    return true;
}

export function sensorShowInConditions(user, sensor){
    // IR device
    if (sensor.device.model.includes("X3IT01")) return false;
    // Gate
    if (sensor.device.model.includes("X3Gw01")) return false;
    // PromaSat gateway
    if (sensor.device.model.includes("PromasatGateway")) return false;

    if (sensor.sensorValueType === "WithoutValue") return true;
    if (sensor.possibleOperators === undefined || sensor.possibleOperators === null) return false;
    //if (sensor.model.includes("OnlineSensor")) return false;
    if (sensor.model.includes("LinkQualitySensor")) return false;
    if (sensor.model.includes("BatterySensor") && deviceIsPowered(sensor.device)) return false;
    if (sensor.model.includes("BatteryVoltageSensor")) return false;
    if (sensor.model.includes("AddDeviceModeSensor")) return false;
    if (sensor.model.includes("RemoveDeviceSensor")) return false;
    if (sensor.model.includes("RmsCurrentPowerSensor")) return false;
    if (sensor.model.includes("Course")) return false;
    if (sensor.model.includes("GeoSensor") && 
        (user.locations === null || user.locations === undefined || user.locations.length === 0)) 
        return false;

    return true;
}

export function sensorShowInActors(user, sensor){
    // Gate
    if (sensor.device.model.includes("X3Gw01")) return false;
    // PromaSat gateway
    if (sensor.device.model.includes("PromasatGateway")) return false;
    // PromaSat tracker
    if (sensor.device.model.includes("PromasatTracker")) return false;

    if (sensor.model.includes("BatteryVoltageSensor")) return false;
    if (sensor.model.includes("RmsCurrentPowerSensor")) return false;
    if (sensor.model.includes("Course")) return false;

    if (sensor.isActor === true) return true; 

    return false;
}

export function getConditions(user){
    var devices = [];
    var sensors = [];
    let alldevices = getAllDevicesWithSensors(user);
    for(var i in alldevices){
        var d = alldevices[i];
        var added = false;
        for(var j in d.sensors){
            var sensor = d.sensors[j];
            if (sensorShowInConditions(user, sensor) === true){
                if (added === false){
                    devices.push(d);
                    added = true;
                }
                sensors.push(sensor);
            }
        }
    }
    return {devices, sensors};
}

export function getActors(user){
    timeDelayDevice.sensors[0].device = timeDelayDevice;
    var devices = [timeDelayDevice];
    var sensors = [timeDelayDevice.sensors[0]];
    let alldevices = getAllDevicesWithSensors(user);
    for(var i in alldevices){
        var d = alldevices[i];
        var added = false;
        for(var j in d.sensors){
            var sensor = d.sensors[j];
            if (sensorShowInActors(user, sensor) === true){
                if (added === false){
                    devices.push(d);
                    added = true;
                }
                sensors.push(sensor);
            }
        }
    }
    return {devices, sensors};
}

export function sensorShowInDeviceInfo(sensor){
    if (sensor.model.includes("RmsCurrentPowerSensor")) return false;
    if (sensor.model.includes("BatteryVoltageSensor")) return false;
    if (sensor.model.includes("Course")) return false;
    
    if (sensor.model === "X3IT01.ButtonSensor") return false;

    if (deviceIsPowered(sensor.device) 
        && sensor.model.includes("BatterySensor")) return false;

    return true;
}

export function sensorIconFill(user, sensor, color){
    var style = {
        stroke: color, 
        strokeWidth: 1, 
        strokeMiterlimit: 0, 
        fill: "#00000000"
    }

    if (sensor.model.includes("HeatAlarmSensor")) style.fill = color;
    if (sensor.model.includes("LinkQualitySensor")) style.fill = color;
    if (sensor.model.includes("BatterySensor")) style.fill = color;
    if (sensor.model.includes("SpeedSensor")
        || sensor.model.includes("CourseSensor")
        || sensor.model.includes("GeoSensor")
        || sensor.model.includes("IgnitionSensor")) {
        style.fill = color;
        style.opacity = .7;
    }
    if (sensor.device.model === "OnOffSwitch")
    {
        var trueDevice = deviceTrueDevice(user, sensor.device);
        if (trueDevice.model.includes("CompositeDevice") 
            && trueDevice.devices.length === 2){
            style.fill = color;
        }
        else {
            style.strokeWidth = 3;
        }
    } else {
        style.strokeWidth = 3;
    }
    if (sensor.settings !== null
        && sensor.settings !== undefined
        && sensor.settings.ButtonType !== undefined){
            style.strokeWidth = buttonGetIconStrokeWidth(sensor.settings.ButtonType);
            style.fill = buttonGetIconFill(sensor.settings.ButtonType, color);
    }

    if (sensor.model.includes("TimerDevice")){
        style.fill = "#00000000";
    }
    
    return style;
}

export function buttonGetIconStrokeWidth(type){
    if (type === ButtonTypes.jacuzzi) return 1;
    if (type === ButtonTypes.pump) return 1;
    if (type === ButtonTypes.fireplace) return 1;
    if (type === ButtonTypes.heater) return 1;
    if (type === ButtonTypes.greenenergy) return 1;
    if (type === ButtonTypes.garden) return 1;
    if (type === ButtonTypes.house) return 1;
    if (type === ButtonTypes.kitchen) return 1;
    if (type === ButtonTypes.livingroom) return 1;
    if (type === ButtonTypes.planting) return 1;
    if (type === ButtonTypes.rain) return 1;
    if (type === ButtonTypes.sauna) return 1;
    if (type === ButtonTypes.sun) return 1;
    if (type === ButtonTypes.swimmingpool) return 1;
    if (type === ButtonTypes.wateringcan) return 1;
    return 3;
}

export function buttonGetIconFill(type, color){
    if (type === ButtonTypes.jacuzzi) return undefined;
    if (type === ButtonTypes.pump) return undefined;
    return color;
}

export function sensorWOvalueName(sensor, local){
    var dem = sensorDemension(sensor, local);
    if (sensor.model.includes("X3IT01.ButtonSensor")
        && sensor.settings.PresetButton !== null
        && sensor.settings.PresetButton !== undefined
        && sensor.settings.Preset !== PresetsIR.misc
        ){
            return `${dem}: ${presetIrButtonFullName(sensor.settings, local)}`;
    } 
    return `${dem}: ${sensor.settings?.Name}`;
}

export function sensorIsActor(sensor){
    return sensor.isActor === true;
}

export function sensorShowMessage(sensor){
    if (sensor.model.includes("OnlineSensor")) return false;
    if (sensor.model.includes("BatterySensor")) return false;
    if (sensor.model.includes("LinkQualitySensor")) return false;
    if (sensor.model.includes("BatteryVoltageSensor")) return false;

    return true;
}

function presetIrButtonFullName(settings, local){
    var name = presetGetName(settings.Preset, local);
    var suffix = presetGetButtonSuffix(settings.PresetButton);
    var bName = presetGetButtonName(settings.PresetButton, local);
    var arr = [];
    if (name !== "") arr.push(name);
    if (suffix !== "") arr.push(suffix);
    if (bName !== "") arr.push(bName);
    return arr.join(" ");
}

function presetGetName(preset, local){
    if (preset === PresetsIR.conditioner) return local.PresetsConditioner;
    if (preset === PresetsIR.tv) return local.PresetsTv;
    if (preset === PresetsIR.misc) return local.Open;
    return "";
}

function presetGetButtonSuffix(button){
    if (button === PresetButtons.volumeMinus
        || button === PresetButtons.volumePlus)
        return "volume";
    if (button === PresetButtons.channelMinus
        || button === PresetButtons.channelPlus)
        return "channel";
    return "";
}

function presetGetButtonName(button, local)
{
    if (button === PresetButtons.on ) return local.PresetButtonOn;
    if (button === PresetButtons.off ) return local.PresetButtonOff;
        
    if (button === PresetButtons.mode ) return local.PresetButtonMode;
    if (button === PresetButtons.minus ) return local.PresetButtonMinus;
    if (button === PresetButtons.plus ) return local.PresetButtonPlus;
    if (button === PresetButtons.fan ) return local.PresetButtonFan;

    if (button === PresetButtons.volumeMinus ) return local.PresetButtonVolumeMinus;
    if (button === PresetButtons.volumePlus ) return local.PresetButtonVolumePlus;
    if (button === PresetButtons.channelMinus ) return local.PresetButtonChannelMinus;
    if (button === PresetButtons.channelPlus ) return local.PresetButtonChannelPlus;
    if (button === PresetButtons.mute ) return local.PresetButtonMute;
    if (button === PresetButtons.source ) return local.PresetButtonSource;
    if (button === PresetButtons.smart ) return local.PresetButtonSmart;
    if (button === PresetButtons.menu ) return local.PresetButtonMenu;
    if (button === PresetButtons.menuLeft ) return local.PresetButtonMenuLeft;
    if (button === PresetButtons.menuUp ) return local.PresetButtonMenuUp;
    if (button === PresetButtons.menuRight ) return local.PresetButtonMenuRight;
    if (button === PresetButtons.menuDown ) return local.PresetButtonMenuDown;
    if (button === PresetButtons.menuOk ) return local.PresetButtonMenuOk;
    if (button === PresetButtons.menuExit ) return local.PresetButtonMenuExit;

    return local.PresetsMisc;
}

export function sensorName(user, sensor, local){
    if ((sensor.sensorValueType === "WithoutValue" 
        || sensor.model.includes("X3IT01.ButtonSensor")) 
        && sensor.settings !== null 
        && sensor.settings !== undefined){
        return sensorWOvalueName(sensor, local);
    }

    if (sensor.model.includes("TimeDelay")) return local.ScenarioThenTimeDelay;
    if (sensor.model.includes("OnlineSensor")) return local.SensorTypeNameOnline;
    if (sensor.model.includes("BatterySensor")) return local.SensorTypeNameBattery;
    if (sensor.model.includes("BatteryVoltageSensor")) return local.SensorTypeNameBatteryVoltage;
    if (sensor.model.includes("LinkQualitySensor")) return local.SensorTypeNameLinkQuality;
    if (sensor.model.includes("KnockSensor")) return local.SensorTypeNameKnock;
    if (sensor.model.includes("HeatAlarmSensor")) return local.SensorValueHeatAlarm;
    if (sensor.model.includes("GenericIpcam.MotionSensor")) return local.SensorTypeNameMotion;
    if (sensor.model.includes("X3WD01.WaterSensor")) return local.SensorTypeNameLeakage;
    if (sensor.model.includes("X3MD01.HumiditySensor")) return local.SensorTypeNameHumidity;
    if (sensor.model.includes("X3MD01.TemperatureSensor")) return local.SensorTypeNameTemperature;
    if (sensor.model.includes("X3MD01.BrightnessSensor")) return local.SensorTypeNameBrightness;
    if (sensor.model.includes("X3MD01.MotionSensor")) return local.SensorTypeNameMotion;
    if (sensor.model.includes("X3MD01.TamperProofSensor")) return local.SensorTypeNameTamperProof;
    if (sensor.model.includes("X3DS01.OpenCloseSensor")) return local.SensorTypeNameTheDoor;
    if (sensor.model.includes("X3PD01.TemperatureSensor")) return local.SensorTypeNamePM25Temperature;
    if (sensor.model.includes("X3PD01.HumiditySensor")) return local.SensorTypeNamePM25Humidity;
    if (sensor.model.includes("X3PD01.Pm25Sensor")) return local.SensorTypeNamePM25particles;
    if (sensor.model.includes("X3GD02.GasSensor")) return local.SensorTypeNameGasLeak;
    if (sensor.model.includes("X2SK11.SwitchSensor")) return local.SensorTypeNamePlugSwitch;
    if (sensor.model.includes("X2SK11.PowerConsumptionSensor")) return local.SensorTypeNameРowerConsumption;
    if (sensor.model.includes("X2SK11.RmsVoltageSensor")) return local.SensorTypeNameRMSVoltage;
    if (sensor.model.includes("X2SK11.RmsCurrentPowerSensor")) return local.SensorTypeNameRMSCurrentPower;
    if (sensor.model.includes("X2SK11.ActivePowerSensor")) return local.SensorTypeNameActivePower;
    if (sensor.model.includes("PromasatTracker.CourseSensor")) return local.SensorTypeNameCourse;
    if (sensor.model.includes("PromasatTracker.SpeedSensor")) return local.SensorTypeNameSpeed;
    if (sensor.model.includes("PromasatTracker.GeoSensor")) return local.SensorTypeNameGeo;
    if (sensor.model.includes("PromasatTracker.IgnitionSensor")) return local.SensorTypeNameIgnition;
    if (sensor.model.includes("X3SD02.SmokeSensor")) return local.SensorTypeNameSmokeSensor;
    if (sensor.model.includes("X2CC11.ControlSensor")) return local.SensorTypeNameCurtainMotor;
    if (sensor.model.includes("X2CC11.TripSensor")) return local.SensorTypeNameCurtainTrip;
    if (sensor.model.includes("X3IT01.ButtonSensor")) return local.SensorTypeNameIRButton;
    if (sensor.model.includes("X3Gw01.AddDeviceModeSensor")) return local.SensorTypeNameAddNewDevice;
    if (sensor.model.includes("X3Gw01.RemoveDeviceSensor")) return local.SensorTypeNameRemoveDevice;
    if (sensor.model.includes("Dimmer.DimmerSensor")) return local.SensorTypeNameSmartDimmer;
    if (sensor.model.includes("SwitchSensor")) return local.SensorTypeNameSwitch;

    return sensor.model;
}

export function buttonGetIcon(buttonType){
    if (buttonType === ButtonTypes.GoToBed) return go_to_bed;
    if (buttonType === ButtonTypes.Holidays) return holidays;
    if (buttonType === ButtonTypes.LeaveHome) return leave_home;
    if (buttonType === ButtonTypes.ReturnHome) return return_home;
    if (buttonType === ButtonTypes.bath) return bath;
    if (buttonType === ButtonTypes.crop) return crop;
    if (buttonType === ButtonTypes.fireplace) return fireplace;
    if (buttonType === ButtonTypes.garden) return garden;
    if (buttonType === ButtonTypes.gate) return gate;
    if (buttonType === ButtonTypes.greenenergy) return greenenergy;
    if (buttonType === ButtonTypes.heater) return heater;
    if (buttonType === ButtonTypes.house) return house;
    if (buttonType === ButtonTypes.jacuzzi) return jacuzzi;
    if (buttonType === ButtonTypes.kitchen) return kitchen;
    if (buttonType === ButtonTypes.livingroom) return livingroom;
    if (buttonType === ButtonTypes.planting) return planting;
    if (buttonType === ButtonTypes.pump) return pump;
    if (buttonType === ButtonTypes.rain) return rain;
    if (buttonType === ButtonTypes.sauna) return sauna;
    if (buttonType === ButtonTypes.sun) return sun;
    if (buttonType === ButtonTypes.swimmingpool) return swimmingpool;
    if (buttonType === ButtonTypes.wateringcan) return wateringcan;

    return button;
}

export function buttonGetTypeName(type, res){
    switch (type)
    {
        case ButtonTypes.GoToBed:
            return res.ButtonTypeGoToBed;
        case ButtonTypes.Holidays:
            return res.ButtonTypeHolidays;
        case ButtonTypes.LeaveHome:
            return res.ButtonTypeLeaveHome;
        case ButtonTypes.ReturnHome:
            return res.ButtonTypeReturnHome;
        case ButtonTypes.bath:
            return res.ButtonTypeBath;
        case ButtonTypes.crop:
            return res.ButtonTypeCrop;
        case ButtonTypes.fireplace:
            return res.ButtonTypeFireplace;
        case ButtonTypes.garden:
            return res.ButtonTypeGarden;
        case ButtonTypes.gate:
            return res.ButtonTypeGate;
        case ButtonTypes.greenenergy:
            return res.ButtonTypeGreenenergy;
        case ButtonTypes.heater:
            return res.ButtonTypeHeater;
        case ButtonTypes.house:
            return res.ButtonTypHouse;
        case ButtonTypes.jacuzzi:
            return res.ButtonTypeJacuzzi;
        case ButtonTypes.kitchen:
            return res.ButtonTypeKitchen;
        case ButtonTypes.livingroom:
            return res.ButtonTypeLivingRoom;
        case ButtonTypes.planting:
            return res.ButtonTypePlanting;
        case ButtonTypes.pump:
            return res.ButtonTypePump;
        case ButtonTypes.rain:
            return res.ButtonTypeRain;
        case ButtonTypes.sauna:
            return res.ButtonTypeSauna;
        case ButtonTypes.sun:
            return res.ButtonTypeSun;
        case ButtonTypes.swimmingpool:
            return res.ButtonTypeSwimmingPool;
        case ButtonTypes.wateringcan:
            return res.ButtonTypWateringCan;
        default:
            return res.ButtonTypeOther;
    }
}

const PresetsIR = {
    tv: 0,
    conditioner: 1,
    misc: 2,
}

const PresetButtons = {
    // common
    on: 0,
    misc: 1,

    // conditioner
    mode: 2,
    minus: 3,
    plus: 4,
    fan: 5,

    // tv
    volumeMinus: 6,
    volumePlus: 7,
    channelMinus: 8,
    channelPlus: 9,
    mute: 10,
    source: 11,
    smart: 12,
    menu: 13,
    menuLeft: 14,
    menuUp: 15,
    menuDown: 16,
    menuRight: 17,
    menuOk: 18,
    menuExit: 19,

    // new buttons
    off: 20,
}

export const ButtonTypes = {
    Other: 0,
    GoToBed: 1,
    Holidays: 2,
    LeaveHome: 3,
    ReturnHome: 4,
    bath: 5,
    crop: 6,
    fireplace: 7,
    garden: 8,
    gate: 9,
    greenenergy: 10,
    heater: 11,
    house: 12,
    jacuzzi: 13,
    kitchen: 14,
    livingroom: 15,
    planting: 16,
    pump: 17,
    rain: 18,
    sauna: 19,
    sun: 20,
    swimmingpool: 21,
    wateringcan: 22,
}