import { Component } from 'react';

import { Samy } from 'react-samy-svg';
import { deviceImageByModel, deviceIconFillByModel } from '../../../helpers/helperDevice';
import { deviceColors, dashFilterDefault } from '../../../helpers/constants';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class DeviceFilter extends Component {
    constructor(props){
        super(props);

        this.setActiveModel = this.setActiveModel.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);

        this.state = {
            hovered: false
        }
    }

    setActiveModel(){
        let model = this.props.model;

        this.props.appActions.setDashFilterRoom({room: dashFilterDefault});
        this.props.appActions.setDashFilterModel({model: model});
    }
    
    onMouseOver() {
        this.setState({ hovered: true });
    }

    onMouseOut() {
        this.setState({ hovered: false });
    }

    render() {
        let model = this.props.model;
        let selectedModel = this.props.appState.dashModelFilter;
        let isDark = this.props.appState.isDarkTheme;

        let borderColor = selectedModel === model || this.state.hovered === true
            ? deviceColors.borderBlue
            : isDark 
                ? deviceColors.borderGreyDark 
                : deviceColors.borderGreyLight;
        let iconStyle = deviceIconFillByModel(model, borderColor);


        return (
            <div className="DeviceFilter" onClick={this.setActiveModel}
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}>
                <Samy path={deviceImageByModel(model)} style={iconStyle} />
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(DeviceFilter);