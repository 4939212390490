import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import './modalSubscription.css';

import { getCurrencyName, getDurationName,
    openNotificationWithIcon } from '../../helpers/funcs';
import { updateSubscription } from '../../helpers/helperApi';
import { ApiActions, deviceColors } from "../../helpers/constants";

function ModalSubscription({ resolve, local, user, isDark }) {
    const [changed, setChanged] = useState(true);
    const [data, setData] = useState(null);
    function clickExit() {
        removeDialog();
        resolve(null);
    }
    function clickSubs(obj){
        if (user.account.amount < obj.amount){
            openNotificationWithIcon('error', local.appTitle, local.NoMoneyTitle);
            return;
        }

        removeDialog();
        resolve(obj);
    }
    useEffect(() => {
        async function fetchData() {
            let result = await updateSubscription(null, ApiActions.Get);
            if (Array.isArray(result) === true){
                let filteredSubs = [];
                for(let s of result){
                    if (s.enabled === true) filteredSubs.push(s);
                }
                setChanged(false);
                setData(filteredSubs);
            }
        }
        if (changed === false) return;

        fetchData();
    })
    return (
        <div id="modal__container__subscription__dialog" 
            className='modal__container__subscription__dialog'
            style={{
                borderColor: deviceColors.borderBlue,
                backgroundColor: isDark 
                    ? deviceColors.backGroundDark 
                    : deviceColors.backGroundLight}}>
            <p>{local.SubscriptionEnableListTitle}</p>
            <div className='modal__container__subscription__dialog__units'>
            {
                data === null ? null :
                data.map((el, i) => {
                    return <div key={`transaction__${i}`}
                        className='modal__container__subscription__dialog__unit'>
                        <div className='left'>
                            <p className='cost'>{`${local.SubCostTitle} ${el.amount}${getCurrencyName(el.currency, local)}`}</p>
                            <p className='cost'>{`${local.SubDurationTitle}: ${getDurationName(el.duration, local)}`}</p>
                        </div>
                        <button 
                            style={{margin: "10px", width: "100px", height:"45px"}}
                            onClick={clickSubs.bind(this, el)}>
                            {local.SubEnableTitle}
                        </button>
                    </div>;
                })
            }
            </div>
            <div className='modal__confirm__container_dialog__buttons'>
                <button onClick={clickExit}>{local.Close}</button>
            </div>
        </div>
    );
}

export default function modalSubscription(local, user, isDark = true) {
    return new Promise((resolve, reject) => {
        addDialog(local, user, isDark, resolve);
    });
}

function addDialog(local, user, isDark, resolve) {
    const body = document.getElementById("Vku_App");
    const div = document.createElement("div");
    div.setAttribute("id", "modal__container__subscription");
    div.setAttribute("className", "modal__container__subscription");
    body.appendChild(div);
    ReactDOM.render(
        <ModalSubscription local={local} user={user} isDark={isDark} resolve={resolve} />,
        div
    );
}

function removeDialog() {
    const div = document.getElementById("modal__container__subscription");
    const body = document.getElementById("Vku_App");
    body.removeChild(div);
}