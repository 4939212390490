import './rooms.css';
import { Component } from 'react';

import { Samy } from 'react-samy-svg';
import { roomGetDevices } from '../../helpers/helperRoom';
import ViewButton from '../viewButton/viewButton';
import ViewRoom from '../viewRoom/viewRoom';
import { userGetScenarioDevices, userGetScenarioButtons,
    userGetCamerasDevices, getSecurityButtonEnable } from '../../helpers/funcs';

import { ModalActions, deviceColors} from '../../helpers/constants';
import plus from '../../images/menu/menu_plus.svg';
import { v4 as uuidv4 } from 'uuid';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Rooms extends Component {
    constructor(props){
        super(props);

        this.addNewRoom = this.addNewRoom.bind(this);

        var user = this.props.appState.user;
        for(let r of user.rooms){
            if (r.devices !== undefined && r.realDevices === undefined){
                r.realDevices = roomGetDevices(user, r);
            }
        }
    }

    addNewRoom(){
        this.props.appActions.setShowModal({
            modalAction: ModalActions.NewRoom,
            objectModal: { 
                guid: uuidv4(),
                favourite: false,
                name: '',
                description: '',
                locationType: 0,
                devices: [],
                realDevices: []
            }
        });
    }

    render() {
        let user = this.props.appState.user;
        if (user === null || user === undefined) return null;
        let isDark = this.props.appState.isDarkTheme;
        let cameras = userGetCamerasDevices(user);
        let local = this.props.appState.local;
        let tab = this.props.appState.activeTab;
        let scenarioDevices = userGetScenarioDevices(user);
        let buttons = [];
        let security = getSecurityButtonEnable(user);
        if (security.securityButtonExists === true){
            buttons.push({
                guid: 'security',
                settings:{
                    Name: local.ScenarioSecurityTitle,
                    Enabled: security.enabled
                }
            })
        }
        if (cameras.length > 0){
            buttons.push({
                guid: 'cameras',
                settings:{
                    Name: local.CameraPreviewsTitle,
                    Enabled: true
                }
            })
        }
        buttons = buttons.concat(userGetScenarioButtons(user, scenarioDevices));

        var rooms = [];
        for(let r of user.rooms){
            r.realDevices = roomGetDevices(user, r);
            if (r.realDevices !== undefined && r.realDevices.length > 0) rooms.push(r);
        }

        let roomViews = rooms
            .sort(roomSortFunc)
            .map((el, index) => {
                return <ViewRoom key={`dashroom${index}${el.guid}`} room={el} />
            });
        roomViews.push(
            <div className='ViewRoom AddButton' 
                style={{borderColor: deviceColors.borderBlue}}
                onClick={this.addNewRoom}>
                <Samy path={plus} />
            </div>);


        return (
            <div style={{display: tab === 2 ? "block" : "none"}}>
                <div className="Rooms__buttons" style={{
                    borderColor: isDark ? 'rgba(255, 255, 255, 0.25)' : 'rgba(0, 0, 0, 0.75)',
                    backgroundColor: isDark ? '' : 'rgba(0, 0, 0, 0.1)'
                }}>
                    {
                        buttons.map((b, i)=>{
                            return <ViewButton key={`dashbuttonroom${i}${b.guid}`} button={b} />
                        })
                    }
                </div>
                <div className="Rooms">
                    {
                        roomViews
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(Rooms);

const roomSortFunc = (a, b) => {
    if (a.favourite === true && b.favourite === true)
        return b.devices.length - a.devices.length;
    if (a.favourite === true)
        return -1;
    if (b.favourite === true)
        return 1;
    
    return b.devices.length - a.devices.length;
}