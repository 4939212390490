import './settingsViewDevice.css';
import { Component } from 'react';

import { Samy } from 'react-samy-svg';
import { sensorImage, sensorIconFill } from '../../../helpers/helperSensor';
import { deviceColors, ModalActions } from "../../../helpers/constants";

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class SettingsViewDevice extends Component {
    constructor(props){
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(){
        this.props.appActions.setShowModal({
            modalAction: ModalActions.EditObject,
            objectModal: this.props.sensor
        });
    }

    render() {
        var user = this.props.appState.user;
        var sensor = this.props.sensor;
        var iconStyle = sensorIconFill(user, sensor, deviceColors.borderBlue);
        
        return (
            <div className="SettingsButtonsDevice" 
                style={{borderColor: deviceColors.borderBlue}}
                onClick={this.onClick}>
                <div className="SettingsButtonsDevice__Image">
                    <Samy path={sensorImage(user, sensor)} style={iconStyle} />
                </div>
                <div className="SettingsButtonsDevice__Name">
                    {sensor.settings?.Name ?? sensor.Settings?.Name}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(SettingsViewDevice);