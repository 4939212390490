import './App.css';
import { Component } from 'react';

import * as appActions from './actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AppTitle from './components/appTitle/appTitle';
import ViewLogin from './components/viewLogin/viewLogin';
import ViewMenu from './components/viewMenu/viewMenu';
import MainMenu from './components/mainMenu/mainMenu';
import Dashboard from './components/dashboard/dashboard';
import Scenarios from './components/scenarios/scenarios';
import Settings from './components/settings/settings';
import Rooms from './components/rooms/rooms';
import ViewCameraPreview from './components/viewCameraPreview/viewCameraPreview';
import ViewBaseModal from './components/viewBaseModal/viewBaseModal';
import ViewDeviceSelector from './components/viewDeviceSelector/viewDeviceSelector';
//import CameraViews from './components/cameraViews/cameraViews';

import {
    openNotificationWithIcon,
    parseWsBlob,
    updateParsedValue,
    checkNewUserForNewDevices,
    deviceFullName,
    checkPremiumLimits
} from './helpers/funcs';
import { 
    sensorShowMessage, 
    sensorName, 
    sensorValueWithDem 
} from './helpers/helperSensor';
import { 
    getWsToken, 
    getUser, 
    updateToken,
    updateUserSettings, 
    updateIrButton 
} from './helpers/helperApi';
import {
    wsServerProt, serverProt, serverAddress,
    Credentials, ApiActions,
    deviceColors,
    CheckDev
} from './helpers/constants';

import Cookies from 'js-cookie';
import packageJson from '../package.json';
import 'antd/dist/antd.css';

const appStatusTimerInterval = 2000;

class VkuApp extends Component {
    constructor(props) {
        super(props);
        
        this.checkPrem = this.checkPrem.bind(this);
        this.themeChanged = this.themeChanged.bind(this);
        this.wsOnError = this.wsOnError.bind(this);
        this.wsOnMessage = this.wsOnMessage.bind(this);
        this.wsOnClose = this.wsOnClose.bind(this);
        this.wsOnOpen = this.wsOnOpen.bind(this);
        this.appStatusTimer = this.appStatusTimer.bind(this);
        
        this.onMouseMenuOverOut = this.onMouseMenuOverOut.bind(this);

        var themeMatchMedia = window.matchMedia('(prefers-color-scheme: dark)');
        this.props.appState.isDarkTheme = themeMatchMedia.matches;
        themeMatchMedia.onchange = this.themeChanged;

        this.checkedForPrem = false;

        this.isUpdatingUser = false;

        this.state = {
            appStatusTimerId: null,
            menuCollapsed: true
        }
    }

    themeChanged(e) {
        let theme = Cookies.get('Theme') ?? 'ThemeControlled';
        if (theme === 'ThemeControlled'){
            let isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
            this.props.appActions.setTheme({ isDarkTheme: isDark });
        }
    }

    async appStatusTimer() {
        var owner = this;
        var user = this.props.appState.user;
        var token = this.props.appState.token;

        if (user !== null && Credentials.token !== null && Credentials.token !== undefined) {
            const ws = owner.props.appState.ws;

            if (ws !== null && ws.readyState === 1) {
                if (this.props.appState.appWsOk !== true)
                    this.props.appActions.setAppWsIsOk({ok: true});
            } else {
                if (this.props.appState.appWsOk !== false)
                    this.props.appActions.setAppWsIsOk({ok: false});
                // if (ws !== null){
                //   openNotificationWithIcon("info", local.AppTitle, 'WS not OK, reconnect');
                // }
                if (await getWsToken(user, serverProt, serverAddress, token) === true) {
                    var serverUri = `${wsServerProt}://${serverAddress}/api/websocket/${user.login}/${user.wsToken}`;
                    const _ws = new WebSocket(serverUri);
                    _ws.onerror = owner.wsOnError;
                    _ws.onmessage = owner.wsOnMessage;
                    _ws.onclose = owner.wsOnClose
                    _ws.onopen = owner.wsOnOpen;
                    owner.props.appState.ws = _ws;
                } else {
                    await updateToken();
                }
            }

            // ws.readyState
            // 0	CONNECTING	Socket has been created. The connection is not yet open.
            // 1	OPEN	The connection is open and ready to communicate.
            // 2	CLOSING	The connection is in the process of closing.
            // 3	CLOSED	The connection is closed or couldn't be opened.
        }
        this.setState({
            appStatusTimerId: setTimeout(this.appStatusTimer, appStatusTimerInterval)
        });
    }

    async wsOnMessage(event) {
        let owner = this;
        let user = this.props.appState.user;
        let isDark = this.props.appState.isDarkTheme;
        let local = this.props.appState.local;
        let showCurrentNotifications = this.props.appState.settings.showCurrentNotifications;

        if (user === null) return;

        // blob to ArrayBuffer
        var buffer = await event.data.arrayBuffer();
        var message = parseWsBlob(buffer);

        if (message.messageType !== 0) return;

        let sensor = user.sensors[message.guid];
        if (sensor === undefined) return;

        var isSensorEvent = false;
        if (sensor.model.includes("TamperProofSensor")
            || sensor.model.includes("KnockSensor")
            || sensor.model.includes("HeatAlarmSensor")) {
            isSensorEvent = true;
            // show sensor event
            sensor.isShowingEvent = true;
            setTimeout(() => {
                sensor.isShowingEvent = false;
                owner.props.appActions.setUser({ user: user });
            }, 5000);
        }

        if (sensor.model === "X3IT01.ButtonSensor"){
            // Ir button
            if (message.value[0] === 0){
                // learn success
                sensor.settings.IsLearned = true;
                owner.props.appActions.setUser({ user: user });
                openNotificationWithIcon("info", local.appTitle, local.ButtonIrLearnSuccessTitle);
                let result = await updateIrButton(sensor, sensor.settings, ApiActions.Put);
            } else if (message.value[0] === 1){
                // invoke success
                openNotificationWithIcon("info", local.appTitle, local.ButtonIrInvokeSuccessTitle);
            } else if (message.value[0] === 2){
                // learn failed
                openNotificationWithIcon("info", local.appTitle, local.ButtonIrLearnFailedTitle);
            } else if (message.value[0] === 3){
                // learn stoped
            } else if (message.value[0] === 4){
                // invoke failed
                openNotificationWithIcon("info", local.appTitle, local.ButtonIrInvokeFailedTitle);
            } else if (message.value[0] === 5){
                // delete success
            } else if (message.value[0] === 6){
                // delete failed
            }
        } else {
            let pv = sensor.parsedValue;
            sensor.value = message.value;
            let pvNew = updateParsedValue(sensor);

            if (pv !== pvNew || isSensorEvent === true) {
                if (sensorShowMessage(sensor) === true 
                    && showCurrentNotifications
                    //&& CheckDev() === false
                    ) {
                    var name = sensorName(user, sensor, local);
                    var device = deviceFullName(sensor.device);
                    var text = sensorValueWithDem(sensor, local);
                    openNotificationWithIcon("info", `${device} - ${name}`, text);
                }
                owner.props.appActions.setUser({ user: user });
            }
        }

        if (message.userVersion !== user.version) {
            let newUser = await getUser();
            if (this.isUpdatingUser === false) {
                owner.isUpdatingUser = true;
                openNotificationWithIcon("info", local.appTitle, local.MessageUserUpdating);
                setTimeout(() => {
                    owner.isUpdatingUser = false;
                }, 2000);
            }
            if (newUser !== null) {
                checkNewUserForNewDevices(local, user, newUser, async () => {
                    if (await updateUserSettings(user.settings, ApiActions.Post) === true){
                        // 
                    }
                }, isDark);
                owner.props.appActions.setUser({ user: newUser })
            }
        }
    }

    wsOnError(event) {
        console.error("WebSocket error observed:", event);
    }

    wsOnClose(event) {
        if (this.props.appState.appWsOk !== false)
            this.props.appActions.setAppWsIsOk({ok: false});
        console.log("WebSocket is closed now.");
    }

    wsOnOpen(event) {
        if (this.props.appState.appWsOk !== true)
            this.props.appActions.setAppWsIsOk({ok: true});
        console.log("WebSocket is open now.");
    }

    async checkPrem(){
        let owner = this;
        let local = this.props.appState.local;
        let user = this.props.appState.user;
        let needToUpdateUser = await checkPremiumLimits(user, local);
        if (needToUpdateUser){
            let newUser = await getUser();
            owner.props.appActions.setUser({ user: newUser });
        }
    }

    onMouseMenuOverOut(over) {
        this.setState({ menuCollapsed: over });
    }

    render() {
        let local = this.props.appState.local;

        document.title = local.appTitle;
        var tabIcon = document.getElementById('tabIcon');
        if (tabIcon.href.includes(packageJson.version) === false) {
            tabIcon.href = tabIcon.href + packageJson.version;
        }

        var isDark = this.props.appState.isDarkTheme;

        if (this.state.appStatusTimerId === null) {
            this.state.appStatusTimerId = setTimeout(this.appStatusTimer, appStatusTimerInterval);
        }

        if (this.props.appState.user !== null && this.checkedForPrem === false){
            this.checkedForPrem = true;
            this.checkPrem();
        }

        return (
            // <div className={isDark ? "AppDark" : "AppLight"}>
                <div id={"Vku_App"} className={`App ${isDark ? "AppDark" : "AppLight"}`}>
                    {
                        this.props.appState.user !== null ? null :
                            <ViewLogin />
                    }
                    {
                        this.props.appState.user === null ? null :
                            <div className='AppLogged'>
                                <ViewBaseModal />
                                <ViewDeviceSelector />
                                
                                <ViewMenu
                                    setCollapsed={this.onMouseMenuOverOut}
                                    collapsed={this.state.menuCollapsed}/>
                                <div className='app__main__content'
                                    style={{paddingLeft: `var(${this.state.menuCollapsed 
                                        ? '--menu-width-collapsed' 
                                        : '--menu-width-opened'})`}}>
                                    <div className='AppHeader'>
                                        <AppTitle />
                                    </div>
                                    <div className="AppContent">
                                        <Dashboard />
                                        <Rooms />
                                        <Scenarios />
                                        <ViewCameraPreview />
                                        <Settings />
                                    </div>
                                    <div className='AppFooter'></div>
                                    {/* <MainMenu /> */}
                                    {/* <div className='App__WsOk'
                                        style={{ backgroundColor: wsOk ? deviceColors.statusOk : deviceColors.statusOff }} /> */}
                                </div>
                            </div>
                    }
                </div>
            //</div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(VkuApp);
