import './viewSensor.css';
import { Component } from 'react';

import { Samy } from 'react-samy-svg';
import { deviceBorderColor } from '../../helpers/funcs';
import { sensorImage, sensorValueWithDem, sensorIconFill, 
    sensorName, sensorIsActor } from '../../helpers/helperSensor';
import { sendToGuidBool } from '../../helpers/helperWs';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewSensor extends Component {
    constructor(props){
        super(props);

        this.setExpanded = this.setExpanded.bind(this);
    }

    setExpanded(event){
        let sensor = this.props.sensor
        let user = this.props.appState.user;;

        if (sensorIsActor(sensor) === true){
            let value = sensor.parsedValue === null
                || sensor.parsedValue === undefined
                ? true : !sensor.parsedValue;
            sendToGuidBool(this.props.appState.ws, 
                user,
                sensor.guid, value);
            event.stopPropagation();
        }
    }
    
    render() {
        let user = this.props.appState.user;
        let local = this.props.appState.local;
        let sensor = this.props.sensor;
        let isDarkTheme = this.props.appState.isDarkTheme;
        let borderColor = sensor.device === undefined
            ? "#00000000"
            : deviceBorderColor(sensor.device, isDarkTheme);
        let iconStyle = sensorIconFill(user, sensor, borderColor);

        return (
            <div className="ViewSensor" onClick={this.setExpanded} title={sensorName(user, sensor, local)}
                style={{cursor: sensorIsActor(sensor) === true ? 'pointer' : 'auto'}}>
                <div className="ViewSensor__MainImage">
                    <Samy path={sensorImage(user, sensor)} style={iconStyle} />
                </div>
                <div className="ViewSensor__Name" title={sensorValueWithDem(sensor, local, true)}
                    style={{borderColor: sensorIsActor(sensor) === true ? borderColor : null}}>
                    {sensorValueWithDem(sensor, local, true)}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewSensor);