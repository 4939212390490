import PM from '../images/devices/PM.svg';
import car from '../images/devices/car.svg';
import webcam from '../images/devices/webcam.svg';
import Repeater from '../images/devices/Repeater.svg';
import lightbulb from '../images/devices/lightbulb.svg';
import Gas_sensor from '../images/devices/Gas_sensor.svg';
import Smart_plug from '../images/devices/Smart_plug.svg';
import Door_sensor from '../images/devices/Door_sensor.svg';
import Water_sensor from '../images/devices/Water_sensor.svg';
import Smoke_sensor from '../images/devices/Smoke_sensor.svg';
import Multi_Detector from '../images/devices/Multi_Detector.svg';
import IR_Transmitter from '../images/devices/IR_Transmitter.svg';
import Сurtains_device from '../images/devices/Сurtains_device.svg';
import Smart_wall_switch_01 from '../images/devices/Smart_wall_switch_01.svg';
import Smart_wall_switch_02 from '../images/devices/Smart_wall_switch_02.svg';
// import Smart_wall_switch_03 from '../images/devices/Smart_wall_switch_03.svg';
// import Smart_wall_switch_04 from '../images/devices/Smart_wall_switch_04.svg';
//import  from '../images/devices/.svg';
import camera from '../images/devicePictures/camera.png';
import dimer from '../images/devicePictures/dimer.png';
import door from '../images/devicePictures/door.png';
import hub from '../images/devicePictures/hub.png';
import ir from '../images/devicePictures/ir.png';
import multi from '../images/devicePictures/multi.png';
import plug from '../images/devicePictures/plug.png';
import relay from '../images/devicePictures/relay.png';
import smoke from '../images/devicePictures/smoke.png';
import water from '../images/devicePictures/water.png';

import timer from '../images/sensors/timer.svg';
import button from '../images/sensors/button.svg';

import all from '../images/devices/menu_dash.svg';

import { fillDevices, userGetHubs } from './funcs';
import { sensorImage, sensorIsActor, sensorShowInDashboard } from './helperSensor';
import { updateRoom } from './helperApi';
import { ApiActions, dashFilterDefault } from './constants';

export function deviceImage(user, device){
    if (device.model === "ScenarioDevice") return button;
    if (device.model === "TimerDevice") return timer;
    if (device.model === "TimeDelay") return timer;

    if (device.model === "OnOffSwitch")
    {
        var trueDevice = deviceTrueDevice(user, device);
        if (trueDevice.model.includes("CompositeDevice") 
            && trueDevice.devices.length === 2)
            return lightbulb;
        else {
            var sensorsCount = device.sensors.length - 2;
            return Smart_wall_switch_01;
            // if (sensorsCount === 1) return Smart_wall_switch_01;
            // if (sensorsCount === 2) return Smart_wall_switch_02;
            // if (sensorsCount === 3) return Smart_wall_switch_03;
            // if (sensorsCount === 4) return Smart_wall_switch_04;
        }
    }

    if (device.model === "GenericIpcam") return webcam;
    if (device.model === "X3PD01") return PM;
    if (device.model === "X3MD01") return Multi_Detector;
    if (device.model === "X3DS01"){
        let openCloseSensor = deviceGetOpenCloseSensor(device);
        if (openCloseSensor === null) return Door_sensor;
        return sensorImage(user, openCloseSensor);
    }
    if (device.model === "X3IT01") return IR_Transmitter;
    if (device.model === "X3WD01") return Water_sensor;
    if (device.model === "X3GD02") return Gas_sensor;
    if (device.model === "X2SK11") return Smart_plug;
    if (device.model === "X3SD02") return Smoke_sensor;
    if (device.model === "X2CC11") return Сurtains_device;
    if (device.model === "Dimmer") return Smart_wall_switch_02;
    if (device.model === "X0RE01") return Repeater;
    if (device.model === "X3MC01MultiControl") return Smart_wall_switch_01;
    if (device.model === "PromasatTracker") return car;
}

export function deviceImageByModel(model){
    if (model === dashFilterDefault) return all;
    if (model === "ScenarioDevice") return button;
    if (model === "TimerDevice") return timer;
    if (model === "TimeDelay") return timer;
    if (model.includes("CompositeDevice")) return lightbulb;
    if (model === "OnOffSwitch") return Smart_wall_switch_01;
    if (model === "GenericIpcam") return webcam;
    if (model === "X3PD01") return PM;
    if (model === "X3MD01") return Multi_Detector;
    if (model === "X3DS01")return Door_sensor;
        //return sensorImage(user, openCloseSensor);
    if (model === "X3IT01") return IR_Transmitter;
    if (model === "X3WD01") return Water_sensor;
    if (model === "X3GD02") return Gas_sensor;
    if (model === "X2SK11") return Smart_plug;
    if (model === "X3SD02") return Smoke_sensor;
    if (model === "X2CC11") return Сurtains_device;
    if (model === "Dimmer") return Smart_wall_switch_02;
    if (model === "X0RE01") return Repeater;
    if (model === "X3MC01MultiControl") return Smart_wall_switch_01;
    if (model === "PromasatTracker") return car;

    return null;
}

export function devicePicture(user, device){

    if (device.model === "TimeDelay") return timer;
    if (device.model === "X3Gw01") return hub;
    if (device.model === "X3IT01") return ir;
    if (device.model === "GenericIpcam") return camera;
    if (device.model === "X3MD01") return multi;
    if (device.model === "X3PD01" || device.model === "X3WD01") return water;
    if (device.model === "X3SD02") return smoke;
    if (device.model === "X3DS01") return door;
    if (device.model === "X2SK11") return plug;
    if (device.model === "OnOffSwitch") {
        var d = deviceTrueDevice(user, device);
        if (d.model.includes("CompositeDevice"))
            return dimer;
        else
            return relay;
    }
    return null;
}

export function deviceTrueDevice(user, device){
    var devices = {};
    fillDevices(user.devices, devices);
    for(const guid of Object.keys(devices)){
        if (devices[guid].devices !== undefined
            && devices[guid].devices !== null
            && devices[guid].devices.includes(device)){
                if (devices[guid].model.includes("CompositeDevice")) 
                    return devices[guid];
                break;
            }
    }
    return device;
}

export function getDeviceHub(user, device){
    var hubs = userGetHubs(user);
    for(let h of hubs){
        for(let d of h.devices){
            if (d.guid === device.guid){
                return h;
            }
        }
    }
    return null;
}

export function deviceIconFill(user, device, color){
    var style = {
        stroke: color,
        strokeWidth: 1, 
        strokeMiterlimit: 0, 
        opacity: .7,
        fill: "#00000000"
    };
    if (device.model === undefined) return style;
    
    if (device.model === "OnOffSwitch"){
        var trueDevice = deviceTrueDevice(user, device);
        if (trueDevice.model.includes("CompositeDevice") 
            && trueDevice.devices.length === 2){
            style.fill = color;
        }
        else {
            style.strokeWidth = 3;
        }
    } else {
        if (device.model !== "PromasatTracker"){
            style.strokeWidth = 3;
        }
    }
    if (device.model.includes("PromasatTracker")) {
        style.fill = color;
    }
    if (device.model.includes("X0RE01")) {
        style.fill = color;
        style.strokeWidth = 1;
    }

    return style;
}

export function deviceIconFillByModel(model, color){
    var style = {
        stroke: color,
        strokeWidth: 1, 
        strokeMiterlimit: 0, 
        opacity: .7,
        fill: "#00000000"
    };
    if (model === undefined) return style;
    if (model === "CompositeDevice") style.fill = color;
    else if (model === "OnOffSwitch") style.strokeWidth = 3;
    else if (model !== "PromasatTracker") style.strokeWidth = 3;
    
    if (model.includes("PromasatTracker")) style.fill = color;
    if (model.includes("X0RE01") || model === dashFilterDefault) {
        style.fill = color;
        style.strokeWidth = 1;
    }

    return style;
}

export function deviceIsInRoom(user, device){
    for(var i in user.rooms){
        var r = user.rooms[i];
        if (r.devices.includes(device.guid)) 
            return true;
    }
    return false;
}

export async function removeFromRooms(user, device){
    for(var i in user.rooms){
        var r = user.rooms[i];
        var index = r.devices.indexOf(device.guid);
        if (index !== -1) {
            r.devices.splice(index, 1);

            let res = await updateRoom(user, r, ApiActions.Put);
        }
    }
    return true;
}

export function deviceIsLinked(user, device){
    return device.guid in user.relations.linked;
}

export function deviceIsShared(user, device){
    return device.guid in user.relations.shared;
}

export function getGeoSensorValue(device){
    if (device.model.includes("PromasatTracker") === false) return null;
    for(var s of device.sensors){
        if (s.model.includes('GeoSensor')){
            if (s.parsedValue === undefined) return null;
            return s.parsedValue;
        }
    }
    return null;
}

export function deviceHasActor(device){
    if (device.sensors === null
        || device.sensors === undefined
        || device.sensors.length === 0)
        return false;

    for(var i in device.sensors){
        var s = device.sensors[i];
        if (sensorIsActor(s) === true)
            return true;
    }
    return false;
}

export function deviceGetActor(device){
    if (device.sensors === null
        || device.sensors === undefined
        || device.sensors.length === 0)
        return null;

    for(var i in device.sensors){
        var s = device.sensors[i];
        if (sensorIsActor(s) === true)
            return s;
    }
    return null;
}

export function deviceGetOpenCloseSensor(device){
    if (device.sensors === null
        || device.sensors === undefined
        || device.sensors.length === 0)
        return null;

    for(var i in device.sensors){
        var s = device.sensors[i];
        if (s.model.includes("OpenCloseSensor"))
            return s;
    }
    return null;
}

export function deviceGetDashSensors(device){
    if (device.sensors === null
        || device.sensors === undefined
        || device.sensors.length === 0)
        return null;

    var sensors = [];
    for(var i in device.sensors){
        var s = device.sensors[i];
        if (sensorShowInDashboard(s) === true)
            sensors.push(s);
    }
    return sensors;
}

export function deviceGetHistorySensors(device){
    if (device.sensors === null
        || device.sensors === undefined
        || device.sensors.length === 0)
        return null;

    var sensors = [];
    if (device.model === "PromasatTracker") return sensors;
    for(var s of device.sensors){
        if (s.model.includes("BatterySensor")) continue;
        //if (s.model.includes("LinkQualitySensor")) continue;
        if (s.model.includes("BatteryVoltageSensor")) continue;
        if (s.sensorValueType === 'Float' || s.sensorValueType === 'Integer')
            sensors.push(s);
    }
    return sensors;
}

export function getHubSensorValue(hub, sensorName){
    if (hub.model.includes("X3Gw01") === false) return false;
    for(var s of hub.sensors){
        if (s.model.includes(sensorName)){
            if (s.parsedValue === undefined) return false;
            return s.parsedValue;
        }
    }
    return false;
}

export function getHubSensor(hub, sensorName){
    if (hub.model.includes("X3Gw01") === false) return null;
    for(var s of hub.sensors){
        if (s.model.includes(sensorName)){
            return s;
        }
    }
    return null;
}

export function deviceGetDefaultMainSensor(device){
    if (device.sensors === null || device.sensors === undefined) 
        return null;
    for(var sensor of device.sensors){
        if (sensor.model.includes("OnlineSensor")
            || sensor.model.includes("BatterySensor")
            || sensor.model.includes("BatteryVoltageSensor")) continue;
        return sensor;
    }
    return null;
}