import './viewDevice.css';
import { Component } from 'react';

import { Samy } from 'react-samy-svg';
import { ModalActions, ApiActions, LimitType } from '../../helpers/constants';
import { deviceBorderColor, deviceFullName, 
    openNotificationWithIcon, checkLimitByType, deviceIsCamera } from '../../helpers/funcs';
import { deviceImage, deviceIconFill, deviceIsInRoom,
    removeFromRooms, deviceIsLinked, deviceIsShared,
    deviceHasActor, deviceGetActor,
    deviceGetDashSensors } from '../../helpers/helperDevice';
import { sensorShowInDashboard, sensorValueWithDem } from '../../helpers/helperSensor';
import { sendToGuidBool } from '../../helpers/helperWs';

import { getCameraInfo, updatePromaSatUnitVkuLink, updatePromaSatUnit,
    updateUserRelationsLinked, getUser, updateDevice } from '../../helpers/helperApi';

import settings from '../../images/menu/menu_settings.svg';
import confirm from '../viewModal/modalConfirm';

import ViewSensor from '../viewSensor/viewSensor';
import ViewDeviceOptions from '../viewDeviceOptions/viewDeviceOptions';

import { Popover } from 'antd';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewDevice extends Component {
    constructor(props){
        super(props);

        this.setExpanded = this.setExpanded.bind(this);
        this.sendCommand = this.sendCommand.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.hidePopover = this.hidePopover.bind(this);

        this.openDevice = this.openDevice.bind(this);
        this.addToLocation = this.addToLocation.bind(this);
        this.removeFromRooms = this.removeFromRooms.bind(this);
        this.removeLink = this.removeLink.bind(this);
        this.editShare = this.editShare.bind(this);
        this.deleteDevice = this.deleteDevice.bind(this);
        this.openCamera = this.openCamera.bind(this);

        this.loadCameraInfo = this.loadCameraInfo.bind(this);

        const device = this.props.device;
        this.hasActor = deviceHasActor(device);
        this.actor = deviceGetActor(device);
        this.sensors = deviceGetDashSensors(device);

        this.state = {
            hovered: false,
            ppVisible: false
        }

        if (device.model === 'GenericIpcam' && device.info === undefined){
            this.loadCameraInfo();
            //setTimeout(this.loadCameraInfo, 100);
        }
    }

    async loadCameraInfo() {
        const device = this.props.device;
        
        device.info = await getCameraInfo(device.guid);
    }

    openCamera(){
        const device = this.props.device;

        window.open(`http://${device.info.localIp}`, '_blank').focus();
    }
    
    onMouseOver() {
        this.setState({ hovered: true });
    }

    onMouseOut() {
        this.setState({ hovered: false });
    }

    async setExpanded(){
        var device = this.props.device;

        if (device.model === "X3IT01"){
            this.props.appActions.setShowModal({
                modalAction: ModalActions.OpenIR,
                objectModal: device
            });
        } else if (this.hasActor === true && this.sensors.length === 1){
            //var deviceName = deviceFullName(device);

            // actor action
            if (this.actor.model.includes('SwitchSensor')){
                let value = this.actor.parsedValue === null
                    || this.actor.parsedValue === undefined
                    ? true : !this.actor.parsedValue;
                sendToGuidBool(this.props.appState.ws, 
                    this.props.appState.user,
                    this.actor.guid, value);
                //openNotificationWithIcon("info", `${deviceName}`, `Command sended - ${value}`);
            }

        } else {
            if (this.props.appState.dashExpandedDevice === device.guid)
                this.props.appActions.setDashExpandedDevice({id: null});
            else
                this.props.appActions.setDashExpandedDevice({id: device.guid});
        }
    }

    sendCommand(event){
        let value = this.actor.parsedValue === null
            || this.actor.parsedValue === undefined
            ? true : !this.actor.parsedValue;
        sendToGuidBool(this.props.appState.ws, 
            this.props.appState.user,
            this.actor.guid, value);

        event.stopPropagation();
    }

    openDevice(e) {
        this.props.appActions.setShowModal({
            modalAction: ModalActions.EditObject,
            objectModal: this.props.device
        });
    }

    addToLocation(){
        this.props.appActions.setShowModal({
            modalAction: ModalActions.SelectRoom,
            objectModal: this.props.device
        });
    }

    async removeFromRooms(){
        let owner = this;
        var local = owner.props.appState.local;
        var user = owner.props.appState.user;
        var device = owner.props.device;
        let res = await removeFromRooms(user, device);

        if (res){
            let newUser = await getUser();
            owner.props.appActions.setUser({ user: newUser });
            openNotificationWithIcon("info", 
                local.DeviceActionsDeleteFromLocation, local.Success);
        } else {
            openNotificationWithIcon("info", local.Save, local.Fail);
        }
    }

    async removeLink(){
        var local = this.props.appState.local;
        var device = this.props.device;

        var result = await updateUserRelationsLinked({guid: device.guid}, ApiActions.Delete);
        if (result === true){
            const newUser = await getUser();
            this.props.appActions.setUser({user: newUser});
            openNotificationWithIcon("info", 
                local.RelationsLinkedDelete, local.Success);
        } else {
            openNotificationWithIcon("info", 
                local.RelationsLinkedDelete, local.Fail);
        }
    }

    editShare(){
        let local = this.props.appState.local;
        let user = this.props.appState.user;
        let device = this.props.device;
        let limitMsg = checkLimitByType(user, local, 
            deviceIsCamera(device) === true ? LimitType.CameraShare : LimitType.DeviceShare);

        if (limitMsg === null){
            this.props.appActions.setShowModal({
                modalAction: ModalActions.EditShare,
                objectModal: this.props.device
            });
        } else {
            openNotificationWithIcon("info", local.AppName, limitMsg);
        }
    }

    async deleteDevice(){
        let local = this.props.appState.local;
        let user = this.props.appState.user;
        let ws = this.props.appState.ws;
        let device = this.props.device;
        let isDarkTheme = this.props.appState.isDarkTheme;

        if (await confirm(local.appTitle, local.DeviceActionsAskDelete, 
            local.Yes, local.Cancel, isDarkTheme) === false){
            return;
        }

        let result = false;

        if (device.model === "PromasatTracker"){
            result = await updatePromaSatUnitVkuLink({
                login: user.settings.promaSatUser.login,
                password: user.settings.promaSatUser.password,
                enable: false,
                Id: device.unitId
            }, ApiActions.Get);
            if (result === true){
                result = await updatePromaSatUnit({ Id: device.unitId }, ApiActions.Delete);
            }
        } else {
            result = await updateDevice(user, ws, device, ApiActions.Delete);
        }

        if (result === true){
            let newUser = await getUser();
            this.props.appActions.setUser({ user: newUser })
            openNotificationWithIcon("info", local.AppName, local.Success);
        } else {
            openNotificationWithIcon("info", local.AppName, local.Fail);
        }
    }

    handleVisibleChange(visible) {
        this.setState({ ppVisible: visible });
    };

    hidePopover(){
        this.setState({ ppVisible: false });
    }
    
    render() {
        let local = this.props.appState.local;
        let user = this.props.appState.user;
        var device = this.props.device;
        let isDarkTheme = this.props.appState.isDarkTheme;
        let isExpanded = this.props.appState.dashExpandedDevice === device.guid;
        let isCamera = device.model === 'GenericIpcam'
            && device.info !== undefined
            && device.info !== null;
        let viewClass = isExpanded ? " ViewDeviceExpanded" : " ViewDeviceCollapsed";
        let borderColor = deviceBorderColor(device, isDarkTheme);
        let iconStyle = deviceIconFill(user, device, borderColor);
        let isSocket = this.hasActor === true 
            && this.sensors.length > 1
            && device.model !== "X3IT01";

        return (
            <div className={"ViewDevice"+viewClass} style={{borderColor: borderColor}} 
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
                onClick={this.setExpanded}>
                <div className="Name" style={{opacity: device.online ? 1 : .5}}>
                    <p>
                        {deviceFullName(device, local)}
                    </p>
                    <Popover
                        onVisibleChange={this.handleVisibleChange}
                        mouseEnterDelay={0}
                        trigger="hover"
                        visible={this.state.ppVisible}
                        content={<ViewDeviceOptions 
                            isDevice={true}
                            hidePopover={this.hidePopover}
                            openDevice={this.openDevice}
                            addToLocation={this.addToLocation}
                            inRoom={deviceIsInRoom(user, device)}
                            removeFromRooms={this.removeFromRooms}
                            isLinked={deviceIsLinked(user, device)}
                            removeLink={this.removeLink}
                            isShared={deviceIsShared(user, device)}
                            editShare={this.editShare}
                            deleteDevice={this.deleteDevice}
                            isCamera={isCamera}
                            openCamera={this.openCamera}
                            isDarkTheme={isDarkTheme}
                            local={local}
                            user={user}/>
                        }>
                        <img style={{opacity:this.state.hovered ? 1 : 0 }} 
                            src={settings} alt=""
                            onClick={(e) => e.stopPropagation()}
                            />
                    </Popover>
                </div>
                <div className="ViewDevice__Sensors">
                    <div className={isSocket === true
                            ? 'ViewDevice__Sensors__ShortBlock'
                            : 'ViewDevice__Sensors__MainBlock'}>
                        <div className={isSocket
                            ? "ViewDevice__Sensors__MainBlock__ShortImage"
                            : "ViewDevice__Sensors__MainBlock__MainImage"}>
                            <Samy path={deviceImage(user, device)} style={iconStyle} />
                        </div>
                        {
                            isSocket
                            ? <div className='ViewDevice__Sensors__ShortBlock__Button' 
                                style={{borderColor: borderColor}}
                                onClick={this.sendCommand}>
                                {sensorValueWithDem(this.actor, local, true)}
                            </div>
                            : null
                        }
                    </div>
                    {
                        !isExpanded ? null :
                        this.sensors.map((el, i) =>{
                            if (!sensorShowInDashboard(el)) return null;
                            return <ViewSensor key={"dashSensor" + el.guid}  sensor={el} />;
                        })
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewDevice);