import React, {useState} from "react";
import ReactDOM from "react-dom";
import './modalIrNewButton.css';

import { deviceColors } from "../../helpers/constants";

function ModalConfirm({resolve, local, isDark}) {
    const [name, setName] = useState('');
    const [group, setGroup] = useState('');
    function clickOK() {
        removeDialog();
        
        resolve({
            Name: name,
            GroupName: group,
            Preset: 2,
            PresetButton: 1
        });
    }
    function clickClose() {
        removeDialog();
        resolve(null);
    }
    return (
        <div id="modal__irnewbutton__container_dialog" 
            className='modal__irnewbutton__container_dialog'
            style={{
                borderColor: deviceColors.borderBlue,
                backgroundColor: isDark 
                    ? deviceColors.backGroundDark 
                    : deviceColors.backGroundLight}}>
            <p>{local.ButtonsIrMiscNewTitle}</p>
            <input className="ViewEditDevice__Name"
                value={name}
                onChange={(e)=> setName(e.target.value)}
                style={{height:"45px"}}
                placeholder={local.ButtonsIrMiscNewNameTitle}/>
            <input className="ViewEditDevice__Name"
                value={group}
                onChange={(e)=> setGroup(e.target.value)}
                style={{height:"45px"}}
                placeholder={local.ButtonsIrMiscNewGroupTitle}/>
            <div className='modal__irnewbutton__container_dialog__buttons'>
                <button disabled={name === ''} onClick={clickOK}>{local.OK}</button>
            </div>
            <div className='modal__irnewbutton__container_dialog__buttons'>
                <button onClick={clickClose}>{local.Cancel}</button>
            </div>
        </div>
    );
}

export default function irNewButton(local, isDark = true) {
    return new Promise((resolve, reject) => {
        addDialog(local, isDark, resolve);
    });
}

function addDialog(local, isDark, resolve) {
    const body = document.getElementById("Vku_App");
    const div = document.createElement("div");
    div.setAttribute("id", "modal__irnewbutton__container");
    div.setAttribute("className", "modal__irnewbutton__container");
    body.appendChild(div);
    ReactDOM.render(
        <ModalConfirm local={local} isDark={isDark} resolve={resolve} />,
        div
    );
}

function removeDialog(id) {
    const div = document.getElementById("modal__irnewbutton__container");
    const body = document.getElementById("Vku_App");
    body.removeChild(div);
}