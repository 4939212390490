import './viewEditDevice.css';
import { Component } from 'react';

import { Samy } from 'react-samy-svg';

import { sendToGuidByte } from '../../../helpers/helperWs';
import { getUser, updateUserSettings } from '../../../helpers/helperApi';
import modalDeviceHistory from '../../viewModal/modalDeviceHistory';
import { ApiActions } from "../../../helpers/constants";
import { devicePicture, getGeoSensorValue, 
    deviceGetHistorySensors } from '../../../helpers/helperDevice';
import { deviceBorderColor, openNotificationWithIcon } from '../../../helpers/funcs';
import { sensorShowInDeviceInfo, sensorImage, sensorValueWithDem, sensorIconFill, 
    sensorName } from '../../../helpers/helperSensor';

import { googleMapApiKey } from '../../../helpers/constants'; 

import GoogleMapReact from 'google-map-react';

import pin_red from '../../../images/other/pin_red.png';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewEditDevice extends Component {
    constructor(props){
        super(props);

        this.save = this.save.bind(this);
        
        this.plugReset = this.plugReset.bind(this);
        this.showHistory = this.showHistory.bind(this);
        this.close = props.close;

        let device = this.props.device;
        
        this.state = {
            changed: false,
            name: device.settings.name,
        };
    }

    async save(){
        var device = this.props.device;
        var user = this.props.appState.user;
        var local = this.props.appState.local;
        if (device.settings.name !== this.state.name){
            device.settings.name = this.state.name;
            if (await updateUserSettings(user.settings, ApiActions.Post) === true){
                let newUser = await getUser();
                this.props.appActions.setUser({ user: newUser });
                openNotificationWithIcon("info", 
                    local.Save, local.Success);
                this.close();
            }
        }
    }

    async showHistory(){
        var local = this.props.appState.local;
        var user = this.props.appState.user;
        var device = this.props.device;
        var isDarkTheme = this.props.appState.isDarkTheme;
        await modalDeviceHistory(local, user, device, isDarkTheme);
    }

    plugReset(){
        const device = this.props.device;
        var sensor = null;
        for(var s of device.sensors){
            if (s.model.includes("X2SK11.SwitchSensor")){
                sensor = s;
                break;
            }
        }
        if (sensor === null) return;
        sendToGuidByte(this.props.appState.ws, 
            this.props.appState.user,
            sensor.guid, 0xBB);
    }

    render() {
        var local = this.props.appState.local;
        var user = this.props.appState.user;
        var device = this.props.device;
        var deviceImage = devicePicture(this.props.appState.user, device);
        var isPlug = device.model.includes('X2SK11');
        var point = getGeoSensorValue(device);

        var isDarkTheme = this.props.appState.isDarkTheme;
        var borderColor = device === undefined
            ? "#00000000"
            : deviceBorderColor(device, isDarkTheme);
        var historyButtonEnabled = deviceGetHistorySensors(device).length > 0;
        
        return (
            <div className="ViewEditDevice">
                <p>
                    {device.modelDescription.replace('detector', 'sensor')}
                </p>
                <div className='ViewEditDevice__Image' style={{display:deviceImage===null?'none':'block'}}>
                    <img src={deviceImage} alt='' />
                </div>
                <input className="ViewEditDevice__Name"
                    value={this.state.name}
                    onInput={(e, value) => {
                        if (e.constructor.name === "SyntheticBaseEvent") 
                            this.forceUpdate();
                    }}
                    onChange={(e)=> this.setState({name: e.target.value, changed: true})}
                    style={{margin: "10px 0px", height:"45px" }}
                    placeholder={local.ScenarioCreateNewButtonName}/>
                <div style={{display:!isPlug?'none':'block'}}>
                    <button 
                        style={{margin: "5px 0px", height:"45px", width: '100%'}}
                        onClick={this.plugReset}>
                        {local.PlugResetTitle}
                    </button>
                </div>
                <p>
                    {local.DeviceInfoSensors}
                </p>
                <div className='ViewEditDevice__Sensors'>
                    {   
                        device.sensors.map((sensor, i) =>{
                            if (!sensorShowInDeviceInfo(sensor)) return null;
                            //console.log(sensor);

                            var iconStyle = sensorIconFill(user, sensor, borderColor);
                            return <div className='ViewEditDevice__Sensor'
                                key={'deviceEditSensor'+sensor.guid}>
                                <div className="ViewEditDevice__Sensor__Image">
                                    <Samy path={sensorImage(user, sensor)} style={iconStyle} />
                                </div>
                                <div className="ViewEditDevice__Sensor__Name">
                                    {sensorName(user, sensor, local)}
                                </div>
                                <div className="ViewEditDevice__Sensor__Value">
                                    {sensorValueWithDem(sensor, local)}
                                </div>
                            </div>;
                        })
                    }
                </div>
                {
                    point === null ? null :
                    <div className='ViewEditDevice__Map'>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: googleMapApiKey }}
                            defaultCenter={point}
                            defaultZoom={13}>
                            <MapMarker
                                lat={point.lat}
                                lng={point.lng}
                                text="some text"
                            />
                        </GoogleMapReact>
                    </div>
                }
                <div className='ViewEditDevice__Buttons'>
                    <button 
                        disabled={this.state.changed === false}
                        onClick={this.save}>
                        {local.Save}
                    </button>
                    {
                        historyButtonEnabled === false ? null :
                        <button
                            onClick={this.showHistory}>
                            {local.DeviceInfoChartsTitle}
                        </button>
                    }
                </div>
                
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewEditDevice);

const MapMarker = ({ text }) => 
    <div className='Tracker__Map__Pin'>
        <img className='Tracker__Map__Pin__Image' src={pin_red} alt='' />
    </div>;