import './mainMenu.css';
import { Component } from 'react';

import MenuItem from './menuItem';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class MainMenu extends Component {

  render() {

    return (
      <div className="MainMenu">
          <MenuItem activeTab={1} />
          <MenuItem activeTab={2} />
          <MenuItem activeTab={3}/>
          <MenuItem activeTab={4} />
          <MenuItem activeTab={5} />
      </div>
    );
  }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);