import { Component } from 'react';

import { userIsDemo, openNotificationWithIcon } from '../../helpers/funcs';
//import confirm from '../viewModal/modalConfirm';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewDeviceOptions extends Component {

    constructor(props){
        super(props);

        this.click = this.click.bind(this);
    }

    async click(e, b){
        var isScenario = this.props.isScenario;

        if (e) e.stopPropagation();
        this.props.hidePopover();

        let local = this.props.appState.local;
        if (userIsDemo(this.props.appState.user) === true){
            openNotificationWithIcon("info", 
                local.AppName, local.NotInDemo);
            return;
        }

        if (b === local.DeviceActionsInfo){
            this.props.openDevice();
        }
        if (b === local.DeviceActionsAddToExistingLocation){
            this.props.addToLocation();
        }
        if (b === local.DeviceActionsDeleteFromLocation){
            this.props.removeFromRooms();
        }
        if (b === local.RelationsLinkedDelete){
            this.props.removeLink();
        }
        if (b === local.RelationsSharedTitle){
            this.props.editShare();
        }
        if (b === local.DeviceActionsDelete){
            this.props.deleteDevice();
        }

        // if (b === 'test'){
        //     console.log(await confirm("1", "m", "2", "3"));
        // }

        if (isScenario){
            if (b === local.TurnOn){
                this.props.changeEnabled(true);
            }
            if (b === local.TurnOff){
                this.props.changeEnabled(false);
            }
            if (b === local.Edit){
                this.props.editTrigger();
            }
            if (b === local.Delete){
                this.props.deleteTrigger();
            }
        }

        if (b === local.ButtonIrPush){
            this.props.irPush();
        }
        if (b === local.ButtonIrLearn){
            this.props.irLearn();
        }
        if (b === local.ButtonIrCurrentDelete){
            this.props.irDelete();
        }
    }

    render() {
        let user = this.props.user;
        let local = this.props.local;
        let isDark = this.props.isDarkTheme;
        let inRoom = this.props.inRoom;
        let isLinked = this.props.isLinked;
        let isDevice = this.props.isDevice;
        let isScenario = this.props.isScenario;
        let isEnabled = this.props.isEnabled;

        let showIrPush = this.props.showIrPush;
        let showIrLearn = this.props.showIrLearn;
        let showIrDelete = this.props.showIrDelete;

        let popupButtons = [ ];

        if (isDevice){
            popupButtons.push(local.DeviceActionsInfo);
            popupButtons.push(local.DeviceActionsAddToExistingLocation);
        }

        if (inRoom === true){
            popupButtons.push(local.DeviceActionsDeleteFromLocation);
        }
        if (isLinked === true){
            popupButtons.push(local.RelationsLinkedDelete);
        }
        if (isLinked === false){
            popupButtons.push(local.DeviceActionsDelete);
            const friends = user.relations.friends;
            if (friends.length > 0){
                popupButtons.push(local.RelationsSharedTitle);
            }
        }

        if (isScenario){
            popupButtons.push(local.Edit);
            popupButtons.push(local.Delete);
            if (isEnabled === true){
                popupButtons.push(local.TurnOff);
            } else {
                popupButtons.push(local.TurnOn);
            }
        }

        if (showIrPush === true){
            popupButtons.push(local.ButtonIrPush);
        }
        if (showIrLearn === true){
            popupButtons.push(local.ButtonIrLearn);
        }
        if (showIrDelete === true){
            popupButtons.push(local.ButtonIrCurrentDelete);
        }

        // if (true){
        //     popupButtons.push('test');
        // }

        
        return (
            <div className="ViewDeviceOptions">
                {
                    popupButtons.map((b, i)=>{
                        return  <button key={"deviceOption"+b}
                            style={{
                                color: isDark ? "#B8B8B8" : "white",
                                backgroundColor: '#6B6965',
                                borderColor: '#6B6965',
                                margin: "10px 0px", 
                                width: "100%", 
                                height:"45px"
                            }}
                            onClick={(e) => this.click(e, b)}>
                            {b}
                        </button>;
                    })
                }
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewDeviceOptions);