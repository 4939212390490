import React, {useState} from "react";
import ReactDOM from "react-dom";
import './modalFriendRequest.css';

import { openNotificationWithIcon,
    getUserFriendExists } from '../../helpers/funcs';
import { checkUserExists, 
    updateUserRelations } from '../../helpers/helperApi';
import { ApiActions, deviceColors } from "../../helpers/constants";

function ModalFriendRequestDialog({ resolve, local, user, isDark }) {
    const [login, setLogin] = useState('');
    async function clickOK() {
        let result = getUserFriendExists(user, local, login);
        if (result !== local.OK){
            openNotificationWithIcon('info', local.appTitle, result);
            return;
        }
        result = await checkUserExists(login, ApiActions.Get);
        if (result === false){
            openNotificationWithIcon('info', local.appTitle, local.LoginDoestExists);
            return;
        }
        result = await updateUserRelations(login, 'outgoing', ApiActions.Post);
        removeDialog();
        if (result === true){
            resolve(true);
        } else {
            resolve(false);
        }
    }
    function clickExit() {
        removeDialog();
        resolve(null);
    }
    return (
        <div id="modal__friend_request__container_dialog" 
            className='modal__friend_request__container_dialog'
            style={{
                borderColor: deviceColors.borderBlue,
                backgroundColor: isDark 
                    ? deviceColors.backGroundDark 
                    : deviceColors.backGroundLight}}>
            <p>{local.RelationsSendRequest}</p>
            <input className="modal__friend_request__container_dialog__name"
                value={login}
                onChange={(e)=> setLogin(e.target.value)}
                style={{height:"45px"}}
                placeholder={local.RelationsUserLogin}/>
            <div className='modal__friend_request__container_dialog__button'>
                <button onClick={clickOK}>{local.RelationsSendRequest}</button>
            </div>
            <div className='modal__friend_request__container_dialog__button'>
                <button onClick={clickExit}>{local.Close}</button>
            </div>
        </div>
    );
}

export default function modalFriendRequest(local, user, isDark = true) {
    return new Promise((resolve, reject) => {
        addDialog(local, user, isDark, resolve);
    });
}

function addDialog(local, user, isDark, resolve) {
    const body = document.getElementById("Vku_App");
    const div = document.createElement("div");
    div.setAttribute("id", "modal__friend__request__container");
    div.setAttribute("className", "modal__friend__request__container");
    body.appendChild(div);
    ReactDOM.render(
        <ModalFriendRequestDialog local={local} user={user} isDark={isDark} resolve={resolve} />,
        div
    );
}

function removeDialog() {
    const div = document.getElementById("modal__friend__request__container");
    const body = document.getElementById("Vku_App");
    body.removeChild(div);
}