import { parse as uuidParse } from 'uuid';

export function sendMessage(ws, bytes){

}

export function sendToButton(ws, user, buttonGuid){
    var buffer = new Uint8Array(27);
    buffer[0] = 0x00; // protocol version
    buffer[1] = 0x00; // type - event
    buffer[2] = user.version;

    var guid = uuidParse(buttonGuid);
    buffer[3] = guid[3];
    buffer[4] = guid[2];
    buffer[5] = guid[1];
    buffer[6] = guid[0];
 
    buffer[7] = guid[5];
    buffer[8] = guid[4];
 
    buffer[9] = guid[7];
    buffer[10] = guid[6];
 
    buffer[11] = guid[8];
    buffer[12] = guid[9];
     
    buffer[13] = guid[10];
    buffer[14] = guid[11];
    buffer[15] = guid[12];
    buffer[16] = guid[13];
    buffer[17] = guid[14];
    buffer[18] = guid[15];

    let d = new Date();
    d.setTime(d.getTime() + (d.getTimezoneOffset() * 60000));
    var ticks = ((d.getTime() * 10000) + 621355968000000000);
    var ticksArray = new numToArray(ticks);
    for(var i=0; i<8; i++){
        buffer[i + 19] = ticksArray[i];
    }

    try{
        ws.send(buffer);
    }catch(error){
        if (console.log(error));
    }
}

export function sendToGuidBool(ws, user, sensorGuid, value){
    //WebSocket.send() // data: string | ArrayBufferLike | Blob | ArrayBufferView

    var buffer = new Uint8Array(28);
    buffer[0] = 0x00; // protocol version
    buffer[1] = 0x01; // type - command
    buffer[2] = user.version;

    var guid = uuidParse(sensorGuid);
    buffer[3] = guid[3];
    buffer[4] = guid[2];
    buffer[5] = guid[1];
    buffer[6] = guid[0];
 
    buffer[7] = guid[5];
    buffer[8] = guid[4];
 
    buffer[9] = guid[7];
    buffer[10] = guid[6];
 
    buffer[11] = guid[8];
    buffer[12] = guid[9];
     
    buffer[13] = guid[10];
    buffer[14] = guid[11];
    buffer[15] = guid[12];
    buffer[16] = guid[13];
    buffer[17] = guid[14];
    buffer[18] = guid[15];

    let d = new Date();
    d.setTime(d.getTime() + (d.getTimezoneOffset() * 60000));
    var ticks = ((d.getTime() * 10000) + 621355968000000000);
    var ticksArray = new numToArray(ticks);
    for(var i=0; i<8; i++){
        buffer[i + 19] = ticksArray[i];
    }

    buffer[27] = value === true ? 1 : 0;
    try{
        ws.send(buffer);
    }catch(error){
        if (console.log(error));
    }
}

export function sendToGuidByte(ws, user, sensorGuid, value){
    //WebSocket.send() // data: string | ArrayBufferLike | Blob | ArrayBufferView

    var buffer = new Uint8Array(28);
    buffer[0] = 0x00; // protocol version
    buffer[1] = 0x01; // type - command
    buffer[2] = user.version;

    var guid = uuidParse(sensorGuid);
    buffer[3] = guid[3];
    buffer[4] = guid[2];
    buffer[5] = guid[1];
    buffer[6] = guid[0];
 
    buffer[7] = guid[5];
    buffer[8] = guid[4];
 
    buffer[9] = guid[7];
    buffer[10] = guid[6];
 
    buffer[11] = guid[8];
    buffer[12] = guid[9];
     
    buffer[13] = guid[10];
    buffer[14] = guid[11];
    buffer[15] = guid[12];
    buffer[16] = guid[13];
    buffer[17] = guid[14];
    buffer[18] = guid[15];

    let d = new Date();
    d.setTime(d.getTime() + (d.getTimezoneOffset() * 60000));
    var ticks = ((d.getTime() * 10000) + 621355968000000000);
    var ticksArray = new numToArray(ticks);
    for(var i=0; i<8; i++){
        buffer[i + 19] = ticksArray[i];
    }

    buffer[27] = value;
    try{
        ws.send(buffer);
    }catch(error){
        if (console.log(error));
    }
}

export function sendToGuidGuid(ws, user, sensorGuid, Guid){
    //WebSocket.send() // data: string | ArrayBufferLike | Blob | ArrayBufferView

    var buffer = new Uint8Array(43);
    buffer[0] = 0x00; // protocol version
    buffer[1] = 0x01; // type - command
    buffer[2] = user.version;

    var guid = uuidParse(sensorGuid);
    buffer[3] = guid[3];
    buffer[4] = guid[2];
    buffer[5] = guid[1];
    buffer[6] = guid[0];
 
    buffer[7] = guid[5];
    buffer[8] = guid[4];
 
    buffer[9] = guid[7];
    buffer[10] = guid[6];
 
    buffer[11] = guid[8];
    buffer[12] = guid[9];
     
    buffer[13] = guid[10];
    buffer[14] = guid[11];
    buffer[15] = guid[12];
    buffer[16] = guid[13];
    buffer[17] = guid[14];
    buffer[18] = guid[15];

    let d = new Date();
    d.setTime(d.getTime() + (d.getTimezoneOffset() * 60000));
    var ticks = ((d.getTime() * 10000) + 621355968000000000);
    var ticksArray = new numToArray(ticks);
    for(var i=0; i<8; i++){
        buffer[i + 19] = ticksArray[i];
    }

    var guidValue = uuidParse(Guid);
    buffer[27] = guidValue[3];
    buffer[28] = guidValue[2];
    buffer[29] = guidValue[1];
    buffer[30] = guidValue[0];

    buffer[31] = guidValue[5];
    buffer[32] = guidValue[4];

    buffer[33] = guidValue[7];
    buffer[34] = guidValue[6];

    buffer[35] = guidValue[8];
    buffer[36] = guidValue[9];
    
    buffer[37] = guidValue[10];
    buffer[38] = guidValue[11];
    buffer[39] = guidValue[12];
    buffer[40] = guidValue[13];
    buffer[41] = guidValue[14];
    buffer[42] = guidValue[15];

    try{
        ws.send(buffer);
    }catch(error){
        if (console.log(error));
    }
}

function  numToArray(num){
    var arr = new Uint8Array(8);

    var del = num;
    for(var i=7; i>=0; i--){
        var pow = Math.pow(256, i)
        arr[i] = Math.floor(del / pow);
        del -= arr[i] * pow;
    }
    return arr;
}