import './viewIrButtons.css';
import { Component } from 'react';

import { ApiActions, ModalActions, deviceColors } from '../../helpers/constants';
import { openNotificationWithIcon, deviceFullName } from '../../helpers/funcs';
import { sensorShowInDashboard } from '../../helpers/helperSensor';
import { getUser, updateIrButton } from '../../helpers/helperApi';

import ViewIrButton from './viewIrButton';

import irNewButton from '../viewModal/modalIrNewButton';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewIrButtons extends Component {
    constructor(props){
        super(props);

        this.addNew = this.addNew.bind(this);
        this.close = props.close;
        
        this.state = {
            hovered: null,
            ppVisible: null
        };
    }

    async addNew(){
        let owner = this;
        let local = this.props.appState.local;
        let device = this.props.device;
        let isDarkTheme = this.props.appState.isDarkTheme;

        let settings = await irNewButton(local, isDarkTheme);
        if (settings !== null){
            let result = await updateIrButton({device:{guid:device.guid}}, settings, ApiActions.Post);
            if (result === true){
                let user = await getUser();
                owner.props.appActions.setUser({ user: user });

                openNotificationWithIcon("info", local.ButtonTitleNew, local.Success);

                owner.props.appActions.setShowModal({
                    modalAction: ModalActions.EditObject,
                    objectModal: null
                });
                owner.props.appActions.setShowModal({
                    modalAction: ModalActions.OpenIR,
                    objectModal: user.allDevices[device.guid]
                });
            }
        }
    }

    render() {
        let local = this.props.appState.local;
        let device = this.props.device;

        let buttons = [];
        let buttonGroups = [];
        for(let sensor of device.sensors){
            if (sensorShowInDashboard(sensor) === false
                || sensor.settings === null
                || sensor.settings === undefined
                || sensor.settings.PresetButton !== 1) continue;
            if (sensor.settings.GroupName === '') buttons.push(sensor);
            else {
                if (buttonGroups.includes(sensor.settings.GroupName) === false)
                    buttonGroups.push(sensor.settings.GroupName);
            }
        }
        for(let group of buttonGroups){
            buttons.push(group);
            for(let sensor of device.sensors){
                if (sensor.settings?.GroupName === group){
                    buttons.push(sensor);
                }
            }
        }
        
        return (
            <div className="ViewIrButtons">
                <p>
                    {deviceFullName(device, local)}
                </p>
                <div className='ViewIrButtons__Sensors'>
                    {
                        buttons.map((el, i) => {
                            if (typeof el === 'string'){
                                // group name
                                return <div 
                                    style={{ borderColor: deviceColors.borderBlue }}
                                    className='ViewIrButtons__Sensors__Group'
                                    key={`ir_button_${i}`}>
                                    {el}
                                </div>;
                            } else {
                                // sensor
                                return <ViewIrButton sensor={el} 
                                    close={this.close}
                                    key={`ir_button_${el.guid}`} />;
                            }
                        })
                    }
                </div>
                {/* <input className="ViewHubNew__Login"
                    value={this.state.login}
                    onChange={this.handleLogin}
                    style={{margin: "10px 0px", height:"45px" }}
                    placeholder={local.AuthLogin}/>
                <input className="ViewHubNew__Password"
                    value={this.state.password}
                    onChange={this.handlePassword}
                    style={{margin: "10px 0px", height:"45px" }}
                    placeholder={local.AuthPassword}/> */}
                <button
                    onClick={this.addNew}>
                    {local.ButtonTitleNew}
                </button>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewIrButtons);