import { Component } from 'react';

import { ApiActions, ModalActions, deviceColors } from '../../helpers/constants';
import { sendToGuidByte } from '../../helpers/helperWs';
import { Popover } from 'antd';
import { openNotificationWithIcon } from '../../helpers/funcs';

import { getUser, updateIrButton } from '../../helpers/helperApi';
import ViewDeviceOptions from '../viewDeviceOptions/viewDeviceOptions';
import confirm from '../viewModal/modalConfirm';

import settings from '../../images/menu/menu_settings.svg';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewIrButton extends Component {
    constructor(props){
        super(props);

        this.irPush = this.irPush.bind(this);
        this.irLearn = this.irLearn.bind(this);
        this.irDelete = this.irDelete.bind(this);

        this.onMouseOut = this.onMouseOut.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.hidePopover = this.hidePopover.bind(this);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        
        this.state = {
            hovered: false,
            ppVisible: false
        };
    }
    
    onMouseOver() {
        this.setState({ hovered: true });
    }

    onMouseOut() {
        this.setState({ hovered: false });
    }

    handleVisibleChange(visible) {
        this.setState({ ppVisible: visible });
    };

    hidePopover(){
        this.setState({ ppVisible: false });
    }

    irPush(){
        let sensor = this.props.sensor;
        let local = this.props.appState.local;

        sendToGuidByte(this.props.appState.ws, 
            this.props.appState.user,
            sensor.guid, 0x01);

        //openNotificationWithIcon("info", local.appTitle, local.Success);
    }

    async irDelete(){
        let owner = this;
        let sensor = this.props.sensor;
        let local = this.props.appState.local;
        let result = false;
        if (await confirm(local.ButtonIrCurrentTitle, 
            local.ButtonDeleteAskMain, local.Yes, local.Cancel) === true){
            if (sensor.settings.IsLearned === true){
                sendToGuidByte(this.props.appState.ws, 
                    this.props.appState.user,
                    sensor.guid, 0x02);
            }
            result = await updateIrButton(sensor, null, ApiActions.Delete);
        }
        if (result === true){
            let user = await getUser();
            owner.props.appActions.setUser({ user: user });
            openNotificationWithIcon("info", local.Delete, local.Success);

            owner.props.close();
            owner.props.appActions.setShowModal({
                modalAction: ModalActions.OpenIR,
                objectModal: user.allDevices[sensor.device.guid]
            });
        }
    }

    async irLearn(){
        let sensor = this.props.sensor;
        let local = this.props.appState.local;

        if (await confirm(local.ButtonIrLearnTitle, 
            local.ButtonIrLearnAsk, local.Yes, local.Cancel) === true){
            
            sendToGuidByte(this.props.appState.ws, 
                this.props.appState.user,
                sensor.guid, 0x00);
        }
    }

    render() {
        let user = this.props.appState.user;
        let local = this.props.appState.local;
        let isDarkTheme = this.props.appState.isDarkTheme;
        let sensor = this.props.sensor;
        
        return (
            <div
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
                onClick={sensor.settings.IsLearned === true
                    ? this.irPush
                    : this.irLearn}
                style={{ borderColor: 
                    sensor.settings.IsLearned === true 
                    ? deviceColors.borderOrange 
                    : deviceColors.borderBlue }}
                className='ViewIrButtons__Sensors__Sensor'>
                    <Popover
                        onVisibleChange={this.handleVisibleChange}
                        mouseEnterDelay={0}
                        trigger="hover"
                        visible={this.state.ppVisible}
                        content={<ViewDeviceOptions
                            hidePopover={this.hidePopover}
                            showIrPush={sensor.settings.IsLearned === true}
                            irPush={this.irPush}
                            showIrDelete={true}
                            irDelete={this.irDelete}
                            showIrLearn={sensor.settings.IsLearned !== true}
                            irLearn={this.irLearn}
                            isDarkTheme={isDarkTheme}
                            local={local}
                            user={user}/>
                        }>
                        <img style={{opacity:this.state.hovered ? 1 : 0 }} 
                            src={settings} alt=""
                            onClick={(e) => e.stopPropagation()}/>
                    </Popover>
                    <p>{sensor.settings.Name}</p>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewIrButton);