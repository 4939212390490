import { Component } from 'react';

import { deviceColors } from "../../../helpers/constants";

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class SettingsEquipmentTitle extends Component {
    render() {
        var title = this.props.title;
        var hub = this.props.hub;

        return (
            <div className="SettingsEquipmentTitle" style={{borderColor: deviceColors.borderBlue}}>
                {title ?? hub.settings.name}
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(SettingsEquipmentTitle);