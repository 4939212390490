import Bathroom from '../images/rooms/Bathroom.png';
import Bedroom from '../images/rooms/Bedroom.png';
import Garage from '../images/rooms/Garage.png';
import Hall from '../images/rooms/Hall.png';
import Kitchen from '../images/rooms/Kitchen.png';
import Living_room from '../images/rooms/Living_room.png';
import Nursery from '../images/rooms/Nursery.png';
import bathroom1 from '../images/rooms/bathroom1.png';
import bathroom2 from '../images/rooms/bathroom2.png';
import garage1 from '../images/rooms/garage1.png';
import garage2 from '../images/rooms/garage2.png';
import garden1 from '../images/rooms/garden1.png';
import garden2 from '../images/rooms/garden2.png';
import gate1 from '../images/rooms/gate1.png';
import gate2 from '../images/rooms/gate2.png';
import hall1 from '../images/rooms/hall1.png';
import hall2 from '../images/rooms/hall2.png';
import kitchen1 from '../images/rooms/kitchen1.png';
import kitchen2 from '../images/rooms/kitchen2.png';
import house1 from '../images/rooms/house1.png';
import house2 from '../images/rooms/house2.png';
import placeholder from '../images/other/placeholder.png';

export function roomGetDevices(user, room){
    var devices = [];
    for(var id of room.devices){
        if (user.allDevices[id] !== undefined){
            devices.push(user.allDevices[id]);
        }
    }
    return devices;
}

export function roomGetImage(room) {
    if (room.locationType !== LocationTypes.Custom) {
        return roomStandartImage(room.locationType);
    }
    return null;
}

export function roomStandartImage(locType) {
    if (locType === LocationTypes.Bathroom) return Bathroom;
    if (locType === LocationTypes.Bedroom) return Bedroom;
    if (locType === LocationTypes.Garage) return Garage;
    if (locType === LocationTypes.Hall) return Hall;
    if (locType === LocationTypes.Kitchen) return Kitchen;
    if (locType === LocationTypes.Living_room) return Living_room;
    if (locType === LocationTypes.Nursery) return Nursery;
    if (locType === LocationTypes.Bathroom1) return bathroom1;
    if (locType === LocationTypes.Bathroom2) return bathroom2;
    if (locType === LocationTypes.Garage1) return garage1;
    if (locType === LocationTypes.Garage2) return garage2;
    if (locType === LocationTypes.Garden1) return garden1;
    if (locType === LocationTypes.Garden2) return garden2;
    if (locType === LocationTypes.Gate1) return gate1;
    if (locType === LocationTypes.Gate2) return gate2;
    if (locType === LocationTypes.Hall1) return hall1;
    if (locType === LocationTypes.Hall2) return hall2;
    if (locType === LocationTypes.Kitchen1) return kitchen1;
    if (locType === LocationTypes.Kitchen2) return kitchen2;
    if (locType === LocationTypes.House1) return house1;
    if (locType === LocationTypes.House2) return house2;
    if (locType === LocationTypes.Custom) return placeholder;

    return null;
}

export const LocationTypes = {
    Bathroom: 0,
    Bedroom: 1,
    Garage: 2,
    Hall: 3,
    Kitchen: 4,
    Living_room: 5,
    Nursery: 6,

    Bathroom1: 7,
    Bathroom2: 8,
    Garage1: 9,
    Garage2: 10,
    Garden1: 11,
    Garden2: 12,
    Gate1: 13,
    Gate2: 14,
    Hall1: 15,
    Hall2: 16,
    Kitchen1: 17,
    Kitchen2: 18,
    House1: 19,
    House2: 20,

    Custom: 21
}