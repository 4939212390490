import './settingsViewLocation.css';
import { Component } from 'react';

import { Samy } from 'react-samy-svg';
import { deviceColors, ModalActions } from "../../../helpers/constants";
import pin from '../../../images/sensors/pin.svg';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class SettingsViewLocation extends Component {
    constructor(props){
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(){
        this.props.appActions.setShowModal({
            modalAction: ModalActions.EditPlace,
            objectModal: this.props.sensor
        });
    }
    
    render() {
        var sensor = this.props.sensor;
        var iconStyle = {
            stroke: deviceColors.borderBlue, 
            strokeWidth: 1, 
            strokeMiterlimit: 0, 
            fill: deviceColors.borderBlue,
            opacity: .75
        }
        
        return (
            <div className="SettingsButtonsDevice" 
                style={{borderColor: deviceColors.borderBlue}}
                onClick={this.onClick}>
                <div className="SettingsButtonsDevice__Image">
                    <Samy path={pin} style={iconStyle} />
                </div>
                <div className="SettingsButtonsDevice__Name">
                    {sensor.label}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(SettingsViewLocation);