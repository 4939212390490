import './settingsLocations.css';
import { Component } from 'react';

import SettingsLocationDevice from '../settingsViewLocation/settingsViewLocation';

import { Samy } from 'react-samy-svg';
import { ModalActions, deviceColors } from '../../../helpers/constants';
import plus from '../../../images/menu/menu_plus.svg';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class SettingsLocations extends Component {
    constructor(props){
        super(props);

        this.addNewLoc = this.addNewLoc.bind(this);
    }

    addNewLoc(){
        this.props.appActions.setShowModal({
            modalAction: ModalActions.EditPlace,
            objectModal: {
                color: '#FF0000',
                geoPoint:{
                    latitude: this.props.appState.defPoint.latitude,
                    longitude: this.props.appState.defPoint.longitude
                },
                label: ''
            }
        });
    }

    render() {
        var tab = this.props.appState.activeTab;
        var user = this.props.appState.user;
        if (user === null || user === undefined) return null;
        
        var locs = user.locations;
        if (locs === undefined) return null;

        let locViews = locs
            .map((el, index) => {
                return <SettingsLocationDevice key={"settingsLocationsDevice" + el.guid} sensor={el} />;
            });
        locViews.splice(0, 0, 
            <div className='SettingsButtonsDevice AddButton' 
                style={{borderColor: deviceColors.borderBlue}}
                onClick={this.addNewLoc}>
                <Samy path={plus} />
            </div>);

        
        return (
            <div style={{display: tab === 5 ? "block" : "none"}}>
                <div className="SettingsLocations">
                    {
                        locViews
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(SettingsLocations);