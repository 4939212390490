import './settingsEquipment.css';
import { Component } from 'react';

import SettingsEquipmentTitle from './settingsEquipmentTitle';
import ViewDevice from '../../viewDevice/viewDevice';

import { userGetHubs, userGetLinkedDevices, getNotIgnoredDevices,
    userGetCameraDevices, userGetPromaSatDevices } from '../../../helpers/funcs';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class SettingsEquipment extends Component {
    
    render() {
        var tab = this.props.appState.activeTab;
        var user = this.props.appState.user;
        if (user === null || user === undefined) return null;
        var local = this.props.appState.local;
        var equipment = [];
        var promaDevices = userGetPromaSatDevices(user);
        if (promaDevices.length > 0){
            equipment.push(<SettingsEquipmentTitle key={"hubTracker"} title={local.EquipmentTrackersTitle}/>)
            promaDevices.forEach((d, i) => {
                equipment.push(<ViewDevice key={"eqProma"+d.guid} device={d}/>);
            });
        }
        var cameraDevices = userGetCameraDevices(user);
        if (cameraDevices.length > 0){
            equipment.push(<SettingsEquipmentTitle key={"hubCameras"} title={local.CamerasTitle}/>)
            cameraDevices.forEach((d, i) => {
                equipment.push(<ViewDevice key={"eqCameras"+d.guid} device={d}/>);
            });
        }
        var hubs = userGetHubs(user);
        for(var hub of hubs){
            equipment.push(<SettingsEquipmentTitle key={"hub"+hub.guid} hub={hub}/>);
            var hubDevices = getNotIgnoredDevices(hub);
            hubDevices.forEach((d, i) => {
                equipment.push(<ViewDevice key={"eqHub"+d.guid} device={d}/>);
            });
        }
        var linkedDevices = userGetLinkedDevices(user);
        if (linkedDevices.length > 0){
            equipment.push(<SettingsEquipmentTitle key={"hubLinked"} title={local.EquipmentLinkedDevicesTitle}/>)
            linkedDevices.forEach((d, i) => {
                equipment.push(<ViewDevice key={"eqLinked"+d.guid} device={d}/>);
            });
        }
        
        return (
            <div style={{display: tab === 5 ? "block" : "none"}}>
                <div className="SettingsEquipment">
                    { equipment }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(SettingsEquipment);