import './settingsAccount.css';
import { Component } from 'react';

import user_default from '../../../images/menu/user_default.png';

import { openNotificationWithIcon, imageDecode,
    getCurrencyName, getAccountModeName } from '../../../helpers/funcs';
import { ApiActions, vkuSite, Credentials, ModeEnum } from '../../../helpers/constants';
import { userPicture, updateSubscription, 
    getUser, updateToken, accountDelete,
    updateUserSettings } from '../../../helpers/helperApi';
import { DatePicker } from 'antd';

import moment from 'moment';
import modalTransactions from '../../viewModal/modalTransactions';
import modalSubscription from '../../viewModal/modalSubscription';
import modalPasswordChange from '../../viewModal/modalPasswordChange';
import confirm from '../../viewModal/modalConfirm';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
const format = 'DD.MM.yyyy';

class SettingsAccount extends Component {

    constructor(props){
        super(props);

        var user = this.props.appState.user;
        if (!user.settings.info) {
            user.settings.info = {
              name: '',
              burnDate: null
            }
        }
        const info = user.settings.info;
        var account = user.account;

        this.loadImage = this.loadImage.bind(this);
        this.loadNewImage = this.loadNewImage.bind(this);

        this.userAddMoney = this.userAddMoney.bind(this);
        this.saveAccountInfo = this.saveAccountInfo.bind(this);
        this.showPremiumInfo = this.showPremiumInfo.bind(this);
        this.subscriptionEnable = this.subscriptionEnable.bind(this);
        this.showTransactions = this.showTransactions.bind(this);
        this.changePassword = this.changePassword.bind(this);

        this.accountDelete = this.accountDelete.bind(this);
        this.exit = this.exit.bind(this);

        if (account.image === undefined){
            setTimeout(this.loadImage, 50);
        }

        this.state = {
            selectedFile: null,
            name: info?.name ?? '',
            burnDate: info?.burnDate,
            changed: false
        }
    }
    
    async loadImage(){
        let owner = this;
        let user = this.props.appState.user;
        const account = user.account;

        const image = await userPicture(user, null, ApiActions.Get);
        if (image !== null && image !== false){
            account.image = image;
            owner.forceUpdate();
        } else {
            account.image = null;
        }
    }  

    loadNewImage(file){
        let owner = this;
        let user = this.props.appState.user;
        let local = this.props.appState.local;
        var reader = new FileReader();
        
        reader.onload = async function(e) {
            let decodedBytes = imageDecode(reader.result);
            let result = await userPicture(user, decodedBytes, ApiActions.Post);
            if (result === true){
                await owner.loadImage();
                openNotificationWithIcon('info', local.appTitle, local.Success);
            } else 
                openNotificationWithIcon('info', local.appTitle, local.Fail);
        };
        reader.readAsBinaryString(file);
    }

    userAddMoney(){
        window.open(`${vkuSite}?token=${Credentials.token}`, '_blank').focus();
    }

    async subscriptionEnable(){
        let owner = this;
        let user = this.props.appState.user;
        let local = this.props.appState.local;
        let isDark = this.props.appState.isDarkTheme;
        let result = await modalSubscription(local, user, isDark);

        if (result !== null){
            result = await updateSubscription(result.title, ApiActions.Post);
            if (result === true){
                let newUser = await getUser();
                owner.props.appActions.setUser({ user: newUser });
            }
        }
    }

    async showTransactions(){
        let user = this.props.appState.user;
        let local = this.props.appState.local;
        let isDark = this.props.appState.isDarkTheme;
        await modalTransactions(local, user, isDark);
    }

    async changePassword(){
        let user = this.props.appState.user;
        let owner = this;
        let local = this.props.appState.local;
        let isDark = this.props.appState.isDarkTheme;
        let result = await modalPasswordChange(local, user, isDark);
        // if (result !== null){
        //     Credentials.password = result;
        //     result = updateToken();
        //     if (result === true){
        //         let newUser = await getUser();
        //         owner.props.appActions.setUser({user: newUser});
        //     }
        // }
    }

    exit() {
        Credentials.token = null;
        Credentials.login = null;
        Credentials.password = null;
        const ws = this.props.appState.ws;
        if (ws !== null && ws.readyState === 1){
            ws.close();
        }
        this.props.appState.activeTab = 2;
        this.props.appState.isDarkTheme = true;
        this.props.appState.dashExpandedDevice = null;
        this.props.appState.settingsDefTab = 0;
        this.props.appState.ws = null;
        this.props.appActions.setUser({user: null});
    }

    async accountDelete(){
        let local = this.props.appState.local;
        let isDarkTheme = this.props.appState.isDarkTheme;
        if (await confirm(local.appTitle, 
            local.DeleteUserInfo, 
            local.OK, local.Cancel, isDarkTheme) === true){
            let result = await accountDelete();

            if (result === true){
                this.exit();
            } else 
                openNotificationWithIcon('info', local.appTitle, local.Fail);
        }
    }

    async showPremiumInfo(){
        let local = this.props.appState.local;
        let isDarkTheme = this.props.appState.isDarkTheme;
        if (await confirm(local.appTitle, 
            local.LimitAboutTextTitle, 
            local.UserAddMoney, local.Cancel, isDarkTheme) === true){
            this.userAddMoney();
        }
    }

    async saveAccountInfo(){
        let owner = this;
        let user = this.props.appState.user;
        user.settings.info.burnDate = this.state.burnDate;
        user.settings.info.name = this.state.name;
        if (await updateUserSettings(user.settings, ApiActions.Post) === true){
            let newUser = await getUser();
            owner.props.appActions.setUser({ user: newUser });
            owner.setState({changed: false});
            setTimeout(this.loadImage, 50);
        }
    }

    render() {
        let tab = this.props.appState.activeTab;
        let local = this.props.appState.local;
        let user = this.props.appState.user;
        let isDark = this.props.appState.isDarkTheme;
        const account = user.account;
        const info = user.settings.info;

        return (
            <div style={{display: tab === 5 ? "block" : "none"}}>
                <div className="SettingsAccount">
                    <img src={account.image ?? user_default} alt='' />
                    <div className='SettingsAccount__File__Label'
                        style={{
                            borderColor: isDark ? '#B8B8B8' : '#6B6965',
                            backgroundColor: isDark ? '#282828' : '#FFF',
                            color: isDark ? '#B8B8B8' : '#6B6965'
                        }}>
                        <input style={{display: 'none'}}
                            onChange={(e) => this.loadNewImage(e.target.files[0])}
                            type="file"
                            id="SettingsAccount__File"
                            name="file"
                            accept='.png, .jpg, .jpeg' />
                        <label htmlFor='SettingsAccount__File' >
                            {local.AccountFileSelector}
                        </label>
                    </div>
                    <div className='SettingsAccount__Text'>
                        <p>{local.PasswordChangeLogin}</p>
                        <p>{user.login}</p>
                    </div>
                    <div className='SettingsAccount__Text'>
                        <p>{local.UserInfoName}</p>
                        {/* <p>{info.name}</p> */}
                        <input className="SettingsAccount__Text__Name"
                            value={this.state.name}
                            onChange={(e) => this.setState({name: e.target.value, changed: true})}
                            placeholder={local.UserInfoName} />
                    </div>
                    <div className='SettingsAccount__Text'>
                        <p>{local.UserInfoDate}</p>
                        {/* <p>{moment(new Date(info.burnDate)).format('DD.MM.yyyy')}</p> */}
                        <DatePicker
                            style={{width: '200px'}}
                            value={moment(this.state.burnDate)}
                            allowClear={false}
                            showNow={false}
                            format={format}
                            onChange={(e) => this.setState({burnDate: e.format(), changed: true})} />
                    </div>
                    <div className='SettingsAccount__Text'>
                        <p>{local.AmountTitle}</p>
                        <p>{`${account.amount} ${getCurrencyName(account.currency, local)}`}</p>
                    </div>
                    <div className='SettingsAccount__Text'
                        style={{cursor: 'pointer'}}
                        onClick={this.showPremiumInfo}>
                        <p>{`${local.AccountTypeTitle} ⓘ`}</p>
                        <p>{getAccountModeName(account.mode, local)}</p>
                    </div>
                    {
                        user.account.mode === ModeEnum.Free ? null :
                        <div className='SettingsAccount__Text'>
                            <p>{local.AccountPremiumEndTitle}</p>
                            <p>{`${moment(user.account.endOn).format('HH:mm DD.MM.yyyy')}`}</p>
                        </div>
                    }
                    {
                        this.state.changed === false ? null :
                        <button 
                            onClick={this.saveAccountInfo}>
                            {local.Save}
                        </button>
                    }
                    <button 
                        onClick={this.userAddMoney}>
                        {local.UserAddMoney}
                    </button>
                    {
                        user.account.mode === ModeEnum.Premium ? null :
                        <button 
                            onClick={this.subscriptionEnable}>
                            {local.SubscriptionEnableTitle}
                        </button>
                    }
                    <button 
                        onClick={this.showTransactions}>
                        {local.TransactionsTitle}
                    </button>
                    <button 
                        onClick={this.changePassword}>
                        {local.ChangePasswordTitle}
                    </button>
                    <button 
                        onClick={this.accountDelete}>
                        {local.DeleteUserButtonTitle}
                    </button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(SettingsAccount);