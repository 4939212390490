import { APP, ModalActions, globalLocale, dashFilterDefault } from '../helpers/constants';
import { LocalizedStrings } from '../helpers/local.js';

import { settings } from '../helpers/settings.js';

const initialState = {
    user: null,

    // состояние приложения
    local: LocalizedStrings['enEn'], // локаль
    activeTab: 2, // активное окно
    settingsDefTab: 7,
    settings: settings, // текущие настройки
    loading: true, // индикатор загрузки
    isDarkTheme: true,
    defPoint: { latitude: 55.751244, longitude: 37.618423 },
    localTZid: 'Etc/UTC',
    defCron: '10 08 ? * MON,TUE,WED,THU,FRI',
    //
    dashExpandedDevice: null,

    modalAction: ModalActions.EditObject,
    objectModal: null,
    modalSecondAction: ModalActions.EditObject,
    modalSecondFunc: null,
    ws: null,

    dashModelFilter: dashFilterDefault,
    dashRoomFilter: dashFilterDefault,

    appWsOk: false
}

//var Enumerable = require('linq');

export default function appState(state = initialState, action) {
    switch (action.type) {
        case APP.USER:
            return { ...state, user: action.payload.user };
        case APP.DATA:
            return { ...state, data: action.payload.data };
        case APP.ACTIVE_TAB:
            return { ...state, activeTab: action.payload.activeTab }
        case APP.SETTINGS:
            return { ...state, settings: action.payload.settings }
        case APP.THEME:
            return { ...state, isDarkTheme: action.payload.isDarkTheme }
        case APP.LOCAL:
            var local = LocalizedStrings[action.payload.local];
            globalLocale.locale = local.cultureName;
            return { ...state, local: local };
        case APP.DASH_EXPANDED_DEVICE:
            return { ...state, dashExpandedDevice: action.payload.id }
        case APP.SHOW_DEFAULT_MODAL:
            return {
                ...state,
                modalAction: action.payload.modalAction,
                objectModal: action.payload.objectModal
            }
        case APP.SHOW_SECOND_MODAL:
            return {
                ...state,
                modalSecondAction: action.payload.modalSecondAction,
                modalSecondFunc: action.payload.modalSecondFunc
            }
        case APP.DASH_FILTER_MODEL:
            return { ...state, dashModelFilter: action.payload.model }
        case APP.DASH_FILTER_ROOM:
            return { ...state, dashRoomFilter: action.payload.room }
        case APP.APP_WS_IS_OK:
            return { ...state, appWsOk: action.payload.ok }
        default:
            return state;
    }
}