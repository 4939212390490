import './viewHubNew.css';
import { Component } from 'react';

import { ApiActions } from '../../helpers/constants';
import { openNotificationWithIcon } from '../../helpers/funcs';
import { updateHub } from '../../helpers/helperApi';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewHubNew extends Component {
    constructor(props){
        super(props);

        this.save = this.save.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.close = props.close;
        
        this.state = {
            login: "",
            password: "",
        };
    }

    async save(){
        var local = this.props.appState.local;

        var result = await updateHub(local, {
            login: this.state.login,
            password: this.state.password
        }, ApiActions.Post);
        openNotificationWithIcon("info", local.GateNewTitle, result);
        
        this.close();
    }

    handleLogin(event){
        this.setState({
            login: event.target.value,
            changed: true
        });
    }

    handlePassword(event){
        this.setState({
            password: event.target.value,
            changed: true
        });
    }

    render() {
        var local = this.props.appState.local;
        
        return (
            <div className="ViewHubNew">
                <p>
                    {local.GateNewTitle}
                </p>
                <p>
                    {local.GateNewHelp}
                </p>
                <input className="ViewHubNew__Login"
                    value={this.state.login}
                    onChange={this.handleLogin}
                    style={{margin: "10px 0px", height:"45px" }}
                    placeholder={local.AuthLogin}/>
                <input className="ViewHubNew__Password"
                    value={this.state.password}
                    onChange={this.handlePassword}
                    style={{margin: "10px 0px", height:"45px" }}
                    placeholder={local.AuthPassword}/>
                <button
                    onClick={this.save}>
                    {local.GateNewButtonTitle}
                </button>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewHubNew);