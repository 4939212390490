import './viewEditRoom.css';
import { Component } from 'react';

import { ApiActions } from '../../../helpers/constants';
import { roomGetImage } from '../../../helpers/helperRoom';
import ViewDevice from '../../viewDevice/viewDevice';
import { openNotificationWithIcon, deleteByGuid } from '../../../helpers/funcs';
import { getUser, updateRoom } from '../../../helpers/helperApi';
import confirm from '../../viewModal/modalConfirm';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewEditRoom extends Component {
    constructor(props){
        super(props);

        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.close = props.close;

        var room = this.props.room;
        
        this.state = {
            changed: false,
            name: room.name,
            descr: room.description,
            favourite: room.favourite
        };
    }

    async save(){
        var user = this.props.appState.user;
        var local = this.props.appState.local;
        var room = this.props.room;
        room.name = this.state.name;
        room.description = this.state.descr;
        room.favourite = this.state.favourite;

        var result = await updateRoom(user, room, ApiActions.Put);
        if (result === true){
            let newUser = await getUser();
            this.props.appActions.setUser({ user: newUser });
            this.close();
            openNotificationWithIcon("info", local.Save, local.Success);
        } else {
            // TODO if error what??
        }
    }

    async delete(){
        var user = this.props.appState.user;
        var local = this.props.appState.local;
        var room = this.props.room;
        var isDarkTheme = this.props.appState.isDarkTheme;
        
        if (await confirm(local.appTitle, local.RoomDeleteAsk, local.Yes, local.Cancel, isDarkTheme) === false){
            return;
        }

        var result = await updateRoom(user, room, ApiActions.Delete);
        if (result === true){
            //deleteByGuid(user.rooms, room);
            let newUser = await getUser();
            this.props.appActions.setUser({ user: newUser });
            this.close();
            openNotificationWithIcon("info", local.Delete, local.Success);
        } else {
            // TODO if error what??
        }
    }

    render() {
        var local = this.props.appState.local;
        var room = this.props.room;
        
        return (
            <div className="ViewEditRoom">
                {/* <img src={roomGetImage(room) ?? room.imageData} alt="" /> */}
                
                <input className="ViewEditRoom__Name"
                    value={this.state.name}
                    onInput={(e, value) => {
                        if (e.constructor.name === "SyntheticBaseEvent") 
                            this.forceUpdate();
                    }}
                    onChange={(e)=> this.setState({name: e.target.value, changed: true})}
                    style={{margin: "10px 0px", height:"45px" }}
                    placeholder={local.LocationName}/>
                
                <input className="ViewEditRoom__Descr"
                    value={this.state.descr}
                    onInput={(e, value) => {
                        if (e.constructor.name === "SyntheticBaseEvent") 
                            this.forceUpdate();
                    }}
                    onChange={(e)=> this.setState({descr: e.target.value, changed: true})}
                    style={{margin: "10px 0px", height:"45px" }}
                    placeholder={local.LocationDescription}/>
                <div className="checkbox">
                    <input type="checkbox"
                            id={"ViewEditRoom-fav"+room.guid}
                            name={"ViewEditRoom-fav"+room.guid}
                            value={this.state.favourite}
                            className="custom-checkbox"
                            defaultChecked={this.state.favourite} 
                            onChange={(e)=> this.setState({favourite: !this.state.favourite, changed: true})}
                        />
                    <label htmlFor={"ViewEditRoom-fav"+room.guid}>
                        {local.LocationFavourite}
                    </label>
                </div>
                <div className="ViewEditRoom__Devices">
                    {
                        room.realDevices.map((el, index) => {
                            return <ViewDevice key={"roomEditDevice" + el.guid} device={el} />;
                        })
                    }
                </div>
                <div className='ViewEditRoom__Buttons'>
                    <button 
                        disabled={this.state.changed === false}
                        onClick={this.save}>
                        {local.Save}
                    </button>
                    <button
                        onClick={this.delete}>
                        {local.Delete}
                    </button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewEditRoom);