import './viewBaseModal.css';
import { Component } from 'react';

import { deviceColors, ModalActions } from "../../helpers/constants";
import ViewEditButton from "../edit/viewEditButton/viewEditButton";
import ViewEditTimer from "../edit/viewEditTimer/viewEditTimer";
import ViewEditDevice from "../edit/viewEditDevice/viewEditDevice";
import ViewEditRoom from "../edit/viewEditRoom/viewEditRoom";
import ViewEditShare from "../edit/viewEditShare/viewEditShare";
import ViewRoomsSelector from "../viewRoomsSelector/viewRoomsSelector";
import ViewRoomNew from "../viewRoomNew/viewRoomNew";
import ViewHubNew from "../viewHubNew/viewHubNew";
import ViewDeviceNew from "../viewDeviceNew/viewDeviceNew";
import ViewCameras from "../viewCameras/viewCameras";
import ViewEditPlace from "../edit/viewEditPlace/viewEditPlace";
import ViewEditScenario from "../edit/viewEditScenario/viewEditScenario";
import ViewIrButtons from "../viewIrButtons/viewIrButtons";

import close from '../../images/other/close.png';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewBaseModal extends Component {

    constructor(props){
        super(props);

        this.onClose = this.onClose.bind(this);
    }

    onClose(){
        this.props.appActions.setShowModal({
            modalAction: ModalActions.EditObject,
            objectModal: null
        });
    }
    
    render() {
        var object = this.props.appState.objectModal;
        var modalAction = this.props.appState.modalAction;

        var local = this.props.appState.local;
        var isDark = this.props.appState.isDarkTheme;
        var viewIsEmpty = true;

        //if (object !== null) console.log(modalAction, object);
        
        if (modalAction === ModalActions.EditObject){
            viewIsEmpty = object === null || object === undefined 
                || object.model === null || object.model === "" || object.model === undefined;
        }
        if (modalAction === ModalActions.EditRoom 
            || modalAction === ModalActions.SelectRoom
            || modalAction === ModalActions.EditShare
            || modalAction === ModalActions.NewRoom
            || modalAction === ModalActions.EditPlace
            || modalAction === ModalActions.EditScenario
            || modalAction === ModalActions.NewScenario){
            viewIsEmpty = object === null || object === undefined;
        }
        if (modalAction === ModalActions.NewGate
            || modalAction === ModalActions.OpenCameras
            || modalAction === ModalActions.NewDevice){
            viewIsEmpty = false;
        }
        if (modalAction === ModalActions.OpenIR){
            viewIsEmpty = object === null || object === undefined;
        }

        return (
            <div className={"ViewBaseModal"} 
                style={{display: viewIsEmpty ? "none" : "block"}}>
                {
                    viewIsEmpty === true ? null :
                    <div className={"ViewBaseModal__main"}
                        style={{
                            borderColor: deviceColors.borderBlue,
                            backgroundColor: isDark 
                                ? deviceColors.backGroundDark 
                                : deviceColors.backGroundLight}}>
                        {
                            modalAction !== ModalActions.EditObject
                                || object?.model.includes("ButtonSensor") === false ? null :
                            <ViewEditButton sensor={object} close={this.onClose}/>
                        }
                        {
                            modalAction !== ModalActions.EditObject
                                || object?.model.includes("TimerSensor") === false ? null :
                            <ViewEditTimer sensor={object} close={this.onClose}/>
                        }
                        {
                            modalAction !== ModalActions.EditObject
                                || object?.sensors === null || object?.sensors === undefined ? null :
                            <ViewEditDevice device={object} close={this.onClose}/>
                        }
                        {
                            modalAction !== ModalActions.EditRoom ? null :
                            <ViewEditRoom room={object} close={this.onClose}/>
                        }
                        {
                            modalAction !== ModalActions.SelectRoom ? null :
                            <ViewRoomsSelector device={object} close={this.onClose}/>
                        }
                        {
                            modalAction !== ModalActions.EditShare ? null :
                            <ViewEditShare device={object} close={this.onClose}/>
                        }
                        {
                            modalAction !== ModalActions.NewRoom ? null :
                            <ViewRoomNew room={object} close={this.onClose}/>
                        }
                        {
                            modalAction !== ModalActions.NewGate ? null :
                            <ViewHubNew close={this.onClose}/>
                        }
                        {
                            modalAction !== ModalActions.NewDevice ? null :
                            <ViewDeviceNew close={this.onClose}/>
                        }
                        {
                            modalAction !== ModalActions.OpenCameras ? null :
                            <ViewCameras close={this.onClose}/>
                        }
                        {
                            modalAction !== ModalActions.EditPlace ? null :
                            <ViewEditPlace place={object} close={this.onClose}/>
                        }
                        {
                            modalAction !== ModalActions.EditScenario 
                            && modalAction !== ModalActions.NewScenario ? null :
                            <ViewEditScenario trigger={object} close={this.onClose}/>
                        }
                        {
                            modalAction !== ModalActions.OpenIR ? null :
                            <ViewIrButtons device={object} close={this.onClose}/>
                        }
                        <img className='ViewBaseModal__close' 
                            src={close} 
                            alt='' 
                            onClick={this.onClose}/>
                    </div>
                }
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewBaseModal);