import './viewEditTimer.css';
import { Component } from 'react';

import { deviceColors, Days, ApiActions } from "../../../helpers/constants";
import { TimerTypeEnum } from '../../../helpers/helperTimer';
import { openNotificationWithIcon, userGetTimersDevice, deleteByGuid } from '../../../helpers/funcs';
import { getUser, updateTimer } from '../../../helpers/helperApi';
import confirm from '../../viewModal/modalConfirm';

import { TimePicker } from 'antd';
import moment from 'moment';
//import { findWindows, findIana, findIanaAliases } from 'windows-iana';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const ct = require('countries-and-timezones');
const format = 'HH:mm';

class ViewEditTimer extends Component {
    constructor(props){
        super(props);

        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.close = props.close;

        var allTimeZones = ct.getAllTimezones();
        this.timeZones = [];
        for(var tz in allTimeZones){
            var t = allTimeZones[tz];
            this.timeZones.push({
                utcOffset: t.utcOffset,
                name: `${t.utcOffsetStr} ${t.name}`,
                id: t.name
            });
        }
        this.timeZones = this.timeZones.sort((a, b) => a.utcOffset - b.utcOffset);

        var timer = this.props.sensor;
        if (timer.cronExpression === undefined ||
            timer.cronExpression === null){
            timer.cronExpression = this.props.appState.defCron;
        }
        if (timer.type === undefined ||
            timer.type === null){
            timer.type = TimerTypeEnum.Time;
        }
        if (timer.timeZoneId === undefined ||
            timer.timeZoneId === null){
            timer.timeZoneId = this.props.appState.localTZid;
        } 
        //var iana = findIana(timer.timeZoneId);
        //timer.timeZoneId = iana[0];

        if (timer.Settings === undefined){
            timer.Settings = {
                
            };
        }
        var time = timer.cronExpression.split(' ');
        var timeStart = new Date();
        timeStart.setHours(time[1], time[0], 0, 0);
        
        this.state = {
            changed: false,
            cronExpression: timer.cronExpression,
            name: timer.settings?.Name ?? timer.Settings?.Name,
            days: [false, false, false, false, false, false, false],
            type: timer.type,
            time: moment(timeStart),
            offset: timer.offset,
            locGuid: timer.locationGuid,
            timeZoneId: timer.timeZoneId,
        };

        for(var d=0; d<7; d++){
            this.state.days[d] = timer.cronExpression.includes(Days[d]);
        }
    }

    async save(){
        var user = this.props.appState.user;
        var local = this.props.appState.local;
        var timer = this.props.sensor;

        var tStart = this.state.time.toDate();
        timer.CronExpression = `${tStart.getMinutes()} ${tStart.getHours()} ? * `;
        var cron = [];
        for(var d=0; d<7; d++){
            if (this.state.days[d] === true)
                cron.push(Days[d]);
        }
        timer.CronExpression += cron.join(",");
        if (timer.Settings !== undefined && timer.Settings !== null)
            timer.Settings.Name = this.state.name;
        if (timer.settings !== undefined && timer.settings !== null)
            timer.settings.Name = this.state.name;
        timer.type = this.state.type;
        timer.Offset = this.state.offset;
        timer.offset = this.state.offset;
        timer.locationGuid = this.state.locGuid;

        // const resultZone = findWindows(this.state.timeZoneId);
        //console.log(findIanaAliases(this.state.timeZoneId));
        // timer.TimeZoneId = resultZone[0];

        timer.TimeZoneId = this.state.timeZoneId;

        if (timer.guid === undefined){
            let result = await updateTimer(user, timer, ApiActions.Post);
            if (result === true){
                let newUser = await getUser();
                this.props.appActions.setUser({ user: newUser });
                openNotificationWithIcon("info", local.Save, local.Success);
                this.close();
            } else {
                // TODO if error what??
            }
        } else {
            let result = await updateTimer(user, timer, ApiActions.Put);
            if (result === true){
                let newUser = await getUser();
                this.props.appActions.setUser({ user: newUser });
                openNotificationWithIcon("info", local.Update, local.Success);
                this.close();
            } else {
                // TODO if error what??
            }
        }
    }

    async delete(){
        var local = this.props.appState.local;
        var user = this.props.appState.user;
        var timer = this.props.sensor;
        var isDarkTheme = this.props.appState.isDarkTheme;
        
        if (await confirm(local.appTitle, local.TimerDeleteAskMain, local.Yes, local.Cancel, isDarkTheme) === false){
            return;
        }

        var result = await updateTimer(user, timer, ApiActions.Delete);
        if (result === true){
            var td = userGetTimersDevice(user);
            if (td !== null){
                deleteByGuid(td.sensors, timer);
                let newUser = await getUser();
                this.props.appActions.setUser({ user: newUser });
            }
            openNotificationWithIcon("info", local.Delete, local.Success);
            this.close();
        } else {
            // TODO if error what??
        }
    }

    render() {
        var user = this.props.appState.user;
        var local = this.props.appState.local;
        var timer = this.props.sensor;

        var daysNames = [ local.TimerButtonMonday, local.TimerButtonTuesday, 
            local.TimerButtonWednesday, local.TimerButtonThursday, 
            local.TimerButtonFriday, local.TimerButtonSaturday, 
            local.TimerButtonSunday];
        var locations = user.locations;
        
        return (
            <div className="ViewEditTimer">
                <p>
                    {timer.guid === undefined ? local.TimerTitleNew : local.TimerTitleEdit}
                </p>
                <input className="ViewEditTimer__Name"
                    value={this.state.name}
                    onInput={(e, value) => {
                        if (e.constructor.name === "SyntheticBaseEvent") 
                            this.forceUpdate();
                    }}
                    onChange={(e)=> this.setState({name: e.target.value, changed: true})}
                    style={{margin: "10px 0px", height:"45px"}}
                    placeholder={local.ScenarioCreateNewButtonName}/>
                <div className="ViewEditTimer__WeekDays" style={{margin: "10px 0px"}}>
                    <button 
                        style={{margin: "0px 10px", height:"45px"}}
                        onClick={()=>{
                            this.state.days = [true, true, true, true, true, false, false];
                            this.state.changed = true;
                            this.forceUpdate();
                        }}>
                        {local.TimerButtonWeekdays}
                    </button>
                    <button 
                        style={{margin: "0px 10px", height:"45px"}}
                        onClick={()=>{
                            this.state.days = [false, false, false, false, false, true, true];
                            this.state.changed = true;
                            this.forceUpdate();
                        }}>
                        {local.TimerButtonWeekends}
                    </button>
                </div>
                <div className="ViewEditTimer__Days" style={{margin: "10px 0px"}}>
                    {
                        daysNames.map((d, i)=>{
                            return <button style={{
                                backgroundColor: this.state.days[i] === true
                                ? deviceColors.borderBlue
                                : deviceColors.backGroungCircleButton}}
                                onClick={()=>{
                                    this.state.days[i] = !this.state.days[i];
                                    this.state.changed = true;
                                    this.forceUpdate();
                                }}
                                key={'day'+i}>
                                {d}
                            </button>;
                        })
                    }
                </div>
                <div className="ViewEditTimer__Radios" 
                    style={{margin: "10px 0px", display: "flex", justifyContent: "space-between"}}>
                    <div className="radio">
                        <input type="radio"
                            id="viewTime-time"
                            name="viewTime-time"
                            className="custom-checkbox"
                            checked={this.state.type === TimerTypeEnum.Time} 
                            onChange={(e)=> this.setState({type: TimerTypeEnum.Time, changed: true})} 
                            />
                        <label htmlFor="viewTime-time">
                            {local.TimerTypeTime}
                        </label>
                    </div>
                    <TimePicker
                        value={this.state.time}
                        disabled={this.state.type !== TimerTypeEnum.Time}
                        allowClear={false}
                        showNow={false}
                        format={format}
                        onChange={(e)=> this.setState({time: e, changed: true})} />
                </div>
                <div className="ViewEditTimer__Radios" 
                    style={{margin: "10px 0px", display: "flex"}}>
                    <div className="radio">
                        <input type="radio"
                            id="viewTime-sunset"
                            name="viewTime-sunset"
                            className="custom-checkbox"
                            checked={this.state.type === TimerTypeEnum.SunSet} 
                            onChange={(e)=> this.setState({type: TimerTypeEnum.SunSet, changed: true})} 
                            />
                        <label htmlFor="viewTime-sunset">
                            {local.TimerTypeSunset}
                        </label>
                    </div>
                </div>
                <div className="ViewEditTimer__Radios" 
                    style={{margin: "10px 0px", display: "flex"}}>
                    <div className="radio">
                        <input type="radio"
                            id="viewTime-sunrise"
                            name="viewTime-sunrise"
                            className="custom-checkbox"
                            checked={this.state.type === TimerTypeEnum.SunRise} 
                            onChange={(e)=> this.setState({type: TimerTypeEnum.SunRise, changed: true})} 
                            />
                        <label htmlFor="viewTime-sunrise">
                            {local.TimerTypeSunrise}
                        </label>
                    </div>
                </div>
                <div className="ViewEditTimer__Radios" 
                    style={{margin: "10px 0px", display: "flex", justifyContent: "space-between"}}>
                    <label style={{minWidth: 200}}>
                        {local.TimerTypeOffset}
                    </label>
                    <input
                        value={this.state.offset}
                        onInput={(e, value) => {
                            if (e.constructor.name === "SyntheticBaseEvent") 
                                this.forceUpdate();
                        }}
                        onChange={(e)=> {
                            let v = parseInt(e.target.value);
                            if (Number.isNaN(v)) v = 0;
                            if (v > 7200) v = 7200;
                            if (v < 0) v = 0;
                            this.setState({offset: v, changed: true})
                        }}
                    />
                </div>
                <div className="ViewEditTimer__Radios" 
                    style={{margin: "10px 0px", display: "flex", justifyContent: "space-between"}}>
                    <label style={{minWidth: 200}}>
                        {local.TimerTypeAdress}
                    </label>
                    <select required={true} 
                        defaultValue={this.state.type}
                        onChange={(e)=>this.setState({locGuid: e, changed: true})}>
                        {   
                            locations.map((el, i)=>{
                                return <option value={el.guid} key={"LocationsInTimer"+el.guid}>
                                    {el.label}
                            </option>;
                        })}
                    </select>
                </div>
                <div className="ViewEditTimer__Radios" 
                    style={{margin: "10px 0px", display: "flex", justifyContent: "space-between"}}>
                    <label style={{minWidth: 200}}>
                        {local.TimeZoneTitle}
                    </label>
                    <select required={true} 
                        defaultValue={this.state.timeZoneId}
                        onChange={(e)=>this.setState({timeZoneId: e.target.value, changed: true})}>
                        {   
                            this.timeZones.map((el, i)=>{
                                return <option value={el.id} key={"TimeZone"+el.name}>
                                    {el.name}
                            </option>;
                        })}
                    </select>
                </div>
                <div className='ViewEditTimer__Buttons'>
                    <button 
                        disabled={this.state.changed === false}
                        onClick={this.save}>
                        {local.Save}
                    </button>
                    {
                        timer.guid === undefined ? null :
                        <button
                            onClick={this.delete}>
                            {local.Delete}
                        </button>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewEditTimer);