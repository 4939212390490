import './viewEditScenario.css';
import { Component } from 'react';

import ViewIfThenDevice from '../../viewDeviceSelector/viewIfThenDevice';

import { openNotificationWithIcon, deleteByGuid } from '../../../helpers/funcs';
import { deviceColors, Days, 
    ApiActions, ModalActions, 
    NotificationTypeEnum } from '../../../helpers/constants';
import confirm from '../../viewModal/modalConfirm';

import { Samy } from 'react-samy-svg';
import { TimePicker } from 'antd';
import moment from 'moment';

import { updateTrigger, getUser } from '../../../helpers/helperApi';

import plusDark from '../../../images/menu/plusDark.svg';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const ct = require('countries-and-timezones');
const format = 'HH:mm';

class ViewEditScenario extends Component {
    constructor(props){
        super(props);

        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handleDescr = this.handleDescr.bind(this);
        this.handleIf = this.handleIf.bind(this);
        this.handleThen = this.handleThen.bind(this);
        this.addIfThen = this.addIfThen.bind(this);
        
        var trigger = this.props.trigger;
        var time = trigger.timeFilter.cronStart.split(' ');
        var timeStart = new Date();
        timeStart.setHours(time[1], time[0], 0, 0);
        var timeEnd = new Date(timeStart.getTime() + trigger.timeFilter.duration*60000);
        this.close = props.close;

        var allTimeZones = ct.getAllTimezones();
        this.timeZones = [];
        for(var tz in allTimeZones){
            var t = allTimeZones[tz];
            this.timeZones.push({
                utcOffset: t.utcOffset,
                name: `${t.utcOffsetStr} ${t.name}`,
                id: t.name
            });
        }
        this.timeZones = this.timeZones.sort((a, b) => a.utcOffset - b.utcOffset);
        
        this.state = {
            enabled: trigger.enabled,
            guid: trigger.guid,
            changed: false,
            name: trigger.title,
            descr: trigger.description,
            useAsShield: trigger.useAsShield,
            timeFilterEnabled: trigger.timeFilter.enabled,
            days: [false, false, false, false, false, false, false],
            timeStart: moment(timeStart),
            timeEnd: moment(timeEnd),
            timeZoneId: trigger.timeFilter.timeZoneId,
            showIfAdd: false,
            showThenAdd: false,
            actors: trigger.actors,
            conditions: trigger.conditions,
            sendPush: trigger.sendPush,
            importantAlert: trigger.notificationType === NotificationTypeEnum.Alert
        };
        for(var d=0; d<7; d++){
            this.state.days[d] = trigger.timeFilter.cronStart.includes(Days[d]);
        }
    }

    async save(){
        var modalAction = this.props.appState.modalAction;

        var local = this.props.appState.local;
        var user = this.props.appState.user;
        var trigger = this.props.trigger;

        trigger.title = this.state.name;
        trigger.description = this.state.descr;
        trigger.sendPush = this.state.sendPush;
        trigger.useAsShield = this.state.useAsShield;
        trigger.notificationType = this.state.importantAlert === true
            ? NotificationTypeEnum.Alert
            : NotificationTypeEnum.Regular;
        trigger.enabled = this.state.enabled;
        trigger.guid = this.state.guid;

        var tStart = this.state.timeStart.toDate();
        var tEnd = this.state.timeEnd.toDate();
        trigger.timeFilter.cronStart = `${tStart.getMinutes()} ${tStart.getHours()} ? * `;
        var cron = [];
        for(var d=0; d<7; d++){
            if (this.state.days[d] === true)
                cron.push(Days[d]);
        }
        trigger.timeFilter.cronStart += cron.join(",");
        trigger.timeFilter.timeZoneId = this.state.timeZoneId;

        var diff = tEnd.getTime() - tStart.getTime();
        diff /= 1000 * 60; // minutes
        if (diff < 0){
            diff = 24 * 60 - diff;
        }
        trigger.timeFilter.duration = diff;
        trigger.timeFilter.enabled = this.state.timeFilterEnabled;

        trigger.actors = this.state.actors;
        trigger.conditions = this.state.conditions;

        var isNew = modalAction === ModalActions.NewScenario
        if (await updateTrigger(trigger, isNew ? ApiActions.Post : ApiActions.Put)){
            if (isNew) user.triggers.push(trigger);

            let newUser = await getUser();
            this.props.appActions.setUser({ user: newUser });
            openNotificationWithIcon("info", local.Save, local.Success);
            this.close();
        }
    }

    async delete(){
        const local = this.props.appState.local;
        var isDarkTheme = this.props.appState.isDarkTheme;

        if (await confirm(local.appTitle, local.TriggerDeleteAsk, local.Yes, local.Cancel, isDarkTheme) === false){
            return;
        }
        const trigger = this.props.trigger;
        const user = this.props.appState.user;
        if (await updateTrigger(trigger, ApiActions.Delete) === true){
            deleteByGuid(user.triggers, trigger);
            let newUser = await getUser();
            this.props.appActions.setUser({ user: newUser });
            openNotificationWithIcon("info", local.Delete, local.Success);
            this.close();
        }
    }

    handleName(event){
        this.setState({
            name: event.target.value,
            changed: true
        });
    }

    handleDescr(event){
        this.setState({
            descr: event.target.value,
            changed: true
        });
    }

    addIfThen(list, obj){
        if (list === 'condition'){
            this.state.conditions.push(obj);
        }
        if (list === 'actor'){
            this.state.actors.push(obj);
        }

        this.setState({changed: true});
    }

    handleIf(){
        this.props.appActions.setShowSecondModal({
            modalSecondAction: ModalActions.SelectIf,
            modalSecondFunc: this.addIfThen
        })
    }

    handleThen(){
        this.props.appActions.setShowSecondModal({
            modalSecondAction: ModalActions.SelectThen,
            modalSecondFunc: this.addIfThen
        })
    }

    render() {
        var local = this.props.appState.local;
        var trigger = this.props.trigger;

        var daysNames = [ local.TimerButtonMonday, local.TimerButtonTuesday, 
            local.TimerButtonWednesday, local.TimerButtonThursday, 
            local.TimerButtonFriday, local.TimerButtonSaturday, 
            local.TimerButtonSunday];

        var iconIfThenIconStyle ={
            strokeWidth: 1, 
            strokeMiterlimit: 0, 
            fill: deviceColors.borderBlue
        };
        
        return (
            <div className="ViewEditScenario">
                <p>
                    {trigger.guid === undefined ? local.ScenarioTitleNew : local.ScenarioTitleEdit}
                </p>
                <input className="ViewEditScenario__Name"
                    value={this.state.name}
                    onChange={(e) => this.handleName(e)}
                    style={{margin: "10px 0px", height:"45px" }}
                    placeholder={local.ScenarioName}/>
                <input className="ViewEditScenario__Descr"
                    value={this.state.descr}
                    onChange={(e) => this.handleDescr(e)}
                    style={{margin: "10px 0px", height:"45px" }}
                    placeholder={local.ScenarioDescription}/>
                <div className="checkbox" style={{textAlign: 'start'}}>
                    <input type="checkbox"
                            id={"ViewEditScenario-fav-useAsShield"}
                            name={"ViewEditScenario-fav-useAsShield"}
                            value={this.state.useAsShield}
                            className="custom-checkbox"
                            defaultChecked={this.state.useAsShield} 
                            onChange={(e)=> this.setState({useAsShield: !this.state.useAsShield, changed: true})}
                        />
                    <label htmlFor={"ViewEditScenario-fav-useAsShield"}>
                        {local.ScenarioUseAsShieldTitle}
                    </label>
                </div>
                <div className="checkbox" style={{textAlign: 'start'}}>
                    <input type="checkbox"
                            id={"ViewEditScenario-fav-timeFilterEnabled"}
                            name={"ViewEditScenario-fav-timeFilterEnabled"}
                            value={this.state.timeFilterEnabled}
                            className="custom-checkbox"
                            defaultChecked={this.state.timeFilterEnabled} 
                            onChange={(e)=> this.setState({timeFilterEnabled: !this.state.timeFilterEnabled, changed: true})}
                        />
                    <label htmlFor={"ViewEditScenario-fav-timeFilterEnabled"}>
                        {local.ScenarioTimeEnabled}
                    </label>
                </div>
                {
                    this.state.timeFilterEnabled === false ? null :
                    <div className='ViewEditScenario__TimeFilter' style={{borderColor: deviceColors.borderBlue}}>
                        <div className="ViewEditScenario__TimeFilter__Days" style={{margin: "10px 0px"}}>
                            {
                                daysNames.map((d, i)=>{
                                    return <button key={`daysNames${i}`} style={{
                                        backgroundColor: this.state.days[i] === true
                                        ? deviceColors.borderBlue
                                        : deviceColors.backGroungCircleButton}}
                                        onClick={()=>{
                                            this.state.days[i] = !this.state.days[i];
                                            this.state.changed = true;
                                            this.forceUpdate();
                                        }}>
                                        {d}
                                    </button>;
                                })
                            }
                        </div>
                        <div className="ViewEditScenario__TimeFilter__Radios" 
                            style={{margin: "10px 0px", display: "flex", justifyContent: "space-between"}}>
                            <label>
                                {local.ScenarioTimeStart}
                            </label>
                            <TimePicker
                                value={this.state.timeStart}
                                allowClear={false}
                                showNow={false}
                                format={format}
                                onChange={(e)=> this.setState({timeStart: e, changed: true})} />
                            <label>
                                {local.ScenarioTimeDuration}
                            </label>
                            <TimePicker
                                value={this.state.timeEnd}
                                allowClear={false}
                                showNow={false}
                                format={format}
                                onChange={(e)=> this.setState({timeEnd: e, changed: true})} />
                        </div>
                        <div className="ViewEditScenario__TimeFilter__Radios" 
                            style={{margin: "10px 0px", display: "flex", justifyContent: "space-between"}}>
                            <label style={{minWidth: 200}}>
                                {local.TimeZoneTitle}
                            </label>
                            <select required={true} 
                                defaultValue={this.state.timeZoneId}
                                onChange={(e)=>this.setState({timeZoneId: e.target.value, changed: true})}>
                                {   
                                    this.timeZones.map((el, i)=>{
                                        return <option value={el.id} key={"TimeZone"+el.name}>
                                            {el.name}
                                    </option>;
                                })}
                            </select>
                        </div>
                    </div>
                }
                <div className='ViewEditScenario__IfThen_Section'>
                    <div className='ViewEditScenario__IfThen_Section__If' 
                        style={{borderColor: deviceColors.borderBlue}}
                        onMouseOver={()=>this.setState({showIfAdd: true})}
                        onMouseOut={()=>this.setState({showIfAdd: false})}>
                        <div className='ViewEditScenario__If'>
                            <div style={{display: this.state.showIfAdd === true ? 'block' : 'none'}} 
                                className="ViewEditScenario__If__MainImage">
                                <Samy path={plusDark} style={iconIfThenIconStyle} />
                            </div>
                            <p onClick={this.handleIf}>{local.ScenarioIfTitle}</p>
                            <div className='ViewEditScenario__IfThen__Conditions'>
                                {
                                    this.state.conditions.map((c, i)=>
                                        <ViewIfThenDevice cond={c} key={c.guid} />)
                                }
                            </div>
                        </div>
                    </div>
                    <div className='ViewEditScenario__IfThen_Section__Then' 
                        style={{borderColor: deviceColors.borderBlue}}
                        onMouseOver={()=>this.setState({showThenAdd: true})}
                        onMouseOut={()=>this.setState({showThenAdd: false})}>
                        <div className='ViewEditScenario__Then'>
                            <div style={{display: this.state.showThenAdd === true ? 'block' : 'none'}} 
                                className="ViewEditScenario__Then__MainImage">
                                <Samy path={plusDark} style={iconIfThenIconStyle} />
                            </div>
                            <p onClick={this.handleThen}>{local.ScenarioThenTitle}</p>
                            <div className="checkbox" style={{textAlign: 'start', margin: '10px'}}>
                                <input type="checkbox"
                                        id={"ViewEditScenario-fav-sendNotification"}
                                        name={"ViewEditScenario-fav-sendNotification"}
                                        value={this.state.sendPush}
                                        className="custom-checkbox"
                                        defaultChecked={this.state.sendPush} 
                                        onChange={(e)=> this.setState({sendPush: !this.state.sendPush, changed: true})}
                                    />
                                <label htmlFor={"ViewEditScenario-fav-sendNotification"}>
                                    {local.ScenarioSendPushTitle}
                                </label>
                            </div>
                            <div className="checkbox" style={{textAlign: 'start', margin: '10px'}}>
                                <input type="checkbox"
                                        id={"ViewEditScenario-fav-importantAlert"}
                                        name={"ViewEditScenario-fav-importantAlert"}
                                        value={this.state.importantAlert}
                                        className="custom-checkbox"
                                        defaultChecked={this.state.importantAlert} 
                                        onChange={(e)=> this.setState({importantAlert: !this.state.importantAlert, changed: true})}
                                    />
                                <label htmlFor={"ViewEditScenario-fav-importantAlert"}>
                                    {local.ScenarioAlertPushTitle}
                                </label>
                            </div>
                            <div className='ViewEditScenario__IfThen__Conditions'>
                                {
                                    this.state.actors.map((c, i)=>
                                        <ViewIfThenDevice cond={c} key={c.guid} />)
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    
                </div>
                <button 
                    disabled={this.state.changed === false}
                    onClick={this.save}>
                    {local.Save}
                </button>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewEditScenario);