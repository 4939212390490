import './appTitle.css';
import { Component } from 'react';

import cloud_left from '../../images/weather/cloud_left.png';
import cloud_right from '../../images/weather/cloud_right.png';

import { globalLocale, optGeocode,
    deviceColors, ApiActions } from '../../helpers/constants';
import { getNotifications } from '../../helpers/helperApi';
import { getDashBoardDevices } from '../../helpers/funcs';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Samy } from 'react-samy-svg';
import ring from '../../images/other/ring.svg';
import modalNotifications from '../viewModal/modalNotifications';

import {
    openNotificationWithIcon
} from '../../helpers/funcs';

const openGeocoder = require('node-open-geocoder');
//const NodeGeocoder = require('node-geocoder');

const ct = require('countries-and-timezones');
var weather = require('openweather-apis');

class AppTitle extends Component {
    constructor(props){
        super(props);

        this.getWeather = this.getWeather.bind(this);
        this.getLocation = this.getLocation.bind(this);
        this.openNotifications = this.openNotifications.bind(this);
        this.getUnreadedNotifications = this.getUnreadedNotifications.bind(this);

        var allTimeZones = ct.getAllTimezones();
        var localOffset = new Date().getTimezoneOffset() * (-1);
        for(var tz in allTimeZones){
            var t = allTimeZones[tz];
            if (t.utcOffset === localOffset){
                this.props.appState.localTZid = t.name;
            }
        }

        this.state = {
            location: "",
            city: "",
            hum: "",
            temp: "",
            pres: "",
            count: null
        }
        setTimeout(this.getLocation, 10);
    }

    getLocation() {
        let local = this.props.appState.local;

        var owner = this;
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition(p => {
                owner.props.appState.defPoint.latitude = p.coords.latitude;
                owner.props.appState.defPoint.longitude = p.coords.longitude;

                setTimeout(this.getWeather(p.coords), 10);

                openGeocoder()
                    .reverse(p.coords.longitude, p.coords.latitude)
                    .end((err, res) => {
                        if (err === null) {
                            if (res.address !== undefined && res.address.city !== undefined) {
                                this.setState({ city: res.address.city });
                            } else {
                                if (res.address !== undefined && res.address.state !== undefined) {
                                    this.setState({ city: res.address.state });
                                }
                            }
                        }
                    });
            }, (error) => {
                openNotificationWithIcon('error', local.appTitle, local.LocationPermissionError)
            });
        } else {
            this.setState({ location: "Geolocation is not supported by this browser." });
        }
    }

    getWeather(coords){
        let owner = this;
        let local = this.props.appState.local;
        weather.setAPPID("1e060027a80410562dc6b68e759ddef8");
        weather.setLang(local.weatherLocale);
        weather.setCoordinate(coords.latitude, coords.longitude);

        weather.getTemperature(function(err, temp){
            //if (console && err) console.log(err);
            if (!err) 
                owner.setState({temp: temp});
        });
        weather.getHumidity(function(err, hum){
            //if (console && err) console.log(err);
            if (!err) 
                owner.setState({hum: hum});
        });
        weather.getPressure(function(err, pres){
            //if (console && err) console.log(err);
            if (!err) 
                owner.setState({pres: 0.75006 * pres});
        });
        
    }

    async getUnreadedNotifications(){
        let result = await getNotifications(ApiActions.Get);
        if (result === false) return;

        let count = 0;
        for(let n of result){
            if (n.isRead === false) count++;
        }
        this.setState({
            count: count
        });
    }

    async openNotifications(){
        let user = this.props.appState.user;
        let local = this.props.appState.local;
        let isDark = this.props.appState.isDarkTheme;
        await modalNotifications(local, user, isDark);
    }

    render() {
        // Geocode.setLanguage(globalLocale.locale);
        let local = this.props.appState.local;
        let isDark = this.props.appState.isDarkTheme;
        let wsIsOk = this.props.appState.appWsOk;
        let user = this.props.appState.user;


        if (this.state.count === null){
            setTimeout(this.getUnreadedNotifications, 10);
        }

        let alldDevices = getDashBoardDevices(user);
        let sensorsWithActivePower = [];
        let totalActivePower = 0;
        alldDevices.forEach((d) => {
            if (!d.sensors) return;
            d.sensors.forEach((s) => {
                if (s.model.includes('ActivePowerSensor')){
                    sensorsWithActivePower.push(s);
                    if (s.parsedValue) totalActivePower += s.parsedValue;
                }
            })
        });

        var iconStyle = {
            stroke: deviceColors.borderBlue,
            strokeWidth: 0, 
            strokeMiterlimit: 0, 
            fill: deviceColors.borderBlue
        }

        return (
            <div className="AppTitle">
                {
                    /* <img className={`CloudLeft ${isDark ? "" : "imageInvertColors"}`} 
                    src={cloud_left} alt=""/> */}
                {
                    !this.state.city ? null :
                    <>
                        <p className='AppTitle__City' style={{color: isDark ? '' : '#6B6965'}}>
                            {this.state.city}
                        </p>
                        <div className='AppTitle__Weather'>
                            <span className='AppTitle__Temp' style={{color: isDark ? '' : '#6B6965'}}>
                                {`${local.SensorTypeNameTemperature} ${Math.round(this.state.temp * 10)/10}°`}
                            </span>
                            <span className='AppTitle__Hum' style={{color: isDark ? '' : '#6B6965'}}>
                                {`${local.SensorTypeNameHumidity} ${this.state.hum}%`}
                            </span>
                            <span className='AppTitle__Pressure' style={{color: isDark ? '' : '#6B6965'}}>
                                {`${local.SensorTypeNamePressure} ${Math.round(this.state.pres)} ${local.SensorPressure}`}
                            </span>
                        </div>
                    </>
                }

                <div className='AppTitle__AppStatusInfo'>
                    <span>{`${local.WsStatusTitle}: ${wsIsOk === true ? local.WsStatusOkTitle : local.WsStatusFailTitle}`}</span>
                    {
                        sensorsWithActivePower.length === 0 ? null :
                        <span>{`${local.TotalActivePower}: ${totalActivePower.toFixed(2)} ${local.SensorDemW}`}</span>
                    }
                </div>
                
                <img className={`CloudRight ${isDark ? "" : "imageInvertColors"}`}
                    src={cloud_right} alt=""/>

                <div className="AppTitle__Ring" 
                    title={local.NotificationsTitle}
                    onClick={this.openNotifications}>
                    <Samy path={ring} style={iconStyle} />
                </div>
                <div className='AppTitle__Ring__Count'
                    onClick={this.openNotifications}
                    style={{
                        backgroundColor: deviceColors.borderOrange,
                        color: deviceColors.backGroundDark
                    }}
                    title={this.state.count}>
                        {this.state.count}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(AppTitle);