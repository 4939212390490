import './viewCameraPreview.css';
import React from 'react';

import { userGetCamerasDevices } from '../../helpers/funcs';

//import { deviceColors } from '../../helpers/constants';

import { openNotificationWithIcon } from '../../helpers/funcs';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Select } from 'antd';
const { Option } = Select;

const maxCount = 9;
const cameraSize = { w: 1920, h: 1080 };

class ViewCameraPreview extends React.Component {
    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.preview = this.preview.bind(this);
        this.handleChange = this.handleChange.bind(this);
        //this.updateContainers = this.updateContainers.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);

        this.links = [];
        this.lastChannel = 0;

        let user = this.props.appState.user;
        this.cameras = userGetCamerasDevices(user);
        this.options = this.cameras.map((cam, i) =>
            <Option key={cam.deviceId}>
                {cam.settings?.name ?? `${cam.deviceId}`}
            </Option>);

        this.state = {
            wrapperWidth: -1,
            selectedCameras: []
        };
    }

    open(camera) {
        if (!camera.info || !camera.info.localIp) return;

        window.open(`http://${camera.info.localIp}/web/index.html`, '_blank').focus();
    }

    preview(camera) {
        if (!camera.info || !camera.info.localIp) return;

        window.open(`http://${camera.info.localIp}:8085/123-0.jpg`, '_blank').focus();
    }

    updateDimensions() {
        let wrapper = document.getElementById('ViewCameraPreview__windows');
        if (wrapper === null) return;

        if (wrapper.clientWidth !== this.state.wrapperWidth) {
            this.links = [];
            this.setState({ wrapperWidth: wrapper.clientWidth });
        }
    };

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    handleChange(value) {
        let local = this.props.appState.local;

        if (value.length > maxCount) {
            openNotificationWithIcon("info",
                local.appTitle, local.ViewCameraPreviewSelectionMaxCount);
            return;
        }
        let newList = [];
        for (let id of value) {
            let oldEl = null;
            for (let cam of this.state.selectedCameras) {
                if (cam.deviceId === id) {
                    oldEl = cam;
                    break;
                }
            }
            if (oldEl === null) {
                for (let cam of this.cameras) {
                    if (cam.deviceId === id) {
                        
                        if (cam.info === undefined) {
                            openNotificationWithIcon("info",
                                local.appTitle, local.ViewCameraPreviewSelectionError);
                            return;
                        } else {
                            oldEl = cam;
                        }
                        break;
                    }
                }
            }
            if (oldEl !== null) {
                newList.push(oldEl);
            }
        }
        this.setState({ selectedCameras: newList });
    }

    render() {
        let local = this.props.appState.local;
        let tab = this.props.appState.activeTab;
        if (tab !== 6) return null;
        if (this.state.wrapperWidth === -1) {
            setTimeout(this.updateDimensions, 10);
        }

        var elWidth = 0, scale = 1, delta = 1, shiftX = 0, shiftY = 0;
        if (this.state.wrapperWidth !== -1){
            let count = this.state.selectedCameras.length;
            
            if (count === 1) {
                elWidth = this.state.wrapperWidth / delta;
                scale = elWidth / cameraSize.w;
            } else if (count < 5) {
                delta = 2;
                elWidth = this.state.wrapperWidth / delta;
                scale = elWidth / cameraSize.w;
            } else if (count < 10) {
                delta = 3;
                elWidth = this.state.wrapperWidth / delta;
                scale = elWidth / cameraSize.w;
            } else {
                delta = 4;
                elWidth = this.state.wrapperWidth / delta;
                scale = elWidth / cameraSize.w;
            }
            var elHeight = elWidth * (cameraSize.h / cameraSize.w);
            if (scale !== 1){
                shiftX = (elWidth - elWidth * scale) / 2;
                shiftY = (elHeight - elHeight * scale) / 2;
            }
        }

        if (this.links.length !== this.state.selectedCameras.length && this.state.wrapperWidth !== - 1) {
            this.lastChannel = this.lastChannel === 0 ? 1 : 0;
            this.links = this.state.selectedCameras.map((cam, i) =>
                cam.info === undefined ? null :
                    <div style={{ width: elWidth, height: elHeight, transform: `scale(${scale})` }}
                        className='camera_preview_wrapper'
                        key={cam.guid}>
                        <iframe
                            style={{ width: cameraSize.w, height: cameraSize.h }}
                            className='camera_preview_iframe'
                            id={cam.guid}
                            title={cam.guid}
                            src={`http://${cam.info.localIp}:${8085}/123-${this.lastChannel}.jpg`}
                            //frameBorder='0' 
                            scrolling='no' />
                    </div>);
        }
        return (
            <div className="ViewCameraPreview">
                <div className='ViewCameraPreview__CamerasSelection'>
                    <p>{local.ViewCameraPreviewSelectionTitle}</p>
                    <Select
                        mode="tags" style={{ width: '100%' }}
                        allowClear
                        placeholder=""
                        onChange={this.handleChange}>
                        {this.options}
                    </Select>
                </div>
                <div className='ViewCameraPreview__windows'
                    style={{ transform: `translate(-${shiftX}px, -${shiftY}px)` }}
                    id='ViewCameraPreview__windows'>
                    {this.links}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewCameraPreview);