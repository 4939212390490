import './scenarios.css';
import { Component } from 'react';

import ViewScenario from '../viewScenario/viewScenario';

import { Samy } from 'react-samy-svg';
import { deviceColors, 
    ModalActions, 
    LimitType, 
    NotificationTypeEnum } from '../../helpers/constants';
import plus from '../../images/menu/menu_plus.svg';
import { defTimer,
    checkLimitByType,
    openNotificationWithIcon } from "../../helpers/funcs";
import { v4 as uuidv4 } from 'uuid';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Scenarios extends Component {

    constructor(props){
        super(props);

        this.addNewTrigger = this.addNewTrigger.bind(this);
    }

    addNewTrigger(){
        let local = this.props.appState.local;
        let user = this.props.appState.user;

        let limitMsg = checkLimitByType(user, local, LimitType.ScenariosCount);
        if (limitMsg === null){
            this.props.appActions.setShowModal({
                modalAction: ModalActions.NewScenario,
                objectModal: {
                    guid: uuidv4(),
                    sendPush: true,
                    enabled: true,
                    showOnDashBoard: false,
                    notificationType: NotificationTypeEnum.Regular,
                    useAsShield: false,
                    actors: [],
                    conditions: [],
                    timeFilter: defTimer(
                        this.props.appState.defCron,
                        this.props.appState.localTZid)
                }
            });
        } else {
            openNotificationWithIcon("info", local.AppName, limitMsg);
        }
    }

    render() {
        var tab = this.props.appState.activeTab;
        var user = this.props.appState.user;

        let triggerViews = user.triggers
            .map((el, index)=>{
                return <ViewScenario key={"dashTrigger"+el.guid} trigger={el} />
            });
        
        triggerViews.splice(0, 0, 
            <div className='ViewScenario AddButton' 
                style={{borderColor: deviceColors.borderBlue}}
                onClick={this.addNewTrigger}>
                <Samy path={plus} />
            </div>);

        return (
            <div style={{display: tab === 4 ? "block" : "none"}}>
                <div className="Scenarios">
                    {
                        triggerViews
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(Scenarios);