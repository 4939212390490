import './viewScenario.css';
import { Component } from 'react';

import { deviceColors } from "../../helpers/constants";
import { Samy } from 'react-samy-svg';
import shield from '../../images/other/shield.svg';
import ring from '../../images/other/ring.svg';

import { ModalActions, ApiActions } from '../../helpers/constants';
import { deleteByGuid } from '../../helpers/funcs';
import settings from '../../images/menu/menu_settings.svg';

import ViewDeviceOptions from '../viewDeviceOptions/viewDeviceOptions';
import { getUser, updateTrigger } from '../../helpers/helperApi';
import confirm from '../viewModal/modalConfirm';

import { Popover } from 'antd';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewScenario extends Component {

    constructor(props){
        super(props);

        this.onMouseOut = this.onMouseOut.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.hidePopover = this.hidePopover.bind(this);
        this.changeEnabled = this.changeEnabled.bind(this);
        this.editTrigger = this.editTrigger.bind(this);
        this.deleteTrigger = this.deleteTrigger.bind(this);

        this.state = {
            hovered: false,
            ppVisible: false
        }
    }
    
    onMouseOver() {
        this.setState({ hovered: true });
    }

    onMouseOut() {
        this.setState({ hovered: false });
    }

    handleVisibleChange(visible) {
        this.setState({ ppVisible: visible });
    };

    hidePopover(){
        this.setState({ ppVisible: false });
    }

    async changeEnabled(enable){
        const trigger = this.props.trigger;
        trigger.enabled = enable;
        let result = await updateTrigger(trigger, ApiActions.Put);
        let user = await getUser();
        this.setState({ hovered: false });
        this.props.appActions.setUser({ user: user });
    }

    editTrigger(){
        const trigger = this.props.trigger;

        this.props.appActions.setShowModal({
            modalAction: ModalActions.EditScenario,
            objectModal: trigger
        });
    }

    async deleteTrigger(){
        const local = this.props.appState.local;
        var isDarkTheme = this.props.appState.isDarkTheme;

        if (await confirm(local.appTitle, local.TriggerDeleteAsk, local.Yes, local.Cancel, isDarkTheme) === false){
            return;
        }
        const trigger = this.props.trigger;
        const user = this.props.appState.user;
        if (await updateTrigger(trigger, ApiActions.Delete) === true){
            deleteByGuid(user.triggers, trigger);
            this.props.appActions.setUser({
                user: user
            });
        }
        this.setState({ hovered: false });
    }

    render() {
        var local = this.props.appState.local;
        var trigger = this.props.trigger;
        var isDark = this.props.appState.isDarkTheme;
        var borderColor = trigger.enabled 
            ? deviceColors.borderOrange
            : deviceColors.borderBlue;

        var iconStyleShield = {
            strokeWidth: 1, 
            strokeMiterlimit: 0, 
            opacity: .7,
            stroke: trigger.useAsShield 
                ? deviceColors.borderBlue
                : (isDark 
                    ? deviceColors.borderGreyDark 
                    : deviceColors.borderGreyLight)
        };
        iconStyleShield.fill = iconStyleShield.stroke;

        var iconStyleRing = {
            strokeWidth: 1, 
            strokeMiterlimit: 0, 
            opacity: .7,
            stroke: trigger.sendPush 
                ? deviceColors.borderBlue 
                : (isDark 
                    ? deviceColors.borderGreyDark 
                    : deviceColors.borderGreyLight)
        };
        iconStyleRing.fill = iconStyleRing.stroke;

        return (
            <div className="ViewScenario" style={{borderColor: borderColor}}
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
                onClick={()=>this.changeEnabled(!trigger.enabled)}>
                <div className="ViewScenario__Name">
                    <p>
                        {trigger.title}
                    </p>
                    <Popover
                        onVisibleChange={this.handleVisibleChange}
                        mouseEnterDelay={0}
                        trigger="hover"
                        visible={this.state.ppVisible}
                        content={<ViewDeviceOptions 
                            // hidePopover={this.hidePopover}
                            // openDevice={this.openDevice}
                            // addToLocation={this.addToLocation}
                            // inRoom={deviceIsInRoom(user, device)}
                            // removeFromRooms={this.removeFromRooms}
                            // isLinked={deviceIsLinked(user, device)}
                            // removeLink={this.removeLink}
                            // isShared={deviceIsShared(user, device)}
                            // editShare={this.editShare}
                            // deleteDevice={this.deleteDevice}
                            isScenario={true}
                            hidePopover={this.hidePopover}
                            isEnabled={trigger.enabled}
                            changeEnabled={this.changeEnabled}
                            editTrigger={this.editTrigger}
                            deleteTrigger={this.deleteTrigger}
                            isDarkTheme={isDark}
                            local={local}/>
                        }>
                        <img style={{opacity:this.state.hovered ? 1 : 0 }} 
                            src={settings} alt=""
                            onClick={(e) => e.stopPropagation()}
                            />
                    </Popover>
                </div>
                <div className="ViewScenario__icons">
                    <div className="ViewScenario__icon">
                        <Samy path={shield} style={iconStyleShield} />
                    </div>
                    <div className="ViewScenario__icon">
                        <Samy path={ring} style={iconStyleRing} />
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewScenario);