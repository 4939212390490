import React, { useState } from "react";
import ReactDOM from "react-dom";
import './modalPasswordChange.css';

import { openNotificationWithIcon } from '../../helpers/funcs';
import { changePassword } from '../../helpers/helperApi';
import { deviceColors, Credentials, ApiActions } from "../../helpers/constants";

function ModalPaaswordChange({ resolve, local, user, isDark }) {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    
    function clickExit() {
        removeDialog();
        resolve(null);
    }
    async function clickSave() {
        if (!oldPassword || !newPassword || !repeatPassword){
            openNotificationWithIcon('info', local.ChangePasswordTitle, local.RegistrationPasswordEmpty);
            return;
        }
        if (newPassword !== repeatPassword){
            openNotificationWithIcon('info', local.ChangePasswordTitle, local.ChangePasswordNotSame);
            return;
        }
        if (Credentials.password !== oldPassword){
            openNotificationWithIcon('info', local.ChangePasswordTitle, local.ChangePasswordLoginNotExists);
            return;
        }
        let result = await changePassword(user.login, newPassword, ApiActions.Post);
        if (result === true){
            openNotificationWithIcon('info', local.ChangePasswordTitle, local.ChangePasswordConfirm);
            removeDialog();
            resolve(newPassword);
        } else {
            openNotificationWithIcon('error', local.ChangePasswordTitle, local.Fail);
        }
    }
    return (
        <div id="modal__container__passwordchange__dialog" 
            className='modal__container__passwordchange__dialog'
            style={{
                borderColor: deviceColors.borderBlue,
                backgroundColor: isDark 
                    ? deviceColors.backGroundDark 
                    : deviceColors.backGroundLight}}>
            <p>{local.ChangePasswordTitle}</p>
            <input className="ViewEditDevice__Name"
                value={oldPassword}
                onChange={(e)=> setOldPassword(e.target.value)}
                style={{height:"45px"}}
                placeholder={local.PasswordChangePasswordOld}/>
            <input className="ViewEditDevice__Name"
                value={newPassword}
                onChange={(e)=> setNewPassword(e.target.value)}
                style={{height:"45px"}}
                placeholder={local.PasswordChangePasswordNew}/>
            <input className="ViewEditDevice__Name"
                value={repeatPassword}
                onChange={(e)=> setRepeatPassword(e.target.value)}
                style={{height:"45px"}}
                placeholder={local.PasswordChangePasswordConfirm}/>
            <button onClick={clickSave}>{local.ChangePasswordTitle}</button>
            <div className='modal__confirm__container_dialog__buttons'>
                <button onClick={clickExit}>{local.Close}</button>
            </div>
        </div>
    );
}

export default function modalPasswordChange(local, user, isDark = true) {
    return new Promise((resolve, reject) => {
        addDialog(local, user, isDark, resolve);
    });
}

function addDialog(local, user, isDark, resolve) {
    const body = document.getElementById("Vku_App");
    const div = document.createElement("div");
    div.setAttribute("id", "modal__container__passwordchange");
    div.setAttribute("className", "modal__container__passwordchange");
    body.appendChild(div);
    ReactDOM.render(
        <ModalPaaswordChange local={local} user={user} isDark={isDark} resolve={resolve} />,
        div
    );
}

function removeDialog() {
    const div = document.getElementById("modal__container__passwordchange");
    const body = document.getElementById("Vku_App");
    body.removeChild(div);
}