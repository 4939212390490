import { Component } from 'react';

import DeviceFilter from './deviceFilter';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class DashFilter extends Component {
    constructor(props){
        super(props);
    }

    render() {
        return (
            <div className="DashFilter">
                {
                    this.props.uniqueDevices.map((el, index) => {
                        return <DeviceFilter model={el} />;
                    })
                }
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(DashFilter);