import './settingsPromaSat.css';
import { Component } from 'react';

import { openNotificationWithIcon, 
    checkNewUserForNewDevices,
    checkLimitByType } from '../../../helpers/funcs';
import { checkUnitConnected, getUnitName } from '../../../helpers/helperPromaSat';
import { getUser, promaSatAccount, promaSatUnits, updateUserSettings,
    updatePromaSatUnit, updatePromaSatUnitVkuLink } from '../../../helpers/helperApi';
import { ApiActions, deviceColors, LimitType } from '../../../helpers/constants';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class SettingsPromaSat extends Component {

    constructor(props){
        super(props);

        this.login = this.login.bind(this);
        this.unitClick = this.unitClick.bind(this);

        var user = this.props.appState.user;

        this.state = {
            login: user.settings.promaSatUser.login,
            password: user.settings.promaSatUser.password,
            units: []
        }
    }

    async login(event) {
        var local = this.props.appState.local;
        let obj = {
            login:this.state.login,
            password: this.state.password
        };
        var result = await promaSatAccount(obj, ApiActions.Get);
        if (result === true){
            let units = await promaSatUnits(obj, ApiActions.Get);
            this.setState({units: units});
        } else {
            openNotificationWithIcon("info", local.AppName, local.LoginError);
        }
    }

    async unitClick(unit){
        let user = this.props.appState.user;
        let local = this.props.appState.local;
        let isDark = this.props.appState.isDarkTheme;

        var connected = checkUnitConnected(user, unit);
        let result = false;
        let showResult = true;

        if(connected === false){
            let limitMsg = checkLimitByType(user, local, LimitType.PromasatUnitsCount);
            if (limitMsg === null){
                result = await updatePromaSatUnit({ Id: unit.Id }, ApiActions.Post);
                if (result === true){
                    result = await updatePromaSatUnitVkuLink({
                        login: this.state.login,
                        password: this.state.password,
                        enable: true,
                        Id: unit.Id
                    }, ApiActions.Get);
                }
            } else {
                showResult = false;
                openNotificationWithIcon("info", local.AppName, limitMsg);
            }
        } else {
            result = await updatePromaSatUnitVkuLink({
                login: this.state.login,
                password: this.state.password,
                enable: false,
                Id: unit.Id
            }, ApiActions.Get);
            if (result === true){
                result = await updatePromaSatUnit({ Id: unit.Id }, ApiActions.Delete);
            }
        }

        if (showResult === true){
            if (result === true){
                let newUser = await getUser();
                checkNewUserForNewDevices(local, user, newUser, async () => {
                    if (await updateUserSettings(user.settings, ApiActions.Post) === true){
                        // 
                    }
                }, isDark);
                this.props.appActions.setUser({ user: newUser })
                openNotificationWithIcon("info", local.AppName, local.Success);
            } else {
                openNotificationWithIcon("info", local.AppName, local.Fail);
            }
        }
    }

    render() {
        var tab = this.props.appState.activeTab;
        var user = this.props.appState.user;
        var local = this.props.appState.local;
        var units = this.state.units;

        return (
            <div style={{display: tab === 5 ? "block" : "none"}}>
                <div className="SettingsPromaSat">
                    <p>{local.PromaSatHelp}</p>
                    <input className="SettingsPromaSat__Login"
                        value={this.state.login}
                        onChange={(e)=> this.setState({login: e.target.value, changed: true})}
                        style={{margin: "10px 0px", height:"45px" }}
                        placeholder={local.AuthLogin}/>
                    <input className="SettingsPromaSat__Password"
                        value={this.state.password}
                        onChange={(e)=> this.setState({password: e.target.value, changed: true})}
                        style={{margin: "10px 0px", height:"45px" }}
                        placeholder={local.AuthPassword}/>
                    <button 
                        style={{margin: "10px 0px", width: "100%", height:"45px"}}
                        onClick={(event) => this.login(event)}>
                        {local.AuthEnter}
                    </button>
                    <div style={{display: units.length > 0 ? 'block' : 'none'}}>
                        <p>{local.PromaSatUnitHelp}</p>
                        <div className='SettingsPromaSat__Units' 
                            style={{borderColor: deviceColors.borderBlue}}>
                        {
                            units.map((el, i) =>{
                                return <div 
                                    onClick={(e) => { this.unitClick(el) }}
                                    key={`promaUnit${i}`} 
                                    className='SettingsPromaSat__Unit'>
                                    <p className='SettingsPromaSat__Unit__Name'>
                                        {getUnitName(el)}
                                    </p>
                                    <p className='SettingsPromaSat__Unit__Status'>
                                        { checkUnitConnected(user, el) === true
                                            ? local.PromaSatUnitInSystem
                                            : '' }
                                    </p>
                                </div>;
                            })
                        }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(SettingsPromaSat);