export const TimerTypeEnum = {
    /// <summary>
    /// Расписание
    /// </summary>
    Time: "Time",

    /// <summary>
    /// Закат
    /// </summary>
    SunSet: "SunSet",

    /// <summary>
    /// Рассвет
    /// </summary>
    SunRise: "SunRise"
}

