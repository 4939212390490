import React, {useState} from "react";
import ReactDOM from "react-dom";
import './settingsApp.css';
import { Component } from 'react';

import { Credentials } from '../../../helpers/constants';
import Cookies from 'js-cookie';
import packageJson from '../../../../package.json';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class SettingsApp extends Component {

    constructor(props){
        super(props);

        this.exit = this.exit.bind(this);

        this.changeShowSummaryVoltage = this.changeShowSummaryVoltage.bind(this);
        this.changeShowCurrentNotifications = this.changeShowCurrentNotifications.bind(this);

        this.state = {
            language: Cookies.get('Language') ?? 'AppLanguageEn',
            theme: Cookies.get('Theme') ?? 'ThemeControlled',
            appHome: Cookies.get('AppHome') ?? 'AppHomeRooms',
            showSummaryVoltage: props.appState.settings.showSummaryVoltage,
            showCurrentNotifications: props.appState.settings.showCurrentNotifications,
        }
    }

    exit(event) {
        Credentials.token = null;
        Credentials.login = null;
        Credentials.password = null;
        const ws = this.props.appState.ws;
        if (ws !== null && ws.readyState === 1){
            ws.close();
        }
        this.props.appState.activeTab = 2;
        this.props.appState.isDarkTheme = true;
        this.props.appState.dashExpandedDevice = null;
        this.props.appState.settingsDefTab = 0;
        this.props.appState.ws = null;
        this.props.appActions.setUser({user: null});
    }

    changeLanguage(prop){
        this.setState({language: prop});
        Cookies.set('Language', prop);
        if (prop === 'AppLanguageEn')
            this.props.appActions.setLocal({local: 'enEn'})
        if (prop === 'AppLanguageRu')
            this.props.appActions.setLocal({local: 'ruRu'})
    }

    changeTheme(prop){
        this.setState({theme: prop});
        Cookies.set('Theme', prop);
        if (prop === 'ThemeLight')
            this.props.appActions.setTheme({isDarkTheme: false})
        if (prop === 'ThemeDark')
            this.props.appActions.setTheme({isDarkTheme: true})
        if (prop === 'ThemeControlled'){
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                this.props.appActions.setTheme({isDarkTheme: true})
            } else {
                this.props.appActions.setTheme({isDarkTheme: false})
            }
        }
    }

    changeAppHome(prop){
        this.setState({appHome: prop});
        Cookies.set('AppHome', prop);
    }

    changeShowCurrentNotifications(){
        let showCurrentNotifications = !this.state.showCurrentNotifications;

        this.setState({showCurrentNotifications: showCurrentNotifications});
        Cookies.set('ShowCurrentNotifications', showCurrentNotifications);
        this.props.appState.settings.showCurrentNotifications = showCurrentNotifications;
        this.props.appActions.setSettings({settings: this.props.appState.settings});
    }

    changeShowSummaryVoltage(){
        let showSummaryVoltage = !this.state.showSummaryVoltage;

        this.setState({showSummaryVoltage: showSummaryVoltage});
        Cookies.set('ShowSummaryVoltage', showSummaryVoltage);
        this.props.appState.settings.showSummaryVoltage = showSummaryVoltage;
        this.props.appActions.setSettings({settings: this.props.appState.settings});
    }

    render() {
        var tab = this.props.appState.activeTab;
        var local = this.props.appState.local;

        return (
            <div style={{display: tab === 5 ? "block" : "none"}}>
                <div className="SettingsApp">
                    <div className='SettingsApp__Strings'>
                        <p>{local.AppLanguage}</p>
                        <div className='SettingsApp__Strings__Inputs'>
                            <input type="radio"
                                id='AppLanguageEn'
                                value='AppLanguageEn'
                                checked={this.state.language === 'AppLanguageEn'}
                                onChange={this.changeLanguage.bind(this, 'AppLanguageEn')}/>
                            <label htmlFor='AppLanguageEn'>{local.AppLanguageEn}</label>
                            <input type="radio"
                                id='AppLanguageRu'
                                value='AppLanguageRu'
                                checked={this.state.language === 'AppLanguageRu'}
                                onChange={this.changeLanguage.bind(this, 'AppLanguageRu')}/>
                            <label htmlFor='AppLanguageRu'>{local.AppLanguageRu}</label>
                        </div>
                    </div>

                    <div className='SettingsApp__Strings'>
                        <p>{local.ThemeSettingNameTitle}</p>
                        <div className='SettingsApp__Strings__Inputs'>
                            <input type="radio"
                                id='ThemeLight'
                                value='ThemeLight'
                                checked={this.state.theme === 'ThemeLight'}
                                onChange={this.changeTheme.bind(this, 'ThemeLight')}/>
                            <label htmlFor='ThemeLight'>{local.ThemeSettingNameLight}</label>
                            <input type="radio"
                                id='ThemeDark'
                                value='ThemeDark'
                                checked={this.state.theme === 'ThemeDark'}
                                onChange={this.changeTheme.bind(this, 'ThemeDark')}/>
                            <label htmlFor='ThemeDark'>{local.ThemeSettingNameDark}</label>
                            <input type="radio"
                                id='ThemeControlled'
                                value='ThemeControlled'
                                checked={this.state.theme === 'ThemeControlled'}
                                onChange={this.changeTheme.bind(this, 'ThemeControlled')}/>
                            <label htmlFor='ThemeControlled'>{local.ThemeSettingNameControlled}</label>
                        </div>
                    </div>

                    <div className='SettingsApp__Strings'>
                        <p>{local.AppHomeScreen}</p>
                        <div className='SettingsApp__Strings__Inputs'>
                            <input type="radio"
                                id='AppHomeDash'
                                value='AppHomeDash'
                                checked={this.state.appHome === 'AppHomeDash'}
                                onChange={this.changeAppHome.bind(this, 'AppHomeDash')}/>
                            <label htmlFor='AppHomeDash'>{local.AppHomeScreenDash}</label>
                            <input type="radio"
                                id='AppHomeRooms'
                                value='AppHomeRooms'
                                checked={this.state.appHome === 'AppHomeRooms'}
                                onChange={this.changeAppHome.bind(this, 'AppHomeRooms')}/>
                            <label htmlFor='AppHomeRooms'>{local.AppHomeScreenRooms}</label>
                        </div>
                    </div>

                    <div className='SettingsApp__Strings'>
                        {/* <div className='SettingsApp__Strings__Inputs'>
                            <input type="radio"
                                onChange={() => {}}
                                id='showSummaryVoltage'
                                checked={this.state.showSummaryVoltage === true}
                                onClick={this.changeShowSummaryVoltage}/>
                            <label htmlFor='showSummaryVoltage'>{local.SettingsShowSummaryVoltageTitle}</label>
                        </div> */}
                        <div className='SettingsApp__Strings__Inputs'>
                            <input type="radio"
                                onChange={() => {}}
                                id='showCurrentNotifications'
                                checked={this.state.showCurrentNotifications === true}
                                onClick={this.changeShowCurrentNotifications}/>
                            <label htmlFor='showCurrentNotifications'>{local.SettingsShowCurrentNotificationsTitle}</label>
                        </div>
                    </div>

                    <div className='SettingsApp__Strings'>
                        <p>{local.AppVersion}</p>
                        <p>{packageJson.version}</p>
                    </div>

                    <button 
                        style={{margin: "10px 0px", width: "300px", height:"45px"}}
                        onClick={(event) => this.exit(event)}>
                        {local.Exit}
                    </button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(SettingsApp);