import './viewButton.css';
import { Component } from 'react';

import { Samy } from 'react-samy-svg';

import { sensorImage } from '../../helpers/helperSensor';
import { deviceColors, ModalActions, ApiActions } from '../../helpers/constants';
import { sendToButton } from '../../helpers/helperWs';
import { getSecurityButtonEnable, userGetCameraDevices,
    openNotificationWithIcon } from '../../helpers/funcs';
import { updateTrigger, getUser } from '../../helpers/helperApi';

import Cookies from 'js-cookie';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewButton extends Component {

    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }
    
    async onClick(){
        let button = this.props.button;
        let user = this.props.appState.user;
        let local = this.props.appState.local;

        if (button.guid === "cameras"){
            this.props.appActions.setShowModal({
                modalAction: ModalActions.OpenCameras,
                objectModal: null
            });
        } else 
        if (button.guid === "security"){
            let security = getSecurityButtonEnable(user);
            let newState = !security.enabled;
            let result = false;
            for(let t of user.triggers){
                if (t.useAsShield !== true) continue;
                t.enabled = newState;
                result = await updateTrigger(t, ApiActions.Put);
            }
            let newUser = await getUser();
            this.props.appActions.setUser({ user: newUser });
            if (result === true)
                openNotificationWithIcon("info", local.appTitle, local.Success);
            else
                openNotificationWithIcon("info", local.appTitle, local.Fail);
            let cameras = userGetCameraDevices(user);
            if (cameras.length > 0){
                openNotificationWithIcon("info", local.appTitle, local.ScenarioSecurityInfo);
            }
        } else {
            sendToButton(this.props.appState.ws, 
                this.props.appState.user,
                button.guid);
            Cookies.set('lastButton', button.guid);
            this.props.appActions.setUser({ user: this.props.appState.user });
        }
    }
    
    render() {
        let user = this.props.appState.user;
        let isDark = this.props.appState.isDarkTheme;

        let button = this.props.button;
        let buttonStyle = {};
        let iconStyle = {
            strokeWidth: 1, 
            strokeMiterlimit: 0, 
            opacity: .7,
            stroke: isDark
                ? deviceColors.borderGreyDark
                : deviceColors.borderGreyLight
        };
        iconStyle.fill = iconStyle.stroke;
        if (button.guid === "security"){
            iconStyle.stroke = "#FFF";
            iconStyle.opacity = 1;
            buttonStyle.backgroundColor = button.settings.Enabled
                ? deviceColors.borderOrange
                : deviceColors.borderBlue;
        }
        if (button.guid === "cameras"){
            iconStyle.stroke = "#FFF";
            buttonStyle.backgroundColor = 'rgba(44,152,210,0.5)';
        }

        var isLast = Cookies.get('lastButton') === button.guid;
        if (isLast){
            buttonStyle.borderColor = deviceColors.borderBlue;
            buttonStyle.borderWidth = 2;
        }

        return (
            <div className="ViewButton"
                style={buttonStyle}
                onClick={this.onClick}>
                <Samy path={sensorImage(user, button)} style={iconStyle} />
                <p>{button.settings.Name}</p>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewButton);