import './viewLoading.css';
import { Component } from 'react';

class ViewLoading extends Component {
    render() {
        var enabled = this.props.enabled;

        return (
            <div className="svg-loading-back" style={{display: enabled ? "block" : "none"}}>
                <div className="svg-loader">
                    <svg className="svg-container" height="100" width="100" viewBox="0 0 100 100">
                        {/* <circle class="loader-svg bg" cx="50" cy="50" r="45"></circle> */}
                        <circle className="loader-svg animate" cx="50" cy="50" r="45"></circle>
                    </svg>
                </div>
            </div>
        );
    }
}

export default ViewLoading;