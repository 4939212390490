import './viewDeviceNew.css';
import { Component } from 'react';

import { userGetHubs } from '../../helpers/funcs';
import { getHubSensorValue, getHubSensor } from '../../helpers/helperDevice';
import { sendToGuidBool } from '../../helpers/helperWs';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewDeviceNew extends Component {
    constructor(props){
        super(props);

        this.save = this.save.bind(this);
        this.onHubChanged = this.onHubChanged.bind(this);
        this.close = props.close;

        var user = this.props.appState.user;
        this.hubs = userGetHubs(user);
        this.addSensorState = false;

        this.state = {
            currentHub: this.hubs[0]
        };
    }

    onHubChanged(i){
        this.setState({ currentHub: this.hubs[i] });
    }

    save(){
        const sensor = getHubSensor(this.state.currentHub, 'AddDeviceModeSensor');
        const state = getHubSensorValue(this.state.currentHub, 'AddDeviceModeSensor');

        sendToGuidBool(this.props.appState.ws, 
            this.props.appState.user, sensor.guid, !state);
    }

    render() {
        var local = this.props.appState.local;

        this.addSensorState = getHubSensorValue(this.state.currentHub, 'AddDeviceModeSensor');
        
        return (
            <div className="ViewDeviceNew">
                <p>
                    {local.DeviceNewTitle}
                </p>
                <p>
                    {local.DeviceNewHelp}
                </p>
                <select required={true} 
                    style={{margin: "15px 0px", height:"45px"}}
                    defaultValue={this.state.currentHub}
                    onChange={(e) => this.onHubChanged(e.target.value)}>
                    {
                        this.hubs.map((el, i)=>{
                            return <option value={i} key={"hubs"+i}>
                                {el.settings.name}
                        </option>;
                    })}
                </select>
                <button
                    onClick={this.save}>
                    {`${local.DeviceNewModeTitle} ${this.addSensorState === true ? local.On : local.Off}`}
                </button>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewDeviceNew);