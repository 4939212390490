import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import './modalTransactions.css';

import { getCurrencyName } from '../../helpers/funcs';
import { getTransactions } from '../../helpers/helperApi';
import { ApiActions, deviceColors } from "../../helpers/constants";
import moment from 'moment';
const format = 'HH:mm DD.MM.yyyy';

function ModalTransactions({ resolve, local, user, isDark }) {
    const [changed, setChanged] = useState(true);
    const [data, setData] = useState(null);
    function clickExit() {
        removeDialog();
        resolve(false);
    }
    useEffect(() => {
        async function fetchData() {
            let result = await getTransactions(ApiActions.Get);
            if (Array.isArray(result) === true){

                setChanged(false);
                setData(result.sort((a, b) => {
                    var dateA = new Date(a.receivedOn).getTime();
                    var dateB = new Date(b.receivedOn).getTime();
                    return dateA < dateB ? 1 : -1;
                }));
            }
        }
        if (changed === false) return;

        fetchData();
    })
    return (
        <div id="modal__container__transactions__dialog" 
            className='modal__container__transactions__dialog'
            style={{
                borderColor: deviceColors.borderBlue,
                backgroundColor: isDark 
                    ? deviceColors.backGroundDark 
                    : deviceColors.backGroundLight}}>
            <p>{local.NotificationsTitle}</p>
            <div className='modal__container__transactions__dialog__units'>
            {
                data === null ? null :
                data.map((el, i) => {
                    let time = moment(el.receivedOn).format(format);
                    return <div key={`transaction__${i}`}
                        className='modal__container__transactions__dialog__unit'>
                        <div className='top'>
                            <p className='amount'>{`${el.amount} ${getCurrencyName(el.currency, local)}`}</p>
                            <p className='time'>{time}</p>
                        </div>
                        <p className='paymentMethod'>{el.paymentMethod}</p>
                        <p className='comment'>{el.comment}</p>
                    </div>;
                })
            }
            </div>
            <div className='modal__confirm__container_dialog__buttons'>
                <button onClick={clickExit}>{local.Close}</button>
            </div>
        </div>
    );
}

export default function modalTransactions(local, user, isDark = true) {
    return new Promise((resolve, reject) => {
        addDialog(local, user, isDark, resolve);
    });
}

function addDialog(local, user, isDark, resolve) {
    const body = document.getElementById("Vku_App");
    const div = document.createElement("div");
    div.setAttribute("id", "modal__container__transactions");
    div.setAttribute("className", "modal__container__transactions");
    body.appendChild(div);
    ReactDOM.render(
        <ModalTransactions local={local} user={user} isDark={isDark} resolve={resolve} />,
        div
    );
}

function removeDialog() {
    const div = document.getElementById("modal__container__transactions");
    const body = document.getElementById("Vku_App");
    body.removeChild(div);
}