import React from "react";
import ReactDOM from "react-dom";
import './modalConfirm.css';

import { deviceColors } from "../../helpers/constants";

function ModalConfirm({ resolve, title, message, ok, cancel, isDark }) {
    function clickOK() {
        removeDialog();
        resolve(true);
    }
    function clickCancel() {
        removeDialog();
        resolve(false);
    }
    return (
        <div id="modal__confirm__container_dialog" 
            className='modal__confirm__container_dialog'
            style={{
                borderColor: deviceColors.borderBlue,
                backgroundColor: isDark 
                    ? deviceColors.backGroundDark 
                    : deviceColors.backGroundLight}}>
            <p>{title}</p>
            {
                message.split('<br>').map((el, i) => <p key={`text-${i}`}>{el}</p>)
            }
            <div className='modal__confirm__container_dialog__buttons'>
                <button onClick={clickOK}>{ok}</button>
                <button onClick={clickCancel}>{cancel}</button>
            </div>
        </div>
    );
}

export default function confirm(title, message, ok, cancel, isDark = true) {
    return new Promise((resolve, reject) => {
        addDialog(title, message, ok, cancel, isDark, resolve);
    });
}

function addDialog(title, message, ok, cancel, isDark, resolve) {
    const body = document.getElementById("Vku_App");
    const div = document.createElement("div");
    div.setAttribute("id", "modal__confirm__container");
    div.setAttribute("className", "modal__confirm__container");
    body.appendChild(div);
    ReactDOM.render(
        <ModalConfirm title={title} message={message} ok={ok} cancel={cancel} isDark={isDark} resolve={resolve} />,
        div
    );
}

function removeDialog() {
    const div = document.getElementById("modal__confirm__container");
    const body = document.getElementById("Vku_App");
    body.removeChild(div);
}