import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import './modalNotifications.css';

import { openNotificationWithIcon } from '../../helpers/funcs';
import { getNotifications } from '../../helpers/helperApi';
import { ApiActions, deviceColors } from "../../helpers/constants";
import moment from 'moment';
const format = 'HH:mm DD.MM.yyyy';

function ModalNotifications({ resolve, local, user, isDark }) {
    const [changed, setChanged] = useState(true);
    const [data, setData] = useState(null);
    function clickExit() {
        removeDialog();
        resolve(false);
    }
    async function clickNot(not){
        if (not.isRead === true) return;
        not.isRead = true;
        let result = await getNotifications(ApiActions.Put, [not]);
        if (result === true){
            setChanged(true);
            openNotificationWithIcon("info", local.NotificationActionReaded, local.Success);
        }
        else
            openNotificationWithIcon("info", local.NotificationActionReaded, local.Fail);
    }
    useEffect(() => {
        async function fetchData() {
            let result = await getNotifications(ApiActions.Get);
            setChanged(false);
            setData(result);
        }
        if (changed === false) return;

        fetchData();
    })
    return (
        <div id="modal__container__notifications__dialog" 
            className='modal__container__notifications__dialog'
            style={{
                borderColor: deviceColors.borderBlue,
                backgroundColor: isDark 
                    ? deviceColors.backGroundDark 
                    : deviceColors.backGroundLight}}>
            <p>{local.NotificationsTitle}</p>
            <div className='modal__container__notifications__dialog__units'>
            {
                data === null ? null :
                data.map((el, i) => {
                    let time = moment(el.sentOn).format(format);
                    return <div key={`notification__${i}`}
                        onClick={clickNot.bind(this, el)}
                        className='modal__container__notifications__dialog__unit'>
                        <p className='message'>{el.message}</p>
                        <div className='down'>
                            <p className='time'>{time}</p>
                            <p style={{color: 'rgba(0,0,0,0)'}}>{'__'}</p>
                            {
                                el.type !== 'Alert' ? null : 
                                <p className='alert'>{local.ScenarioAlertPushTitle}</p>
                            }
                            <p style={{color: 'rgba(0,0,0,0)'}}>{'__'}</p>
                            <p className='status'>
                                { el.isRead === true 
                                    ? local.NotificationActionReaded 
                                    : local.NotificationActionNotReaded }
                            </p>
                        </div>
                    </div>;
                })
            }
            </div>
            <div className='modal__confirm__container_dialog__buttons'>
                <button onClick={clickExit}>{local.Close}</button>
            </div>
        </div>
    );
}

export default function modalNotifications(local, user, isDark = true) {
    return new Promise((resolve, reject) => {
        addDialog(local, user, isDark, resolve);
    });
}

function addDialog(local, user, isDark, resolve) {
    const body = document.getElementById("Vku_App");
    const div = document.createElement("div");
    div.setAttribute("id", "modal__container__notifications");
    div.setAttribute("className", "modal__container__notifications");
    body.appendChild(div);
    ReactDOM.render(
        <ModalNotifications local={local} user={user} isDark={isDark} resolve={resolve} />,
        div
    );
}

function removeDialog() {
    const div = document.getElementById("modal__container__notifications");
    const body = document.getElementById("Vku_App");
    body.removeChild(div);
}