import './mainMenu.css';
import { Component } from 'react';

import dashSelected from '../../images/menu/menu_dash_selected.svg';
import dash from '../../images/menu/menu_dash.svg';
import roomsSelected from '../../images/menu/menu_rooms_selected.svg';
import rooms from '../../images/menu/menu_rooms.svg';
import scenariosSelected from '../../images/menu/menu_scenarios_selected.svg';
import scenarios from '../../images/menu/menu_scenarios.svg';
import settingsSelected from '../../images/menu/menu_settings_selected.svg';
import settings from '../../images/menu/menu_settings.svg';
import plus from '../../images/menu/menu_plus.svg';

import { Popover } from 'antd';

import ViewNewObject from '../viewNewObject/viewNewObject';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class MenuItem extends Component {

    constructor(props){
        super(props);

        var tab = props.activeTab;
        this.setActiveTab = this.setActiveTab.bind(this, tab);
        this.onMouseOut = this.onMouseOut.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.handleVisibleChange = this.handleVisibleChange.bind(this);
        this.hidePopover = this.hidePopover.bind(this);

        this.state = {
            hovered: false,
            ppVisible: false
        }
    }

    setActiveTab(tab){
        if (tab !== 3){
            this.props.appActions.setActiveTab({activeTab: tab});
        } else {
            //console.log(tab);
        }
    }
    
    onMouseOver() {
        this.setState({ hovered: true });
    }

    onMouseOut() {
        this.setState({ hovered: false });
    }

    handleVisibleChange(visible) {
        this.setState({ ppVisible: visible });
    };

    hidePopover(){
        this.setState({ ppVisible: false });
    }

    render() {
        var tab = this.props.activeTab;
        var isSelected = this.state.hovered ||
            tab === this.props.appState.activeTab;
        var local = this.props.appState.local;

        var imgSrc = plus;
        var nameTitle = "";
        if (tab === 1){
            imgSrc = isSelected ? dashSelected : dash;
            nameTitle = local.MenuDashboard;
        }
        if (tab === 2){
            imgSrc = isSelected ? roomsSelected : rooms;
            nameTitle = local.MenuHome;
        }
        if (tab === 4){
            imgSrc = isSelected ? scenariosSelected : scenarios;
            nameTitle = local.MenuScenarios;
        }
        if (tab === 5){
            imgSrc = isSelected ? settingsSelected : settings;
            nameTitle = local.MenuSettings;
        }

        return (
            <div className="MenuItem" 
                onMouseOver={this.onMouseOver}
                onMouseOut={this.onMouseOut}
                onClick={this.setActiveTab}>
                {
                    tab === 3
                    ? <div>
                        <Popover
                            onVisibleChange={this.handleVisibleChange}
                            mouseEnterDelay={0}
                            trigger="hover"
                            visible={this.state.ppVisible}
                            content={tab === 3 ? <ViewNewObject hidePopover={this.hidePopover} /> : null}>
                            <img className={"menu_main_plus_img"} 
                                src={imgSrc} alt=""
                                onClick={(e) => e.stopPropagation()}/>
                        </Popover>
                    </div>
                    : <div>
                        <img src={imgSrc} alt=""/>
                        <div className={isSelected ? "menu_item_selected" : "menu_item"}> 
                            {nameTitle}
                        </div>
                    </div>  
                }
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(MenuItem);