import './dashboard.css';
import { Component } from 'react';

import { Samy } from 'react-samy-svg';
import ViewDevice from '../viewDevice/viewDevice';
import DashFilter from './filter/dashFilter';
import RoomsFilter from './filter/roomsFilter';

import { 
    getDashBoardDevices,
    getDashBoardUniqueDevices } from '../../helpers/funcs';
import { roomGetDevices } from '../../helpers/helperRoom';
import { dashFilterDefault, deviceColors, ModalActions } from '../../helpers/constants';
import plus from '../../images/menu/menu_plus.svg';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Dashboard extends Component {
    constructor(props){
        super(props);

        this.addNewDevice = this.addNewDevice.bind(this);
    }

    addNewDevice(){
        this.props.appActions.setShowModal({
            modalAction: ModalActions.NewDevice,
            objectModal: null
        });
    }

    render() {
        let user = this.props.appState.user;
        if (user === null || user === undefined) return null;
        let tab = this.props.appState.activeTab;
        let local = this.props.appState.local;
        let model = this.props.appState.dashModelFilter;
        let room = this.props.appState.dashRoomFilter;
        
        let alldDevices = getDashBoardDevices(user);
        let uniqueDevices = getDashBoardUniqueDevices(user, alldDevices);
        
        var rooms = [{ name: local.AllRooms, guid: dashFilterDefault }];
        for(let r of user.rooms){
            r.realDevices = roomGetDevices(user, r);
            if (r.realDevices !== undefined && r.realDevices.length > 0) rooms.push(r);
        }

        // filtered devices
        let devices = [];
        if (model !== dashFilterDefault){
            devices = getDashBoardDevices(user, model);
        } else if (room !== dashFilterDefault){
            for(let r of user.rooms){
                if (r.guid === room){
                    devices = roomGetDevices(user, r);
                    break;
                }
            }
        } else {
            devices = alldDevices
        }

        let deviceViews = devices.map((el, index) => {
            return <ViewDevice key={"dashDevice" + user.version + el.guid} device={el} />;
        });
        deviceViews.splice(0, 0, 
            <div className='ViewDevice ViewDeviceCollapsed AddButton' 
                style={{borderColor: deviceColors.borderBlue}}
                onClick={this.addNewDevice}>
                <Samy path={plus} />
            </div>);

        return (
            <div style={{display: tab === 1 ? "block" : "none"}}>
                {
                    uniqueDevices.length < 2 ? null :
                    <DashFilter uniqueDevices={uniqueDevices} />
                }
                {
                    rooms.length === 0 ? null :
                    <RoomsFilter rooms={rooms} />
                }
                <div className="Dashboard">
                    {
                        deviceViews
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);