import './settingsTimers.css';
import { Component } from 'react';

import SettingsViewDevice from '../settingsViewDevice/settingsViewDevice';

import { Samy } from 'react-samy-svg';
import { userGetTimersDevice } from '../../../helpers/funcs';
import { ModalActions, deviceColors } from '../../../helpers/constants';
import plus from '../../../images/menu/menu_plus.svg';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class SettingsTimers extends Component {
    constructor(props){
        super(props);

        this.addNewTimer = this.addNewTimer.bind(this);
    }

    addNewTimer(){
        this.props.appActions.setShowModal({
            modalAction: ModalActions.EditObject,
            objectModal: { 
                model: 'TimerDevice.TimerSensor',
                locationGuid: null,
                offset: 0,
                Settings: {
                    Name: ''
                }
            }
        });
    }

    render() {
        var tab = this.props.appState.activeTab;
        var user = this.props.appState.user;
        if (user === null || user === undefined) return null;
        
        var device = userGetTimersDevice(user);
        if (device === undefined) return null;

        let timerViews = device.sensors
            .map((el, index) => {
                return <SettingsViewDevice key={"settingsTimmersDevice" + el.guid} sensor={el} />;
            });
        timerViews.splice(0, 0, 
            <div className='SettingsButtonsDevice AddButton' 
                style={{borderColor: deviceColors.borderBlue}}
                onClick={this.addNewTimer}>
                <Samy path={plus} />
            </div>);
        
        return (
            <div style={{display: tab === 5 ? "block" : "none"}}>
                <div className="SettingsTimers">
                    {
                        timerViews
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(SettingsTimers);