import React from "react";
import ReactDOM from "react-dom";
import './modalAbout.css';

import logo from '../../images/other/icon1024.png';
import packageJson from '../../../package.json';

import { deviceColors } from "../../helpers/constants";

function ModalAbout({ resolve, local, isDark }) {
    function clickOK() {
        removeDialog();
        resolve(true);
    }
    return (
        <div id="modal__about__container_dialog" 
            className='modal__about__container_dialog'
            style={{
                borderColor: deviceColors.borderBlue,
                backgroundColor: isDark 
                    ? deviceColors.backGroundDark 
                    : deviceColors.backGroundLight}}>
            <img src={logo} alt='' />
            <div className='modal__about__text'>
                {
                    local.Modal__About__MainText.split('<br>').map((el, i) => <p key={`text-${i}`}>{el}</p>)
                }
                <div className='modal__about__text__info'>
                    <p>{local.AppVersion}</p>
                    <p>{packageJson.version}</p>
                </div>
                {
                    local.Modal__About__1Text.split('<br>').map((el, i) => <p key={`text-${i}`}>{el}</p>)
                }
                <div className='modal__about__text__info'>
                    <p>{local.Modal__About__21Text}</p>
                    <a href='https://vkonnektu.com' target="_blank">
                        <p>{'vkonnektu.com'}</p>
                    </a>
                </div>
                <div className='modal__about__text__info'>
                    <p>{local.Modal__About__22Text}</p>
                    <a href='mailto:support@vkonnektu.com'>
                        <p>{'support@vkonnektu.com'}</p>
                    </a>
                </div>
                <div className='modal__about__text__info'>
                    <p>{local.Modal__About__23Text}</p>
                    <a href='mailto:alexs@vkonnektu.com'>
                        <p>{'alexs@vkonnektu.com'}</p>
                    </a>
                </div>
                {
                    local.Modal__About__24Text.split('<br>').map((el, i) => <p key={`text-${i}`}>{el}</p>)
                }
                <div className='modal__about__text__info'>
                    <a href='https://vkonnektu.com/privacy-policy' target="_blank">
                        <p>{local.Modal__About__25Text}</p>
                    </a>
                </div>
            </div>
            
            
            <div className='modal__about__container_dialog__buttons'>
                <button onClick={clickOK}>{local.Close}</button>
            </div>
        </div>
    );
}

export default function showAbout(local, isDark = true) {
    return new Promise((resolve, reject) => {
        addDialog(local, isDark, resolve);
    });
}

function addDialog(local, isDark, resolve) {
    const body = document.getElementById("Vku_App");
    const div = document.createElement("div");
    div.setAttribute("id", "modal__about__container");
    div.setAttribute("className", "modal__about__container");
    body.appendChild(div);
    ReactDOM.render(
        <ModalAbout local={local} isDark={isDark} resolve={resolve} />,
        div
    );
}

function removeDialog() {
    const div = document.getElementById("modal__about__container");
    const body = document.getElementById("Vku_App");
    body.removeChild(div);
}