import './settingsFriends.css';
import { Component } from 'react';

import { openNotificationWithIcon, checkNewUserForNewDevices, 
    getAllFriends, getFriendStatusTitle, getFriendDevicesTitle,
    checkLimitByType } from '../../../helpers/funcs';
import { checkUnitConnected, getUnitName } from '../../../helpers/helperPromaSat';
import { updateUserRelations, userPicture, getUser } from '../../../helpers/helperApi';
import { ApiActions, FriendStatus, deviceColors, LimitType } from '../../../helpers/constants';

import modalFriendRequest from '../../viewModal/modalFriendRequest';
import modalConfirm from '../../viewModal/modalConfirm';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class SettingsFriends extends Component {

    constructor(props){
        super(props);

        this.loadPicture = this.loadPicture.bind(this);
        this.sendRequest = this.sendRequest.bind(this);

        this.state = {
        }
    }

    async sendRequest(event) {
        let user = this.props.appState.user;
        let isDarkTheme = this.props.appState.isDarkTheme;
        let local = this.props.appState.local;

        let limitMsg = checkLimitByType(user, local, LimitType.FriendsCount);
        if (limitMsg === null){
            let result = await modalFriendRequest(local, user, isDarkTheme);
            if (result === true){
                let newUser = await getUser();
                this.props.appActions.setUser({ user: newUser })
                openNotificationWithIcon('info', local.appTitle, local.Success);
            }
            if (result === false)
                openNotificationWithIcon('info', local.appTitle, local.Fail);
        } else {
            openNotificationWithIcon("info", local.AppName, limitMsg);
        }
    }

    async clickFriend(friend){
        let local = this.props.appState.local;
        let result = false;
        switch (friend.status){
            case FriendStatus.Incoming:{
                result = await modalConfirm(
                    local.UserInfoFriends, local.RelationsIncoming,
                    local.RelationsIncomingAccept, local.RelationsIncomingReject);
                if (result === true){
                    result = await updateUserRelations(friend.name, 'incoming', ApiActions.Put);
                } else {
                    result = await updateUserRelations(friend.name, 'incoming', ApiActions.Delete);
                }
                if (result === true){
                    let newUser = await getUser();
                    this.props.appActions.setUser({ user: newUser })
                    openNotificationWithIcon('info', local.appTitle, local.Success);
                }
                else
                    openNotificationWithIcon('info', local.appTitle, local.Fail);
                break;
            }
            case FriendStatus.Outgoing:{
                result = await modalConfirm(
                    local.UserInfoFriends, local.RelationsOutgoingCancel,
                    local.Yes, local.No);
                if (result === true){
                    result = await updateUserRelations(friend.name, 'outgoing', ApiActions.Delete);
                    if (result === true){
                        let newUser = await getUser();
                        this.props.appActions.setUser({ user: newUser })
                        openNotificationWithIcon('info', local.appTitle, local.Success);
                    }
                    else
                        openNotificationWithIcon('info', local.appTitle, local.Fail);
                }
                break;
            }
            default:{
                result = await modalConfirm(
                    local.UserInfoFriends, local.RelationsUserFriendDelete,
                    local.Yes, local.No);
                if (result === true){
                    result = await updateUserRelations(friend.name, 'friends', ApiActions.Delete);
                    if (result === true){
                        let newUser = await getUser();
                        this.props.appActions.setUser({ user: newUser })
                        openNotificationWithIcon('info', local.appTitle, local.Success);
                    }
                    else
                        openNotificationWithIcon('info', local.appTitle, local.Fail);
                }
                break;
            }
        }
    }

    async loadPicture(name){
        let data = await userPicture({login: name}, '', ApiActions.Get);
        if (data !== false){
            let state = this.state;
            state[name] = data;
            this.setState(state);
        }
    }

    render() {
        var tab = this.props.appState.activeTab;
        let user = this.props.appState.user;
        let local = this.props.appState.local;

        var friends = getAllFriends(user);

        return (
            <div style={{display: tab === 5 ? "block" : "none"}}>
                <div className="SettingsFriends">
                    <button 
                        style={{margin: "10px 0px", width: "100%", height:"45px"}}
                        onClick={(event) => this.sendRequest(event)}>
                        {local.RelationsSendRequest}
                    </button>
                    <div style={{display: friends.length > 0 ? 'block' : 'none'}}>
                        <div className='SettingsFriends__Friends' 
                            style={{borderColor: deviceColors.borderBlue}}>
                        {
                            friends.map((el, i) => {
                                if (this.state[el.name] === undefined) {
                                    this.state[el.name] = null;
                                    if (el.status !== FriendStatus.Outgoing)
                                        this.loadPicture(el.name);
                                }
                                return <div 
                                    key={`friend${i}`} 
                                    className='SettingsFriends__Friend'
                                    onClick={this.clickFriend.bind(this, el)}>
                                    {
                                        this.state[el.name] === null ? <div className='SettingsFriends__Friend__Mock' /> :
                                        <img src={this.state[el.name]} alt='' />
                                    }
                                    <div className='SettingsFriends__Friend__Info'>
                                        <p className='SettingsFriends__Friend__Name'>
                                            {el.name}
                                        </p>
                                        <p className='SettingsFriends__Friend__Count'>
                                            {getFriendDevicesTitle(local, user.relations, el.name)}
                                        </p>
                                    </div>
                                    <p className='SettingsFriends__Friend__Status'>
                                        {getFriendStatusTitle(local, el.status)}
                                    </p>
                                </div>;
                            })
                        }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(SettingsFriends);