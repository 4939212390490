import React, {useState, useEffect} from "react";
import ReactDOM from "react-dom";
import './modalHistory.css';

import { LineChart, Line, Tooltip, YAxis } from 'recharts';

import { getHistory } from '../../helpers/helperApi';
import { deviceColors } from "../../helpers/constants";
import { sensorName, sensorDemension } from '../../helpers/helperSensor';
import { deviceGetHistorySensors } from '../../helpers/helperDevice';
import { deviceFullName, parseFloatValue } from '../../helpers/funcs';

import { DatePicker } from 'antd';
import moment from 'moment';
const format = 'DD.MM.yyyy';

const CustomTooltip = ({ active, payload, label }, isDark) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;
        return (
            <div className="custom-tooltip">
                <p className="label">{data.date.format("DD.MM.YY HH:mm")}</p>
                <p className="desc">{data.unit === undefined 
                    ? `${data.value}` 
                    : `${data.value}, ${data.unit}`}</p>
            </div>
        );
    }
    return null;
};

function ModalHistory({ resolve, local, user, device, isDark }) {
    const sensors = deviceGetHistorySensors(device);

    const [changed, setChanged] = useState(true);
    const [startDate, setStartDate] = useState(moment().add(-1, 'day'));
    const [endDate, setEndDate] = useState(moment());
    const [sensor, setSensor] = useState(sensors[0]);
    const [data, setData] = useState(null);

    useEffect(() => {
        async function fetchData() {
            let result = await getHistory(sensor, startDate, endDate);
            for(let f in result){
                result[f] = parseFloatValue(sensor, result[f]);
            }
            var formatedData = [];
            for(let f in result){
                formatedData.push({
                    date: moment.utc(f),
                    value: result[f],
                    unit: sensorDemension(sensor, local)
                })
            }
    
            setChanged(false);
            setData(formatedData);
        }
        if (changed === false) return;

        fetchData();
    })

    function clickOK() {
        removeDialog(device.guid);
        resolve(true);
    }
    return (
        <div id="modal__history__container_dialog" 
            className='modal__history__container_dialog'
            style={{
                borderColor: deviceColors.borderBlue,
                backgroundColor: isDark 
                    ? deviceColors.backGroundDark 
                    : deviceColors.backGroundLight}}>
            <p>{`${local.DeviceInfoChartsTitle} ${deviceFullName(device, local)}`}</p>
            
            <div className='modal__history__container_time'>
                <DatePicker
                    value={startDate}
                    allowClear={false}
                    showNow={false}
                    format={format}
                    onChange={(e) => {setStartDate(e); setChanged(true); }} />
                <p>{'->'}</p>
                <DatePicker
                    value={endDate}
                    allowClear={false}
                    showNow={false}
                    format={format}
                    onChange={(e) => {setEndDate(e); setChanged(true); }} />
            </div>
            <select required={true} 
                defaultValue={sensor}
                onChange={(e) => {setSensor(sensors[e.target.value]); setChanged(true); }}>
                {
                    sensors.map((el, i)=>{
                        return <option value={i} key={"historySensor"+i+el.guid}>
                            {sensorName(user, el, local)}
                    </option>;
                })}
            </select>
            {
                data === null ? null :
                <LineChart width={500} height={400} data={data}>
                    <YAxis type='number' domain={['auto', 'auto']} />
                    <Tooltip content={<CustomTooltip />}
                        cursor={{ stroke: deviceColors.borderOrange, strokeWidth: 1 }} />
                    <Line 
                        dot={false} 
                        type="monotone" dataKey="value" 
                        strokeWidth='3'
                        strokeOpacity='0.8'
                        stroke={deviceColors.borderBlue} />
                </LineChart>
            }
            <div className='modal__history__container_dialog__buttons'>
                <button onClick={clickOK}>{local.OK}</button>
            </div>
        </div>
    );
}

export default function modalDeviceHistory(local, user, device, isDark = true) {
    return new Promise((resolve, reject) => {
        addDialog(local, user, device, isDark, resolve);
    });
}

function addDialog(local, user, device, isDark, resolve) {
    const body = document.getElementById("Vku_App");
    const div = document.createElement("div");
    div.setAttribute("id", "modal__history__container"+device.guid);
    div.setAttribute("className", "modal__history__container");
    body.appendChild(div);
    ReactDOM.render(
        <ModalHistory local={local} user={user} device={device} isDark={isDark} resolve={resolve} />,
        div
    );
}

function removeDialog(id) {
    const div = document.getElementById("modal__history__container"+id);
    const body = document.getElementById("Vku_App");
    body.removeChild(div);
}