import React, {useState} from "react";
import ReactDOM from "react-dom";
import './modalConfirm.css';

import { devicePicture } from '../../helpers/helperDevice';
import { deviceFullName } from '../../helpers/funcs';
import { deviceColors } from "../../helpers/constants";

function ModalConfirm({ resolve, local, user, device, isDark }) {
    const [name, setName] = useState(deviceFullName(device, local));
    function clickOK() {
        removeDialog(device.guid);
        if (device.settings.name !== name){
            device.settings.name = name;
            resolve(true);
        } else {
            resolve(false);
        }
    }
    return (
        <div id="modal__confirm__container_dialog" 
            className='modal__confirm__container_dialog'
            style={{
                borderColor: deviceColors.borderBlue,
                backgroundColor: isDark 
                    ? deviceColors.backGroundDark 
                    : deviceColors.backGroundLight}}>
            <p>{local.DeviceNewTitle}</p>
            <p>{local.DeviceNewNameHelp}</p>
            <input className="ViewEditDevice__Name"
                value={name}
                onChange={(e)=> setName(e.target.value)}
                style={{height:"45px"}}
                placeholder={local.ScenarioCreateNewButtonName}/>
            <div className='modal__confirm__container_dialog__img' 
                style={{display:devicePicture(user, device)===null?'none':'block'}}>
                <img src={devicePicture(user, device)} alt='' />
            </div>
            <div className='modal__confirm__container_dialog__buttons'>
                <button onClick={clickOK}>{local.OK}</button>
            </div>
        </div>
    );
}

export default function deviceName(local, user, device, isDark = true) {
    return new Promise((resolve, reject) => {
        addDialog(local, user, device, isDark, resolve);
    });
}

function addDialog(local, user, device, isDark, resolve) {
    const body = document.getElementById("Vku_App");
    const div = document.createElement("div");
    div.setAttribute("id", "modal__confirm__container"+device.guid);
    div.setAttribute("className", "modal__confirm__container");
    body.appendChild(div);
    ReactDOM.render(
        <ModalConfirm local={local} user={user} device={device} isDark={isDark} resolve={resolve} />,
        div
    );
}

function removeDialog(id) {
    const div = document.getElementById("modal__confirm__container"+id);
    const body = document.getElementById("Vku_App");
    body.removeChild(div);
}