import { Component } from 'react';

import { deviceColors, dashFilterDefault } from '../../../helpers/constants';

import * as appActions from '../../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class RoomsFilter extends Component {
    constructor(props){
        super(props);

        this.setActiveTab = this.setActiveRoom;
        this.onMouseOut = this.onMouseOut.bind(this);

        this.state = {
            hovered: null
        }
    }

    setActiveRoom(guid){
        this.props.appActions.setDashFilterModel({model: dashFilterDefault});
        this.props.appActions.setDashFilterRoom({room: guid});
    }
    
    onMouseOver(guid) {
        this.setState({ hovered: guid });
    }

    onMouseOut() {
        this.setState({ hovered: null });
    }

    render() {
        let dashRoomSelected = this.props.appState.dashRoomFilter;
        let isDark = this.props.appState.isDarkTheme;

        let borderColorOff = isDark 
            ? deviceColors.borderGreyDark 
            : deviceColors.borderGreyLight;
        
        return (
            <div className="RoomsFilter">
                {
                    this.props.rooms.map((el, index) => {
                        return <div
                            onClick={this.setActiveRoom.bind(this, el.guid)}
                            className='RoomsFilter__room_name'
                            onMouseOver={this.onMouseOver.bind(this, el.guid)}
                            onMouseOut={this.onMouseOut}
                            style={{
                                borderColor: dashRoomSelected === el.guid || this.state.hovered === el.guid
                                    ? deviceColors.borderBlue 
                                    : borderColorOff,
                                color: dashRoomSelected === el.guid || this.state.hovered === el.guid
                                    ? deviceColors.borderBlue 
                                    : borderColorOff
                            }}>
                                {el.name}
                        </div>;
                    })
                }
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(RoomsFilter);