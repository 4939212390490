import React, { Component } from 'react';

import ConfigProvider from 'antd/lib/config-provider';
import ruRu from 'antd/lib/locale-provider/ru_RU';
import enEn from 'antd/lib/locale-provider/en_US';

import VkuApp from './App.js';
import Cookies from 'js-cookie';

import { CheckDev } from './helpers/constants';

import * as appActions from './actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class LocalProviderWrapper extends Component {
    constructor(props){
        super(props);

        if (CheckDev() === false && window.location.protocol !== 'https:') {
            window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
        }
        
        let lang = Cookies.get('Language') ?? 'AppLanguageEn';
        if (lang === 'AppLanguageEn')
            this.props.appActions.setLocal({local: 'enEn'})
        if (lang === 'AppLanguageRu')
            this.props.appActions.setLocal({local: 'ruRu'})

        let theme = Cookies.get('Theme') ?? 'ThemeControlled';
        if (theme === 'ThemeLight')
            this.props.appActions.setTheme({isDarkTheme: false})
        if (theme === 'ThemeDark')
            this.props.appActions.setTheme({isDarkTheme: true})
        if (theme === 'ThemeControlled'){
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                this.props.appActions.setTheme({isDarkTheme: true})
            } else {
                this.props.appActions.setTheme({isDarkTheme: false})
            }
        }

        let appHome = Cookies.get('AppHome') ?? 'AppHomeRooms';
        if (appHome === 'AppHomeRooms')
            this.props.appActions.setActiveTab({activeTab: 2})
        if (appHome === 'AppHomeDash')
            this.props.appActions.setActiveTab({activeTab: 1})

        let showSummaryVoltage = Cookies.get('ShowSummaryVoltage') ?? 'true';
        this.props.appState.settings.showSummaryVoltage = showSummaryVoltage === 'true';

        let showCurrentNotifications = Cookies.get('ShowCurrentNotifications') ?? 'true';
        this.props.appState.settings.showCurrentNotifications = showCurrentNotifications === 'true';
    }

    render() {
        var local = this.props.appState.local;
        var providerLocal = ruRu;
        if (local.name === 'enUS'){
            providerLocal = enEn;
        }

        return (
            <ConfigProvider snowyButton={false} locale={providerLocal}>
                <VkuApp />
            </ConfigProvider>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(LocalProviderWrapper);

