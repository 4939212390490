import './settings.css';
import { Component } from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import SettingsEquipment from './settingsEquipment/settingsEquipment';
import SettingsButtons from './settingsButtons/settingsButtons';
import SettingsTimers from './settingsTimers/settingsTimers';
import SettingsLocations from './settingsLocations/settingsLocations';
import SettingsApp from './settingsApp/settingsApp';
import SettingsAccount from './settingsAccount/settingsAccount';
import SettingsPromaSat from './settingsPromaSat/settingsPromaSat';
import SettingsFriends from './settingsFriends/settingsFriends';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class Settings extends Component {
    render() {
        var tab = this.props.appState.activeTab;
        var user = this.props.appState.user;
        if (user === undefined || user === null) return null;
        var local = this.props.appState.local;

        return (
            <div style={{ display: tab === 5 ? "block" : "none" }}>
                <div className="Settings">
                    <Tabs forceRenderTabPanel defaultIndex={this.props.appState.settingsDefTab}>
                        <TabList>
                            <Tab>{local.MenuEquipment}</Tab>
                            <Tab>{local.TimerButtonsTitle}</Tab>
                            <Tab>{local.ButtonButtonsTitle}</Tab>
                            <Tab>{local.PlacesTitle}</Tab>
                            <Tab>{local.UserInfoTitle}</Tab>
                            <Tab>{local.PromaSatTitle}</Tab>
                            <Tab>{local.RelationsFriends}</Tab>
                            <Tab>{local.AppSettings}</Tab>
                        </TabList>
                        <TabPanel>
                            <SettingsEquipment />
                        </TabPanel>
                        <TabPanel>
                            <SettingsTimers />
                        </TabPanel>
                        <TabPanel>
                            <SettingsButtons />
                        </TabPanel>
                        <TabPanel>
                            <SettingsLocations />
                        </TabPanel>
                        <TabPanel>
                            <SettingsAccount />
                        </TabPanel>
                        <TabPanel>
                            <SettingsPromaSat />
                        </TabPanel>
                        <TabPanel>
                            <SettingsFriends />
                        </TabPanel>
                        <TabPanel>
                            <SettingsApp />
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(Settings);