import './viewRoomsSelector.css';
import { Component } from 'react';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

import ViewRoom from '../viewRoom/viewRoom';
import { sleep } from '../../helpers/funcs';

import { ApiActions } from '../../helpers/constants';
import { openNotificationWithIcon } from '../../helpers/funcs';
import { getUser, updateRoom } from '../../helpers/helperApi';

import * as appActions from '../../actions/appActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class ViewRoomsSelector extends Component {
    constructor(props){
        super(props);

        this.save = this.save.bind(this);
        this.close = props.close;
        
        this.state = {
            selectedindex: 0
        };
    }

    async save(){
        let owner = this;
        let local = this.props.appState.local;
        let user = owner.props.appState.user;
        let room = user.rooms[owner.state.selectedindex];
        let device = owner.props.device;
        if (!room.devices.includes(device.guid)){
            room.devices.push(device.guid);

            var result = await updateRoom(user, room, ApiActions.Put);
            if (result === true){
                let newUser = await getUser();
                owner.props.appActions.setUser({ user: newUser });
                owner.close();
                openNotificationWithIcon("info", local.Save, local.Success);
            } else {
                openNotificationWithIcon("info", local.Save, local.Fail);
            }
        }
    }

    render() {
        let local = this.props.appState.local;
        let user = this.props.appState.user;
        
        return (
            <div className="viewRoomsSelector">
                <p>
                    {local.LocationRoomsName}
                </p>
                <div className="viewRoomsSelector__Rooms">
                    <Carousel 
                        infiniteLoop={true}
                        showStatus={false}
                        showThumbs={false}
                        onChange={(i)=>this.setState({selectedindex: i})}>
                        {
                            user.rooms.map((el, i)=>
                                <ViewRoom key={"roomSelector"+el.guid} room={el} clickDisabled={true}/>)
                        }
                    </Carousel>
                </div>
                <button
                    style={{margin: "10px 0px", height:"45px"}}
                    onClick={this.save}>
                    {local.Save}
                </button>
            </div>
        );
    }
}

function mapStateToProps(state) { return { appState: state.appState } }
function mapDispatchToProps(dispatch) { return { appActions: bindActionCreators(appActions, dispatch) } }
export default connect(mapStateToProps, mapDispatchToProps)(ViewRoomsSelector);